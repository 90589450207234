<template>
  <div>
    <div
        :style="{
        'border-radius': '10px',
        border: '1px solid black',
        padding: '10px',
        'background-color': '#f9f9f9',
        'text-align': 'center',
        width: '300px'
      }"
        class="img_component"
    >
      <a>
        <b-image
            :src="imageUrl === '' ? imagePlaceholder : imageUrl"
            placeholder="/static/img/icons/favicon-32x32.png"
            responsive
            class="mb-2 doc_image"
        ></b-image>
      </a>

      <p class="image-line mb-3" v-if="imageUrl === ''">
        <span>
          <b-icon icon="white-balance-sunny" size="is-small"/>
          Ensure Good Lighting in the Room
        </span>
      </p>
      <b-button type="is-primary" rounded @click="openCaptureModal">
        {{ captureButtonLabel }}
      </b-button
      >
    </div>

    <b-modal
        v-model="captureModalIsVisible"
        has-modal-card
        full-screen
        :can-cancel="false"
    >
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body" style="padding: 0">
          <div class="columns">
            <div class="column"></div>
            <div class="column is-three-fifths">
              <div class="web-camera-container">
                <div v-show="showInstructionAnimate">
                  <img :src=imageInstrcut width="500" />
                </div>
                <div v-show="isCameraOpen && isLoading" class="camera-loading">
                  <ul class="loader-circle">
                    <li></li>
                    <li></li>
                    <li></li>
                  </ul>
                </div>

                <div
                    v-if="isCameraOpen"
                    v-show="!isLoading"
                    class="camera-box"
                    :class="{ flash: isShotPhoto }"
                >
                  <div
                      class="camera-shutter"
                      :class="{ flash: isShotPhoto }"
                  ></div>

                  <video
                      v-show="!isPhotoTaken"
                      ref="camera"
                      :width="450"
                      :height="337.5"
                      autoplay
                      class="camera-video"
                  ></video>
                  <canvas
                      v-show="isPhotoTaken"
                      id="photoTaken"
                      ref="canvas"
                  ></canvas>
                </div>
              </div>
            </div>
            <div class="column"></div>
          </div>
        </section>

        <footer class="modal-card-foot">
          <div id="video-call-div1"></div>
          <b-button
              v-if="isPhotoTaken"
              label="Done"
              class="is-rounded"
              type="is-info"
              @click="finishImageCapture"
          ></b-button>


          <b-button
              v-if="isPhotoTaken"
              label="Recapture"
              class="is-rounded"
              type="is-primary"
              @click="takePhoto"
          ></b-button>
          <b-button
              v-if="!isPhotoTaken"
              label="Capture"
              class="is-rounded"
              :disabled="recording"
              type="is-primary"
              @click="takePhoto"
          ></b-button>
          <b-button
              v-if="!isPhotoTaken"
              label="Back"
              class="is-rounded"
              type="is-info"
              @click="backToDocumentPage"
          ></b-button>
        </footer>
      </div>
    </b-modal>

    <b-modal
        v-model="cropModalIsVisible"
        has-modal-card
        full-screen
        :can-cancel="false"
    >
      <div class="modal-card" style="width: auto">
        <section class="modal-card-body" style="padding: 0">
          <div class="columns">
            <div class="column"></div>
            <div class="column is-three-fifths">
              <div class="web-camera-container">
                <div class="img-cropper">
                  <vue-cropper
                      ref="cropper"
                      :aspect-ratio="null"
                      :src="imageToCrop"
                      :minScale="0.5"
                      :guides="true"
                      :view-mode="3"
                      preview=".preview"
                  />
                </div>
              </div>
            </div>
            <div class="column"></div>
          </div>
        </section>

        <footer class="modal-card-foot">
          <div id="video-call-div1"></div>
          <b-button
              label="Done"
              type="is-primary"
              class="is-rounded"
              @click="cropImage"
          ></b-button>
          <b-button
              label="Cancel"
              type="is-info"
              class="is-rounded"
              @click="cropModalIsVisible = false"
          ></b-button>
        </footer>
      </div>
    </b-modal>


    <!-- liveness feedback -->
    <b-modal
        v-model="livenessModeIsVisible"
        has-modal-card
        :can-cancel="false"
    >
      <div class="modal-card" style="width: auto;">
        <section class="modal-card-body" style="padding: 5vw">

          <div class="liveness-pop-up-wrapper" v-if="livenessResult === null">
            <b-image
                :src="loader"
                responsive
                class="loader-image"
            />

            <b-notification :closable="false">
              <div style="padding: 10px">
                Verifying Your Face
              </div>
            </b-notification>
          </div>

          <b-notification  type="is-danger is-light" :closable="false" v-if="!isLivenessSuccess && livenessResult">
            Face Verification Failed!
            <br>
            <b-button type="is-primary" rounded @click="handleRecapture" style="margin-top: 15px">
              Capture Again
            </b-button>
          </b-notification>


          <b-notification  type="is-danger is-light" :closable="false" v-if="isLivenessCheckError">
            Unable to Verify Your Face
            <br>
            <b-button type="is-primary" rounded @click="handleRecapture" style="margin-top: 15px">
              Capture Again
            </b-button>
          </b-notification>

        </section>
      </div>
    </b-modal>

  </div>
</template>


<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import {Attachment} from "@/heplers/classes/attachment/attachment";
import {SelfieAttachment} from "@/heplers/classes/attachment/selfieAttachment/selfieAttachment";
import {LivenessResult} from "@/heplers/classes/attachment/selfieAttachment/livenessResult";
import networkManager from "@/utils/networkManager";

export default {
  name: "SelfieCaptureComponent",

  components: {VueCropper},
  props : {
    fileName : {
      type: String,
      required : true,
    },
    selectedImageUrl :{
      type: String,
      required : true,
    },
    captureButtonLabel : {
      type : String,
      required : false,
      default : "Capture"
    },
    useFrontCamera: {
      type: Boolean,
      required : false,
      default : true
    },
    verifyLiveness: {
      type: Boolean,
      required : false,
      default : true
    }
  },
  emits: ["on-captured"],

  data() {
    return {
      imageUrl: "",
      isActive: false,
      captureModalIsVisible: false,
      cropModalIsVisible: false,
      imagePlaceholder: require("@/assets/images/icons/selfieVerifierPlaceholder.png"),
      pdfPlaceholder: require("@/assets/images/icons/pdf.png"),
      file: null,
      imageToCrop: null,
      croppedImg: null,
      requestFrontCamera:false,

      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      imagewidth: null,
      imageheight:null,
      link: "#",
      base64String: "",

      // liveness check related variables
      livenessModeIsVisible: false,
      livenessResult : null,
      isLivenessSuccess: false,
      isLivenessCheckError: false,
      loader: require("@/assets/images/icons/loader.gif"),

      recording: true,
      imageInstrcut: require("@/assets/images/instruct.gif"),
      capturedVideo : null,
      capturedVideoBlob: null,
      showInstructionAnimate:true
    };
  },

  computed: {

  },

  methods: {

    backToDocumentPage: function () {
      this.toggleCamera();
      this.captureModalIsVisible = false;
    },

    clearLivenessCheckResults(){
      const comp = this
      comp.livenessResult = null
      comp.isLivenessSuccess = false
    },

    finishImageCapture: function () {
      this.toggleCamera();
      this.isActive = this.captureModalIsVisible = false;
      this.clearLivenessCheckResults()
      this.initiateImageCrop();
    },


    openCaptureModal: function () {
      this.captureModalIsVisible = true;

      // if(!this.showInstructionAnimate){
      //   this.initiateImageCapture()
      // }else{
      this.showInstructionAnimate = true
      this.recording = true;
        setTimeout(() => {
        this.initiateImageCapture()
        this.showInstructionAnimate = false
      }, 5000);
      // }
    },

    initiateImageCapture: function () {

      this.toggleCamera();
    },

    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },

    async startRecording() {
      try {
        let mediaRecorder;
        let recordedChunks = []

        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });

        if (this.$refs.camera) {
          this.$refs.camera.srcObject = stream;
          mediaRecorder = new MediaRecorder(stream);

          mediaRecorder.ondataavailable = event => {
            recordedChunks.push(event.data);
          };

          mediaRecorder.onstop = () => {
              const blob = new Blob(recordedChunks, { type: 'video/mp4' });
              const url = URL.createObjectURL(blob);
              console.log(url); // Base64 URL
              recordedChunks = [];

              const reader = new FileReader();
              reader.onload = () =>{
                console.log(reader)
                const base64String = reader.result
                this.capturedVideo = base64String.split(',')[1]
                this.capturedVideoBlob = blob;

                console.log(base64String);
                this.$emit('on-recorded', base64String);
              }

              reader.onerror = (error) => {
                console.error("Error reading recorded blob: ", error);
              };

              reader.readAsDataURL(blob)
          };


          mediaRecorder.start();

          setTimeout(() => {
            mediaRecorder.stop()
            this.recording =false
          }, 5000);

        }
      } catch (error) {
        console.error("Error accessing user media: ", error);
      }
    },

    createCameraElement() {
      this.isLoading = true;

      const constraints = (window.constraints = {
        audio: false,
        // video: true,
        video: {
          facingMode: this.requestFrontCamera ? 'user' : 'environment',
          width : {ideal: 1280},
          height: { ideal: 720 },
          frameRate: { ideal:  30, max:  35 },
          // deviceId: {
          //   exact: "a379ef419e0cb30d2f51f97348fc7a01f4ef0e9372b101dac8bfb6c9570d0e44",
          // }
        }
      });

      navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.isLoading = false;
            this.$refs.camera.srcObject = stream;
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            alert("May the browser didn't support or there is some errors.");
          });

          this.startRecording()
    },


    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();

      tracks.forEach((track) => {
        track.stop();
      });
    },


    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;

        const FLASH_TIMEOUT = 50;

        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }

      this.isPhotoTaken = !this.isPhotoTaken;

      const context = this.$refs.canvas.getContext("2d");
      context.webkitImageSmoothingEnabled = false;
      context.mozImageSmoothingEnabled = false;
      context.imageSmoothingEnabled = false;

      // set canvas width and height based on video stream width and height
      this.$refs.canvas.width = this.$refs.camera.videoWidth
      this.$refs.canvas.height = this.$refs.camera.videoHeight

      //set image width and height based on video stream with and height
      context.drawImage(this.$refs.camera, 0, 0, this.$refs.camera.videoWidth, this.$refs.camera.videoHeight);

      this.imageToCrop = document
          .getElementById("photoTaken")
          .toDataURL("image/png");
    },


    downloadImage(file, fileName) {

      let url = window.URL.createObjectURL(file);
      let elm = document.createElement("a");
      document.body.appendChild(elm);
      elm.href = url;
      elm.download = fileName;
      elm.click();
      window.URL.revokeObjectURL(url)
    },

    initiateImageCrop: function () {
      this.cropModalIsVisible = true;
    },

    cropImage: async function () {
      const croppedImageCanvas = this.$refs.cropper.getCroppedCanvas();
      this.croppedImg = croppedImageCanvas.toDataURL();
      this.cropModalIsVisible = false;
      this.imageUrl = this.croppedImg;

      console.log("Waiting for file generation...")
      const croppedImageFile = await this.createFileFromCanvas(croppedImageCanvas)

      console.log("After file generated...")
      console.log("Request liveness check : ",this.verifyLiveness)

      if(this.verifyLiveness){
        // let isVerified = await this.checkLiveness(croppedImageFile)
        let isVerified = await this.checkSelfieLiveness(this.capturedVideoBlob)

        if(isVerified){
          // emit result
          this.emitCroppedImage(croppedImageFile)
        }
      }
    },

    emitCroppedImage(file) {
      const comp = this

      let attachment = null;
      let selfieAttachment = null;
      let livenessResult = null;

      livenessResult = new LivenessResult(comp.livenessResult.result, comp.livenessResult.score)
      attachment = new Attachment(file, "image");
      selfieAttachment = new SelfieAttachment(attachment, livenessResult, comp.capturedVideo)

      this.$emit("on-captured", selfieAttachment); // test code
    },

    /**
     * Use to convert canvas to blob
     * @param canvas
     * @returns {Promise<unknown>}
     */
    createFileFromCanvas(canvas){
      return new Promise(resolve => {
        let fileName = this.fileName + ".jpg";

        canvas.toBlob((blob) => {
          let file = new File([blob], fileName, {type: "image/jpeg"});
          resolve(file);
        }, "image/jpeg");
      })
    },

    handleRecapture(){

      this.livenessModeIsVisible = false;
      this.livenessResult = null;
      this.isLivenessSuccess = false;
      this.isLivenessCheckError = false;
      this.captureModalIsVisible = true
      this.initiateImageCapture()
    },

    checkSelfieLiveness(videoBlob){

      const comp = this
      comp.livenessModeIsVisible = true;

      let videoFile = new File([videoBlob],"liveness_video.mp4", {type:"video/mp4"});
      let formData = new FormData();
      formData.append("video", videoFile);

      return new Promise(resolve => {
        networkManager.apiRequest("api/Cognitive/huawei/faceLiveness", formData, (response)=>{
          console.log("Huawei face liveness result : status code = "+response.statusCode)

          if(response.statusCode === 200){

            comp.livenessResult =  {
              score : response.data.score,
              result : response.data.result
            };

            comp.isLivenessSuccess = response.data.result === "real";

            console.log("Liveness result (custom) ---------", comp.livenessResult)

            if(!comp.isLivenessSuccess){
              comp.clearLivenessData()
              console.warn("Liveness verification unsuccessful")
              resolve(false)

            }
            else{
              console.log("Liveness verification successful")
              comp.livenessModeIsVisible = false;
              resolve(true)
            }

          }
          else
          {
            // error response
            console.warn("Liveness verification error response", response)
            comp.isLivenessCheckError = true;
            comp.clearLivenessData()
            resolve(false)
          }

        })

      }, false, false)
    },

    clearLivenessData(){
      const comp = this
      comp.$emit("on-captured", null);
      comp.imageUrl = "";
      comp.capturedVideoBlob = null;
    },

    setRequestFrontCamera(){
      const comp = this
      console.log("use Front camera prop : "+typeof comp.useFrontCamera)
      switch (typeof comp.useFrontCamera){
        case 'boolean':
          comp.requestFrontCamera = comp.useFrontCamera
          break
        default:
          comp.requestFrontCamera = false
      }
    },

  },



  watch : {
    "imageUrl":function(){
      console.log("image url changed : " + this.imageUrl)
    }
  },




  mounted() {
    console.log("Selected image URL : " + this.selectedImageUrl);
    this.imageUrl = this.selectedImageUrl == null ? "" : this.selectedImageUrl;
    this.capturedVideoBlob = null;
    this.capturedVideo = null;

    this.setRequestFrontCamera()
  },

}
</script>

<style scoped>
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.web-camera-container .camera-button {
  margin-bottom: 2rem;
}

.web-camera-container .camera-box .camera-shutter {
  opacity: 0;
  width: 90%;
  height: auto;
  background-color: #fff;
  position: absolute;
}

.web-camera-container .camera-box .camera-shutter.flash {
  opacity: 1;
}

.web-camera-container .camera-shoot {
  margin: 1rem 0;
}

.web-camera-container .camera-shoot button {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.web-camera-container .camera-shoot button img {
  height: 35px;
  object-fit: cover;
}

.web-camera-container .camera-loading {
  overflow: hidden;
  height: 100%;
  position: absolute;
  width: 100%;
  min-height: 150px;
  margin: 3rem 0 0 -1.2rem;
}

.web-camera-container .camera-loading ul {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 999999;
  margin: 0;
}

.web-camera-container .camera-loading .loader-circle {
  display: block;
  height: 14px;
  margin: 0 auto;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  transform: translateX(-50%);
  position: absolute;
  width: 100%;
  padding: 0;
}

.web-camera-container .camera-loading .loader-circle li {
  display: block;
  float: left;
  width: 10px;
  height: 10px;
  line-height: 10px;
  padding: 0;
  position: relative;
  margin: 0 0 0 4px;
  background: #999;
  animation: preload 1s infinite;
  top: -50%;
  border-radius: 100%;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(2) {
  animation-delay: 0.2s;
}

.web-camera-container .camera-loading .loader-circle li:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes preload {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}

/* cropper styles */

.crop-content {
  display: flex;
  justify-content: space-between;
}

.cropper-area {
  width: 614px;
}

.actions {
  margin-top: 1rem;
}

.actions a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}

.cropped-image img {
  max-width: 100%;
}

.modal-card {
  border-radius: 0;
}

.loader-image{
  max-width: 100px;
}

.liveness-pop-up-wrapper{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.wrapper{
  width: 300px;
}

.camera-video{
  transform: scaleX(-1);
}
</style>
