export default function WebRtcFrontendInterface(url, loggedInUser, connectingAgent, iceServers){
    /* eslint-disable no-unused-vars */
    /* eslint-disable no-case-declarations */


    console.log("url")
    console.log(url)
    let webRtcConn = null
    let peerConn = null
    let isStreaming = false
    let cameraFacingMode = 'user'

    let geoLocation = {
        lat: null,
        long: null
    }

    let ice_servers = iceServers
    let _localVideo = null
    let _remoteVideo = null

    class SendingEvents {
        SEND_ANSWER = 0xfa03
        SEND_CANDIDATE = 0xfa04
        JOIN_CALL = 0xfa05
        CUSTOMER_CONNECTED = 0xfa07
        CALL_ENDED = 0xfa10
    }

    class ReceivingEvents {
        CONNECTED = 0xfb01
        ERROR = 0xfb02
        GET_CALL = 0xfb04
        CANDIDATE = 0xfb05
        OFFER = 0xfb06
        CALL_ENDED = 0xfb08
        AGENT_BUSY = 0xfb09
        CALL_RECORD_START=0xfb10
        CALL_RECORD_STOP=0xfb11
    }

    // Standard response class for socket communication
    class SocketResponse {
        constructor() {
            this.event = 0
            this.data = {}
        }
    }

    // Standard request class for socket communication
    class SocketRequest {
        event = 0
        data = {}

        constructor(json) {
            Object.assign(this, json)
        }
    }

    const _SendingEvents = new SendingEvents()
    const _ReceivingEvents = new ReceivingEvents()

    class CallBackResponse{
        constructor() {
            this.status = ""
            this.response = null
        }
    }

    let _connectedCallback = null
    let _callEndedCallback = null
    let _getCallCallback = null
    let _agentBusyCallback = null
    let _socketClosedCallback = null
    let _videoTrackReceivedCallback = null
    //ns
    let _recordStartCallback = null
    let _recordStopCallback = null
    //ns

    function peerConnOnTrack(event) {
        console.log("peerConn Track received")
        _remoteVideo.srcObject = event.streams[0]
        isStreaming = true

        _videoTrackReceivedCallback()
    }

    function peerConnOnCandidate(e) {
        if (e.candidate != null) {
            let resp = new SocketResponse()
            resp.event = _SendingEvents.SEND_CANDIDATE
            resp.data = {
                candidate: e.candidate
            }

            sendDataToSocket(resp)
        }
    }

    return {
        subscribe: function (connectedCallback, callEndedCallback, getCallCallback,
                             agentBusyCallback, socketClosedCallback, videoTrackReceivedCallback,recordStartCallback,recordStopCallback){
            _connectedCallback = connectedCallback
            _callEndedCallback = callEndedCallback
            _getCallCallback = getCallCallback
            _agentBusyCallback = agentBusyCallback
            _socketClosedCallback = socketClosedCallback
            _videoTrackReceivedCallback = videoTrackReceivedCallback
            _recordStartCallback=recordStartCallback
            _recordStopCallback=recordStopCallback
        },

        initiateSocketConnection: function (callback){
            let obj = new CallBackResponse()

            try {
                webRtcConn = new WebSocket(url)
                webRtcConn.onmessage = websocketOnMessage
                webRtcConn.onclose = webSocketOnClose

                webRtcConn.onopen = (e) => {
                    console.log('Socket opened')
                    obj.status = "success"
                    obj.response = "Socket connection successful"

                    callback(obj)
                }
            }
            catch (e){
                console.error('Socket connection failed!')
                console.error(e)

                obj.status = "failed"
                obj.response = "Socket connection failed"

                callback(obj)
            }
        },

        startCall: function (){

            let resp = new SocketResponse()
            resp.event = _SendingEvents.CUSTOMER_CONNECTED
            resp.data = {
                username: loggedInUser,
                agent: connectingAgent,
                location: geoLocation
            }

            sendDataToSocket(resp)
        },

        getGeoLocation: function (callback){
            let obj = new CallBackResponse()

            try {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((e) => {
                        geoLocation.lat = e.coords.latitude
                        geoLocation.long = e.coords.longitude

                        obj.status = "success"
                        obj.response = {
                            location: geoLocation
                        }

                        callback(obj)
                    })
                } else {
                    console.log("getGeolocation exception...")
                    obj.status = "failed"
                    obj.response = "Geolocation is not supported by the browser..."

                    callback(obj)
                }
            }
            catch (e) {
                console.error('Get geolocation failed!')
                console.error(e)

                obj.status = "failed"
                obj.response = "Get geolocation failed"

                callback(obj)
            }
        },

        joinCall: function (localVideo, remoteVideo, stream, callback) {
            try {
                console.log("Join call, Agent : " + connectingAgent)

                _localVideo = localVideo
                _remoteVideo = remoteVideo

                _localVideo.srcObject = stream
                console.log(ice_servers)

                let configuration = {
                    iceServers: ice_servers
                }

                peerConn = new RTCPeerConnection(configuration)

                stream.getTracks().forEach(function (track) {
                    try {
                        peerConn.addTrack(track, stream)
                    } catch (error) {
                        console.error(error)
                    }
                })

                peerConn.ontrack = peerConnOnTrack
                peerConn.onicecandidate = peerConnOnCandidate

                let resp = new SocketResponse()
                resp.event = _SendingEvents.JOIN_CALL
                resp.data = {
                    agent: connectingAgent,
                    username: loggedInUser
                }

                sendDataToSocket(resp)
                callback()
            }
            catch (e){
                console.log("Join call exception...")
                console.error(e)
            }
        },

        endCall: function (callback){
            console.log("ending call...")
            stopStreaming()
            let resp = new SocketResponse()
            resp.event = _SendingEvents.CALL_ENDED
            resp.data = {
                username: loggedInUser
            }

            sendDataToSocket(resp)
            callback()
        },

        changeCamera: function (navigator, callback){
            let resp = new CallBackResponse()

            _localVideo.srcObject.getTracks().forEach(t => {
                t.stop()
            })

            cameraFacingMode = cameraFacingMode === 'user' ? 'environment' : 'user'

            let defaultsOpts = {audio: true, video: {facingMode: cameraFacingMode}}
            navigator.mediaDevices.getUserMedia(defaultsOpts)
                .then(function (_stream) {

                    _localVideo.srcObject = _stream
                    _localVideo.play()

                    peerConn.getSenders().map(sender => sender.replaceTrack(_stream.getTracks().find(t => t.kind === sender.track.kind)))

                    resp.status = "success"
                    resp.response = ""
                    callback(resp)
                })

                .catch(function (err) {
                    console.error("Change camera exception...")
                    console.error(err)

                    resp.status = "failed"
                    resp.response = ""
                    callback(resp)
                })
        },

        muteAudio: function (){
            if(isStreaming) _localVideo.srcObject.getAudioTracks()[0].enabled = false
        },

        unmuteAudio: function (){
            if(isStreaming) _localVideo.srcObject.getAudioTracks()[0].enabled = true
        },

        pauseVideo: function (){
            if(isStreaming) _localVideo.srcObject.getVideoTracks()[0].enabled = false
        },

        resumeVideo: function (){
            if(isStreaming) _localVideo.srcObject.getVideoTracks()[0].enabled = true
        },

    }

    function websocketOnMessage(e) {
        try {
            const json = JSON.parse(e.data)
            const request = new SocketRequest(json)
            console.log(request)

            let resp = new CallBackResponse()

            switch (request.event) {
                case _ReceivingEvents.OFFER:
                    console.log("`````````````` OFFER ````````````````````")
                    peerConn.setRemoteDescription(request.data.offer)
                    peerConn.createAnswer((answer) => {
                        peerConn.setLocalDescription(answer)

                        let data = new SocketResponse()
                        data.event = _SendingEvents.SEND_ANSWER
                        data.data = {
                            username: loggedInUser,
                            answer: answer
                        }

                        sendDataToSocket(data)
                    }, error => {
                        console.error(error)
                    })
                    break

                case _ReceivingEvents.CANDIDATE:
                    console.log("`````````````` CANDIDATE ````````````````````")
                    peerConn.addIceCandidate(request.data.candidate)
                    break

                case _ReceivingEvents.CALL_ENDED:
                    console.log("`````````````` CALL_ENDED ````````````````````")
                    console.log('call ended')
                    resp.status = "success"
                    resp.response = {
                        reason: request.data.reason
                    }
                    _callEndedCallback(resp)

                    setTimeout(stopStreaming, 500)
                    break

                case _ReceivingEvents.CONNECTED:
                    console.log("`````````````` CONNECTED ````````````````````")
                    console.log("connected")


                    const message = request.data.message;
                    console.log(message)
                    _connectedCallback()

                    break

                case _ReceivingEvents.GET_CALL:
                    console.log("`````````````` GET_CALL ````````````````````")
                    const agent = request.data.username
                    resp.status = "success"
                    resp.response = {
                        agent: agent
                    }

                    _getCallCallback(resp)

                    break

                case _ReceivingEvents.AGENT_BUSY:
                    console.log("`````````````` AGENT_BUSY ````````````````````")
                    console.log('agent busy');
                    _agentBusyCallback()
                    break;
                //ns
                case _ReceivingEvents.CALL_RECORD_START:
                    console.log('record start111111');
                    _recordStartCallback()
                    break
                case _ReceivingEvents.CALL_RECORD_STOP:
                    console.log('record stop');
                    _recordStopCallback()
                    break
                //ns

            }
        }
        catch (e) {
            console.error("websocketOnMessage exception")
            console.error(e)
        }
    }

    function webSocketOnClose(e) {
        console.error("Socket connection closed...")
        console.error(url)

        let data = new CallBackResponse()
        data.status = "disconnected"
        data.response = "Socket connection closed..."

        _socketClosedCallback(data)
    }

    function sendDataToSocket(resp){
        resp.data.username = loggedInUser
        webRtcConn.send(JSON.stringify(resp))
    }

    function stopStreaming(){
        if(_localVideo != null){
            _localVideo.srcObject.getTracks().forEach((track) => {
                track.stop()
                track.enabled = false
            })
        }

        if(_remoteVideo != null){
            _remoteVideo.srcObject.getTracks().forEach((track) => {
                track.stop()
                track.enabled = false
            })
        }

        isStreaming = false
    }
}

