<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
    <logopage></logopage></transition>
    <transition appear @before-enter="$animations.beforeEnterFade" @enter="$animations.enterFade">
      <div class="columns is-centered mb-0" >
        <div class="column is-half ps-5" style="text-align: center">
          <p class="subhead-line is-size-4" style="line-height: 10px">
            <img :src=hiWave alt="hi-img" width="7%" />
            {{ $root.selectedLanguageData.hello}}
            <span class="ml-3" style="margin-left: 0.5rem !important">
              {{ kycFormStore.formData.calling_name || "Charith" }}
            </span>
          </p>
          <p class="headline-font">
            {{ $root.selectedLanguageData.start_line }}
          </p>
        </div>
      </div>
    </transition>
    <transition appear @before-enter="$animations.beforeEnterFade" @enter="$animations.enterFade" >
    <div style="display: flex; justify-content: center">
      <div
        style="border-bottom: 2px solid rgba(35, 31, 32, 0.79); opacity: 0.5"
        class="underline"
      ></div>
    </div></transition>
   
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <div class="columns is-centered">
          <div class="column is-half pt-5 ps-5">
            <transition appear @before-enter="$animations.beforeEnterBelow" @enter="$animations.enterBelow">

            <div class="box" style="padding: 0% 7% 5%">
              <p class="has-text-centered  pt-4 mb-4 astric-font-size">
                <span class="has-text-danger  mr-2">{{
                  $root.selectedLanguageData.note
                }}</span><b class="has-text-danger">*</b><span class="text-transparency">
               Indicates mandatory fields that are required</span>
              </p>
              <div>
                <label>
                  {{ $root.selectedLanguageData.are_you
                  }}<span class="has-text-danger">*</span></label
                >
                <b-field>
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns is-mobile two-column-parent">
                      <div class="column is-half-">
                        <b-radio
                          class="en-style"
                          :disabled="kycFormStore.formData.isChangeMobile || kycFormStore.formData.isChangeEmail"
                          v-model="kycFormStore.formData.citizenship"
                          name="nationality"
                          native-value="Sri Lankan" >
                          {{ $root.selectedLanguageData.srilankan }}
                        </b-radio>
                      </div>
                    </div>

                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </div>

              <div >
                <label>
                  {{ $root.selectedLanguageData.your_resident
                  }}<span class="has-text-danger">*</span></label
                >
                <b-field >
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns is-mobile two-column-parent">
                      <div class="column is-half">
                        <b-radio
                          class="en-style"
                          v-model="kycFormStore.formData.residentship"
                          :disabled="
                            kycFormStore.formData.isChangeMobile ||
                            kycFormStore.formData.isChangeEmail
                          "
                          name="resident"
                          @click.native="
                            () => {
                              resetValues();
                              residentshipSriLankaClicked();
                            }
                          "
                          native-value="Sri Lanka"
                        >
                          {{ $root.selectedLanguageData.srilanka }}
                        </b-radio>
                      </div>
                      <div class="column is-half">
                        <b-radio
                          class="en-style"
                          v-model="kycFormStore.formData.residentship"
                          :disabled="
                            kycFormStore.formData.isChangeMobile ||
                            kycFormStore.formData.isChangeEmail
                          "
                          name="resident"
                          @click.native="
                            () => {
                              resetValues();
                              residentshipOutOfSriLankaClicked();
                            }
                          "
                          native-value="Out of Sri Lanka"
                        >
                          {{ $root.selectedLanguageData.out_of_srilanka }}
                        </b-radio>
                      </div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                    
                  </ValidationProvider>
                </b-field>
              </div>

              <!-- srilankans ID type -->
              
              <div v-if="kycFormStore.formData.residentship === `Sri Lanka`">
                <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
                <div class="mt-4 mb-5">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.customer_nic.trim() === '' &&
                      !isNicInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.your_nic_eic
                      }}<span class="has-text-danger">*</span></template
                    >
                    <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="{
                          required: true,
                          multiRegex: [/^([0-9]{9}[Xx||Vv])$/, /^[0-9]{12}$/],
                        }"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          v-model="kycFormStore.formData.customer_nic"
                          :disabled="
                            kycFormStore.formData.isChangeMobile ||
                            kycFormStore.formData.isChangeEmail
                          "
                          @focus="
                            () => {
                              isNicInputFocus = true;
                            }
                          "
                          @input="splitNic()"
                          @blur="
                            () => {
                              isNicInputFocus = false;
                            }
                          "
                        ></b-input>

                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/nic.png"
                          alt="Call-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div></transition>
                <!-- passport -->
                
                <div class="mt-4 mb-5">
                  <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
                  <div
                    class="icon-text"
                    :style="{
                      cursor: 'pointer',
                      color: '#000000',
                      'line-height': '20px',

                      'letter-spacing': '0.7px',
                    }"
                  >
                    <p
                      v-if="!isShow"
                      @click="passoprtLabelVisible()"
                      class="is-flex is-flex-direction-row is-align-content-center"
                      :disabled="
                        kycFormStore.formData.isChangeMobile ||
                        kycFormStore.formData.isChangeEmail
                      "
                    >
                      <span class="icon has-text-info">
                        <img
                          :src=criclePlus
                          alt="Circle_Plus image"
                        />
                      </span>
                      <span class="is-size-6-mobile">{{
                        $root.selectedLanguageData.add_your_passport
                      }}</span>
                    </p>
                  </div></transition>
                  <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
                  <div v-if="isShow">
                    <div
                      class="icon-text mb-2"
                      :style="{
                        cursor: 'pointer',
                        color: '#000000',
                        'line-height': '20px',

                        'letter-spacing': '0.7px',
                      }"
                    >
                      <p
                        @click="passoprtLabelInvisible()"
                        class="is-flex is-flex-direction-row is-align-content-center"
                      >
                        <span class="icon has-text-info">
                          <img
                            :src=circleMinus
                            alt="Circle_Mini image"
                          />
                        </span>
                        <span class="is-size-6-mobile">{{
                          $root.selectedLanguageData.add_your_passport
                        }}</span>
                      </p>
                    </div>

                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.customer_passport_no.trim() ===
                          '' && !isPassportInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.your_passport_num }}
                      </template>
                      <p class="control has-icons-right">
                        <ValidationProvider
                          :rules="optionalPassportValidateObject()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            type="text"
                            v-model="kycFormStore.formData.customer_passport_no"
                            :disabled="
                              kycFormStore.formData.isChangeMobile ||
                              kycFormStore.formData.isChangeEmail
                            "
                            @focus="
                              () => {
                                isPassportInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPassportInputFocus = false;
                              }
                            "
                          />
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <span class="icon is-small is-right mr-2">
                          <img
                            src="../assets/images/icons/nic.png"
                            alt="contact-name-img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div></transition>
                </div>
              </div>
              <!-- Out of Sri Lankan's ID Type -->
              <div
                v-if="kycFormStore.formData.residentship === `Out of Sri Lanka`"
              >  <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
                <div class="mt-4 mb-5">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.customer_nic.trim() === '' &&
                      !isNicInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.your_nic_eic
                      }}<span class="has-text-danger">*</span></template
                    >
                    <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="{
                          required: true,
                          multiRegex: [/^([0-9]{9}[Xx||Vv])$/, /^[0-9]{12}$/],
                        }"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          v-model="kycFormStore.formData.customer_nic"
                          :disabled="
                            kycFormStore.formData.isChangeMobile ||
                            kycFormStore.formData.isChangeEmail
                          "
                          @input="splitNic()"
                          @focus="
                            () => {
                              isNicInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isNicInputFocus = false;
                            }
                          "
                        ></b-input>

                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/nic.png"
                          alt="Call-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div></transition>
                <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
                <div class="mt-4 mb-5">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.customer_passport_no.trim() ===
                        '' && !isPassportInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.your_passport_num
                      }}<span class="has-text-danger">*</span></template
                    >
                    <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="passportValidateObject()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="text"
                          :disabled="
                            kycFormStore.formData.isChangeMobile ||
                            kycFormStore.formData.isChangeEmail
                          "
                          v-model="kycFormStore.formData.customer_passport_no"
                          @focus="
                            () => {
                              isPassportInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isPassportInputFocus = false;
                            }
                          "
                        />
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <span class="icon is-small is-right mr-2">
                        <img
                          src="../assets/images/icons/nic.png"
                          alt="contact-name-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div></transition>
              </div>

              <p
                class="has-text-primary mt-2"
                style="font-family: Gotham-Medium; font-size: 1.1rem"
                v-if="kycFormStore.formData.isChangeMobile"
              >
                {{ $root.selectedLanguageData.mobile_number_entered_correctly }}
              </p>
              <p
                class="has-text-primary"
                style="font-family: Gotham-Medium; font-size: 1.1rem"
                v-if="kycFormStore.formData.isChangeEmail"
              >
                {{ $root.selectedLanguageData.email_entered_correctly }}
              </p>
              <div
                class="columns mb-5"
                v-if="
                  !kycFormStore.formData.isChangeMobile &&
                  !kycFormStore.formData.isChangeEmail
                "
              >
                <div class="column is-two-fifths">
                  <div>
                    <section>
                      <b-field>
                        <p class="control has-icons-right has-icons-left">
                          <b-autocomplete
                            size="is-medium"
                            v-model="userCode"
                            icon-pack="fi"
                            :icon="iconString"
                            open-on-focus
                            :data="filteredDataArr"
                            maxlength="4"
                            rounded
                            @input="
                              () => {
                                ++count;
                                if (count === 2) {
                                  kycFormStore.formData.countryCode = ``;
                                  isCountryCodeTouched = true;
                                }
                              }
                            "
                            @select="
                              (option, e) => {
                                testSelected(option, e);
                              }
                            "
                          >
                          </b-autocomplete>
                          <span class="icon is-small is-right mr-2 mt-1">
                            <img
                              :src=autoComleteDropDownIcon
                              alt="Stroke-img"
                              width="15"
                              height="15"
                            />
                          </span>
                          <span
                            class="has-text-danger"
                            v-if="
                              isCountryCodeTouched &&
                              !kycFormStore.formData.countryCode
                            "
                            >Please select a valid country code</span
                          >
                        </p>
                      </b-field>
                    </section>
                  </div>
                </div>
                <div class="column">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.mobileNumber.trim() === '' &&
                      !isMobileNumberInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.mobile_number
                      }}<span class="has-text-danger">*</span></template
                    >

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="Mobile Number"
                        :rules="{ required: true, onlyNumbers: true }"
                        v-slot="{ errors }"
                      >
                        <b-input
                          type="tel"
                          v-model="kycFormStore.formData.mobileNumber"
                          @focus="
                            () => {
                              isMobileNumberInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isMobileNumberInputFocus = false;
                            }
                          "
                          @input="
                            () => {
                              isMobileNumberTouched = true;
                              mobileNumValidate();
                              calPhoneNumber();
                            }
                          "
                          rounded
                        ></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                        <span
                          class="has-text-danger"
                          v-if="
                            isMobileNumberTouched &&
                            !isMobileNumberValid &&
                            !errors[0]
                          "
                          >Please enter a valid Mobile Number.
                        </span>
                        <span
                          class="has-text-danger"
                          v-if="isMobileNumberStartsWithZero && !errors[0]"
                          >For LK - please enter your mobile number without the 0</span
                        >
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/Call.png"
                          alt="Call-img"
                          width="25"
                          height="25"
                          style="opacity: calc(60%)"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>
              </div>

              <div
                class="columns mb-3"
                v-if="
                  kycFormStore.formData.isChangeMobile ||
                  kycFormStore.formData.isChangeEmail
                "
              >
                <div class="column is-two-fifths">
                  <div>
                    <section>
                      <b-field>
                        <p class="control has-icons-right">
                          <b-autocomplete
                            maxlength="4"
                            size="is-medium"
                            v-model="userCodeEdit"
                            icon-pack="fi"
                            :icon="iconStringEdit"
                            open-on-focus
                            :data="filteredDataArrEdit"
                            rounded
                            @input="
                              () => {
                                ++count;
                                if (count === 2) {
                                  countryCodeEdit = ``;
                                  isCountryCodeTouched = true;
                                  isMobileNumberTouched = true;
                                }
                              }
                            "
                            @select="
                              (option) => {
                                if (option) {
                                  countryCodeEdit = `+${option.split(`+`)[1]}`;
                                  shortNameEdit = option.split(' ')[0];
                                } else {
                                  countryCodeEdit = ``;
                                }
                                if (countryCodeEdit) {
                                  noEnrolementCountries(userCodeEdit)
                                  changeSelectionEdit();
                                  calEditPhoneNumber();
                                }
                              }
                            "
                          >
                          </b-autocomplete>
                          <span class="icon is-small is-right mr-2 mt-1">
                            <img
                              :src=autoComleteDropDownIcon
                              alt="Stroke-img"
                              width="15"
                              height="15"
                            />
                          </span>
                          <span
                            class="has-text-danger"
                            v-if="isCountryCodeTouched && !countryCodeEdit"
                            >You have to select a valid country code</span
                          >
                        </p>
                      </b-field>
                    </section>
                  </div>
                </div>
                <div class="column">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      mobileNumberEdit.trim() === '' &&
                      !isMobileNumberInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.mobile_number
                      }}<span class="has-text-danger">*</span></template
                    >

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="Mobile Number"
                        :rules="{ required: true, onlyNumbers: true }"
                        v-slot="{ errors }"
                      >
                        <b-input
                          type="tel"
                          v-model="mobileNumberEdit"
                          @focus="
                            () => {
                              isMobileNumberInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isMobileNumberInputFocus = false;
                            }
                          "
                          @input="
                            () => {
                              isMobileNumberTouched = true;
                              mobileNumEditValidate();
                              calEditPhoneNumber();
                            }
                          "
                          rounded
                        ></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                        <span
                          class="has-text-danger"
                          v-if="
                            isMobileNumberTouched &&
                            !isMobileNumberValidEdit &&
                            !errors[0]
                          "
                          >Please enter a valid Mobile Number.
                        </span>
                        <span
                          class="has-text-danger"
                          v-if="isMobileNumberEditStartsWithZero && !errors[0]"
                          >For LK - please enter your mobile number without the 0</span
                        >
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/Call.png"
                          alt="Call-img"
                          width="25"
                          height="25"
                          style="opacity: calc(60%)"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>
              </div>
              <div
                class="is-flex is-justify-content-end"
                v-if="kycFormStore.formData.isChangeMobile"
              >
                <b-button
                  class="is-primary"
                  :disabled="
                    invalid || countryError ||
                    !countryCodeEdit ||
                    !isMobileNumberValidEdit ||
                    isMobileNumberEditStartsWithZero ||
                    !isMobileNumberTouched
                  "
                  rounded
                  @click="saveEditedNumber"
                  >Save
                </b-button>
              </div>

              <div
                class="mt-5 mb-5"
                v-if="!kycFormStore.formData.isChangeEmail"
              >
                <b-field
                  label-position="inside"
                  :custom-class="
                    kycFormStore.formData.email.trim() === '' &&
                    !isEmailIdInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label
                    >{{ $root.selectedLanguageData.email_address
                    }}<span class="has-text-danger">*</span></template
                  >
                  <p class="control has-icons-right">
                    <ValidationProvider
                      :rules="{
                        email: true,
                        required: true,
                        lessOrEqualLength: 256,
                      }"
                      name="Email ID"
                      v-slot="{ errors }"
                    >
                      <b-input
                        rounded
                        v-model="kycFormStore.formData.email"
                        :disabled="kycFormStore.formData.isChangeMobile"
                        @focus="
                          () => {
                            isEmailIdInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isEmailIdInputFocus = false;
                          }
                        "
                        @input="emailValidation()"
                      ></b-input>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <span class="icon is-small is-right">
                      <img
                        src="../assets/images/icons/Message.png"
                        alt="Call-img"
                        width="25"
                        height="25"
                      />
                    </span>
                  </p>
                </b-field>
              </div>

              <div class="mt-5 mb-3" v-if="kycFormStore.formData.isChangeEmail">
                <b-field
                  label-position="inside"
                  :custom-class="
                    emailEdit.trim() === '' && !isEmailIdInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label
                    >{{ $root.selectedLanguageData.email_address
                    }}<span class="has-text-danger">*</span></template
                  >
                  <p class="control has-icons-right">
                    <ValidationProvider
                      :rules="{
                        email: true,
                        required: true,
                        lessOrEqualLength: 256,
                      }"
                      name="Email ID"
                      v-slot="{ errors }"
                    >
                      <b-input
                        rounded
                        v-model="emailEdit"
                        @focus="
                          () => {
                            isEmailIdInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isEmailIdInputFocus = false;
                          }
                        "
                        @input="
                          () => {
                            isEmailTouched = true;
                            emailEditValidation();
                          }
                        "
                      ></b-input>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <span class="icon is-small is-right">
                      <img
                        src="../assets/images/icons/Message.png"
                        alt="Call-img"
                        width="25"
                        height="25"
                      />
                    </span>
                  </p>
                </b-field>
              </div>

              <div
                class="is-flex is-justify-content-end"
                v-if="kycFormStore.formData.isChangeEmail"
              >
                <b-button
                  class="is-primary"
                  :disabled="
                    invalid ||
                    !countryCodeEdit ||
                    !isMobileNumberValidEdit ||
                    isMobileNumberEditStartsWithZero ||
                    (!isEmailTouched && !isMobileNumberTouched)
                  "
                  rounded
                  @click="saveEditedNumber"
                  >Save
                </b-button>
              </div>

              <!-- <div class="mt-5" v-if="kycFormStore.formData.customer_nic"> -->
              <div class="mt-5">
                <b-field>
                  <b-checkbox
                    native-value="Allow Location Services"
                    :disabled="
                      kycFormStore.formData.isChangeMobile ||
                      kycFormStore.formData.isChangeEmail
                    "
                    v-model="
                      kycFormStore.formData
                        .nicNumberAllowToLocationServicesInYourBrowser
                    "
                    @input="drpValue()"
                  >
                  </b-checkbox>
                  <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
                  <div v-show="!flagshow">
                    <p class="en-style" style="text-align: justify">
                      {{ $root.selectedLanguageData.drp_registration }}
                      <br />
                      {{ $root.selectedLanguageData.drp_registration_2 }}
                      <span class="has-text-danger">* </span>

                      <span class="click_style" @click="flagshow = true">{{
                        $root.selectedLanguageData.read_more
                      }}</span>
                    </p>
                  </div></transition>
                  <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
                  <div v-show="flagshow">
                    
                    <div class="en-style">
                      <p style="text-align: justify">
                        {{
                          $root.selectedLanguageData
                            .allowLocationServicesInYourBrowserShowContant_1
                        }}
                      </p>
                      <p class="en-style" style="text-align: justify">
                        <!-- {{
                          $root.selectedLanguageData
                              .allowLocationServicesInYourBrowserShowContant_2
                        }} -->
                        <span @click="flagshow = false" class="click_style">{{
                          $root.selectedLanguageData.shoeLess
                        }}</span>
                      </p>
                    </div>
                  </div></transition>
                </b-field>
              </div>
              <div
                class="mt-4 flex-start align"
                style="justify-content: flex-start !important"
              >
                <b-field>
                  <b-checkbox
                    native-value="DRP"
                    :disabled="
                      kycFormStore.formData.isChangeMobile ||
                      kycFormStore.formData.isChangeEmail
                    "
                    v-model="kycFormStore.formData.nicNumberAllowToDRP"
                    @input="locationValue()"
                  ></b-checkbox>
                  <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
                  <div v-show="!showFlag">
                    <p class="en-style" style="text-align: justify">
                      {{ $root.selectedLanguageData.location_browser
                      }}<span class="has-text-danger">* </span>

                      <span class="click_style" @click="showFlag = true">{{
                        $root.selectedLanguageData.read_more
                      }}</span>
                    </p>
                  </div></transition>
                  <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
                  <div v-show="showFlag">
                    <p class="en-style" style="text-align: justify">
                      {{ $root.selectedLanguageData.drpShowContant }}
                      <span @click="showFlag = false" class="click_style">{{
                        $root.selectedLanguageData.shoeLess
                      }}</span>
                    </p>
                  </div></transition>
                </b-field>
              </div>
            </div>
          </transition>
            <!-- button -->
            <transition appear
                @before-enter="$animations.beforeEnterBelow"
                @enter="$animations.enterBelow"
    >

            <div class="is-flex is-flex-direction-row-reverse mt-2 mb-4">
              <b-button
                class="is-primary is-rounded continue-button-en py-5"
                :loading="continueBtnIsLoading"
                @click="() => {
                        checkTeen();
                }
                 "
                :disabled="
                  invalid || countryError ||
                  !kycFormStore.formData.countryCode ||
                  kycFormStore.formData.isChangeEmail ||
                  kycFormStore.formData.isChangeMobile ||
                  !isMobileNumberValid ||
                  isMobileNumberStartsWithZero ||
                  !kycFormStore.formData
                    .nicNumberAllowToLocationServicesInYourBrowser ||
                  !kycFormStore.formData.nicNumberAllowToDRP 
                "
                >{{ $root.selectedLanguageData.continue }}
              </b-button>
              <b-button
                class="is-rounded back-button-en py-5"
                @click="$router.push('/')"
                :disabled="
                  kycFormStore.formData.isChangeMobile ||
                  kycFormStore.formData.isChangeEmail ||
                  kycExist
                "
                >{{ $root.selectedLanguageData.back }}
              </b-button>
            </div></transition>
            
          </div>
        </div>
      </form>
    </ValidationObserver>
    <!-- <GetHelp /> -->
    <b-modal
      v-model="isModalActive"
      scroll="keep"
      :width="540"
      :can-cancel="['x']"
    >  <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
      <div class="model_box mt-3">
        <div
          class="has-text-centered"
          style="transition-timing-function: ease-in-out"
        >
          <div class="modal-close-button is-pulled-right mb-6">
            <button
              class="delete"
              aria-label="close"
              @click="
                () => {
                  isModalActive = false;
                  isConsent = false;
                  isLocation = false;
                }
              "
            ></button>
          </div>
          <div>
            <img
              :src="require(`../assets/images/icons/account_fail.gif`)"
              responsive
              class="account_fail"
            />

            <p class="has-text-primary hero-subhead-line">
              {{ $root.selectedLanguageData.account_opening_faild }}
            </p>
            <!-- nicNumberAllowToLocationServicesInYourBrowser -->
            <div
              style="font-size: 18px"
              class="mt-2"
              v-if="
                !kycFormStore.formData
                  .nicNumberAllowToLocationServicesInYourBrowser &&
                kycFormStore.formData.nicNumberAllowToDRP
              "
            >
              {{ $root.selectedLanguageData.sorry
              }}<span style="font-family: Gotham-Medium" class="mx-2"
                >{{ kycFormStore.formData.calling_name || "Charith" }},</span
              >{{ $root.selectedLanguageData.account_faild_line }}
            </div>
            <!-- nicNumberAllowToDRP -->
            <div
              style="font-size: 18px"
              class="mt-2"
              v-if="
                !kycFormStore.formData.nicNumberAllowToDRP &&
                kycFormStore.formData
                  .nicNumberAllowToLocationServicesInYourBrowser
              "
            >
              {{ $root.selectedLanguageData.sorry
              }}<span style="font-family: Gotham-Medium" class="mx-2"
                >{{ kycFormStore.formData.calling_name || "Charith" }},</span
              >{{ $root.selectedLanguageData.account_faild_line_2 }}
            </div>
            <!--            both-->
            <div
              style="font-size: 20px"
              v-if="
                !kycFormStore.formData.nicNumberAllowToDRP &&
                !kycFormStore.formData
                  .nicNumberAllowToLocationServicesInYourBrowser
              "
            >
              {{ $root.selectedLanguageData.sorry
              }}<span style="font-family: Gotham-Medium" class="mx-2"
                >{{ kycFormStore.formData.calling_name || "Charith" }},</span
              >{{ $root.selectedLanguageData.account_faild_line_3 }}
            </div>
          </div>
          <div style="margin-inline: 100px">
            <b-button
              type="is-primary"
              class="py-5 mt-5"
              expanded
              rounded
              @click="
                () => {
                  isModalActive = false;
                  isConsent = false;
                  isLocation = false;
                }
              "
              >{{ $root.selectedLanguageData.ok }}
            </b-button>
          </div>
        </div>
      </div></transition>
    </b-modal>
   
  </div>
</template>
<script>
import { ValidationProvider } from "vee-validate";
import logopage from "./CompanyTopLogo.vue";
import NetworkManager from "@/utils/networkManager";
import { BuefyHelper } from "@/heplers/buefyHelper";
import { useKycFormStore } from "@/stores/KycFormStore";
import { GlobalFunctions } from "@/heplers/globalFunctions";
// import GetHelp from "./GetHelp.vue";

export default {
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },
  components: {
    logopage,
    ValidationProvider,
    // GetHelp,
  },
  data() {
    return {
      criclePlus : require("@/assets/images/icons/Circle-plus.png"),
      circleMinus : require("@/assets/images/icons/CircleMinus.png"),
      hiWave : require("@/assets/images/icons/hi.png"),
      autoComleteDropDownIcon : require("@/assets/images/icons/Stroke-3.png"),
      previousRoute: null, // variable to store previous route details
      continueBtnIsLoading: false,
      showFlag: false,
      flagshow: false,
      isShow: false,
      count: 0,
      isEmailTouched: false,
      isCountryCodeTouched: false,
      isMobileNumberTouched: false,
      isMobileNumberStartsWithZero: false,
      isMobileNumberEditStartsWithZero: false,
      notMatch: false,
      userCode: "",
      userCodeEdit: "",
      mobileNumberEdit: "",
      countryCodeEdit: "",
      contactNoEdit: "",
      shortNameEdit: "",
      countryCodeArray: [],
      iconString: "",
      iconStringEdit: "",
      emailEdit: "",
      isMobileNumberValid: false,
      isMobileNumberValidEdit: false,
      isModalActive: false,
      isConsent: false,
      isLocation: false,
      countryError :false,

      regex: {
        srilankan_mobile: /^([7][0||1||2||4||5||6||7||8||][0-9]{7})$/,
        indian_mobile: /^[6-9]\d{9}$/,
        old_nic: /^([0-9]{9}[Xx||Vv])$/,
        passport: /^[0-9A-Za-z]{4,18}$/,
        new_nic: /[0-9]{12}/,
      },
      // focus
      isMobileNumberInputFocus: false,
      isEmailIdInputFocus: false,
      isNicInputFocus: false,
      isPassportInputFocus: false,
      kycExist: false,
      under18: false,
      under16: false,
    };
  },

  // in component navigation guard
  beforeRouteEnter(to, from, next) {
    next((comp) => {
      console.log("previous route : ", from);

      // get previous route
      comp.previousRoute = from;
    });
  },
  watch: {
  },
  methods: {

    noEnrolementCountries(option){
      if(option == "MM +95" || option == "KP +850" || option == "IR +98" )
      {
        BuefyHelper.showToastMessage("Sorry! Selected country is not allowed for digital onboarding.","is-danger")
        this.countryError =true;
      }
      else
      {
        this.countryError =false;
      }
    },
    testSelected(option, e) {
      console.log("at selected");
      console.log("selected option " + option);
      console.log("selected event ", e);
      const comp = this;
      comp.noEnrolementCountries(option);
      if (option) {
        comp.kycFormStore.formData.countryCode = `+${option.split(`+`)[1]}`;
        comp.kycFormStore.formData.country_short_name = option.split(" ")[0];
      } else {
        comp.kycFormStore.formData.countryCode =
          comp.kycFormStore.formData.countryCode.trim() !== ""
            ? comp.kycFormStore.formData.countryCode
            : "";
      }

      if (comp.kycFormStore.formData.countryCode) {
        comp.changeSelection();
        comp.calPhoneNumber();
      }
    },
    residentshipSriLankaClicked() {
      if (
        !this.kycFormStore.formData.isChangeMobile &&
        !this.kycFormStore.formData.isChangeEmail
      ) {
        this.userCode = "LK +94";
        this.kycFormStore.formData.country_short_name = `LK`;
        this.kycFormStore.formData.countryCode = `+94`;

        console.log("mobile num c.o" + this.kycFormStore.formData.countryCode);
        this.changeSelection();
        this.calPhoneNumber();
        console.log("clicked event handler ------------------");
      }
    },
    residentshipOutOfSriLankaClicked() {
      if (
        !this.kycFormStore.formData.isChangeMobile &&
        !this.kycFormStore.formData.isChangeEmail
      ) {
        this.kycFormStore.formData.countryCode = "";
        this.kycFormStore.formData.country_short_name = "";
        this.userCode = "";
        this.kycFormStore.formData.mobileNumber = "";
        this.changeSelection();
        this.calPhoneNumber();

        this.shortNameEdit = this.kycFormStore.formData.country_short_name;
        this.iconStringEdit = this.iconString;
        this.countryCodeEdit = this.kycFormStore.formData.countryCode;
        this.mobileNumberEdit = this.kycFormStore.formData.mobileNumber;
        this.contactNoEdit = this.kycFormStore.formData.contact_no;
        this.userCodeEdit = this.userCode;
      }
    },

    resetNationalityValue() {
      if (
        !this.kycFormStore.formData.isChangeMobile &&
        !this.kycFormStore.formData.isChangeEmail
      ) {
        this.kycFormStore.formData.permanent_address_district = "";
        this.kycFormStore.formData.permanent_address_country = "";
        this.kycFormStore.formData.correspondent_address_district = "";
        this.kycFormStore.formData.correspondent_address_country = "";
        this.kycFormStore.formData.employer_address_district = "";
        this.kycFormStore.formData.employer_address_country = "";
      }
    },
    resetValues() {
      if (
        !this.kycFormStore.formData.isChangeMobile &&
        !this.kycFormStore.formData.isChangeEmail
      ) {
        this.kycFormStore.formData.customer_passport_no = "";
        this.kycFormStore.formData.customer_nic = "";
        this.isShow = false;
        this.kycFormStore.formData.gender = "";
        this.kycFormStore.formData.date_of_birth = "";
        this.kycFormStore.formData.correspondence_address_differ_with_permanent_address = false;
        this.kycFormStore.formData.permanent_address_line1 = "";
        this.kycFormStore.formData.permanent_address_line2 = "";
        this.kycFormStore.formData.permanent_address_line3 = "";
        this.kycFormStore.formData.permanent_address_town = "";
        this.kycFormStore.formData.permanent_address_postal_code = "";
        this.kycFormStore.formData.permanent_address_district = "";
        this.kycFormStore.formData.permanent_address_country = "";
        this.kycFormStore.formData.correspondence_address_differ = "";
        this.kycFormStore.formData.correspondent_address_line1 = "";
        this.kycFormStore.formData.correspondent_address_line2 = "";
        this.kycFormStore.formData.correspondent_address_line3 = "";
        this.kycFormStore.formData.correspondent_address_town = "";
        this.kycFormStore.formData.correspondent_address_postal_code = "";
        this.kycFormStore.formData.correspondent_address_district = "";
        this.kycFormStore.formData.correspondent_address_country = "";
        this.kycFormStore.formData.employer_address_line1 = "";
        this.kycFormStore.formData.employer_address_line2 = "";
        this.kycFormStore.formData.employer_address_line3 = "";
        this.kycFormStore.formData.employer_address_town = "";
        this.kycFormStore.formData.employer_address_postal_code = "";
        this.kycFormStore.formData.employer_address_district = "";
        this.kycFormStore.formData.employer_address_country = "";
      }
    },
    drpValue() {
      if (
        !this.kycFormStore.formData
          .nicNumberAllowToLocationServicesInYourBrowser
      ) {
        this.isConsent = true;
        this.isModalActive = true;
      }
    },
    locationValue() {
      if (!this.kycFormStore.formData.nicNumberAllowToDRP) {
        this.isLocation = true;
        this.isModalActive = true;
      }
    },
    splitNic() {
      this.kycFormStore.isNicChange = true;
      this.kycFormStore.formData.customer_nic = this.kycFormStore.formData.customer_nic.toUpperCase()
      if (this.kycFormStore.formData.customer_nic.length === 10) {
        this.kycFormStore.formData.idType = "ONIC";
      } else {
        this.kycFormStore.formData.idType = "NNIC";
      }
    },

    emailValidation(){

      this.kycFormStore.formData.email = this.kycFormStore.formData.email.toLowerCase()

    },
    emailEditValidation(){
      this.emailEdit = this.emailEdit.toLocaleLowerCase()
    },

    passoprtLabelVisible() {
      if (
        !this.kycFormStore.formData.isChangeMobile &&
        !this.kycFormStore.formData.isChangeEmail
      ) {
        this.isShow = true;
      }
    },
    passoprtLabelInvisible() {
      if (
        !this.kycFormStore.formData.isChangeMobile &&
        !this.kycFormStore.formData.isChangeEmail
      ) {
        this.isShow = false;
        this.kycFormStore.formData.customer_passport_no = "";
      }
    },

    mobileNumValidate: function () {
      if (this.kycFormStore.formData.countryCode === "+94") {
        const srilankanMobileRegEx = /^([7][0||1||2||4||5||6||7||8][0-9]{7})$/;

        this.isMobileNumberValid = srilankanMobileRegEx.test(
          this.kycFormStore.formData.mobileNumber
        );
      } else {
        const foreignMobileRegEx = /^[0-9]{0,15}$/;

        this.isMobileNumberValid = foreignMobileRegEx.test(
          this.kycFormStore.formData.mobileNumber
        );
      }
    },

    mobileNumEditValidate: function () {
      if (this.countryCodeEdit === "+94") {
        const srilankanMobileRegEx = /^([7][0||1||2||4||5||6||7||8][0-9]{7})$/;

        this.isMobileNumberValidEdit = srilankanMobileRegEx.test(
          this.mobileNumberEdit
        );
      } else {
        this.isMobileNumberValidEdit = /^\d+$/.test(this.mobileNumberEdit);
      }
    },

    /**
     * This function is used to get geolocation. it returns a promise
     * @returns {Promise<unknown>}
     */
    getGeoLocation() {
      return new Promise(function (resolve, error) {
        const options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        };

        navigator.geolocation.getCurrentPosition(resolve, error, options);
      });
    },
    
    checkTeen() {
      const comp = this;

      if (comp.kycFormStore.formData.customer_nic) {
        let year;
        let genderValue;
        let monthAndDayInDays;
        let dateOfBirth;

        if (comp.kycFormStore.formData.customer_nic.length === 10) {
          genderValue = comp.kycFormStore.formData.customer_nic.slice(2, 5);
          monthAndDayInDays =
            parseInt(genderValue, 10) >= 500
              ? parseInt(genderValue - 500, 10)
              : parseInt(genderValue, 10);
          year = parseInt(
            `19${comp.kycFormStore.formData.customer_nic.slice(0, 2)}`,
            10
          );
        } else if (comp.kycFormStore.formData.customer_nic.length === 12) {
          genderValue = comp.kycFormStore.formData.customer_nic.slice(4, 7);
          monthAndDayInDays =
            parseInt(genderValue, 10) >= 500
              ? parseInt(genderValue - 500, 10)
              : parseInt(genderValue, 10);
          year = parseInt(
            `${comp.kycFormStore.formData.customer_nic.slice(0, 4)}`,
            10
          );
        }
        let month;
        let day;
        if (monthAndDayInDays <= 31) {
          month = "01";
          if (monthAndDayInDays < 10) {
            day = `0${monthAndDayInDays}`;
          } else {
            day = monthAndDayInDays;
          }
        } else if (monthAndDayInDays <= 60) {
          month = "02";
          day = monthAndDayInDays - 31;
        } else if (monthAndDayInDays <= 91) {
          month = "03";
          day = monthAndDayInDays - 60;
        } else if (monthAndDayInDays <= 121) {
          month = "04";
          day = monthAndDayInDays - 91;
        } else if (monthAndDayInDays <= 152) {
          month = "05";
          day = monthAndDayInDays - 121;
        } else if (monthAndDayInDays <= 182) {
          month = "06";
          day = monthAndDayInDays - 152;
        } else if (monthAndDayInDays <= 213) {
          month = "07";
          day = monthAndDayInDays - 182;
        } else if (monthAndDayInDays <= 244) {
          month = "08";
          day = monthAndDayInDays - 213;
        } else if (monthAndDayInDays <= 274) {
          month = "09";
          day = monthAndDayInDays - 244;
        } else if (monthAndDayInDays <= 305) {
          month = "10";
          day = monthAndDayInDays - 274;
        } else if (monthAndDayInDays <= 335) {
          month = "11";
          day = monthAndDayInDays - 305;
        } else {
          month = "12";
          day = monthAndDayInDays - 335;
        }
        //check date of birth feild is edit or not
        dateOfBirth = new Date(`${year.toString()}-${month}-${day}`);
        // calculate month difference from current date in time
        var month_diff = Date.now() - dateOfBirth.getTime();
        // convert the calculated difference in date format
        var age_dt = new Date(month_diff);
        // extract year from date
        var b_year = age_dt.getUTCFullYear();
        // now calculate the age of the user
        var age = Math.abs(b_year - 1970);
        console.log(age);
        if (age < 18) {
          comp.under18 = true;
          if (age < 16) {
            comp.under16 = true;
          } else {
            comp.under16 = false;
          }
        } else {
          comp.under18 = false;
          comp.under16 = false;
        }
      }
      if ((comp.kycFormStore.formData.citizenship === `Sri Lankan with Dual Citizenship` &&  comp.kycFormStore.formData.residentship === `Sri Lanka`) || (comp.kycFormStore.formData.citizenship === `Sri Lankan with Dual Citizenship` &&  comp.kycFormStore.formData.residentship === `Out of Sri Lanka`))
      {
            BuefyHelper.showSnackBar(
            "We apologize, but we are unable to onboard you. If you have further questions or need clarification, please call our dedicated contact centre at 0112350000.",
            "Close",
            "is-danger",
            () => {
              comp.$router.replace("/");
            }
          );
         
      }else if (comp.kycFormStore.formData.residentship === `Out of Sri Lanka`) {
        if (comp.under18) {
          BuefyHelper.showSnackBar(
            "We apologize, but we are unable to onboard you. If you have further questions or need clarification, please call our dedicated contact centre at 0112350000.",
            "Close",
            "is-danger",
            () => {
              comp.$router.replace("/");
            }
          );
        } else {
          comp.countryCodeType();
        }
      } else {
        if (comp.under16) {
          BuefyHelper.showSnackBar(
            "We apologize, but we are unable to onboard you. If you have further questions or need clarification, please call our dedicated contact centre at 0112350000.",
            "Close",
            "is-danger",
            () => {
              comp.$router.replace("/");
            }
          );
        } else {
          comp.countryCodeType();
        }
      }
    },

    async countryCodeType() {
      const comp = this;

      console.log("previous route " + comp.previousRoute);

      comp.continueBtnIsLoading = true;

      // if user comes from the personal details and verified nic/passport is not changed by the customer
      if (
        comp.previousRoute.path === "/personalDetail" &&
        !comp.checkVerifiedInformationIsChanged()
      ) {
        console.log("check mobile number is changed");

        // check customer is out of sri lanka
        if (GlobalFunctions.CheckCustomerIsOutOfSriLanka(comp.kycFormStore)) {
          // out of sri lanka customer

          // check mobile number or email changed
          if (
            comp.kycFormStore.formData.verified_data.otp.verified_email !==
              comp.kycFormStore.formData.email ||
            comp.kycFormStore.formData.verified_data.otp
              .verified_mobile_number !== comp.kycFormStore.formData.contact_no
          ) {
            console.log(
              "Outside SL customer, email or mobile no. changed, verify OTP"
            );

            comp.continueBtnIsLoading = false;
            comp.$router.push({
              path: "/enterEmailOtp",
              query: { next: "/personalDetail", previous: "/userInfo" },
            });
          } else {
            // no need to verify otp, directly go to personal details
            comp.$router.push("/personalDetail");
          }
        } else {
          // sri lankan customer
          // check otp is verified and contact number is changed
          if (
            comp.kycFormStore.formData.verified_data.otp.verified &&
            comp.kycFormStore.formData.verified_data.otp
              .verified_mobile_number !== comp.kycFormStore.formData.contact_no
          ) {
            comp.continueBtnIsLoading = false;

            // need to verify mobile number again
            comp.$router.push({
              path: "/enterOtp",
              query: { next: "/personalDetail", previous: "/userInfo" },
            });
          } else {
            // no need to verify otp, directly go to personal details
            comp.$router.push("/personalDetail");
          }
        }
      } else if (
        (comp.previousRoute.path === "/enterOtp" ||
          comp.previousRoute.path === "/enterEmailOtp") &&
        !comp.checkVerifiedInformationIsChanged()
      ) {
        // check customer is out of sri lanka
        console.warn("inside new else branch");
        if (GlobalFunctions.CheckCustomerIsOutOfSriLanka(comp.kycFormStore)) {
          // out of sri lanka customer

          // otp is not verified yet
          if (!comp.kycFormStore.formData.verified_data.otp.verified) {
            comp.continueBtnIsLoading = false;
            comp.$router.push({
              path: "/enterEmailOtp",
              query: { next: "/personalDetail", previous: "/userInfo" },
            });
          }
          // check mobile number or email changed
          else if (
            comp.kycFormStore.formData.verified_data.otp.verified_email !==
              comp.kycFormStore.formData.email ||
            comp.kycFormStore.formData.verified_data.otp
              .verified_mobile_number !== comp.kycFormStore.formData.contact_no
          ) {
            console.log(
              "Outside SL customer, email or mobile no. changed, verify OTP"
            );

            comp.continueBtnIsLoading = false;
            comp.$router.push({
              path: "/enterEmailOtp",
              query: { next: "/personalDetail", previous: "/userInfo" },
            });
          } else {
            // no need to verify otp, directly go to personal details
            comp.$router.push("/personalDetail");
          }
        } else {
          // sri lankan customer

          // otp is not verified yet
          if (!comp.kycFormStore.formData.verified_data.otp.verified) {
            comp.continueBtnIsLoading = false;
            // need to verify mobile number again
            comp.$router.push({
              path: "/enterOtp",
              query: { next: "/personalDetail", previous: "/userInfo" },
            });
          }
          // check otp is verified and contact number is changed
          else if (
            comp.kycFormStore.formData.verified_data.otp.verified &&
            comp.kycFormStore.formData.verified_data.otp
              .verified_mobile_number !== comp.kycFormStore.formData.contact_no
          ) {
            comp.continueBtnIsLoading = false;

            // need to verify mobile number again
            comp.$router.push({
              path: "/enterOtp",
              query: { next: "/personalDetail", previous: "/userInfo" },
            });
          } else {
            // no need to verify otp, directly go to personal details
            comp.$router.push("/personalDetail");
          }
        }
      } else {
        // get customer location
        console.log("waiting for geo location");

        await comp.getGeoLocation().then(
          function (location) {
            comp.kycFormStore.formData.customer_location = {
              latitude: location.coords.latitude,
              longitude: location.coords.longitude,
              accuracy: location.coords.accuracy,
            };
          },
          function (error) {
            console.warn(
              `GEOLOCATION ERROR( ${error.code}) : ${error.message}`
            );
          }
        );

        // check if location details are available
        if (comp.kycFormStore.formData.customer_location !== null) {
          console.log(
            "location data received : " +
              JSON.stringify(comp.kycFormStore.formData.customer_location)
          );

          // check kyc exist for given nic/passport (default path)
          let requestNic = "";
          let requestPassport = "";

          // for both nic/passport both entered situations (only nic either passport is changed) assign dummy value to nic/passport field
          if (
            (comp.kycFormStore.formData.customer_nic ===
              comp.kycFormStore.formData.verified_data.nic_eic &&
              comp.kycFormStore.formData.customer_passport_no !==
                comp.kycFormStore.formData.verified_data.passport) ||
            (comp.kycFormStore.formData.customer_passport_no ===
              comp.kycFormStore.formData.verified_data.passport &&
              comp.kycFormStore.formData.customer_nic !==
                comp.kycFormStore.formData.verified_data.nic_eic)
          ) {
            if (
              comp.kycFormStore.formData.customer_nic ===
              comp.kycFormStore.formData.verified_data.nic_eic
            ) {
              // since nic is not change, assign dummy value
              requestNic = "000000000v";
              requestPassport = comp.kycFormStore.formData.customer_passport_no;
            } else {
              // assign dummy value for passport
              requestPassport = "";
              requestNic = comp.kycFormStore.formData.customer_nic;
            }
          } else {
            requestNic = comp.kycFormStore.formData.customer_nic;
            requestPassport = comp.kycFormStore.formData.customer_passport_no;
          }

          let requestBody = {
            nic_number: requestNic,
            // nic_number: comp.kycFormStore.formData.customer_nic,
            // passport_number: comp.kycFormStore.formData.customer_passport_no,
            passport_number: requestPassport,
            identification_type: comp.kycFormStore.formData.idType,
          };

          console.log("nic pp check api requst body : ", requestBody);
          NetworkManager.apiRequest("api/KycForm/checkKycFormExistForNicPassport", requestBody,function (response) {
              // console.log(response)

              if (response.statusCode === 200 || response.statusCode === 403) {
                if (
                  response.statusCode === 200 &&
                  response.data.is_kyc_exist === true
                ) {
                  // found existing kyc
                  console.log("existing kyc is found");
                  comp.kycExist = true;

                  if (response.data.details.is_kyc_approved === true) {
                    // kyc is already approved
                    console.log("kyc is already approved");

                    BuefyHelper.showSnackBar(
                      "Already approved form is found. Contact the bank for more details",
                      "Close",
                      "is-danger",
                      () => {
                        comp.$router.replace("/");
                      }
                    );
                  } else {
                    // existing kyc found but not approved
                    console.log("redirect to customer portal");

                    BuefyHelper.showSnackBar(
                      "Existing KYC form is found",
                      "Go to customer portal",
                      "is-danger",
                      () => {
                        comp.$router.replace("applicationStatusCheck");
                        comp.kycFormStore.formData.customer_nic_eic_pp =
                          response.data.details.nic_passport_number;
                      }
                    );
                  }
                } else if (
                  response.statusCode === 403 &&
                  response.data.message ===
                    "The customer is already registered with the PayMedia bank"
                ) {
                  console.log("already registered customer with the PayMedia bank");

                  BuefyHelper.showSnackBar(
                    "Sorry, existing PayMedia customers cannot onboard on this platform. If you have further questions or need clarification, please call our dedicated contact centre at 0112350000.",
                    "Close",
                    "is-danger",
                    () => {
                      comp.$router.replace("/");
                    }
                  );
                } else {
                  // no existing kyc form found, then continue the form

                  // make this nic/passport as verified
                  comp.kycFormStore.formData.verified_data.nic_eic =
                    comp.kycFormStore.formData.customer_nic;
                  comp.kycFormStore.formData.verified_data.passport =
                    comp.kycFormStore.formData.customer_passport_no;

                  // check customer is out of sri lanka
                  if (
                    GlobalFunctions.CheckCustomerIsOutOfSriLanka(
                      comp.kycFormStore
                    )
                  ) {
                    // check otp is verified and contact number and email is not changed
                    if (
                      comp.kycFormStore.formData.verified_data.otp.verified &&
                      comp.kycFormStore.formData.verified_data.otp
                        .verified_mobile_number ===
                        comp.kycFormStore.formData.contact_no &&
                      comp.kycFormStore.formData.verified_data.otp
                        .verified_email === comp.kycFormStore.formData.email
                    ) {
                      // no need for the otp, directly navigate to personal info, set new initial kyc data
                      console.log(
                        "NIC/PP changed, OTP verified, mobile no. and email unchanged"
                      );

                      // update initial user data
                      GlobalFunctions.UpdateInitialFormDataAndNavigateToPersonalInfo(
                        comp.kycFormStore,
                        comp.$router,
                        () => {
                          comp.continueBtnIsLoading = false;
                        }
                      );
                    } else {
                      // NIC/PP changed, mobile no. or email also changed, verify otp again
                      console.log(
                        "NIC/PP changed, OTP(email or mobile) NOT verified"
                      );
                      comp.continueBtnIsLoading = false;

                      comp.$router.push({
                        path: "/enterEmailOtp",
                        query: {
                          next: "/personalDetail",
                          previous: "/userInfo",
                        },
                      });
                    }
                  } else {
                    // Sri lankan customer
                    // check otp is verified and contact number is not changed
                    if (
                      comp.kycFormStore.formData.verified_data.otp.verified &&
                      comp.kycFormStore.formData.verified_data.otp
                        .verified_mobile_number ===
                        comp.kycFormStore.formData.contact_no
                    ) {
                      // no need for the otp, directly navigate to personal info, set new initial kyc data
                      console.log(
                        "NIC/PP changed, OTP verified, mobile no. unchanged"
                      );

                      // update initial user data
                      GlobalFunctions.UpdateInitialFormDataAndNavigateToPersonalInfo(
                        comp.kycFormStore,
                        comp.$router,
                        () => {
                          comp.continueBtnIsLoading = false;
                        }
                      );
                    } else {
                      //NIC/PP changed and mobile no. also changed, need to verify the otp again
                      console.log("NIC/PP changed, OTP NOT verified");
                      comp.continueBtnIsLoading = false;

                      comp.$router.push({
                        path: "/enterOtp",
                        query: {
                          next: "/personalDetail",
                          previous: "/userInfo",
                        },
                      });
                    }
                  }
                }
              } else {
                // error from api

                if (response.statusCode === 502) {
                  // error response from T24 endpoint, log error to console
                  console.warn(response.data.message);
                }

                BuefyHelper.showToastMessage(
                  "Failed to check if any forms exist for the given nic number. Try again!",
                  "is-danger"
                );

                comp.continueBtnIsLoading = false;
              }
            },false,true );
        } else {
          // if  location details were not received. unable to proceed
          // notify the user and stay on same page
          BuefyHelper.showSnackBar(
            "Please allow getting your location access from your browser",
            "Close",
            "is-danger",
            () => {
              comp.continueBtnIsLoading = false;
            }
          );
        }
      }
    },

    /**
     * =================================================================================================================
     * This function can be used to check is verified nic/passport is changed by the user
     * @returns {boolean}
     */
    checkVerifiedInformationIsChanged() {
      const comp = this;
      // check verified nic/passport is changed by the user
      return (
        comp.kycFormStore.formData.customer_nic !==
          comp.kycFormStore.formData.verified_data.nic_eic ||
        comp.kycFormStore.formData.customer_passport_no !==
          comp.kycFormStore.formData.verified_data.passport
      );
    },

    changeSelection() {
      this.iconString = `fi fi-${this.kycFormStore.formData.country_short_name.toLowerCase()}`;
    },

    changeSelectionEdit() {
      this.iconStringEdit = `fi fi-${this.shortNameEdit.toLowerCase()}`;
    },

    passportValidateObject() {
      let obj = {
        required: true,
        notMatchRegex: [this.regex.old_nic, this.regex.new_nic],
        regex: this.regex.passport,
      };
      return obj;
    },
    optionalPassportValidateObject() {
      let obj = {
        notMatchRegex: [this.regex.old_nic, this.regex.new_nic],
        regex: this.regex.passport,
      };
      return obj;
    },

    // mobile_num
    calPhoneNumber() {
      if (this.kycFormStore.formData.countryCode === "+94") {
        console.log("LK Mobile number ---------------");
        const tempMobileNumber = this.kycFormStore.formData.mobileNumber.trim();
        if (tempMobileNumber[0] === "0") {
          this.isMobileNumberStartsWithZero = true;
        } else {
          this.isMobileNumberStartsWithZero = false;
        }
      } else {
        this.isMobileNumberStartsWithZero = false;
      }

      this.kycFormStore.formData.contact_no = `${this.kycFormStore.formData.countryCode}${this.kycFormStore.formData.mobileNumber}`;
      console.log(
        "Contact Number Formatted : " + this.kycFormStore.formData.contact_no
      );
    },

    calEditPhoneNumber() {
      if (this.countryCodeEdit === "+94") {
        console.log("LK Mobile number ---------------");
        const tempMobileNumber = this.mobileNumberEdit.trim();
        if (tempMobileNumber[0] === "0") {
          this.isMobileNumberEditStartsWithZero = true;
        } else {
          this.isMobileNumberEditStartsWithZero = false;
        }
      } else {
        this.isMobileNumberEditStartsWithZero = false;
      }

      this.contactNoEdit = `${this.countryCodeEdit}${this.mobileNumberEdit}`;
      console.log("Contact Number Formatted Edit: " + this.contactNoEdit);
    },

    saveEditedNumber() {
      console.log(this.mobileNumberEdit, "Mobile number edit");
      console.log(this.contactNoEdit, "Contact number edit");
      console.log(
        "isEmailTouched && !isMobileNumberTouched",
        this.isEmailTouched,
        this.isMobileNumberTouched
      );
      this.kycFormStore.formData.country_short_name = this.shortNameEdit;
      this.iconString = this.iconStringEdit;
      this.kycFormStore.formData.countryCode = this.countryCodeEdit;
      this.kycFormStore.formData.mobileNumber = this.mobileNumberEdit;
      this.kycFormStore.formData.contact_no = this.contactNoEdit;
      this.kycFormStore.formData.isChangeMobile = false;
      this.kycFormStore.formData.isChangeEmail = false;
      this.kycFormStore.formData.email = this.emailEdit;

      let message = "";
      // let message = this.kycFormStore.formData.mobileNumberAndEmailEditRoute === "enterEmailOtp" ? "Email ID " : "Mobile Number ";
      if (this.isEmailTouched && this.isMobileNumberTouched) {
        message = "Contact Details ";
      } else if (this.isEmailTouched && !this.isMobileNumberTouched) {
        message = "Primary Email ID ";
      } else if (!this.isEmailTouched && this.isMobileNumberTouched) {
        message = "Primary Mobile Number ";
      }
      console.log("message", message);
      message += " changed successfully";
      BuefyHelper.showToastMessage(message, "is-danger");

      // this.$router.push(
      //   this.kycFormStore.formData.mobileNumberAndEmailEditRoute
      // );
      this.$router.push({
        path: this.kycFormStore.formData.mobileNumberAndEmailEditRoute,
        query: {
          next: this.$route.query.next,
          previous: this.$route.query.previous,
        },
      });
    },
  },

  mounted() {
    for (const country of this.kycFormStore.formDataSupport.countriesArray) {
      this.countryCodeArray.push(`${country.code} ${country.dial_code}`);
    }

    if (
      this.kycFormStore.formData.residentship === "Sri Lanka" &&
      !this.kycFormStore.formData.countryCode
    ) {
      this.kycFormStore.formData.countryCode = "+94";
      this.kycFormStore.formData.country_short_name = "LK";
    } else if (
      !this.kycFormStore.formData.countryCode &&
      !this.kycFormStore.formData.residentship
    ) {
      this.kycFormStore.formData.countryCode = "+94";
      this.kycFormStore.formData.country_short_name = "LK";
    }

    console.log(
      "short country code name : " +
        this.kycFormStore.formData.country_short_name
    );

    const tempCountryCodeName =
      this.kycFormStore.formDataSupport.countriesArray.find(
        (country) =>
          country.code === this.kycFormStore.formData.country_short_name
      );

    console.log("country filtered + " + JSON.stringify(tempCountryCodeName));

    let tempUserCode =
      tempCountryCodeName.code + " " + this.kycFormStore.formData.countryCode;
    this.userCode = this.countryCodeArray.find(
      (hello) => hello === tempUserCode
    );

    this.changeSelection();

    this.shortNameEdit = this.kycFormStore.formData.country_short_name;
    this.iconStringEdit = this.iconString;
    this.countryCodeEdit = this.kycFormStore.formData.countryCode;
    this.mobileNumberEdit = this.kycFormStore.formData.mobileNumber;
    this.contactNoEdit = this.kycFormStore.formData.contact_no;
    this.userCodeEdit = this.userCode;
    this.emailEdit = this.kycFormStore.formData.email;

    if (this.kycFormStore.formData.customer_passport_no) {
      this.isShow = true;
    }

    this.mobileNumValidate();
    this.mobileNumEditValidate();
  },
  computed: {
    filteredDataArr() {
      console.log("computed called -------------------");
      console.log("computed user code : " + this.userCode);
      this.noEnrolementCountries(this.userCode);
      return this.countryCodeArray.filter((option) => {
        return option.toLowerCase().indexOf(this.userCode.toLowerCase()) >= 0;
      });
    },
    filteredDataArrEdit() {
      this.noEnrolementCountries(this.userCodeEdit);
      return this.countryCodeArray.filter((option) => {
        return (
          option.toLowerCase().indexOf(this.userCodeEdit.toLowerCase()) >= 0
        );
      });
    },
  },
};
</script>
<style scoped>
.underline {
  width: 417px !important;
}
.b-checkbox.checkbox {
  outline: none;
  display: inline-flex;
  align-items:unset;
}
</style>
