<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
    <logopage /></transition>
    <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
    <img
      src="../assets/images/icons/Arrow.png"
      alt="Arrow"
      width="40px"
      @click="$router.push('applicationStatusCheck')"
      style="cursor: pointer"
    /></transition>
    <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
    <div class="columns is-centered mb-0">
      <div class="column is-half ps-5" style="text-align: center">
        <p class="subhead-line is-size-4" style="line-height: 10px">
          <img :src=hiWave alt="hi-img" width="7%" />
          {{ $root.selectedLanguageData.hello
          }}<span class="ml-3" style="margin-left: 0.5rem !important;"
            >{{ kycFormStore.formData.calling_name || "Charith" }}</span
          >
        </p>
        <p class="headline-font" style="line-height: 1.4">
          {{ $root.selectedLanguageData.welcome_back }}
        </p>
      </div>
    </div> </transition>
    <transition
      appear
          @before-enter="$animations.beforeEnterFade"
          @enter="$animations.enterFade"
    >
    <div style="display: flex; justify-content: center">
      <div
        style="border-bottom: 2px solid rgba(35, 31, 32, 0.79); opacity: 0.5"
        class="underline"
      ></div>
    </div></transition>
   
    <div class="box mt-4">
      <b-tabs>
        <b-tab-item style="padding: 8px">
          <b-loading :is-full-page="false" :active="tableDetails.length === 0" v-if="tableDetails.length ===0" ></b-loading>
          <TabelComponent
              v-else
            :tableData="tableDetails"
            :columns="columnDetails" />
        </b-tab-item>
      </b-tabs>
    </div>
    <!-- <GetHelp/> -->
  </div>
</template>
<script>
import TabelComponent from "./supports/TabelComponent.vue";
import logopage from "./CompanyTopLogo.vue";
// import GetHelp from "./GetHelp.vue";
import NetworkManager from "@/utils/networkManager";

import "../validate.js";
import { useKycFormStore } from "@/stores/KycFormStore";
import { PiniaStoreHelper } from "@/heplers/piniaStoreHelper";
import { tokenGenerator } from "@/heplers/tokenGenerator";
export default {
  /*
   * ===================================================================================================================
   * expose kyc form pinia store to the component
   */
  setup() {
    const kycFormStore = useKycFormStore();
    const piniaStoreHelper = PiniaStoreHelper;
    return { kycFormStore, piniaStoreHelper};
  },

  components: {
    logopage,
    // GetHelp,
    TabelComponent,
  },
  data() {
    return {
      hiWave : require("@/assets/images/icons/hi.png"),
      columnDetails: [
        { id: 1, field: "reference_number", label: "REFERENCE NO:" },
        { id: 2, field: "application_type", label: "APPLICATION TYPE" },
        { id: 3, field: "last_modified_date", label: "LAST MODIFIED DATE" },
        { id: 4, field: "submitted_date", label: "SUBMITTED DATE" },
        { id: 5, field: "status", label: "STATUS" },
      ],
      tableDetails: [],
    };
  },
  // beforeRouteEnter(to, from, next) {
  //   // const comp = this
  //   if ((from.path == "/showsubmittedapplication" || from.path == "/otpPage")) {
  //     next()
  //     }
  //     else{
  //       // Redirect to "application"
  //       next({path:"/applicationStatusCheck"})
    
  //     }
  //   },
  
  beforeRouteEnter(to, from, next) 
  {
    if(tokenGenerator.validateToken())
    {
      next()
    }
    else
    {
      // Redirect to "application"
      next({path:"/applicationStatusCheck"})
    }
  },

  mounted() {
    console.log("comp.kycFormStore.formData.customer_nic_eic_pp",this.kycFormStore.formData.customer_nic_eic_pp)
    this.getCustomerForms();

    if(this.kycFormStore.formData.customer_nic_eic_pp != ''){
      this.getCustomerForms();

    }else{
      console.log("NO NIC")
      this.$router.replace("applicationStatusCheck");
    } 
  },
  methods: {
    getCustomerForms: function () {
      const comp = this;
      const data = {
        nic_passport_number: this.kycFormStore.formData.customer_nic_eic_pp,
      };
      NetworkManager.apiRequest(
        "api/CustomerPortal/getCustomerForms",
        data,
        function (response) {
          if (response.statusCode == 200) {
            console.log(
              response.data.customer_portal_forms,
              "customer_portal_forms"
            );

            if (response.data.customer_portal_forms.length > 0) {
              comp.tableDetails = response.data.customer_portal_forms;
              // comp.kycFormStore.formData.customer_nic_eic_pp = ''
            }
          } 
          else {
            console.log("response.statusCode != 200 ")
            console.log("this.kycFormStore.formData",this.kycFormStore.formData)
            comp.$router.replace("/applicationStatusCheck");
            // const params = {
            //   type: "is-warning",
            //   message: "No existing kyc forms found for the given NIC/EIC/Passport Number",
            // };
            // comp.$buefy.toast.open(params);
          }
        }
      );
    },
  },
};
</script>
<style>
.underline {
  width: 417px !important;
}
</style>
