export  class SubmitAttachment {
    attachment_url = ""
    attachment_type = ""

    constructor(url, type) {
        if(url == "")
        {
            this.attachment_url = ""
            this.attachment_type = ""
        }
        else
        {
            this.attachment_url = url
            this.attachment_type = type
        }
    }
}