<template>
  <div class="box form-padding py-5">
    <p class="sub_headings has-text-centered">
      {{ $root.selectedLanguageData.product_selection }}
    </p>
    <p class="has-text-centered mb-4 note-line">
      <span class="has-text-danger has-text-weight-semibold">{{
        $root.selectedLanguageData.note
      }}</span>
      {{ $root.selectedLanguageData.max_product }}
    </p>
    <p
      v-if="
        kycFormStore.formData.product_selection[productSelectionId].account_type
      "
      class="mb-3 account"
    >
      {{
        kycFormStore.formData.product_selection[productSelectionId].account_type
      }}
    </p>
    <div class="columns is-4 is-variable">
      <div class="column">
        <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
          <b-field
            label-position="inside"
            :custom-class="
              kycFormStore.formData.product_selection[productSelectionId]
                .currency_type === '' && !isCurrencyFocus
                ? 'custom-float-label-initial'
                : 'custom-float-label'
            "
            class="mb-0"
          >
            <template #label>
              {{ $root.selectedLanguageData.currency }}</template
            >
            <b-select
              v-model="
                kycFormStore.formData.product_selection[productSelectionId]
                  .currency_type
              "
              @change.native="clearAccountTypeSelection()"
              rounded
              expanded
              :class="
                kycFormStore.formData.product_selection[productSelectionId]
                  .currency_type === '' && !isCurrencyFocus
                  ? 'arrow-up-before'
                  : 'arrow-up'
              "
              @focus="
                () => {
                  isCurrencyFocus = true;
                }
              "
              @blur="
                () => {
                  isCurrencyFocus = false;
                }
              "
            >
              <option
                v-for="currency in filteredDataArr"
                :key="currency.value"
                :value="currency.value"
              >
                {{ currency.langTranslation }}
              </option>
            </b-select>
          </b-field>
          <span class="has-text-danger">{{ errors[0] }}</span>
        </ValidationProvider>

        <!-- product -->
        <div class="mt-5">
          <div class="sub_topic_label">
            {{ $root.selectedLanguageData.accounts
            }}<span class="has-text-danger">*</span>
          </div>
          <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
            <!-- product list  -->
            <div
              class="columns product-list"
              v-if="
                getFilteredProductList(
                  kycFormStore.formData.product_selection[productSelectionId]
                    .currency_type === ''
                    ? ''
                    : kycFormStore.formData.product_selection[
                        productSelectionId
                      ].currency_type
                ).length > 0
              "
            >
              <div
                class="product-list-item"
                v-for="product in getFilteredProductList(
                  kycFormStore.formData.product_selection[productSelectionId]
                    .currency_type === ''
                    ? ''
                    : kycFormStore.formData.product_selection[
                        productSelectionId
                      ].currency_type
                )"
                :key="product.id"
              >
                <b-field>
                  <b-radio
                    class="en-style"
                    :name="productListGroupName"
                    :native-value="product"
                    v-model="selectedProduct"
                    @change.native="getSelectedProductDetails"
                  >
                    {{ product.name }}
                  </b-radio>
                </b-field>
              </div>
            </div>
            <div v-else><input type="hidden" v-model="selectedProduct" /></div>
            <span v-if="errors[0]" class="has-text-danger"
              >Please select your preferred product</span
            >
          </ValidationProvider>
          <p class="mt-4 product-link-line">
            <span class="has-text-danger has-text-weight-semibold mr-2">{{
              $root.selectedLanguageData.more_info
            }}</span>

            <a
              href="https://www.PayMedia.lk/products-categories/personal/"
              class="link"
              style="
                font-family: Gotham-Book;
                color: #231f20;
                text-decoration: underline;
              "
              target="_blank"
              >https://www.PayMedia.lk/products-categories/personal/</a
            >
          </p>
          <div class="mt-4 product-link">
            <p class="has-text-danger has-text-weight-semibold mr-2">
              {{ $root.selectedLanguageData.more_info }}
            </p>

            <a
              href="https://www.PayMedia.lk/products-categories/personal/"
              class="link"
              style="
                font-family: Gotham-Book;
                color: #231f20;
                text-decoration: underline;
              "
              target="_blank"
              >https://www.PayMedia.lk/products-categories/personal/</a
            >
          </div>
        </div>
        <br />
        <hr
          :style="{
            'background-color': '#1b1b1b',
            height: '1.8px',
            opacity: '0.5',
            'margin-top': '0',
          }"
        />
        <div>
          <label class="sub_topic_label"
            >{{ $root.selectedLanguageData.purpose_of_account_opening
            }}<span class="has-text-danger">*</span></label
          >
          <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
            <div class="columns purposes-list">
              <div
                class="purpose-list-item"
                v-for="(purpose, index) in $root.selectedLanguageData
                  .purposeList"
                :key="index"
              >
                <b-field>
                  <b-checkbox
                    class="en-style"
                    :native-value="
                      new PurposeOfAccountOpening(purpose.key, purpose.value)
                    "
                    v-model="
                      kycFormStore.formData.product_selection[
                        productSelectionId
                      ].purpose_of_opening_account
                    "
                  >
                    {{ purpose.langTranslation }}
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <div v-if="IsPurposeOfOpeningOtherSelected">
          <div>
            <b-field
              label-position="inside"
              :custom-class="
                specified_purpose_of_opening.trim() === '' &&
                !isSpecifiedPurposeInputFocus
                  ? 'custom-float-label-initial'
                  : 'custom-float-label'
              "
            >
              <template #label
                >{{ $root.selectedLanguageData.please_specify
                }}<span class="has-text-danger">*</span>
              </template>
              <p class="control has-icons-right">
                <ValidationProvider
                  name="Purpose"
                  :rules="validatePurpose()"
                  v-slot="{ errors }"
                >
                  <b-input
                    rounded
                    type="text"
                    v-model="specified_purpose_of_opening"
                    @focus="
                      () => {
                        isSpecifiedPurposeInputFocus = true;
                      }
                    "
                    @blur="
                      () => {
                        isSpecifiedPurposeInputFocus = false;
                      }
                    "
                    @change.native="addSpecifiedPurpose"
                  />
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                <span class="icon is-small is-right">
                  <img
                    :src=editImage
                    alt="contact-name-img"
                    width="25"
                    height="25"
                  />
                </span>
              </p>
            </b-field>
          </div>
        </div>
        <hr
          :style="{
            'background-color': '#1b1b1b',
            height: '1.8px',
            opacity: '0.5',
            'margin-top': 4,
          }"
        />

        <!-- Anticipated Volumes of Deposits from above Sources -->
        <div class="mt-5">
          <label class="sub_topic_label">
            {{ $root.selectedLanguageData.anticipated_volumes}}
            <!-- <span v-if="IsCurrencyIsLKR" class="has-text-danger">*</span> -->
            <span class="has-text-danger">*</span>
          </label>
          <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
            <div class="columns volumes-list">
              <div
                class="volume-list-item"
                v-for="(volume, index) in $root.selectedLanguageData
                  .anticipatedVolumesList"
                :key="index"
              >
                <b-field>
                  <b-radio
                    class="en-style"
                    :name="anticipatedVolumesGroupName"
                    :native-value="volume.value"
                    v-model="
                      kycFormStore.formData.product_selection[
                        productSelectionId
                      ].anticipated_volume
                    "
                  >
                    {{ volume.langTranslation }}
                  </b-radio>
                </b-field>
              </div>
            </div>
            <span class="has-text-danger">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>
        <div>
          <p v-if="!IsCurrencyIsLKR" class="mb-4 mt-5 note-line">
            <span class="has-text-danger has-text-weight-semibold">{{
              $root.selectedLanguageData.note
            }}</span>
            {{ $root.selectedLanguageData.fcy_initial_deposit_note }}
          </p>
          <ValidationProvider
            :rules="{
              required: IsCurrencyIsLKR,
              validateInitialDeposit: IsCurrencyIsLKR,
              validateInitialDepositFC: !IsCurrencyIsLKR,
            }"
            v-slot="{ errors }"
          >
            <b-field
              label-position="inside"
              :custom-class="
                initial_deposit.trim() === '' && !isInitialDepositInputFocus
                  ? 'custom-float-label-initial'
                  : 'custom-float-label'
              "
            >
              <template #label v-if="IsCurrencyIsLKR">
                {{ $root.selectedLanguageData.initial_deposit }}
                {{ $root.selectedLanguageData.commas }}
                {{
                  kycFormStore.formData.product_selection[productSelectionId]
                    .currency_type
                }}<span class="has-text-danger">*</span>
              </template>
              <template #label v-else>
                {{ $root.selectedLanguageData.initial_deposit }}
                {{ $root.selectedLanguageData.commas }}
                {{
                  kycFormStore.formData.product_selection[productSelectionId]
                    .currency_type
                }}<span class="has-text-danger"></span>
              </template>

              <p class="control has-icons-right">
                <b-input
                  rounded
                  v-model="initial_deposit"
                  @focus="
                    () => {
                      isInitialDepositInputFocus = true;
                    }
                  "
                  @blur="
                    () => {
                      isInitialDepositInputFocus = false;
                    }
                  "
                  @input="formatInitialDepositAmount()"
                />
                <span class="icon is-small is-right">
                  <img
                    :src=editImage
                    alt="contact-name-img"
                    width="25"
                    height="25"
                  />
                </span>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </p>
            </b-field>
          </ValidationProvider>
        </div>
        <div v-if="IsAbleToAddNewProduct" class="box mt-5 has-text-centered">
          <div
            :style="{
              cursor: 'pointer',
              color: '#F57E82',
              'line-height': '20px',
              'letter-spacing': '0.7px',
            }"
            v-if="!IsNextProductSelectionAvailable"
          >
            <p @click="addNewProductSelection">
              <span class="icon has-text-info mr-2">
                <img
                  :src=criclePlus
                  alt="Circle_Plus image"
                />
              </span>
              <span class="is-size-6-mobile mt-1"
                >{{ $root.selectedLanguageData.select_another_acc }}
              </span>
            </p>
          </div>
          <div
            :style="{
              cursor: 'pointer',
              color: '#F57E82',
              'line-height': '20px',
              'letter-spacing': '0.7px',
            }"
            v-if="IsNextProductSelectionAvailable"
          >
            <p @click="removeNextProductSelection">
              <span class="icon has-text-info mr-2">
                <img
                  :src=circleMinus
                  alt="Circle_Mini image"
                />
              </span>
              <span class="is-size-6-mobile mt-1">
                {{ $root.selectedLanguageData.remove_account_name }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useKycFormStore } from "@/stores/KycFormStore";
import PurposeOfAccountOpening from "@/heplers/classes/productDetail/PurposeOfAccountOpening";
import { ProductDetail } from "@/heplers/classes/productDetail/ProductDetail";

export default {
  name: "ProductSelectionComponent",

  computed: {
    // hasDuplicateProductIds() {
    //   const productIds = new Set();

    //   for (const item of this.kycFormStore.formData.product_selection) {
    //     if (productIds.has(item.product_id)) {
    //       return true; // Duplicates found
    //     }
    //     productIds.add(item.product_id);
    //   }

    //   return false; // No duplicates found
    // },

    filteredDataArr() {
      console.log(
        "this.$root.selectedLanguageData.currencyList",
        this.$root.selectedLanguageData.currencyList
      );

      return this.$root.selectedLanguageData.currencyList
        .slice()
        .sort((a, b) => a.value.localeCompare(b.value));
    },

    PurposeOfAccountOpening() {
      return PurposeOfAccountOpening;
    },

    IsPurposeOfOpeningOtherSelected() {
      console.log("computed");
      const comp = this;
      let otherPurpose = comp.kycFormStore.formData.product_selection[
        comp.productSelectionId
      ].purpose_of_opening_account.find(
        (purpose) => purpose.key === "purpose_other"
      );

      if (!otherPurpose) {
        let otherDescriptionIndex =
          comp.kycFormStore.formData.product_selection[
            comp.productSelectionId
          ].purpose_of_opening_account.findIndex(
            (purpose) => purpose.key === "purpose_other_specified"
          );
        console.log("purpose_other_specified index : " + otherDescriptionIndex);

        if (otherDescriptionIndex !== -1) {
          console.log(
            "purpose_other removed - but specified available - removing specified purpose"
          );
          comp.removePurposeOtherSpecified(otherDescriptionIndex);
        }
      }

      return !!otherPurpose;
    },

    IsAbleToAddNewProduct() {
      const comp = this;
      console.log("check max products added");
      // note : comp.productSelectionId + 1 -> 1 was added since array index start with 0 :D
      return (
        comp.productSelectionId + 1 <
        comp.kycFormStore.formDataSupport.maxProducts
      );
    },

    IsFirstProduct() {
      const comp = this;
      return comp.productSelectionId === 0;
    },

    IsNextProductSelectionAvailable() {
      const comp = this;
      let productSelectionCount =
        comp.kycFormStore.formData.product_selection.length;

      return productSelectionCount - 1 > comp.productSelectionId;
    },

    IsCurrencyIsLKR() {
      const comp = this;
      return (
        comp.kycFormStore.formData.product_selection[comp.productSelectionId]
          .currency_type === "LKR"
      );
    },
  },

  watch: {
    "kycFormStore.formData.product_selection"() {
      console.log(
        "product selection array changed - updating component variables"
      );
      console.log(
        "product selection :",
        this.kycFormStore.formData.product_selection
      );
      const comp = this;
      comp.updateComponentVariables();
      comp.getSelectedProductDetails();
      comp.checkduplicates();
    },
  },

  props: {
    productSelectionId: Number,
  },

  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },

  data() {
    return {
      criclePlus : require("@/assets/images/icons/Circle-plus.png"),
      circleMinus : require("@/assets/images/icons/CircleMinus.png"),
      editImage : require("@/assets/images/icons/Edit.png"),
      isCurrencyFocus: false,
      selectedProduct: null,
      specified_purpose_of_opening: "",
      isSpecifiedPurposeInputFocus: false,
      initial_deposit: "",
      isInitialDepositInputFocus: false,
      anticipatedVolumesGroupName: "",
      productListGroupName: "",
    };
  },

  methods: {
    /**
     * This function can be used to clear the account type selection
     */
    clearAccountTypeSelection() {
      const comp = this;
      comp.kycFormStore.formData.product_selection[
        comp.productSelectionId
      ].product_id = "";
      comp.kycFormStore.formData.product_selection[
        comp.productSelectionId
      ].account_type = "";
      comp.selectedProduct = null;
    },

    /***
     * This function can be used ot ge filtered product list based on currency selection
     * @param currency
     */
    getFilteredProductList(currency) {
      const comp = this;
      let filteredProductList = [];

      // check currency is empty
      if (currency !== "") {
        comp.kycFormStore.formDataSupport.productList.forEach((product) => {
          if (product.currencies.includes(currency)) {
            filteredProductList.push(product);
            console.log("filteredProductList", filteredProductList);
            // if(filteredProductList.length == 0)
            // {
            //   this.$buefy.toast.open({
            //     duration: 5000,
            //     message: "Sorry, We don't have accounts for your currency type",
            //     type: 'is-danger'
            // })
            // }
          }
        });
        if (currency !== "" && filteredProductList.length == 0) {
          console.log("OH NOOOOOOO");
          if (filteredProductList.length == 0) {
            this.$buefy.toast.open({
              duration: 5000,
              message:
                "Sorry, We don't have accounts for your selected currency type",
              type: "is-danger",
            });
          }
        }

        // return filtered product list
        return filteredProductList;
      } else {
        // if currency is not selected, return all products from api
        return comp.kycFormStore.formDataSupport.productList;
      }
    },

    checkduplicates: function () {
      const comp = this;
      const productIds = new Set();
      for (const item of this.kycFormStore.formData.product_selection) {
        if (productIds.has(item.product_id) && item.product_id != "") {
          console.error("ERROR: Duplicate product_id found");
          comp.kycFormStore.buttonState = false;
          comp.$buefy.toast.open({
            duration: 5000,
            message: `You can't select the same Account type more than once`,
            type: "is-danger",
          });
        } else {
          comp.kycFormStore.buttonState = true;
        }
        productIds.add(item.product_id);
      }
    },
    /**
     * This method can be used to update product selection details when account selection is changed
     */
    getSelectedProductDetails() {
      const comp = this;

      console.log("product selection - account type changed");
      console.log(
        "product selection :",
        this.kycFormStore.formData.product_selection
      );
      comp.kycFormStore.formData.product_selection[
        comp.productSelectionId
      ].product_id = comp.selectedProduct.product_id;
      comp.kycFormStore.formData.product_selection[
        comp.productSelectionId
      ].account_type = comp.selectedProduct.name;
      comp.checkduplicates();
    },

    /**
     * This method can be used to format the initial deposit amount
     */
    formatInitialDepositAmount() {
      const comp = this;

      if (comp.initial_deposit.trim() !== "") {
        const number = Number(comp.initial_deposit.trim().replaceAll(`,`, ``));
        comp.initial_deposit = isNaN(number) ? `0` : number.toLocaleString();
        // comp.kycFormStore.formData.product_selection[comp.productSelectionId].initial_deposit_amount= isNaN(number) ? `0` : comp.initial_deposit.trim().replaceAll(`,`, ``);
        comp.kycFormStore.formData.product_selection[
          comp.productSelectionId
        ].initial_deposit_amount = isNaN(number) ? `0` : comp.initial_deposit;
      } else {
        comp.kycFormStore.formData.product_selection[
          comp.productSelectionId
        ].initial_deposit_amount = ``;
        comp.initial_deposit = comp.initial_deposit.trim();
      }
    },

    /**
     * A method to get validation object for the purpose
     *
     */
    validatePurpose() {
      const obj = {
        required: true,
        validatePurpose: true,
        min: 3,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },

    /**
     * A method to check is specified purpose is available in the purposes array
     * @returns {boolean}
     */
    checkSpecifiedPurposeIsAvailable() {
      const comp = this;
      let existing = comp.kycFormStore.formData.product_selection[
        comp.productSelectionId
      ].purpose_of_opening_account.find(
        (purpose) => purpose.key === "purpose_other_specified"
      );

      return !!existing;
    },

    /**
     * A method to add specified purpose to the purposes array
     */
    addSpecifiedPurpose() {
      const comp = this;

      if (comp.checkSpecifiedPurposeIsAvailable()) {
        let exisingPurposeSpecifiedIndex =
          comp.kycFormStore.formData.product_selection[
            comp.productSelectionId
          ].purpose_of_opening_account.findIndex(
            (purpose) => purpose.key === "purpose_other_specified"
          );
        console.log("specified index : " + exisingPurposeSpecifiedIndex);

        // update existing data
        console.log("updating exsiting specified object");
        comp.kycFormStore.formData.product_selection[
          comp.productSelectionId
        ].purpose_of_opening_account[exisingPurposeSpecifiedIndex].purpose =
          comp.specified_purpose_of_opening;
      } else {
        // add new object
        console.log("adding specified as new object");
        let newPurpose = new PurposeOfAccountOpening(
          "purpose_other_specified",
          comp.specified_purpose_of_opening
        );
        comp.kycFormStore.formData.product_selection[
          comp.productSelectionId
        ].purpose_of_opening_account.push(newPurpose);
      }
    },

    /**
     * A method to add new product selection
     */
    addNewProductSelection() {
      console.log("adding new product selection model");
      const comp = this;
      comp.kycFormStore.formData.product_selection.push(new ProductDetail());
    },

    /**
     * A method to remove next product selection
     */
    removeNextProductSelection() {
      console.log("removing next product selection model");
      const comp = this;

      comp.kycFormStore.formData.product_selection.splice(
        comp.productSelectionId + 1,
        1
      );
    },

    /**
     * A method to update component variables
     */
    updateComponentVariables() {
      const comp = this;

      // assign values to component variables
      if (comp.checkSpecifiedPurposeIsAvailable()) {
        let purposeObject = comp.kycFormStore.formData.product_selection[
          comp.productSelectionId
        ].purpose_of_opening_account.find(
          (purpose) => purpose.key === "purpose_other_specified"
        );
        comp.specified_purpose_of_opening = purposeObject.purpose;
      }

      comp.initial_deposit =
        comp.kycFormStore.formData.product_selection[
          comp.productSelectionId
        ].initial_deposit_amount.toLocaleString();

      // let selectedProductId = Number(comp.kycFormStore.formData.product_selection[comp.productSelectionId].product_id)

      // if(selectedProductId !== ""){
      //   console.log("selected product id : "+selectedProductId)
      //   comp.selectedProduct = comp.kycFormStore.formDataSupport.productList.find(product => product.id === selectedProductId)
      //   console.log("selected product : "+JSON.stringify(comp.selectedProduct))
      // }

      let selectedProductName =
        comp.kycFormStore.formData.product_selection[comp.productSelectionId]
          .account_type;

      if (selectedProductName !== "") {
        console.log("selected product name : " + selectedProductName);
        comp.selectedProduct =
          comp.kycFormStore.formDataSupport.productList.find(
            (product) => product.name === selectedProductName
          );
        console.log(
          "selected product : " + JSON.stringify(comp.selectedProduct)
        );
      }

      comp.anticipatedVolumesGroupName =
        "anticipated_volumes_" + comp.productSelectionId.toLocaleString();
      comp.productListGroupName =
        "product_list_" + comp.productSelectionId.toLocaleString();
    },

    /**
     * A method to remove purpose_other_specified from the array
     * @param index
     */
    removePurposeOtherSpecified(index) {
      const comp = this;
      comp.kycFormStore.formData.product_selection[
        comp.productSelectionId
      ].purpose_of_opening_account.splice(index, 1);
    },
  },

  // life cycle hooks

  mounted() {
    //
    const comp = this;
    console.log("Product selection model index : " + comp.productSelectionId);

    comp.updateComponentVariables();
    comp.checkduplicates();
  },
};
</script>

<style scoped>
.purposes-list,
.volumes-list {
  flex-wrap: wrap;
  /*padding: 0.5rem;*/
  align-items: baseline;
}
.purpose-list-item {
  width: 210px;
  height: 64px;
  padding: 12px;
}

.volume-list-item {
  width: 280px;
  height: 64px;
  padding: 12px;
}
.product-link {
  display: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .product-link-line {
    display: none;
  }
  .product-link {
    display: block;
    font-family: "Gotham-Book";
    font-size: 0.8rem;
    color: #231f20;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .product-link-line {
    font-family: "Gotham-Book";
    font-size: 0.72rem;
    color: #231f20;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 675px) {
  .product-link-line {
    font-family: "Gotham-Book";
    font-size: 0.8rem;
    color: #231f20;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 800px) {
  .product-link-line {
    font-family: "Gotham-Book";
    font-size: 0.9rem;
    color: #231f20;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 889px) {
  .product-link-line {
    font-family: "Gotham-Book";
    font-size: 0.92rem;
    color: #231f20;
  }
}
</style>
