<template>
  <div>
    <b-table :data="tableData" :class="styles">
      <b-table-column
        v-for="column in columns"
        :key="column.id"
        :field="column.field"
        :label="column.label"
        v-slot="props"
      >
        <span v-if="column.field !== `status`">
          <p
            v-if="
              column.field === 'submitted_date' ||
              column.field === 'last_modified_date'
            "
          >
            {{
              props.row[column.field] == null
                ? "-"
                : getFormattedDateTime(props.row[column.field])
            }}
          </p>
          <p v-else>
            {{
              props.row[column.field] == null ? "-" : props.row[column.field]
            }}
          </p>
        </span>
        <div v-if="column.field === `status`">
          <p class="mb-0">{{ props.row.status }}</p>
          <div>
            <b-button
              v-if="props.row.status !== 'Incomplete'"
              class="is-primary is-rounded action-btn"
              @click="viewApplication(props.row.reference_number)"
              :loading="isButtonLoading"
            >
              {{ props.row.is_form_submitted ? `View` : `View` }}
            </b-button>

            <b-button
              v-if="props.row.status === 'Incomplete' ||  props.row.status === 'Submitted' || props.row.status === 'ASSIGNED-DETAIL-REVIEWER'|| props.row.status === 'SUBMITTED-CALL-AGENT'|| props.row.status === 'ASSIGNED-CALL-AGENT' "
              class="is-primary is-rounded action-btn"
              @click="
                clickFunction(
                  props.row.form_data,
                  props.row.next_vue_route,
                  props.row.is_form_submitted
                )
              "
              :loading="isButtonLoading"
            >
              {{
                props.row.is_form_submitted ? `Request to Re-schedule` : `View`
              }}
            </b-button>
          </div>
          <!-- <TabelButton
            :buttonName="
              props.row.is_form_submitted
                ? `Request to Re-schedule`
                : `View`
            "
            
            :clickFunc="
              props.row.is_form_submitted ? submitted : incomplete(props.row.form_data,props.row.last_updated_vue_route)
            "
          /> -->
        </div>
      </b-table-column>
    </b-table>

    <b-modal v-model="isModalActive" scroll="keep" :width="630">
     <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
      <div class="model_box mt-3">
        <div
          class="has-text-centered"
          style="transition-timing-function: ease-in-out"
        >
          <div class="modal-close-button is-pulled-right mb-6">
            <button
              class="delete"
              aria-label="close"
              @click="isModalActive = false"
            ></button>
          </div>
          <div>
            <img
              src="../../assets/images/header-img/ResheduleGirlImg.png"
              alt="ResheduleGirlImg"
              width="40%"
              class="mt-3"
            />

            <p class="has-text-primary status-hero-subhead-line is-uppercase">
              {{ $root.selectedLanguageData.request_to_reschedule }}
            </p>
            <div style="font-size: 19px" class="mt-2">
              {{ $root.selectedLanguageData.request_line_one
              }}<span style="font-family: Gotham-Medium" class="mx-2"
                >{{ $root.selectedLanguageData.request_line_two }}</span
              >{{ $root.selectedLanguageData.request_line_three }}
            </div>
            <div class="mt-2">
              <div
                class="is-size-4 is-size-6-mobile reference-num reschedule-email-area"
              >
                <span class="reschedule-email"
                  >digitalonboarding@PayMedia.com</span
                >
                <div class="spacer"></div>
                <button
                  class="reschedule-copy-btn"
                  @click="copyToClipboard('digitalonboarding@PayMedia.com')"
                >
                  <b-icon
                    class="reschedule-icon"
                    icon="content-copy"
                    size="is-medium"
                  />
                </button>
              </div>
            </div>
          </div>
          <div style="margin-inline: 100px">
            <b-button
              type="is-primary"
              class="py-5 mt-5"
              expanded
              rounded
              @click="isModalActive = false"
              >{{ $root.selectedLanguageData.done }}</b-button
            >
          </div>
        </div>
      </div></transition></b-modal
    >
  </div>
</template>

<script>
// import TabelButton from "./TabelButton.vue";
import { ApiRequestManager } from "@/utils/networkManager/apiRequestManager";
import { useKycFormStore } from "@/stores/KycFormStore";
import { DateTimeManger } from "@/utils/dateTimeManager/dateTimeManger";
import { PiniaStoreHelper } from "@/heplers/piniaStoreHelper";
import { BuefyHelper } from "@/heplers/buefyHelper";
// import { tokenGenerator } from "@/heplers/tokenGenerator";

export default {
  /*
   * ===================================================================================================================
   * expose kyc form pinia store to the component
   */
  setup() {
    const kycFormStore = useKycFormStore();
    const piniaStoreHelper = PiniaStoreHelper;
    return { kycFormStore, piniaStoreHelper };
  },

  // components: { TabelButton },
  props: ["tableData", "columns", "styles"],
  name: "TableComp",
  data() {
    return {
      isModalActive: false,
      animated: true,
      isButtonLoading: false,
    };
  },
  methods: {
    /**
     * This function can be used to copy text to clipboard
     * @param text
     */
    copyToClipboard(text) {
      console.log("copy to clipboard called");
      const dummy = document.createElement("textarea");
      document.body.appendChild(dummy);
      dummy.value = text;
      dummy.select();
      document.execCommand("copy");
      document.body.removeChild(dummy);

      BuefyHelper.showToastMessage("Copied to Clipboard", "is-danger");
    },

    getFormattedDateTime(date) {
      return DateTimeManger.formatDateTime(date);
    },

    incomplete(form_data, last_updated_vue_route) {
      console.log("incomplete Clicked");
      // const comp=this
      let a = form_data;
      let b = last_updated_vue_route;
      console.log(a, b);
      // comp.$root.formData=JSON.parse(form_data)
      // comp.$router.push(last_updated_vue_route);
    },

    submitted() {
      console.log("Submitted Clicked");
      this.isModalActive = true;
    },

    viewApplication(ref_no) {
      const comp = this;
      comp.isButtonLoading = true;

      let tempId = comp.kycFormStore.formData.customer_nic_eic_pp
      
      comp.kycFormStore.formData.customer_nic_eic_pp = tempId;

      comp.kycFormStore.kyc_reference_no = ref_no;
      // if(tokenGenerator.validateToken())
      // {
        comp.$router.push("showsubmittedapplication");
      // }
      // else
      // {
      // // clear storage before getting previous values
      // comp.piniaStoreHelper.clearLocalStorage(comp.kycFormStore);
      // comp.$router.replace("applicationStatusCheck");
      
      // }
    },

    clickFunction(form_data, next_vue_route, is_form_submitted) {
      if (!is_form_submitted) {
        console.log("incomplete Clicked");
        const comp = this;

        comp.isButtonLoading = true;

        // clear storage before getting previous values
        // comp.piniaStoreHelper.clearLocalStorage(comp.kycFormStore);

        comp.kycFormStore.formData = JSON.parse(form_data);

        if(comp.kycFormStore.formData.nic_issue_date !== null){
          comp.kycFormStore.formData.nic_issue_date = new Date(
            comp.kycFormStore.formData.nic_issue_date
          );
        }
        comp.kycFormStore.formData.date_of_birth = new Date(
          comp.kycFormStore.formData.date_of_birth
        );

        if(comp.kycFormStore.formData.passport_expiry_date !== null){
          comp.kycFormStore.formData.passport_expiry_date = new Date(
              comp.kycFormStore.formData.passport_expiry_date
          );
        }

        if(comp.kycFormStore.formData.passport_issue_date !== null){
          comp.kycFormStore.formData.passport_issue_date = new Date(
              comp.kycFormStore.formData.passport_issue_date
          );
        }
        comp.kycFormStore.formData.preferred_date_for_video_call = new Date(
          comp.kycFormStore.formData.preferred_date_for_video_call
        );

        // comp.kycFormStore.formData = JSON.parse(form_data);
        // if(comp.kycFormStore.formData.nic_issue_date != ""){
        //   comp.kycFormStore.formData.nic_issue_date = new Date(
        //     comp.kycFormStore.formData.nic_issue_date
        //   );
        // }
        // comp.kycFormStore.formData.date_of_birth = new Date(
        //   comp.kycFormStore.formData.date_of_birth
        // );
        // comp.kycFormStore.formData.passport_expiry_date = new Date(
        //   comp.kycFormStore.formData.passport_expiry_date
        // );
        // comp.kycFormStore.formData.passport_issue_date = new Date(
        //   comp.kycFormStore.formData.passport_issue_date
        // );
        // comp.kycFormStore.formData.preferred_date_for_video_call = new Date(
        //   comp.kycFormStore.formData.preferred_date_for_video_call
        // );

        ApiRequestManager.getCountryList(
          (countries) =>
            (this.kycFormStore.formDataSupport.countriesArray = countries)
        );
        ApiRequestManager.getDistrictList(
          (districts) =>
            (this.kycFormStore.formDataSupport.districtsArray = districts)
        );
        ApiRequestManager.getBranchList((branches) => {
          this.kycFormStore.formDataSupport.branchesArray = branches;
          comp.isButtonLoading = false;
          comp.$router.push(next_vue_route);
        });
      } else {
        console.log("Submitted Clicked");
        this.isModalActive = true;
      }
    },
  },
};
</script>
<style scoped>
.action-btn {
  margin: 3px;
}
</style>
