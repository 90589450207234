import NetworkManager from "@/utils/networkManager/index";

/*
 * ======================================================================================================================
 * This util can be used to define re usable api endpoints
 * */
const ApiRequestManager = {
  /*
   * ==================================================================================================================
   * This method is used to get country list
   *
   *  */
  getCountryList: function (callback) {
    NetworkManager.apiRequest("api/KycForm/getCountryList",{},function (response) {
        if (response.statusCode === 200) {
          console.log("country list loaded successfully");
          callback(response.data.countries);
        } else {
          console.log("failed to load country list");
          callback([]);
        }
      },false,true );
  },

  /*
   * ==================================================================================================================
   * This method is used to get country list
   *
   *  */
  getDistrictList: function (callback) {
    NetworkManager.apiRequest("api/KycForm/getDistrictList",{},function (response) {
        console.log("district list loaded response : ",response);
        if (response.statusCode === 200) {
          console.log("district list loaded successfully");
          callback(response.data.districts);
        } else {
          console.log("failed to load district list");
          callback([]);
        }
      },false,true);
  },

  getBranchList: function (callback) {
    NetworkManager.apiRequest("api/KycForm/branchList",{},function (response) {
        if (response.statusCode === 200) {
          console.log("branch list loaded successfully");
          console.log(response.data, "response.data");
          callback(response.data);
        } else {
          console.log("failed to load branch list");
          callback([]);
        }
      },false,true);
  },

  /***
   * =================================================================================================================
   * This function can be used to update last step details of the kyc form
   * @param token kyc landing token
   * @param lastStep last updated step number
   * @param nextVueRoute next vue route
   * @param lastUpdatedData last updated form data
   * @param callback a callback function get update status as true or false
   * @param lastUpdatedRoute last update vue route
   */
  async updateLastStep(
    token,
    lastStep,
    nextVueRoute,
    lastUpdatedData,
    callback,
    lastUpdatedRoute = ""
  ) {
    let requestBody = {
      token: token,
      last_updated_vue_route: lastUpdatedRoute,
      next_vue_route: nextVueRoute,
      last_step: lastStep,
      last_updated_data: JSON.stringify(lastUpdatedData),
    };

    await NetworkManager.apiRequest("api/KycForm/updateFormLastStep",requestBody,function (response) {
        if (response.statusCode === 200) {
          console.log("Last Step Update : " + response.data.message);
          callback(true);
        } else {
          console.log("Last Step Update : " + response.data.message);
          callback(false);
        }
      },false,true);
  },



  /**
   *===================================================================================================================
   * This function is used make an api request to update initial form data
   * @param token kyc landing token
   * @param applicationType type of the application
   * @param lastStep last updated step
   * @param nicNumber nic number
   * @param passportNumber passport number
   * @param nextVueRoute next vue route
   * @param lastUpdatedData last updated data
   * @param lastUpdatedVueRoute last updated vue route
   * @param callback callback function
   */
  updateInitialFormData: function (token,applicationType,lastStep,nicNumber,passportNumber, nextVueRoute,lastUpdatedData,lastUpdatedVueRoute = "") {
    var requestBody = {
      token: token,
      application_type: applicationType,
      last_step: lastStep,
      nic_number: nicNumber,
      passport_number: passportNumber,
      last_updated_vue_route: lastUpdatedVueRoute,
      next_vue_route: nextVueRoute,
      last_updated_data: JSON.stringify(lastUpdatedData),
    };

    NetworkManager.apiRequest("api/KycForm/updateFormInitialData", requestBody,function (response) {
        if (response.statusCode === 200) {
          console.log(response.data.message);
        } else {
          console.log(response.data.message);
        }
      },false,true );
  },


  /**
   * ===================================================================================================================
   * This function can be used to sent otp to the customer
   * @param sendSms send sms
   * @param sendEmail send email
   * @param customerNic customer nic number
   * @param customerMobileNumber customer mobile number
   * @param customerEmail customer email
   * @param callback callback function
   */
  sendOtp(sendSms,sendEmail,customerNic,customerMobileNumber="",customerEmail="",callback = null){

    const requestBody = {
      customer_nic: customerNic,
      send_email: sendEmail,
      send_sms: sendSms,
      email: customerEmail,
      mobile_number: customerMobileNumber
    }

    NetworkManager.apiRequest("api/KycForm/sendOtp",requestBody,function (response) {
          if (response.statusCode === 200) {
            console.log(response.data.message);

            if (callback !== null)
              callback(true)
          } else {
            console.log(response.data.message);
            if (callback !== null)
              callback(false)
          }
        },false,true);
  },


    /**
     * =================================================================================================================
     * This function can be used to validate an otp
     * @param otp otp needed to be verified
     * @param customerNic customer nic
     * @param callback callback function
     */
  validateOtp(otp, customerNic, callback){

    const requestBody = {
      otp : otp,
      customer_nic : customerNic
    }

    NetworkManager.apiRequest("api/KycForm/validateOtp", requestBody,function (response) {
          if (response.statusCode === 200) {
            callback(response.data.is_otp_validated)
          } else {
            console.log(response.data.message);
            callback(false)
          }
        },false,true);
  }

};

export { ApiRequestManager };
