import {ApiRequestManager} from "@/utils/networkManager/apiRequestManager";

const GlobalFunctions = {

    /**
     * =================================================================================================================
     * This function can be used to update initial form data
     * @param kycFormStore
     * @param router
     * @param callback
     */
    UpdateInitialFormDataAndNavigateToPersonalInfo(kycFormStore,router,callback){

        ApiRequestManager.updateInitialFormData(
            kycFormStore.formData.landing_token,
            "Savings",
            "1",
            kycFormStore.formData.customer_nic,
            kycFormStore.formData.customer_passport_no,
            "/personalDetail",
            kycFormStore.formData
        );
        callback()
        router.push("personalDetail");
    },


    /**
     * =================================================================================================================
     * This function can be used to update last step - step 2 (personal details step)
     * @param kycFormStore
     * @param router
     * @param callback
     */
    UpdateSecondLastStep(kycFormStore,router,callback){
        ApiRequestManager.updateLastStep(
            kycFormStore.formData.landing_token,
            "2",
            "/accountSelection",
            kycFormStore.formData,
            function (status) {
                callback()
                if (status) {
                    router.push("accountSelection");
                }
                else{
                    console.log("failed to update step 2")
                }
            }
        );
    },

    /**
     * =================================================================================================================
     * This function can be used to check whether the customer is out of sri lanka
     * @param kycFormStore
     * @returns {boolean}
     */
    CheckCustomerIsOutOfSriLanka(kycFormStore){
        return kycFormStore.formData.residentship === "Out of Sri Lanka"
    },


    /**
     * A method to check debit card service can be enabled or not
     * @param kycFormStore
     * @returns {false|boolean}
     */
    CheckDebitCardSectionVisible(kycFormStore){
        let isSriLankan = kycFormStore.formData.residentship != 'Out of Sri Lanka'
        let isLKRAvailable = false

        kycFormStore.formData.product_selection.forEach(productSelection => {
            if(productSelection.currency_type === "LKR"){
                isLKRAvailable = true
            }
        })

        let debitCardSectionVisible = isSriLankan && isLKRAvailable
        console.log("Show debit card section : "+debitCardSectionVisible)
        return debitCardSectionVisible
    },

    /**
     * A method to check virtual vault service can be enabled or not
     * @param kycFormStore
     * @returns {false|boolean}
     */
    CheckVirtualVaultSectionVisible(kycFormStore){
        let isSriLankan = kycFormStore.formData.residentship != 'Out of Sri Lanka'

        let virtualVaultSectionVisible = isSriLankan
        console.log("Show Virtual vault section : "+ virtualVaultSectionVisible)
        return virtualVaultSectionVisible
    },


    /**
     * A method to get currency type LKR accounts from the product selection
     * @param kycFormStore
     * @returns {*[]}
     */
    GetOnlyLKRAccounts(kycFormStore){

        let lkrAccounts = []

        kycFormStore.formData.product_selection.forEach(productSelection => {
            if(productSelection.currency_type === "LKR"){
                lkrAccounts.push(productSelection.account_type)
            }
        })

        return lkrAccounts
    },

    /***
     * This function is used to get status of the residence display value
     * @param statusOfResidence
     * @returns {string}
     */
    getStatusOfResidenceDisplayValue(statusOfResidence){
        let outputValue = "";

        switch (statusOfResidence){
            case "Renter":
                outputValue = "On Rent";
                break;
            case "Living With Parents":
                outputValue = statusOfResidence;
                break;
            case "House Holder":
                outputValue = statusOfResidence;
                break;
        }

        return outputValue;
    },

     /**
     * This function can be used to check given index is exist in the given array
     * @param array array
     * @param index array index
     * @returns {boolean}
     */
     checkIndexExistInArray(array,index){
        return typeof array[index] !== 'undefined';
    },

        /**
     * This function can be used to check given agent changed pep detail should show or hide
     * @param pepDeclaration pep declaration kyc attribute
     * @param index array index
     * @param property pep detail property
     * @returns {boolean}
     */
        showAgentChangedPepDetail(pepDeclaration,index,property){
            let customerPepDetails = pepDeclaration.customer_input.pep_person_details // details array
            let agentPepDetails = pepDeclaration.call_agent_input.pep_person_details // details array
    
            // debug logs
            // console.log("PEP TEST ===============")
            // console.log(pepDeclaration)
            // console.log(customerPepDetails[index][property])
            // console.log(customerPepDetails[index][property])
    
            if(pepDeclaration.is_changed_by_call_agent){
                // if(customerPepDetails[index][property])
    
                // check index exist in customer array
                if(this.checkIndexExistInArray(customerPepDetails,index)){
    
                    // check agent input is empty
                    if(agentPepDetails[index][property].trim() !== ''){
    
                        //compare inputs
                        if(customerPepDetails[index][property].trim() === agentPepDetails[index][property].trim()){
                            // inputs identical no need to display
                            return false;
    
                        }
                        else{
                            // show agent input
                            return true;
                        }
                    }
                    else{
                        // no agent input
                        return false;
                    }
                }
                else{
                    // check agent input is empty
                    if(agentPepDetails[index][property].trim() !== ''){
                        // return true since requested index only in agent array and its not empty
                        return true;
                    }
                    else{
                        // empty agent input
                        return false;
                    }
                }
            }
            else{
                return false;
            }
    
        },
    
        /**
         * This function can be used to get the display value of the user selected pep option
         * @param pepDeclaration pep declaration
         * @param isAgentInput is using for agent input or not default to customer input
         * @returns {string}
         */
        getPepSelectionDisplayValue(pepDeclaration,isAgentInput=false){
            let pepSelection;
    
            if(isAgentInput){
                pepSelection = pepDeclaration.call_agent_input
            }
            else{
                pepSelection = pepDeclaration.customer_input
            }
    
            console.log("PEP selection")
            console.log(pepSelection)
    
            // currently pep selection has only 3 following options
            if(pepSelection.not_politically_exposed){
                return "No";
            }
            else if(pepSelection.close_relative_politically_exposed){
                return "Yes : Close Relative Politically Exposed"
            }
            else if(pepSelection.iam_politically_exposed){
                return "Yes : I am Politically Exposed"
            }
        },
    
    
        /**
         * This function can be used to show or hide agent pep selection
         * @param pepDeclaration pep declaration
         * @returns {boolean}
         */
        showAgentChangedPepSelection(pepDeclaration){
            let customerSelection = pepDeclaration.customer_input
            let agentSelection = pepDeclaration.call_agent_input
    
            // check agent changed selection
            if(pepDeclaration.is_changed_by_call_agent){
    
                if(customerSelection.not_politically_exposed){
                   return !agentSelection.not_politically_exposed; // true only agent change other option
                }
                else if(customerSelection.close_relative_politically_exposed){
                    return !agentSelection.close_relative_politically_exposed; // true only agent change other option
                }
                else if(customerSelection.iam_politically_exposed){
                    return !agentSelection.iam_politically_exposed; // true only agent change other option
                }
            }
            else{
                // not changed
                return false
            }
    
        },
        
    /**
     * This function can be used to get the verified kyc field value from a kyc field
     * @param kycField
     * @returns {*}
     */
    getKycFieldVerifiedValue(kycField){
        return kycField.is_changed_by_call_agent === true ?  kycField.call_agent_input :  kycField.customer_input
      },
  
      clearLocalStorageToken(){
        const token = localStorage.getItem("token")
        if(token)
        {   
            console.log("User backed out. Clearing token")
            localStorage.removeItem("token")
        }
      },
      /**
       * This function can be used to show or hide, debit card related fields in the UI
       * @param residentship
       * @param productSelection
       * @returns {false|boolean}
       */
      checkDebitCardSectionVisible1(residentship,productSelection){
  
          console.warn("check debit card section is visible")
          console.log(residentship)
          console.log(productSelection)
          console.log("verified residentship value : "+ this.getKycFieldVerifiedValue(residentship))
          console.log("==============================================")
          let isSriLankan = this.getKycFieldVerifiedValue(residentship) !== 'Out of Sri Lanka'
          let isLKRAvailable = false
  
          productSelection.forEach(productSelection => {
              if(this.getKycFieldVerifiedValue(productSelection.currency_type) === "LKR"){
                  isLKRAvailable = true
              }
          })
  
          let debitCardSectionVisible = isSriLankan && isLKRAvailable
          console.log("Show debit card section : "+debitCardSectionVisible)
          return debitCardSectionVisible
      },
   
}

export  {GlobalFunctions}