import axios from "axios"
import JwtUtils from "@/utils/jwtUtils/jwtUtils";
import {appSettings} from "@/appSettings";
import heiroglyphic from "@/heplers/heiroglyphic";

class NetworkResponse{
    statusCode
    data

    constructor() {
        this.statusCode = 0
        this.data = null
    }
}

const NetworkManager = {

    apiRequest: function (endpoint, data, callBack, withToken = false,useEncryption=false){
        console.log("Base API URL : "+ appSettings.$api_url)

        const URL = appSettings.$api_url + "/" + endpoint

        let config = {
            headers: {},
            timeout: appSettings.timeoutDuration
        }

        if(withToken){
            config.headers = {
                Authorization: `Bearer ${JwtUtils.loadToken().token}`,
                'Content-Type': 'application/json'
            }
        }


        // following codes use when encryption is required
        if(useEncryption){
            let encryptedData = heiroglyphic.encrypt(data)

            console.log("Encrypted data : ",encryptedData)
            data = {
                data : encryptedData
            }
        }

        axios.post(
            URL,
            data,
            config
        )
            .then(function (e){

                let responseData = e.data;

                // console.log("Raw response from the endpoint : ",responseData)

                // following codes use to decrypt the result when encryption is required
                if(useEncryption){
                    // console.log("Encrypted data : ",e.data.data)

                    let decryptedData = heiroglyphic.decrypt(e.data.data)

                    // console.log("Decrypted response : ",decryptedData)

                    let decryptedDataNetworkResponse = new NetworkResponse();
                    decryptedDataNetworkResponse.statusCode = decryptedData.StatusCode;
                    decryptedDataNetworkResponse.data = decryptedData.Data;
                    responseData = decryptedDataNetworkResponse

                    console.log("Formatted Decrypted response : ",responseData)
                }
                callBack(responseData)
            })
            .catch(function (e){
                let resp = new NetworkResponse()
                console.log(e)

                if(e.response){
                    resp.statusCode = e.response.status

                    if(e.response.data){
                        resp = e.response.data
                    }

                    if(resp.statusCode === 401){
                        // JwtUtils.logoutTheUser()
                    }
                }
                else {
                    resp.statusCode = 502
                }

                callBack(resp)
            })
    }
}

export default NetworkManager
