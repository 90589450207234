<template>
  <div id="full-screen-loader">
    <p id="text">{{getLoaderMessage}}</p>
  </div>
</template>

<script>
export default {
  name: "FullScreenLoader",

  props :{
    message : String,

  },

  computed : {
    getLoaderMessage(){
      const comp = this
      return comp.message +" "+comp.counter.toString()
    }
  },


  data(){
    return {
      loaderText : "",
      counter  : 0,
    }
  },


  methods : {
    timer(){
      const comp = this
      comp.counter++
      setTimeout(comp.timer, 1000)
    }

  },


  // life cycle hooks
  mounted() {
    const comp = this
    console.log("full screen loader mounted")

    comp.counter = 0
    setTimeout("",1000)
    comp.timer()
  },

}
</script>

<style scoped>
#full-screen-loader{
  display: flex;
  height: 100vh;
  width: 100%;
  background: gray;
  opacity: 0.95;
  align-items: center;
  justify-content: center;
  top :0;
  position:fixed;
  z-index: 99;
  font-size: 30px;
  left: 0;

}
</style>