
import { PiniaStoreHelper } from "./piniaStoreHelper";
import { useKycFormStore } from "@/stores/KycFormStore";
import { BuefyHelper } from "./buefyHelper";

const tokenGenerator = {

  /**
   * =================================================================================================================
   * This function can be used to generate a loggin token form data
   * @param kycFormStore
   * @param router
   */

  generateToken(kycFormStore) {
    // In a real scenario, you should use a secure token generation mechanism
    // This is just a simplified example
    let id = kycFormStore.formData.customer_nic
    const tokenPayload = {
      userNIC: id,
      expTime: new Date().getTime() + 15*60*1000
      // Add any other relevant data
    };
    
    // Encode the payload into a JWT token (you may need a library for this)
    const token = btoa(JSON.stringify(tokenPayload));
    console.log("USER LOGGED IN")
    localStorage.setItem("token",token)
    return token;
  },

  /**
   * =================================================================================================================
   * This function can be used tocheck the validity of the token
   * @param kycFormStore
   * @param router
   */

  validateToken() {
    // Check if a token exists in local storage and set the isLoggedIn flag
    const storedToken = localStorage.getItem('token')
    console.log("storedToken",storedToken)

    if (!storedToken) {
      // Token is not present, user is not logged in
      return false;
    }
    
    try {
      // Decode the Base64 token
      const decodedToken = atob(storedToken);
      const tokenPayload = JSON.parse(decodedToken);
      console.log("decoded token :",tokenPayload)
      // Check if the token is expired
      const currentTime = new Date().getTime();
      if (currentTime > tokenPayload.expTime) {
        console.log("Your Token is Expired")
        BuefyHelper.showToastMessage("Session Expired", "is-danger")
        localStorage.removeItem("token")
        this.logoutTheUser()
        // Token has expired
        return false;
      }
      console.log("Token Still valid. you have more time")
      console.log("Now time : ", new Date().getTime())
      console.log("Exp time : ",tokenPayload.expTime)
      // Token is valid
      return true;
    } catch (error) {
      // Token is invalid (e.g., malformed)
      return false;
    }

  },


  /**
   * =================================================================================================================
   * This function can be used to logout the user and clear the store
   * @param kycFormStore
   * @param router
   */
  logoutTheUser(){
    console.log("LOGGING OUT THE USER")
    PiniaStoreHelper.clearLocalStorage(useKycFormStore())
  }
}
export {tokenGenerator}
