/**
 * =====================================================================================================================
 * This class can be used to represent single product detail
 */
export class ProductDetail{
    currency_type = ""
    account_type = ""
    product_id = ""
    purpose_of_opening_account = []
    anticipated_volume = ""
    initial_deposit_amount = ""

    // constructor(currencyType,account_type,product_id,anticipated_volume,initial_deposit_amount) {
    //     this.currency_type = currencyType
    //     this.account_type = account_type
    //     this.product_id = product_id
    //     this.anticipated_volume = anticipated_volume
    //     this.initial_deposit_amount = initial_deposit_amount
    // }

    addPurposeOfOpeningAccount(purpose){
        this.purpose_of_opening_account.push(purpose)
    }
}