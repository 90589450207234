import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import Buefy from "buefy";

import "../src/myBuefyStyles/myBuefyStyles.scss";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import "/node_modules/flag-icons/css/flag-icons.min.css";
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

import axios from "axios";
Vue.prototype.$http = axios;

// installed apps use
Vue.use(VueRouter);
Vue.use(Buefy);

import gsap from "gsap";
Vue.prototype.$gsap = gsap;

import animations from "@/assets/js/animations";
Vue.prototype.$animations = animations.animations;

Vue.component("menu-icon", MenuIcon);

import WelcomePage from "./components/WelcomePage.vue";
import UserInfo from "./components/UserInfo.vue";
import EnterOtp from "./components/EnterOtp.vue";
import EnterEmailOtp from "./components/EnterEmailOtp.vue";
import PersonalDetail from "./components/PersonalDetail.vue";
import DocumentId from "./components/DocumentId.vue";
import VedioCallDetails from "./components/VedioCallDetails.vue";
import AdditionalService from "./components/AdditionalService.vue";
import PreviewPage from "./components/PreviewPage.vue";
import VerificationPage from "./components/VerificationPage.vue";
import CongratulationsPage from "./components/CongratulationsPage.vue";
import AccountSelectionPage from "./components/AccountSelection.vue";
import ApplicationStatusCheck from "./components/ApplicationStatusCheck.vue";
import EnterOtpPage from "./components/EnterOtpPage.vue";
import ApplicationStatusSheetVue from "./components/ApplicationStatusSheet.vue";
import ShowSubmittedApplication from "./components/ShowSubmittedApplication.vue";

import * as enLanguageData from "./assets/Lang/en.json";
import * as siLanguageData from "./assets/Lang/si.json";
import * as taLanguageData from "./assets/Lang/ta.json";

// using vue scripts
import VS2 from 'vue-script2'
Vue.use(VS2)

// using vue meta
import VueMeta from "vue-meta";
Vue.use(VueMeta,{refreshOnceOnNavigation : true})

// import and use pinia with vue 2 -------------------------------------------------------------------------------------
import { createPinia, PiniaVuePlugin } from "pinia";
import CustomerVideoCall from "@/views/CustomerVideoCall.vue";
import PreviewPagepdfVue from "./components/PreviewPagepdf.vue";
Vue.use(PiniaVuePlugin);

const pinia = createPinia();

// persisting pinia states ---------------------------------------------------------------------------------------------

pinia.use((context) => {
  const kycFormStoreId = context.store.$id;
  console.log("kyc form pinia store id : " + kycFormStoreId);

  if(kycFormStoreId === "kycFormStore") {

    // sync data from store
    var fromLocalKycFormStorage = JSON.parse(
        localStorage.getItem(kycFormStoreId)
    );

    if (fromLocalKycFormStorage) {
      // update date values - otherwise it will parse as string
      if(fromLocalKycFormStorage.formData.nic_issue_date !== null){
        fromLocalKycFormStorage.formData.nic_issue_date = new Date(
          fromLocalKycFormStorage.formData.nic_issue_date
          );
      }
      fromLocalKycFormStorage.formData.date_of_birth = new Date(
          fromLocalKycFormStorage.formData.date_of_birth
      );

      if(fromLocalKycFormStorage.formData.passport_expiry_date !== null){
        fromLocalKycFormStorage.formData.passport_expiry_date = new Date(
            fromLocalKycFormStorage.formData.passport_expiry_date
        );
      }

      if(fromLocalKycFormStorage.formData.passport_issue_date !== null){
        fromLocalKycFormStorage.formData.passport_issue_date = new Date(
            fromLocalKycFormStorage.formData.passport_issue_date
        );
      }

      fromLocalKycFormStorage.formData.preferred_date_for_video_call = new Date(
          fromLocalKycFormStorage.formData.preferred_date_for_video_call
      );

      console.log("store patch called ========");
      context.store.$patch(fromLocalKycFormStorage);
    }

    // listen to changes and update local storage
    context.store.$subscribe((mutation, state) => {
      localStorage.setItem(kycFormStoreId, JSON.stringify(state));
    });
  }
});
// persisting pinia states - end ---------------------------------------------------------------------------------------

const router = new VueRouter({
  routes: [
    {
      path: "/",
      component: WelcomePage,
    },

    {
      path: "/userInfo",
      component: UserInfo,
    },
    {
      path: "/enterOtp",
      component: EnterOtp,
    },
    {
      path: "/enterEmailOtp",
      component: EnterEmailOtp,
    },

    {
      path: "/personalDetail",
      component: PersonalDetail,
    },
    {
      path: "/accountSelection",
      component: AccountSelectionPage,
    },
    {
      path: "/documentId",
      component: DocumentId,
    },
    {
      path: "/vedioCallDetails",
      component: VedioCallDetails,
    },
    {
      path: "/additionalService",
      component: AdditionalService,
    },
    {
      path: "/previewPage",
      component: PreviewPage,
    },
    {
      path: "/previewPagepdf",
      component: PreviewPagepdfVue,
    },
    {
      path: "/verificationPage",
      component: VerificationPage,
    },
    {
      path: "/congratulationsPage",
      component: CongratulationsPage,
    },
    {
      path: "/applicationStatusCheck",
      component: ApplicationStatusCheck,
    },
    {
      path: "/otpPage",
      component: EnterOtpPage,
    },
    {
      path: "/applicationStatusSheetVue",
      component: ApplicationStatusSheetVue,
    },

      // customer video call routes
    {
      path: "/customerCallView",
      component : CustomerVideoCall
    },
    {
      path: "/showsubmittedapplication",
      component : ShowSubmittedApplication
    },
  ],
  // base: process.env.BASE_URL,
  mode: "history",
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { x: 0, y: 0 };
  },
});


// import and use GA4 using vue-gtag
import VueGtag from "vue-gtag"
Vue.use(VueGtag,{
  config : {id:"G-YSR114XTFR"}
},router)

new Vue({
  pinia, // use pinia
  router,
  data: {
    engLanguageData: enLanguageData,
    tamLanguageData: taLanguageData,
    sinLanguageData: siLanguageData,
    selectedLanguageData: enLanguageData,
    selectedLanguage: "en",
  },
  render: (h) => h(App),
}).$mount("#app");
