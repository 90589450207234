<template>
  <div>
    <ValidationObserver>
      <div class="form-body mt-3">
        <div ref="content">
          <div class="box form-padding pb-5" style="padding-top: 0% !important">     
            <p class="has-text-centered  pt-4 mb-4 astric-font-size">
                <span class="has-text-danger   mr-2">{{
                  $root.selectedLanguageData.note
                }}</span><b class="has-text-danger">*</b><span class="text-transparency">
               Indicates mandatory fields that are required</span>
            </p>       
            <vue-html2pdf
              :show-layout="true"
              :float-layout="false"
              :enable-download="true"
              :preview-modal="false"
              :htmlToPdfOptions="{
                filename: 'ssdsdsd',
                margin: 0.3,
                image: {
                  type: 'jpeg',
                  quality: 1,
                },

                enableLinks: false,

                html2canvas: {
                  scale: 1,
                  useCORS: true,
                },

                jsPDF: {
                  unit: 'in',
                  format: 'a3',
                  orientation: 'portrait',
                  putOnlyUsedFonts: true,
                },
              }"
              :paginate-elements-by-height="1800"
              :pdf-quality="2"
              pdf-orientation="portrait"
              pdf-content-width="90%"
              :manual-pagination="false"
              @progress="onProgress($event)"
              @hasPaginated="hasPaginated()"
              @beforeDownload="beforeDownload($event)"
              @hasDownloaded="hasDownloaded($event)"
              ref="html2Pdf"
            >
              <section slot="pdf-content">
                <div class="mb-5 columns" style="margin-top: 3vh">
                  <div
                    class="column is-narrow has-text-centered"
                    style="padding-block: 0px !important"
                  >
                    <img
                      v-if="kycFormStore.imageURLs.selfie_image"
                      :src="kycFormStore.imageURLs.selfie_image"
                      class="round-image-box"
                    />
                  </div>
                  <div
                    class="preview-img-space column"
                    style="padding-block: 0px !important"
                  >
                    <p
                      :style="{
                        'font-size': '1.1rem',
                        'font-family': 'Gotham-Bold',
                        'letter-spacing': '0.2px',
                      }"
                    >
                      {{ kycFormStore.formData.full_name || "Charith" }}
                    </p>

                    <hr
                      :style="{
                        'background-color': '#231F20',
                        height: '0.3px',
                        'margin-top': '0',
                        'padding-top': '0',
                        'margin-bottom': '0',
                        'padding-bottom': '0',
                        opacity: '0.5',
                      }"
                    />
                    <p style="font-size: 0.9rem" class="mt-2">
                      <img
                        src="../assets/images/icons/calll.webp"
                        alt="call.img"
                        width="4%"
                        class="mr-1"
                      /><span class="pr-4 verticalLine">{{
                        kycFormStore.formData.contact_no || "+9475XXXXXXX"
                      }}</span>
                      <img
                        src="../assets/images/icons/emaill.webp"
                        alt="email.img"
                        width="4%"
                        class="ml-3 mr-1"
                      /><span class="pr-4">{{
                        kycFormStore.formData.email || "yourXXXXX@gmail.com"
                      }}</span>
                    </p>
                  </div>
                </div>
                <!-- Full Name -->
                <div class="columns is-3 is-variable mt-1">
                  <div class="column is-one-third">
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          titlePreview.trim() === '' && !isTittleInputFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                        class="mb-0"
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.title
                          }}<span class="has-text-danger">*</span></template
                        >

                        <b-select
                          rounded
                          expanded
                          disabled
                          v-model="titlePreview"
                          :class="
                            titlePreview === '' && !isTittleInputFocus
                              ? 'arrow-up-before'
                              : 'arrow-up'
                          "
                          @focus="
                            () => {
                              isTittleInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isTittleInputFocus = false;
                            }
                          "
                        >
                          <option value="Dr">
                            {{ $root.selectedLanguageData.dr }}
                          </option>
                          <option value="Master">
                            {{ $root.selectedLanguageData.master }}
                          </option>
                          <option value="Professor">
                            {{ $root.selectedLanguageData.professor }}
                          </option>
                          <option value="Rev">
                            {{ $root.selectedLanguageData.rev }}
                          </option>
                          <option value="Mr">
                            {{ $root.selectedLanguageData.mr }}
                          </option>
                          <option value="Mrs">
                            {{ $root.selectedLanguageData.mrs }}
                          </option>
                          <option value="Ms">
                            {{ $root.selectedLanguageData.ms }}
                          </option>
                          <option value="Miss">
                            {{ $root.selectedLanguageData.miss }}
                          </option>
                        </b-select>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end mt-2"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="kycFormStore.formData.title !== titlePreview"
                          @click="
                            () => {
                              kycFormStore.formData.title = titlePreview;
                              isTittleActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        class="mt-2"
                        v-model="isTittleActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="column">
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.full_name.trim() === '' &&
                        !isFullNameInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.full_name }}
                        {{ $root.selectedLanguageData.as_per_the_nic
                        }}<span class="has-text-danger">*</span></template
                      >

                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="name"
                          :rules="validateName()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            rows="3"
                            cols="43"
                            disabled
                            v-model="kycFormStore.formData.full_name"
                            @focus="
                              () => {
                                isFullNameInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isFullNameInputFocus = false;
                              }
                            "
                          />
                          <!-- <b-input
                          rounded
                          disabled
                          type="text"
                          v-model="fullnamePreview"
                          @focus="
                          () => {
                            isFullNameInputFocus = true;
                          }
                        "
                          @blur="
                          () => {
                            isFullNameInputFocus = false;
                          }
                        "
                      /> -->
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </p>
                    </b-field>
                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        v-if="
                          kycFormStore.formData.full_name !== fullnamePreview
                        "
                        @click="
                          () => {
                            kycFormStore.formData.full_name = fullnamePreview;
                            isActive = true;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>

                    <b-message
                      auto-close
                      size="is-medium"
                      type="is-success"
                      rounded
                      has-icon
                      v-model="isActive"
                      >{{ $root.selectedLanguageData.saved }}
                    </b-message>
                  </div>
                </div>
                <!-- full name end -->
                <!-- NIC -->
                <div
                  class="columns is-4 is-variable mt-1"
                  v-if="kycFormStore.formData.customer_nic"
                >
                  <div class="column is-two-thirds">
                    <div>
                      <b-field
                        label-position="inside"
                        :custom-class="
                          customernicPreview.trim() === '' && !isNicInputFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.nic_eic_num
                          }}<span class="has-text-danger">*</span></template
                        >

                        <p class="control has-icons-right">
                          <ValidationProvider
                            :rules="{
                              required: true,
                              multiRegex: [
                                /^([0-9]{9}[Xx||Vv])$/,
                                /^[0-9]{12}$/,
                              ],
                            }"
                            v-slot="{ errors }"
                          >
                            <b-input
                              rounded
                              v-model="customernicPreview"
                              disabled
                              @focus="
                                () => {
                                  isNicInputFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isNicInputFocus = false;
                                }
                              "
                            ></b-input>
                            <span class="has-text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </p>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.customer_nic !==
                            customernicPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.customer_nic =
                                customernicPreview;
                              isNicActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isNicActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>

                  <div class="column">
                    <ValidationProvider
                      name="Issue Date"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="'custom_float_label_date'"
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.issue_date
                          }}<span class="has-text-danger">*</span></template
                        >
                        <p class="control has-icons-right">
                          <b-datepicker
                            class="date-picking"
                            disabled
                            :max-date="maxDate"
                            :years-range="[-100, 100]"
                            rounded
                            v-model="kycFormStore.formData.nic_issue_date"
                          >
                          </b-datepicker>
                        </p>
                      </b-field>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <!-- Nic End -->

                <!-- passport_number -->
                <div
                  class="columns is-3 is-variable mt-1"
                  v-if="kycFormStore.formData.customer_passport_no"
                >
                  <div class="column is-two-fifths">
                    <b-field
                      label-position="inside"
                      :custom-class="
                        customerpassportnoPreview.trim() === '' &&
                        !isPassportInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.passport_number
                        }}<span class="has-text-danger">*</span></template
                      >

                      <p class="control has-icons-right">
                        <ValidationProvider
                          :rules="passportValidateObject()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            disabled
                            v-model="customerpassportnoPreview"
                            @focus="
                              () => {
                                isPassportInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPassportInputFocus = false;
                              }
                            "
                          ></b-input>
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </p>
                    </b-field>
                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        v-if="
                          kycFormStore.formData.customer_passport_no !==
                          customerpassportnoPreview
                        "
                        @click="
                          () => {
                            kycFormStore.formData.customer_passport_no =
                              customerpassportnoPreview;
                            isPassportActive = true;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>

                    <b-message
                      auto-close
                      size="is-medium"
                      type="is-success"
                      rounded
                      has-icon
                      v-model="isPassportActive"
                      >{{ $root.selectedLanguageData.saved }}
                    </b-message>
                  </div>

                  <div class="column">
                    <b-field
                      label-position="inside"
                      :custom-class="'custom_float_label_date'"
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.issue_date
                        }}<span class="has-text-danger">*</span></template
                      >

                      <p class="control has-icons-right">
                        <b-datepicker
                          :max-date="maxDate"
                          :years-range="[-100, 100]"
                          disabled
                          rounded
                          v-model="kycFormStore.formData.passport_issue_date"
                        >
                        </b-datepicker>

                      </p>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field
                      label-position="inside"
                      :custom-class="'custom_float_label_date'"
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.expiry_date
                        }}<span class="has-text-danger">*</span></template
                      >

                      <p class="control has-icons-right">
                        <b-datepicker
                          :max-date="maxDate"
                          :years-range="[-100, 100]"
                          disabled
                          rounded
                          v-model="kycFormStore.formData.passport_expiry_date"
                        >
                        </b-datepicker>
                      </p>
                    </b-field>
                  </div>
                </div>
                <!-- passport end -->

                <div class="columns is-4 is-variable mt-1">
                  <!-- citizenship -->
                  <div class="column is-two-thirds">
                    <div>
                      <ValidationProvider
                        name="Citizenship"
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <b-field
                          label-position="inside"
                          :custom-class="
                            citizenshipPreview_1.trim() === '' &&
                            !isCitizenInputFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                          class="mb-0"
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.nationality
                            }}<span class="has-text-danger">*</span></template
                          >

                          <b-select
                            style="width: 100%"
                            disabled
                            expanded
                            rounded
                            @click.native="resetNationalityValue()"
                            v-model="citizenshipPreview_1"
                            :class="
                              citizenshipPreview_1 === '' &&
                              !isCitizenInputFocus
                                ? 'arrow-up-before'
                                : 'arrow-up'
                            "
                            @focus="
                              () => {
                                isCitizenInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isCitizenInputFocus = false;
                              }
                            "
                          >
                            <option value="Sri Lankan">
                              {{ $root.selectedLanguageData.srilankan }}
                            </option>
                            <option value="Sri Lankan with Dual Citizenship">
                              {{
                                $root.selectedLanguageData
                                  .sriLankanWithDualCitizenship
                              }}
                            </option>
                            <!-- <option value="Foreign National">
                              {{ $root.selectedLanguageData.foreignNational }}
                            </option>
                            <option
                              value="Foreign National with Dual Citizenship"
                            >
                              {{
                                $root.selectedLanguageData
                                  .foreignNationalWithDualCitizenship
                              }}
                            </option> -->
                          </b-select>
                        </b-field>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.citizenship_1 !==
                            citizenshipPreview_1
                          "
                          @click="
                            () => {
                              kycFormStore.formData.citizenship_1 =
                                citizenshipPreview_1;
                              isCitizenActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isCitizenActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                  <!-- date of birth -->
                  <div
                    class="column"
                    v-if="kycFormStore.formData.date_of_birth"
                  >
                    <ValidationProvider
                      name="date of birth"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="'custom_float_label_date'"
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.date_of_birth
                          }}<span class="has-text-danger">*</span></template
                        >

                        <p class="control has-icons-right">
                          <b-datepicker
                            placeholder="DD/MM/YY"
                            disabled
                            :max-date="maxDate"
                            :years-range="[-100, 100]"
                            rounded
                            v-model="kycFormStore.formData.date_of_birth"
                          >
                          </b-datepicker>
                        </p>
                      </b-field>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="columns is-7 is-variable">
                  <div class="column">
                    <!-- gender -->
                    <div v-if="kycFormStore.formData.gender">
                      <label
                        >{{ $root.selectedLanguageData.gender
                        }}<span class="has-text-danger">*</span></label
                      >
                      <b-field>
                        <!--              @click.native="isGenderModalActive = true"-->

                        <b-radio
                          v-if="kycFormStore.formData.gender === `Male`"
                          disabled
                          checked
                        >
                          {{ $root.selectedLanguageData.male }}
                        </b-radio>
                        <b-radio
                          v-if="kycFormStore.formData.gender === `Female`"
                          disabled
                          checked
                        >
                          {{ $root.selectedLanguageData.female }}
                        </b-radio>
                      </b-field>
                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isGenderActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>

                    <b-modal v-model="isGenderModalActive" :width="540">
                      <div class="box">
                        <div class="modal-close-button">
                          <button
                            class="delete mb-2"
                            aria-label="close"
                            @click="
                              () => {
                                isGenderModalActive = false;
                              }
                            "
                          ></button>
                        </div>
                        <label
                          >{{ $root.selectedLanguageData.gender
                          }}<span class="has-text-danger">*</span></label
                        >

                        <ValidationProvider
                          :rules="{ required: true }"
                          v-slot="{ errors }"
                        >
                          <div class="columns">
                            <div class="column is-one-two">
                              <b-field>
                                <b-radio
                                  class="en-style"
                                  name="gender"
                                  native-value="Male"
                                  v-model="genderPreview"
                                >
                                  {{ $root.selectedLanguageData.male }}
                                </b-radio>
                              </b-field>
                            </div>
                            <div class="column is-one-two">
                              <b-field>
                                <b-radio
                                  class="en-style"
                                  name="gender"
                                  native-value="Female"
                                  v-model="genderPreview"
                                >
                                  {{ $root.selectedLanguageData.female }}
                                </b-radio>
                              </b-field>
                            </div>
                          </div>
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            @click="
                              () => {
                                kycFormStore.formData.gender = genderPreview;
                                isGenderActive = true;
                                isGenderModalActive = false;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>
                      </div>
                    </b-modal>
                  </div>
                  <div class="column">
                    <!-- marital_status -->
                    <div v-if="kycFormStore.formData.marital_status">
                      <label
                        >{{ $root.selectedLanguageData.marital_status
                        }}<span class="has-text-danger">*</span></label
                      >
                      <b-field>
                        <!--                @click.native="isMaritalstatusModalActive = true"-->
                        <b-radio
                          v-if="
                            kycFormStore.formData.marital_status === `Married`
                          "
                          checked
                          class="en-style"
                          disabled
                        >
                          {{ $root.selectedLanguageData.married }}
                        </b-radio>
                        <b-radio
                          disabled
                          v-if="
                            kycFormStore.formData.marital_status === `Unmarried`
                          "
                          checked
                          class="en-style"
                        >
                          {{ $root.selectedLanguageData.single }}
                        </b-radio>
                      </b-field>
                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isMaritalstatusActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                    <b-modal v-model="isMaritalstatusModalActive" :width="540">
                      <div class="box">
                        <div class="modal-close-button">
                          <button
                            class="delete mb-2"
                            aria-label="close"
                            @click="
                              () => {
                                isMaritalstatusModalActive = false;
                              }
                            "
                          ></button>
                        </div>
                        <div class="mt-1">
                          <label
                            >{{ $root.selectedLanguageData.marital_status
                            }}<span class="has-text-danger">*</span></label
                          >
                          <div class="columns">
                            <div class="column is-one-two">
                              <ValidationProvider
                                :rules="{ required: true }"
                                v-slot="{ errors }"
                              >
                                <div class="columns">
                                  <div class="column is-one-two">
                                    <b-field>
                                      <b-radio
                                        class="en-style"
                                        name="marital_status"
                                        native-value="Married"
                                        v-model="maritalStatusPreview"
                                      >
                                        {{ $root.selectedLanguageData.married }}
                                      </b-radio>
                                    </b-field>
                                  </div>
                                  <div class="column is-one-two">
                                    <b-field>
                                      <b-radio
                                        class="en-style"
                                        name="marital_status"
                                        native-value="Unmarried"
                                        v-model="maritalStatusPreview"
                                      >
                                        {{ $root.selectedLanguageData.single }}
                                      </b-radio>
                                    </b-field>
                                  </div>
                                </div>
                                <span class="has-text-danger">{{
                                  errors[0]
                                }}</span>
                              </ValidationProvider>
                            </div>
                            <div class="column is-one-two"></div>
                          </div>
                        </div>
                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            @click="
                              () => {
                                kycFormStore.formData.marital_status =
                                  maritalStatusPreview;
                                isMaritalstatusActive = true;
                                isMaritalstatusModalActive = false;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>
                      </div>
                    </b-modal>
                  </div>
                </div>

                <hr
                  style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
                />
                <!-- permanent_address -->
                <div>
                  <label>{{
                    $root.selectedLanguageData.permanent_address
                  }}</label>
                  <div class="columns is-4 is-variable">
                    <div
                      class="column is-two-fifths"
                      v-if="kycFormStore.formData.permanent_address_line1"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          permanentaddressline1Preview.trim() === '' &&
                          !isPerAddLine1Focus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.building_number }}
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            disabled
                            type="text"
                            v-model="permanentaddressline1Preview"
                            @focus="
                              () => {
                                isPerAddLine1Focus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPerAddLine1Focus = false;
                              }
                            "
                          />
                        </p>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.permanent_address_line1 !==
                            permanentaddressline1Preview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.permanent_address_line1 =
                                permanentaddressline1Preview;
                              isPerAddLine1Active = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isPerAddLine1Active"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                    <div
                      class="column"
                      v-if="kycFormStore.formData.permanent_address_line2"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          permanentaddressline2Preview.trim() === '' &&
                          !isPerAddLine2Focus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.building_name }}
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            disabled
                            type="text"
                            v-model="permanentaddressline2Preview"
                            @focus="
                              () => {
                                isPerAddLine2Focus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPerAddLine2Focus = false;
                              }
                            "
                          />
                        </p>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.permanent_address_line2 !==
                            permanentaddressline2Preview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.permanent_address_line2 =
                                permanentaddressline2Preview;
                              isPerAddLine2Active = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isPerAddLine2Active"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                  <!-- second line -->
                  <div class="columns is-7 is-variable mt-1">
                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.permanent_address_line3"
                    >
                      <div>
                        <b-field
                          label-position="inside"
                          :custom-class="
                            permanentaddressline3Preview.trim() === '' &&
                            !isPerAddLine3Focus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.street
                            }}<span class="has-text-danger">*</span></template
                          >

                          <p class="control has-icons-right">
                            <ValidationProvider
                              :rules="{ required: true }"
                              v-slot="{ errors }"
                            >
                              <b-input
                                rounded
                                disabled
                                type="text"
                                v-model="permanentaddressline3Preview"
                                @focus="
                                  () => {
                                    isPerAddLine3Focus = true;
                                  }
                                "
                                @blur="
                                  () => {
                                    isPerAddLine3Focus = false;
                                  }
                                "
                              />
                              <span class="has-text-danger">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </p>
                        </b-field>
                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            v-if="
                              kycFormStore.formData.permanent_address_line3 !==
                              permanentaddressline3Preview
                            "
                            @click="
                              () => {
                                kycFormStore.formData.permanent_address_line3 =
                                  permanentaddressline3Preview;
                                isPerAddLine3Active = true;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>

                        <b-message
                          auto-close
                          size="is-medium"
                          type="is-success"
                          rounded
                          has-icon
                          v-model="isPerAddLine3Active"
                          >{{ $root.selectedLanguageData.saved }}
                        </b-message>
                      </div>
                    </div>

                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.permanent_address_town"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          permanentaddresstownPreview.trim() === '' &&
                          !isPerAddTownFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.town }}
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            type="text"
                            disabled
                            v-model="permanentaddresstownPreview"
                            @focus="
                              () => {
                                isPerAddTownFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPerAddTownFocus = false;
                              }
                            "
                          />
                        </p>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.permanent_address_town !==
                            permanentaddresstownPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.permanent_address_town =
                                permanentaddresstownPreview;
                              isPerAddTownActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isPerAddTownActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                  <!-- third_line -->
                  <div class="columns is-7 is-variable mt-1">
                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.permanent_address_postal_code"
                    >
                      <div>
                        <b-field
                          label-position="inside"
                          :custom-class="
                            permanentaddresspostalcodePreview.trim() === '' &&
                            !isPerAddPostalCodeFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.postal_code }}
                          </template>
                          <p class="control has-icons-right">
                            <b-input
                              rounded
                              disabled
                              type="text"
                              v-model="permanentaddresspostalcodePreview"
                              @focus="
                                () => {
                                  isPerAddPostalCodeFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isPerAddPostalCodeFocus = false;
                                }
                              "
                            />
                          </p>
                        </b-field>
                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            v-if="
                              kycFormStore.formData
                                .permanent_address_postal_code !==
                              permanentaddresspostalcodePreview
                            "
                            @click="
                              () => {
                                kycFormStore.formData.permanent_address_postal_code =
                                  permanentaddresspostalcodePreview;
                                isPerAddPostalCodeActive = true;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>

                        <b-message
                          auto-close
                          size="is-medium"
                          type="is-success"
                          rounded
                          has-icon
                          v-model="isPerAddPostalCodeActive"
                          >{{ $root.selectedLanguageData.saved }}
                        </b-message>
                      </div>
                    </div>

                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.permanent_address_district"
                    >
                      <div
                        v-if="
                          kycFormStore.formData.residentship === `Sri Lanka`
                        "
                      >
                        <ValidationProvider
                          :rules="{ required: true }"
                          v-slot="{ errors }"
                        >
                          <b-field
                            label-position="inside"
                            :custom-class="
                              permanentaddressdistrictPreview.trim() === '' &&
                              !isPerAddDistrictFocus
                                ? 'custom-float-label-initial'
                                : 'custom-float-label'
                            "
                            class="mb-0"
                          >
                            <template #label
                              >{{ $root.selectedLanguageData.district
                              }}<span class="has-text-danger">*</span></template
                            >

                            <b-select
                              v-model="permanentaddressdistrictPreview"
                              expanded
                              rounded
                              disabled
                              :class="
                                permanentaddressdistrictPreview === '' &&
                                !isPerAddDistrictFocus
                                  ? 'arrow-up-before'
                                  : 'arrow-up'
                              "
                              @focus="
                                () => {
                                  isPerAddDistrictFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isPerAddDistrictFocus = false;
                                }
                              "
                            >
                              <option
                                v-for="district in kycFormStore.formDataSupport
                                  .districtsArray"
                                :key="district"
                                :value="district"
                              >
                                {{ district }}
                              </option>
                            </b-select>
                          </b-field>
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div
                        v-if="
                          kycFormStore.formData.residentship !== `Sri Lanka`
                        "
                      >
                        <div>
                          <b-field
                            label-position="inside"
                            :custom-class="
                              permanentaddressdistrictPreview.trim() === '' &&
                              !isPerAddDistrictFocus
                                ? 'custom-float-label-initial'
                                : 'custom-float-label'
                            "
                          >
                            <template #label
                              >{{ $root.selectedLanguageData.district }}
                            </template>
                            <p class="control has-icons-right">
                              <b-input
                                rounded
                                type="text"
                                disabled
                                v-model="permanentaddressdistrictPreview"
                                @focus="
                                  () => {
                                    isPerAddDistrictFocus = true;
                                  }
                                "
                                @blur="
                                  () => {
                                    isPerAddDistrictFocus = false;
                                  }
                                "
                              />
                            </p>
                          </b-field>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- country -->
                  <div>
                    <div class="columns is-7 is-variable mt-1">
                      <div
                        class="column is-half"
                        v-if="kycFormStore.formData.permanent_address_country"
                      >
                        <ValidationProvider
                          :rules="{ required: true }"
                          v-slot="{ errors }"
                        >
                          <b-field
                            label-position="inside"
                            :custom-class="
                              permanentaddresscountryPreview.trim() === '' &&
                              !isPerAddCountryFocus
                                ? 'custom-float-label-initial'
                                : 'custom-float-label'
                            "
                            class="mb-0"
                          >
                            <template #label
                              >{{ $root.selectedLanguageData.country
                              }}<span class="has-text-danger">*</span></template
                            >

                            <b-select
                              v-model="permanentaddresscountryPreview"
                              expanded
                              disabled
                              rounded
                              :class="
                                permanentaddresscountryPreview === '' &&
                                !isPerAddCountryFocus
                                  ? 'arrow-up-before'
                                  : 'arrow-up'
                              "
                              @focus="
                                () => {
                                  isPerAddCountryFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isPerAddCountryFocus = false;
                                }
                              "
                            >
                              <option
                                v-for="country in kycFormStore.formDataSupport
                                  .countriesArray"
                                :key="country.name"
                                :value="country.name"
                              >
                                {{ country.name }}
                              </option>
                            </b-select>
                          </b-field>
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>

                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            v-if="
                              kycFormStore.formData
                                .permanent_address_country !==
                              permanentaddresscountryPreview
                            "
                            @click="
                              () => {
                                kycFormStore.formData.permanent_address_country =
                                  permanentaddresscountryPreview;
                                isPerAddCountryActive = true;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>

                        <b-message
                          auto-close
                          size="is-medium"
                          type="is-success"
                          rounded
                          has-icon
                          v-model="isPerAddCountryActive"
                          >{{ $root.selectedLanguageData.saved }}
                        </b-message>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div
                class="mt-5 mb-5 flex-start align"
                style="justify-content: flex-start !important"
                v-if="
                  kycFormStore.formData
                    .correspondence_address_differ_with_permanent_address
                "
              >
                <b-field>
                  <b-checkbox :value="true" disabled></b-checkbox>

                  <div>
                    <p class="en-style">
                      {{
                        $root.selectedLanguageData
                          .correspondence_address_differs
                      }}
                    </p>
                  </div>
                </b-field>
              </div> -->

                <!-- correspondence_address -->
                <div class="mb-5 mt-5">
                  <label>{{
                    $root.selectedLanguageData.correspondence_address
                  }}</label>
                  <div class="columns is-4 is-variable">
                    <div
                      class="column is-two-fifths"
                      v-if="kycFormStore.formData.correspondent_address_line1"
                    >
                      <div>
                        <b-field
                          label-position="inside"
                          :custom-class="
                            correspondentaddressline1Preview.trim() === '' &&
                            !isCorAddLine1Focus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.building_number }}
                          </template>
                          <p class="control has-icons-right">
                            <b-input
                              rounded
                              type="text"
                              disabled
                              v-model="correspondentaddressline1Preview"
                              @focus="
                                () => {
                                  isCorAddLine1Focus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isCorAddLine1Focus = false;
                                }
                              "
                            />
                          </p>
                        </b-field>
                      </div>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData
                              .correspondent_address_line1 !==
                            correspondentaddressline1Preview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.correspondent_address_line1 =
                                correspondentaddressline1Preview;
                              isCorAddLine1Active = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isCorAddLine1Active"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                    <div
                      class="column"
                      v-if="kycFormStore.formData.correspondent_address_line2"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          kycFormStore.formData.correspondent_address_line2.trim() ===
                            '' && !isCorAddLine2Focus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.building_name }}
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            type="text"
                            disabled
                            v-model="
                              kycFormStore.formData.correspondent_address_line2
                            "
                            @focus="
                              () => {
                                isCorAddLine2Focus = true;
                              }
                            "
                            @blur="
                              () => {
                                isCorAddLine2Focus = false;
                              }
                            "
                          />
                        </p>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData
                              .correspondent_address_line2 !==
                            correspondentaddressline2Preview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.correspondent_address_line2 =
                                correspondentaddressline2Preview;
                              isCorAddLine2Active = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isCorAddLine2Active"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                  <!-- second line -->
                  <div class="columns is-7 is-variable mt-1">
                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.correspondent_address_line3"
                    >
                      <div>
                        <b-field
                          label-position="inside"
                          :custom-class="
                            correspondentaddressline3Preview.trim() === '' &&
                            !isCorAddLine3Focus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.street
                            }}<span class="has-text-danger">*</span></template
                          >

                          <p class="control has-icons-right">
                            <ValidationProvider
                              :rules="{ required: true }"
                              v-slot="{ errors }"
                            >
                              <b-input
                                rounded
                                type="text"
                                disabled
                                v-model="correspondentaddressline3Preview"
                                @focus="
                                  () => {
                                    isCorAddLine3Focus = true;
                                  }
                                "
                                @blur="
                                  () => {
                                    isCorAddLine3Focus = false;
                                  }
                                "
                              />
                              <span class="has-text-danger">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </p>
                        </b-field>
                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            v-if="
                              kycFormStore.formData
                                .correspondent_address_line3 !==
                              correspondentaddressline3Preview
                            "
                            @click="
                              () => {
                                kycFormStore.formData.correspondent_address_line3 =
                                  correspondentaddressline3Preview;
                                isCorAddLine3Active = true;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>

                        <b-message
                          auto-close
                          size="is-medium"
                          type="is-success"
                          rounded
                          has-icon
                          v-model="isCorAddLine3Active"
                          >{{ $root.selectedLanguageData.saved }}
                        </b-message>
                      </div>
                    </div>
                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.correspondent_address_town"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          correspondentaddresstownPreview.trim() === '' &&
                          !isCorAddTownFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.town }}
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            type="text"
                            disabled
                            v-model="correspondentaddresstownPreview"
                            @focus="
                              () => {
                                isCorAddTownFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isCorAddTownFocus = false;
                              }
                            "
                          />
                        </p>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.correspondent_address_town !==
                            correspondentaddresstownPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.correspondent_address_town =
                                correspondentaddresstownPreview;
                              isCorAddTownActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isCorAddTownActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                  <!-- third_line -->
                  <div class="columns is-7 is-variable mt-1">
                    <div
                      class="column is-half"
                      v-if="
                        kycFormStore.formData.correspondent_address_postal_code
                      "
                    >
                      <div>
                        <b-field
                          label-position="inside"
                          :custom-class="
                            correspondentaddresspostalcodePreview.trim() ===
                              '' && !isCorAddPostalCodeFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.postal_code }}
                          </template>
                          <p class="control has-icons-right">
                            <b-input
                              rounded
                              type="text"
                              disabled
                              v-model="correspondentaddresspostalcodePreview"
                              @focus="
                                () => {
                                  isCorAddPostalCodeFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isCorAddPostalCodeFocus = false;
                                }
                              "
                            />
                          </p>
                        </b-field>
                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            v-if="
                              kycFormStore.formData
                                .correspondent_address_postal_code !==
                              correspondentaddresspostalcodePreview
                            "
                            @click="
                              () => {
                                kycFormStore.formData.correspondent_address_postal_code =
                                  correspondentaddresspostalcodePreview;
                                isCorAddPostalCodeActive = true;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>

                        <b-message
                          auto-close
                          size="is-medium"
                          type="is-success"
                          rounded
                          has-icon
                          v-model="isCorAddPostalCodeActive"
                          >{{ $root.selectedLanguageData.saved }}
                        </b-message>
                      </div>
                    </div>
                    <div
                      class="column is-half"
                      v-if="
                        kycFormStore.formData.correspondent_address_district
                      "
                    >
                      <div
                        v-if="
                          kycFormStore.formData.residentship === `Sri Lanka`
                        "
                      >
                        <ValidationProvider
                          :rules="{ required: true }"
                          v-slot="{ errors }"
                        >
                          <b-field
                            label-position="inside"
                            :custom-class="
                              correspondentaddressdistrictPreview.trim() ===
                                '' && !isCorAddDistrictFocus
                                ? 'custom-float-label-initial'
                                : 'custom-float-label'
                            "
                            class="mb-0"
                          >
                            <template #label
                              >{{ $root.selectedLanguageData.district
                              }}<span class="has-text-danger">*</span></template
                            >
                            <b-select
                              v-model="correspondentaddressdistrictPreview"
                              expanded
                              disabled
                              rounded
                              :class="
                                correspondentaddressdistrictPreview === '' &&
                                !isCorAddDistrictFocus
                                  ? 'arrow-up-before'
                                  : 'arrow-up'
                              "
                              @focus="
                                () => {
                                  isCorAddDistrictFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isCorAddDistrictFocus = false;
                                }
                              "
                            >
                              <option
                                v-for="district in kycFormStore.formDataSupport
                                  .districtsArray"
                                :key="district"
                                :value="district"
                              >
                                {{ district }}
                              </option>
                            </b-select>
                          </b-field>
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                      <div
                        v-if="
                          kycFormStore.formData.residentship !== `Sri Lanka`
                        "
                      >
                        <div>
                          <b-field
                            label-position="inside"
                            :custom-class="
                              correspondentaddressdistrictPreview.trim() ===
                                '' && !isCorAddDistrictFocus
                                ? 'custom-float-label-initial'
                                : 'custom-float-label'
                            "
                          >
                            <template #label
                              >{{ $root.selectedLanguageData.district }}
                            </template>
                            <p class="control has-icons-right">
                              <b-input
                                rounded
                                disabled
                                type="text"
                                v-model="correspondentaddressdistrictPreview"
                                @focus="
                                  () => {
                                    isCorAddDistrictFocus = true;
                                  }
                                "
                                @blur="
                                  () => {
                                    isCorAddDistrictFocus = false;
                                  }
                                "
                              />
                            </p>
                          </b-field>
                        </div>
                      </div>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData
                              .correspondent_address_district !==
                            correspondentaddressdistrictPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.correspondent_address_district =
                                correspondentaddressdistrictPreview;
                              isCorAddDistrictActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isCorAddDistrictActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                  <!-- country -->
                  <div class="columns is-7 is-variable mt-1">
                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.correspondent_address_country"
                    >
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <b-field
                          label-position="inside"
                          :custom-class="
                            correspondentaddresscountryPreview.trim() === '' &&
                            !isCorAddCountryFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                          class="mb-0"
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.country
                            }}<span class="has-text-danger">*</span></template
                          >

                          <b-select
                            v-model="correspondentaddresscountryPreview"
                            expanded
                            disabled
                            rounded
                            :class="
                              correspondentaddresscountryPreview === '' &&
                              !isCorAddCountryFocus
                                ? 'arrow-up-before'
                                : 'arrow-up'
                            "
                            @focus="
                              () => {
                                isCorAddCountryFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isCorAddCountryFocus = false;
                              }
                            "
                          >
                            <option
                              v-for="country in kycFormStore.formDataSupport
                                .countriesArray"
                              :key="country.name"
                              :value="country.name"
                            >
                              {{ country.name }}
                            </option>
                          </b-select>
                        </b-field>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData
                              .correspondent_address_country !==
                            correspondentaddresscountryPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.correspondent_address_country =
                                correspondentaddresscountryPreview;
                              isCorAddCountryActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isCorAddCountryActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                </div>

                <!-- status_of_residence -->
                <div style="page-break-before: always">
                  <div class="mt-4">
                    <label
                      >{{ $root.selectedLanguageData.status_of_residence
                      }}<span class="has-text-danger">*</span></label
                    >
                    <b-field>
                      <!--                @click.native="isStatusOfResidentsModalActive = true"-->
                      <b-radio
                        v-if="
                          kycFormStore.formData.status_of_residence ===
                          `House Holder`
                        "
                        disabled
                        checked
                        class="en-style"
                      >
                        {{ $root.selectedLanguageData.house_holder }}
                      </b-radio>
                      <b-radio
                        v-if="
                          kycFormStore.formData.status_of_residence ===
                          `Living With Parents`
                        "
                        checked
                        disabled
                        class="en-style"
                      >
                        {{ $root.selectedLanguageData.living_with_parents }}
                      </b-radio>
                      <b-radio
                        v-if="
                          kycFormStore.formData.status_of_residence === `Renter`
                        "
                        checked
                        disabled
                        class="en-style"
                      >
                        {{ $root.selectedLanguageData.renter }}
                      </b-radio>
                    </b-field>
                    <b-message
                      auto-close
                      size="is-medium"
                      type="is-success"
                      rounded
                      has-icon
                      v-model="isStatusOfResidentsActive"
                      >{{ $root.selectedLanguageData.saved }}
                    </b-message>
                  </div>
                </div>
                <b-modal v-model="isStatusOfResidentsModalActive" :width="600">
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isStatusOfResidentsModalActive = false;
                          }
                        "
                      ></button>
                    </div>
                    <div>
                      <label
                        >{{ $root.selectedLanguageData.status_of_residence
                        }}<span class="has-text-danger">*</span></label
                      >
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <div class="columns is-7 is-variable">
                          <div class="column">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="status_of_residence"
                                native-value="House Holder"
                                v-model="statusofresidentsPreview"
                              >
                                {{ $root.selectedLanguageData.house_holder }}
                              </b-radio>
                            </b-field>
                          </div>
                          <div class="column">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="status_of_residence"
                                native-value="Living With Parents"
                                v-model="statusofresidentsPreview"
                              >
                                {{
                                  $root.selectedLanguageData.living_with_parents
                                }}
                              </b-radio>
                            </b-field>
                          </div>
                          <div class="column">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="status_of_residence"
                                native-value="Renter"
                                v-model="statusofresidentsPreview"
                              >
                                {{ $root.selectedLanguageData.renter }}
                              </b-radio>
                            </b-field>
                          </div>
                        </div>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            kycFormStore.formData.status_of_residence =
                              statusofresidentsPreview;
                            isStatusOfResidentsActive = true;
                            isStatusOfResidentsModalActive = false;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>
                  </div>
                </b-modal>

                <!-- mobile Number -->
                <div>
                  <div class="columns mt-4 mb-2">
                    <div class="column is-two-fifths">
                      <div>
                        <section>
                          <b-field>
                            <p class="control has-icons-right">
                              <b-autocomplete
                                size="is-medium"
                                v-model="userCode"
                                icon-pack="fi"
                                :icon="iconString"
                                open-on-focus
                                :data="filteredDataArr"
                                disabled
                                rounded
                                @input="
                                  () => {
                                    ++count;
                                    if (count === 2) {
                                      kycFormStore.formData.countryCode = ``;
                                      isCountryCodeTouched = true;
                                    }
                                  }
                                "
                                @select="
                                  (option) => {
                                    if (option) {
                                      kycFormStore.formData.countryCode = `+${
                                        option.split(`+`)[1]
                                      }`;
                                      kycFormStore.formData.country_short_name =
                                        option.split(' ')[0];
                                    } else {
                                      kycFormStore.formData.countryCode = ``;
                                    }

                                    if (kycFormStore.formData.countryCode) {
                                      changeSelection();
                                      calPhoneNumber();
                                    }
                                  }
                                "
                              >
                              </b-autocomplete>

                              <span
                                class="has-text-danger"
                                v-if="
                                  isCountryCodeTouched &&
                                  !kycFormStore.formData.countryCode
                                "
                                >You have to select a valid country code</span
                              >
                            </p>
                          </b-field>
                        </section>
                      </div>
                    </div>
                    <div class="column">
                      <b-field
                        label-position="inside"
                        :custom-class="
                          kycFormStore.formData.mobileNumber.trim() === '' &&
                          !isMobileNumberInputFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.mobile_number
                          }}<span class="has-text-danger">*</span></template
                        >

                        <p class="control has-icons-right">
                          <ValidationProvider
                            name="Mobile Number"
                            :rules="{ required: true, onlyNumbers: true }"
                            v-slot="{ errors }"
                          >
                            <b-input
                              v-model="kycFormStore.formData.mobileNumber"
                              disabled
                              @focus="
                                () => {
                                  isMobileNumberInputFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isMobileNumberInputFocus = false;
                                }
                              "
                              @input="
                                () => {
                                  isMobileNumberTouched = true;
                                  mobileNumValidate();
                                  calPhoneNumber();
                                }
                              "
                              rounded
                            ></b-input>
                            <span class="has-text-danger">{{ errors[0] }}</span>
                            <span
                              class="has-text-danger"
                              v-if="
                                isMobileNumberTouched &&
                                !isMobileNumberValid &&
                                !errors[0]
                              "
                              >Please enter a valid phone number.
                            </span>
                            <span
                              class="has-text-danger"
                              v-if="isMobileNumberStartsWithZero && !errors[0]"
                              >LK phone numbers not allowed to start with
                              0</span
                            >
                          </ValidationProvider>
                        </p>
                      </b-field>
                    </div>
                  </div>
                </div>

                <!--        office number    -->
                <div>
                  <div
                    class="columns mt-4 mb-2"
                    v-if="kycFormStore.formData.officeNumber"
                  >
                    <div class="column is-two-fifths">
                      <div>
                        <section>
                          <b-field>
                            <p class="control has-icons-right">
                              <b-autocomplete
                                size="is-medium"
                                v-model="userOfficeCode"
                                icon-pack="fi"
                                :icon="officeIconString"
                                open-on-focus
                                :data="officeFilteredDataArr"
                                disabled
                                rounded
                                @input="
                                  () => {
                                    ++countThird;
                                    if (countThird === 2) {
                                      kycFormStore.formData.office_country_code = ``;
                                      isOfficeCountryCodeTouched = true;
                                    }
                                  }
                                "
                                @select="
                                  (option) => {
                                    if (option) {
                                      kycFormStore.formData.office_country_code = `${
                                        option.split(` `)[1]
                                      }`;
                                      kycFormStore.formData.office_country_short_name =
                                        option.split(' ')[0];
                                    } else {
                                      kycFormStore.formData.office_country_code = ``;
                                    }

                                    if (
                                      kycFormStore.formData.office_country_code
                                    ) {
                                      changeOfficeSelection();
                                      //calOfficeContactNumber();
                                    }
                                  }
                                "
                              >
                              </b-autocomplete>
                              <span
                                class="has-text-danger"
                                v-if="
                                  isOfficeCountryCodeTouched &&
                                  !kycFormStore.formData.office_country_code
                                "
                                >You have to select a valid country code</span
                              >
                            </p>
                          </b-field>
                        </section>
                      </div>
                    </div>
                    <div class="column">
                      <b-field
                        label-position="inside"
                        :custom-class="
                          kycFormStore.formData.officeNumber.trim() === '' &&
                          !isOfficeNumberInputFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.office_num }}
                        </template>

                        <p class="control has-icons-right">
                          <ValidationProvider
                            name="Phone Number"
                            :rules="{ onlyNumbers: true }"
                            v-slot="{ errors }"
                          >
                            <b-input
                              v-model="kycFormStore.formData.officeNumber"
                              disabled
                              @focus="
                                () => {
                                  isOfficeNumberInputFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isOfficeNumberInputFocus = false;
                                }
                              "
                              @input="
                                () => {
                                  isOfficeNumberInputFocus = true;
                                  //calOfficeContactNumber();
                                }
                              "
                              rounded
                            ></b-input>
                            <span class="has-text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </p>
                      </b-field>
                    </div>
                  </div>
                </div>

                <!-- email_address -->
                <div class="mt-4">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      emailPreview.trim() === '' && !isEmailIdInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.email_address
                      }}<span class="has-text-danger">*</span></template
                    >
                    <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="{
                          email: true,
                          required: true,
                          lessOrEqualLength: 256,
                        }"
                        name="Email ID"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          v-model="emailPreview"
                          disabled
                          @focus="
                            () => {
                              isEmailIdInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isEmailIdInputFocus = false;
                            }
                          "
                        ></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      v-if="kycFormStore.formData.email !== emailPreview"
                      @click="
                        () => {
                          kycFormStore.formData.email = emailPreview;
                          isEmailActive = true;
                        }
                      "
                      >{{ $root.selectedLanguageData.save }}
                    </b-button>
                  </div>

                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isEmailActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>
                <div
                  class="mt-5"
                  v-if="kycFormStore.formData.secondary_email !== ``"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.secondary_email.trim() === '' &&
                      !isSecEmailIdInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label>{{
                      $root.selectedLanguageData.aditional_email
                    }}</template>
                    <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="{
                          email: true,

                          lessOrEqualLength: 256,
                        }"
                        name="Email ID"
                        v-slot="{ errors }"
                      >
                        <b-input
                          disabled
                          rounded
                          v-model="kycFormStore.formData.secondary_email"
                          @focus="
                            () => {
                              isSecEmailIdInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isSecEmailIdInputFocus = false;
                            }
                          "
                        ></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                </div>
                <!-- maiden_name -->
                <div class="mt-5">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      maidennamePreview.trim() === '' && !isMaidenNameInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.maiden_name
                      }}<span class="has-text-danger">*</span></template
                    >

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="maiden name"
                        :rules="validateName()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          v-model="maidennamePreview"
                          disabled
                          type="name"
                          @focus="
                            () => {
                              isMaidenNameInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isMaidenNameInputFocus = false;
                            }
                          "
                        ></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      v-if="
                        kycFormStore.formData.maiden_name !== maidennamePreview
                      "
                      @click="
                        () => {
                          kycFormStore.formData.maiden_name = maidennamePreview;
                          isMaidenNameActive = true;
                        }
                      "
                      >{{ $root.selectedLanguageData.save }}
                    </b-button>
                  </div>

                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isMaidenNameActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>

                <hr
                  style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
                />
                <!-- employment_status -->
                <div class="columns">
                  <div class="column is-half">
                    <div>
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <b-field
                          label-position="inside"
                          :custom-class="
                            employmentstatusPreview === '' &&
                            !isEmploymentStatusFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                          class="mb-0"
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.employment_status
                            }}<span class="has-text-danger">*</span></template
                          >

                          <b-select
                            expanded
                            disabled
                            rounded
                            v-model="employmentstatusPreview"
                            :class="
                              employmentstatusPreview === '' &&
                              !isEmploymentStatusFocus
                                ? 'arrow-up-before'
                                : 'arrow-up'
                            "
                            @focus="
                              () => {
                                isEmploymentStatusFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isEmploymentStatusFocus = false;
                              }
                            "
                          >
                            <option
                              v-for="status in $root.selectedLanguageData
                                .employmentStatusList"
                              :key="status.value"
                              :value="status"
                            >
                              {{ status.langTranslation }}
                            </option>
                          </b-select>
                        </b-field>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end mt-2"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.employment_status !==
                            employmentstatusPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.employment_status =
                                employmentstatusPreview;
                              isEmploymentStatusActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        class="mt-2"
                        has-icon
                        v-model="isEmploymentStatusActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                </div>

                <!-- profession_nature_of_business -->
                <div>
                  <div style="page-break-before: always">
                    <div
                      class="mt-5"
                      v-if="kycFormStore.formData.nature_of_business"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          natureofbusinessPreview.trim() === '' &&
                          !isNatureOfBussinessInputFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{
                            $root.selectedLanguageData
                              .profession_nature_of_business
                          }}<span
                            class="has-text-danger"
                            v-if="
                              kycFormStore.formData.employment_status.value ===
                              `Freelance`
                            "
                            >*</span
                          >
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            v-model="natureofbusinessPreview"
                            disabled
                            @focus="
                              () => {
                                isNatureOfBussinessInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isNatureOfBussinessInputFocus = false;
                              }
                            "
                          ></b-input>
                        </p>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.nature_of_business !==
                            natureofbusinessPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.nature_of_business =
                                natureofbusinessPreview;
                              isNatureOfBusinessActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isNatureOfBusinessActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                </div>
                <!-- Employer / Business Name -->
                <div class="mt-5" v-if="kycFormStore.formData.employer_name">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      employernamePreview.trim() === '' &&
                      !isEmployerNameInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.employer_business }}
                    </template>
                    <p class="control has-icons-right">
                      <b-input
                        rounded
                        v-model="employernamePreview"
                        disabled
                        @focus="
                          () => {
                            isEmployerNameInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isEmployerNameInputFocus = false;
                          }
                        "
                      ></b-input>
                    </p>
                  </b-field>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      v-if="
                        kycFormStore.formData.employer_name !==
                        employernamePreview
                      "
                      @click="
                        () => {
                          kycFormStore.formData.employer_name =
                            employernamePreview;
                          isEmployerNameActive = true;
                        }
                      "
                      >{{ $root.selectedLanguageData.save }}
                    </b-button>
                  </div>

                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isEmployerNameActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>
                <div
                  v-if="
                    kycFormStore.formData.employment_status.value ===
                    `Freelance`
                  "
                  class="mt-4"
                >
                  <label
                    >{{ $root.selectedLanguageData.frelance_employer_business
                    }}<span class="has-text-danger">*</span></label
                  >
                  <b-field>
                    <b-radio
                      v-if="kycFormStore.formData.are_you_a_freelancer"
                      disabled
                      checked
                      class="en-style"
                    >
                      {{ $root.selectedLanguageData.yes }}
                    </b-radio>
                    <b-radio
                      v-if="!kycFormStore.formData.are_you_a_freelancer"
                      disabled
                      checked
                      class="en-style"
                    >
                      {{ $root.selectedLanguageData.no }}
                    </b-radio>
                  </b-field>
                </div>
                <!-- working_platforms -->
                <div
                  v-if="kycFormStore.formData.working_platforms.length !== 0"
                  class="mt-4"
                >
                  <label
                    >{{ $root.selectedLanguageData.what_other_platforms
                    }}<span class="has-text-danger">*</span></label
                  >
                  <div class="columns work-list">
                    <div
                      class="work-list-item"
                      v-for="work in kycFormStore.formData.working_platforms"
                      :key="work.value"
                    >
                      <b-field>
                        <b-checkbox class="en-style" :value="true" disabled>
                          {{ work.langTranslation }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                </div>
                <!-- other working platform -->
                <div
                  class="mt-4"
                  v-if="kycFormStore.formData.specified_working_platforms"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.specified_working_platforms.trim() ===
                        '' && !isWorkingPlatformInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.please_specify
                      }}<span class="has-text-danger">*</span></template
                    >

                    <b-input
                      rounded
                      type="text"
                      disabled
                      v-model="
                        kycFormStore.formData.specified_working_platforms
                      "
                      @focus="
                        () => {
                          isWorkingPlatformInputFocus = true;
                        }
                      "
                      @blur="
                        () => {
                          isWorkingPlatformInputFocus = false;
                        }
                      "
                    />
                  </b-field>
                </div>

                <!-- BusinessAddress -->
                <div
                  class="mt-5"
                  v-if="
                    (kycFormStore.formData.employer_address_line1 &&
                      kycFormStore.formData.employer_address_country) ||
                    (kycFormStore.formData.employer_address_line2 &&
                      kycFormStore.formData.employer_address_country) ||
                    (kycFormStore.formData.employer_address_line3 &&
                      kycFormStore.formData.employer_address_country) ||
                    (kycFormStore.formData.employer_address_postal_code &&
                      kycFormStore.formData.employer_address_country) ||
                    (kycFormStore.formData.employer_address_district &&
                      kycFormStore.formData.employer_address_country) ||
                    (kycFormStore.formData.employer_address_town &&
                      kycFormStore.formData.employer_address_country)
                  "
                >
                  <label>{{
                    $root.selectedLanguageData.employer_business_address
                  }}</label>
                  <div class="columns is-4 is-variable">
                    <div
                      class="column is-two-fifths"
                      v-if="kycFormStore.formData.employer_address_line1"
                    >
                      <div>
                        <b-field
                          label-position="inside"
                          :custom-class="
                            employeraddressline1Preview.trim() === '' &&
                            !isEmAddLine1Focus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.building_number }}
                          </template>
                          <p class="control has-icons-right">
                            <b-input
                              rounded
                              type="text"
                              v-model="employeraddressline1Preview"
                              disabled
                              @focus="
                                () => {
                                  isEmAddLine1Focus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isEmAddLine1Focus = false;
                                }
                              "
                            />
                          </p>
                        </b-field>
                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            v-if="
                              kycFormStore.formData.employer_address_line1 !==
                              employeraddressline1Preview
                            "
                            @click="
                              () => {
                                kycFormStore.formData.employer_address_line1 =
                                  employeraddressline1Preview;
                                isEmpAddLine1Active = true;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>

                        <b-message
                          auto-close
                          size="is-medium"
                          type="is-success"
                          rounded
                          has-icon
                          v-model="isEmpAddLine1Active"
                          >{{ $root.selectedLanguageData.saved }}
                        </b-message>
                      </div>
                    </div>
                    <div
                      class="column"
                      v-if="kycFormStore.formData.employer_address_line2"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          employeraddressline2Preview.trim() === '' &&
                          !isEmAddLine2Focus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.building_name }}
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            type="text"
                            v-model="employeraddressline2Preview"
                            disabled
                            @focus="
                              () => {
                                isEmAddLine2Focus = true;
                              }
                            "
                            @blur="
                              () => {
                                isEmAddLine2Focus = false;
                              }
                            "
                          />
                        </p>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.employer_address_line2 !==
                            employeraddressline2Preview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.employer_address_line2 =
                                employeraddressline2Preview;
                              isEmpAddLine2Active = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isEmpAddLine2Active"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                  <!-- second line -->
                  <div class="columns is-7 is-variable mt-1">
                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.employer_address_line3"
                    >
                      <div>
                        <b-field
                          label-position="inside"
                          :custom-class="
                            employeraddressline3Preview.trim() === '' &&
                            !isEmAddLine3Focus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.street }}
                          </template>
                          <p class="control has-icons-right">
                            <b-input
                              rounded
                              disabled
                              type="text"
                              v-model="employeraddressline3Preview"
                              @focus="
                                () => {
                                  isEmAddLine3Focus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isEmAddLine3Focus = false;
                                }
                              "
                            />
                          </p>
                        </b-field>
                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            v-if="
                              kycFormStore.formData.employer_address_line3 !==
                              employeraddressline3Preview
                            "
                            @click="
                              () => {
                                kycFormStore.formData.employer_address_line3 =
                                  employeraddressline3Preview;
                                isEmpAddLine3Active = true;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>

                        <b-message
                          auto-close
                          size="is-medium"
                          type="is-success"
                          rounded
                          has-icon
                          v-model="isEmpAddLine3Active"
                          >{{ $root.selectedLanguageData.saved }}
                        </b-message>
                      </div>
                    </div>
                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.employer_address_town"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          employeraddresstownPreview.trim() === '' &&
                          !isEmAddTownFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.town }}
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            type="text"
                            v-model="employeraddresstownPreview"
                            disabled
                            @focus="
                              () => {
                                isEmAddTownFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isEmAddTownFocus = false;
                              }
                            "
                          />
                        </p>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.employer_address_town !==
                            employeraddresstownPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.employer_address_town =
                                employeraddresstownPreview;
                              isEmpAddTownActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isEmpAddTownActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                  <!-- third_line -->
                  <div class="columns is-7 is-variable mt-1">
                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.employer_address_postal_code"
                    >
                      <div>
                        <b-field
                          label-position="inside"
                          :custom-class="
                            employeraddresspostalcodePreview.trim() === '' &&
                            !isEmAddPostalCodeFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.postal_code }}
                          </template>
                          <p class="control has-icons-right">
                            <b-input
                              rounded
                              disabled
                              type="text"
                              v-model="employeraddresspostalcodePreview"
                              @focus="
                                () => {
                                  isEmAddPostalCodeFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isEmAddPostalCodeFocus = false;
                                }
                              "
                            />

                          </p>
                        </b-field>
                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            v-if="
                              kycFormStore.formData
                                .employer_address_postal_code !==
                              employeraddresspostalcodePreview
                            "
                            @click="
                              () => {
                                kycFormStore.formData.employer_address_postal_code =
                                  employeraddresspostalcodePreview;
                                isEmpAddPostalCodeActive = true;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>

                        <b-message
                          auto-close
                          size="is-medium"
                          type="is-success"
                          rounded
                          has-icon
                          v-model="isEmpAddPostalCodeActive"
                          >{{ $root.selectedLanguageData.saved }}
                        </b-message>
                      </div>
                    </div>
                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.employer_address_district"
                    >
                      <div
                        v-if="
                          kycFormStore.formData.residentship === `Sri Lanka`
                        "
                      >
                        <b-field
                          label-position="inside"
                          :custom-class="
                            employeraddressdistrictPreview.trim() === '' &&
                            !isEmAddDistrictFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                          class="mb-0"
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.district
                            }}<span class="has-text-danger"></span
                          ></template>
                          <b-select
                            v-model="employeraddressdistrictPreview"
                            expanded
                            disabled
                            rounded
                            :class="
                              employeraddressdistrictPreview === '' &&
                              !isEmAddDistrictFocus
                                ? 'arrow-up-before'
                                : 'arrow-up'
                            "
                            @focus="
                              () => {
                                isEmAddDistrictFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isEmAddDistrictFocus = false;
                              }
                            "
                          >
                            <option
                              v-for="district in kycFormStore.formDataSupport
                                .districtsArray"
                              :key="district"
                              :value="district"
                            >
                              {{ district }}
                            </option>
                          </b-select>
                        </b-field>
                      </div>
                      <div
                        v-if="
                          kycFormStore.formData.residentship !== `Sri Lanka`
                        "
                      >
                        <div>
                          <b-field
                            label-position="inside"
                            :custom-class="
                              employeraddressdistrictPreview.trim() === '' &&
                              !isEmAddDistrictFocus
                                ? 'custom-float-label-initial'
                                : 'custom-float-label'
                            "
                          >
                            <template #label
                              >{{ $root.selectedLanguageData.district }}
                            </template>
                            <p class="control has-icons-right">
                              <b-input
                                rounded
                                disabled
                                type="text"
                                v-model="employeraddressdistrictPreview"
                                @focus="
                                  () => {
                                    isEmAddDistrictFocus = true;
                                  }
                                "
                                @blur="
                                  () => {
                                    isEmAddDistrictFocus = false;
                                  }
                                "
                              />
                            </p>
                          </b-field>
                        </div>
                      </div>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.employer_address_district !==
                            employeraddressdistrictPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.employer_address_district =
                                employeraddressdistrictPreview;
                              isEmpAddDistrictActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isEmpAddDistrictActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                  <!-- country -->
                  <div class="columns is-7 is-variable mt-1">
                    <div
                      class="column is-half"
                      v-if="kycFormStore.formData.employer_address_country"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          employeraddresscountryPreview.trim() === '' &&
                          !isEmAddCountryFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                        class="mb-0"
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.country
                          }}<span class="has-text-danger"></span
                        ></template>

                        <b-select
                          v-model="employeraddresscountryPreview"
                          expanded
                          rounded
                          disabled
                          :class="
                            employeraddresscountryPreview === '' &&
                            !isEmAddCountryFocus
                              ? 'arrow-up-before'
                              : 'arrow-up'
                          "
                          @focus="
                            () => {
                              isEmAddCountryFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isEmAddCountryFocus = false;
                            }
                          "
                        >
                          <option
                            v-for="country in kycFormStore.formDataSupport
                              .countriesArray"
                            :key="country.name"
                            :value="country.name"
                          >
                            {{ country.name }}
                          </option>
                        </b-select>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData.employer_address_country !==
                            employeraddresscountryPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.employer_address_country =
                                employeraddresscountryPreview;
                              isEmpAddCountryActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isEmpAddCountryActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                  </div>
                </div>

                <!-- hr -->
                <hr
                  style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
                />
                <p style="font-weight: 600; font-size: 17px" class="mb-2">
                  {{ $root.selectedLanguageData.tax_details_info }}
                </p>
                <!-- are_you_a_taxpayer -->
                <div>
                  <label
                    >{{ $root.selectedLanguageData.are_you_a_taxpayer
                    }}<span class="has-text-danger">*</span></label
                  >
                  <b-field>
                    <!--                @click.native="isTaxpayerModalActive = true"-->
                    <b-radio
                      v-if="kycFormStore.formData.are_you_a_taxpayer === true"
                      checked
                      disabled
                      class="en-style"
                    >
                      {{ $root.selectedLanguageData.yes }}
                    </b-radio>
                    <b-radio
                      v-if="kycFormStore.formData.are_you_a_taxpayer === false"
                      checked
                      disabled
                      class="en-style"
                    >
                      {{ $root.selectedLanguageData.no }}
                    </b-radio>
                  </b-field>
                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isTaxpayerActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>
                <b-modal v-model="isTaxpayerModalActive" :width="540">
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isTaxpayerModalActive = false;
                          }
                        "
                      ></button>
                    </div>
                    <div>
                      <label
                        >{{ $root.selectedLanguageData.are_you_a_taxpayer
                        }}<span class="has-text-danger">*</span></label
                      >
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <div class="columns">
                          <div class="column is-one-two">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="are_you_a_tax_payer"
                                :native-value="true"
                                v-model="areyoutaxpayerPreview"
                              >
                                {{ $root.selectedLanguageData.yes }}
                              </b-radio>
                            </b-field>
                          </div>
                          <div class="column is-one-two">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="are_you_a_tax_payer"
                                :native-value="false"
                                v-model="areyoutaxpayerPreview"
                              >
                                {{ $root.selectedLanguageData.no }}
                              </b-radio>
                            </b-field>
                          </div>
                        </div>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            kycFormStore.formData.are_you_a_taxpayer =
                              areyoutaxpayerPreview;
                            isTaxpayerActive = true;
                            isTaxpayerModalActive = false;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>
                  </div>
                </b-modal>
                <!-- are_you_a_taxpayer === `Yes` -->
                <div class="mt-4" v-if="kycFormStore.formData.tax_file_num">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      taxfilenumPreview.trim() === '' &&
                      !isTaxFileNumberInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.tax_file_num }}
                    </template>
                    <p class="control has-icons-right">
                      <b-input
                        rounded
                        type="number"
                        disabled
                        v-model="taxfilenumPreview"
                        @focus="
                          () => {
                            isTaxFileNumberInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isTaxFileNumberInputFocus = false;
                          }
                        "
                      />
                    </p>
                  </b-field>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      v-if="
                        kycFormStore.formData.tax_file_num !== taxfilenumPreview
                      "
                      @click="
                        () => {
                          kycFormStore.formData.tax_file_num =
                            taxfilenumPreview;
                          isTaxFileNumActive = true;
                        }
                      "
                      >{{ $root.selectedLanguageData.save }}
                    </b-button>
                  </div>

                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isTaxFileNumActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>
                <!-- hr -->

                <!-- hr -->
                <hr
                  style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
                />

                <div>
                  <label
                    >{{
                      $root.selectedLanguageData
                        .relative_a_politically_exposed_person
                    }}<span class="has-text-danger">*</span></label
                  >
                  <b-field>
                    <!--              @click.native="isPoliticallyexposedModalActive = true"-->
                    <b-radio
                      v-if="
                        kycFormStore.formData.politically_exposed ===
                        `im_politically_exposed`
                      "
                      checked
                      disabled
                      class="en-style"
                    >
                      {{ $root.selectedLanguageData.im_politically_exposed }}
                    </b-radio>
                    <b-radio
                      v-if="
                        kycFormStore.formData.politically_exposed ===
                        `my_close_relative_politically_exposed`
                      "
                      checked
                      disabled
                      class="en-style"
                    >
                      {{
                        $root.selectedLanguageData
                          .my_close_relative_politically_exposed
                      }}
                    </b-radio>
                    <b-radio
                      v-if="kycFormStore.formData.politically_exposed === `No`"
                      checked
                      disabled
                      class="en-style"
                    >
                      {{ $root.selectedLanguageData.no }}
                    </b-radio>
                  </b-field>
                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isPoliticallyexposedActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>
                <b-modal v-model="isPoliticallyexposedModalActive" :width="540">
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isPoliticallyexposedModalActive = false;
                          }
                        "
                      ></button>
                    </div>
                    <div>
                      <label
                        >{{
                          $root.selectedLanguageData
                            .relative_a_politically_exposed_person
                        }}<span class="has-text-danger">*</span></label
                      >
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <div class="columns">
                          <div class="column">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="Are You or Your close Relative a Politically Exposed Person"
                                native-value="im_politically_exposed"
                                @input="resetPoliticalValue()"
                                v-model="politicallyexposedPreview"
                              >
                                {{
                                  $root.selectedLanguageData
                                    .im_politically_exposed
                                }}
                              </b-radio>
                            </b-field>
                          </div>
                          <div class="column">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="Are You or Your close Relative a Politically Exposed Person"
                                native-value="my_close_relative_politically_exposed"
                                v-model="politicallyexposedPreview"
                                @input="resetPoliticalValue()"
                              >
                                {{
                                  $root.selectedLanguageData
                                    .my_close_relative_politically_exposed
                                }}
                              </b-radio>
                            </b-field>
                          </div>
                          <div class="column">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="Are You or Your close Relative a Politically Exposed Person"
                                native-value="No"
                                v-model="politicallyexposedPreview"
                                @input="resetPoliticalValue()"
                              >
                                {{ $root.selectedLanguageData.no }}
                              </b-radio>
                            </b-field>
                          </div>
                        </div>

                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>

                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            kycFormStore.formData.politically_exposed =
                              politicallyexposedPreview;
                            isPoliticallyexposedActive = true;
                            isPoliticallyexposedModalActive = false;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>
                  </div>
                </b-modal>

                <!-- politically_exposed_person_name -->
                <div
                  class="mt-4"
                  v-if="kycFormStore.formData.politically_exposed_name"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      politicallyexposednamePreview.trim() === '' &&
                      !isPoliticallyExposedNameInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.name }}
                    </template>

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="name"
                        :rules="validateNotRequiredName()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="text"
                          v-model="politicallyexposednamePreview"
                          disabled
                          @focus="
                            () => {
                              isPoliticallyExposedNameInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isPoliticallyExposedNameInputFocus = false;
                            }
                          "
                        />
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      v-if="
                        kycFormStore.formData.politically_exposed_name !==
                        politicallyexposednamePreview
                      "
                      @click="
                        () => {
                          kycFormStore.formData.politically_exposed_name =
                            politicallyexposednamePreview;
                          isPoliticallyExposedNameActive = true;
                        }
                      "
                      >{{ $root.selectedLanguageData.save }}
                    </b-button>
                  </div>

                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isPoliticallyExposedNameActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>
                <!-- designation -->
                <div
                  class="mt-5"
                  v-if="kycFormStore.formData.politically_exposed_position"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      politicallyexposedpositionPreview.trim() === '' &&
                      !isPoliticallyExposedDesignationInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.designation }}
                    </template>
                    <p class="control has-icons-right">
                      <b-input
                        rounded
                        disabled
                        type="text"
                        v-model="politicallyexposedpositionPreview"
                        @focus="
                          () => {
                            isPoliticallyExposedDesignationInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isPoliticallyExposedDesignationInputFocus = false;
                          }
                        "
                      />
                    </p>
                  </b-field>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      v-if="
                        kycFormStore.formData.politically_exposed_position !==
                        politicallyexposedpositionPreview
                      "
                      @click="
                        () => {
                          kycFormStore.formData.politically_exposed_position =
                            politicallyexposedpositionPreview;
                          isPoliticallyExposedPositionActive = true;
                        }
                      "
                      >{{ $root.selectedLanguageData.save }}
                    </b-button>
                  </div>

                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isPoliticallyExposedPositionActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>

                <!-- for un editable below one -->
                <div
                  class="mt-5"
                  v-if="kycFormStore.formData.second_politically_exposed_name"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      secondpoliticallyexposednamePreview.trim() === '' &&
                      !isSecondPoliticallyExposedNameInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.name }}
                    </template>

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="name"
                        :rules="validateNotRequiredName()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="text"
                          disabled
                          v-model="secondpoliticallyexposednamePreview"
                          @focus="
                            () => {
                              isSecondPoliticallyExposedNameInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isSecondPoliticallyExposedNameInputFocus = false;
                            }
                          "
                        />
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                </div>

                <div
                  class="mt-5"
                  v-if="
                    kycFormStore.formData.second_politically_exposed_position
                  "
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      secondpoliticallyexposeddesignationPreview.trim() ===
                        '' && !isSecondPoliticallyExposedDesignationInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.designation }}
                    </template>
                    <p class="control has-icons-right">
                      <b-input
                        rounded
                        type="text"
                        disabled
                        v-model="secondpoliticallyexposeddesignationPreview"
                        @focus="
                          () => {
                            isSecondPoliticallyExposedDesignationInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isSecondPoliticallyExposedDesignationInputFocus = false;
                          }
                        "
                      />
                    </p>
                  </b-field>
                </div>
                <!-- exposedRelative -->

                <div
                  class="mt-4"
                  v-if="kycFormStore.formData.politically_exposed_relative"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      politicallyexposedrelativePreview.trim() === '' &&
                      !isPoliticallyExposedRelativeNameInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.name }}
                    </template>

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="name"
                        :rules="validateNotRequiredName()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="text"
                          disabled
                          v-model="politicallyexposedrelativePreview"
                          @focus="
                            () => {
                              isPoliticallyExposedRelativeNameInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isPoliticallyExposedRelativeNameInputFocus = false;
                            }
                          "
                        />
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      v-if="
                        kycFormStore.formData.politically_exposed_relative !==
                        politicallyexposedrelativePreview
                      "
                      @click="
                        () => {
                          kycFormStore.formData.politically_exposed_relative =
                            politicallyexposedrelativePreview;
                          politicallyexposedrelativeActive = true;
                        }
                      "
                      >{{ $root.selectedLanguageData.save }}
                    </b-button>
                  </div>

                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="politicallyexposedrelativeActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>
                <!-- designation -->
                <div
                  class="mt-5"
                  v-if="
                    kycFormStore.formData.politically_exposed_relative_position
                  "
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      politicallyexposedrelativepositionPreview.trim() === '' &&
                      !isPoliticallyExposedRelativePositionInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.designation }}
                    </template>
                    <p class="control has-icons-right">
                      <b-input
                        rounded
                        type="text"
                        disabled
                        v-model="politicallyexposedrelativepositionPreview"
                        @focus="
                          () => {
                            isPoliticallyExposedRelativePositionInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isPoliticallyExposedRelativePositionInputFocus = false;
                          }
                        "
                      />

                    </p>
                  </b-field>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      v-if="
                        kycFormStore.formData
                          .politically_exposed_relative_position !==
                        politicallyexposedrelativepositionPreview
                      "
                      @click="
                        () => {
                          kycFormStore.formData.politically_exposed_relative_position =
                            politicallyexposedrelativepositionPreview;
                          politicallyexposedrelativepositionActive = true;
                        }
                      "
                      >{{ $root.selectedLanguageData.save }}
                    </b-button>
                  </div>

                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="politicallyexposedrelativepositionActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>

                <!-- non editable code below -->
                <div
                  class="mt-5"
                  v-if="
                    kycFormStore.formData.second_politically_exposed_relative
                  "
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      secondpoliticallyexposedrelativePreview.trim() === '' &&
                      !isSecondPoliticallyExposedRelativeNameInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.name }}
                    </template>

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="name"
                        :rules="validateNotRequiredName()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="text"
                          disabled
                          v-model="secondpoliticallyexposedrelativePreview"
                          @focus="
                            () => {
                              isSecondPoliticallyExposedRelativeNameInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isSecondPoliticallyExposedRelativeNameInputFocus = false;
                            }
                          "
                        />
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                </div>
                <div
                  class="mt-5"
                  v-if="
                    kycFormStore.formData
                      .second_politically_exposed_relative_position
                  "
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      secondpoliticallyexposedrelativepositionPreview.trim() ===
                        '' &&
                      !isSecondPoliticallyExposedRelativeDesignationInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.designation }}
                    </template>
                    <p class="control has-icons-right">
                      <b-input
                        rounded
                        disabled
                        type="text"
                        v-model="
                          secondpoliticallyexposedrelativepositionPreview
                        "
                        @focus="
                          () => {
                            isSecondPoliticallyExposedRelativeDesignationInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isSecondPoliticallyExposedRelativeDesignationInputFocus = false;
                          }
                        "
                      />
                    </p>
                  </b-field>
                </div>
                
                <!-- politically relative -->
                <!-- hr -->
                <hr
                  style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
                />
                <!-- source_of_income -->
                <!--            @click="isSourceofincomeModalActive = true"-->
                <div>
                  <label
                    >{{ $root.selectedLanguageData.source_of_funds
                    }}<span class="has-text-danger">*</span></label
                  >

                  <!-- Employment Income -->
                  <div
                    v-if="
                      kycFormStore.formData.source_of_income.includes(
                        `Employment Income`
                      )
                    "
                  >
                    <b-checkbox
                      :value="true"
                      class="en-style"
                      native-value="Employment Income"
                      disabled
                    >
                      {{ $root.selectedLanguageData.employment_income }}
                    </b-checkbox>
                  </div>
                  <!-- Investment Proceeds -->
                  <div
                    v-if="
                      kycFormStore.formData.source_of_income.includes(
                        `Investment Proceeds`
                      )
                    "
                  >
                    <b-checkbox
                      :value="true"
                      class="en-style"
                      native-value="Investment Proceeds"
                      disabled
                    >
                      {{ $root.selectedLanguageData.investment_proceeds }}
                    </b-checkbox>
                  </div>
                  <!-- Gifts -->
                  <div
                    v-if="
                      kycFormStore.formData.source_of_income.includes(`Gifts`)
                    "
                  >
                    <b-checkbox
                      :value="true"
                      class="en-style"
                      native-value="Gifts"
                      disabled
                    >
                      {{ $root.selectedLanguageData.gifts }}
                    </b-checkbox>
                  </div>
                  <!-- Inheritance -->
                  <div
                    v-if="
                      kycFormStore.formData.source_of_income.includes(
                        `Inheritance`
                      )
                    "
                  >
                    <b-checkbox
                      :value="true"
                      class="en-style"
                      native-value="Inheritance"
                      disabled
                    >
                      {{ $root.selectedLanguageData.inheritance }}
                    </b-checkbox>
                  </div>
                  <!-- Commission Income -->
                  <div
                    v-if="
                      kycFormStore.formData.source_of_income.includes(
                        `Commission Income`
                      )
                    "
                  >
                    <b-checkbox
                      :value="true"
                      class="en-style"
                      disabled
                      native-value="Commission Income"
                    >
                      {{ $root.selectedLanguageData.commission_income }}
                    </b-checkbox>
                  </div>
                  <!-- Sale of Property / Asset -->
                  <div
                    v-if="
                      kycFormStore.formData.source_of_income.includes(
                        `Sale of Property / Asset`
                      )
                    "
                  >
                    <b-checkbox
                      :value="true"
                      class="en-style"
                      native-value="Sale of Property / Asset"
                      disabled
                    >
                      {{ $root.selectedLanguageData.sale_of_property }}
                    </b-checkbox>
                  </div>
                  <!-- Family Remittance -->
                  <div
                    v-if="
                      kycFormStore.formData.source_of_income.includes(
                        `Family Remittance`
                      )
                    "
                  >
                    <b-checkbox
                      :value="true"
                      class="en-style"
                      native-value="Family Remittance"
                      disabled
                    >
                      {{ $root.selectedLanguageData.family_remittance }}
                    </b-checkbox>
                  </div>
                  <!-- Donation -->
                  <div
                    v-if="
                      kycFormStore.formData.source_of_income.includes(
                        `Donation`
                      )
                    "
                  >
                    <b-checkbox
                      :value="true"
                      class="en-style"
                      native-value="Donation"
                      disabled
                    >
                      {{ $root.selectedLanguageData.donation }}
                    </b-checkbox>
                  </div>
                  <!-- Other -->
                  <div
                    v-if="
                      kycFormStore.formData.source_of_income.includes(`Other`)
                    "
                  >
                    <b-checkbox
                      :value="true"
                      class="en-style"
                      native-value="Other"
                      disabled
                    >
                      {{ $root.selectedLanguageData.other }}
                    </b-checkbox>
                  </div>
                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isSourceofincomeActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>
                <b-modal v-model="isSourceofincomeModalActive" :width="540">
                  <div class="box">
                    <div class="modal-close-button">
                      <button
                        class="delete mb-2"
                        aria-label="close"
                        @click="
                          () => {
                            isSourceofincomeModalActive = false;
                          }
                        "
                      ></button>
                    </div>
                    <div>
                      <label
                        >{{ $root.selectedLanguageData.source_of_funds
                        }}<span class="has-text-danger">*</span></label
                      >
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <div class="columns">
                          <div class="column is-one-two">
                            <b-field>
                              <b-checkbox
                                class="en-style"
                                native-value="Employment Income"
                                v-model="sourceofincomePreview"
                              >
                                {{
                                  $root.selectedLanguageData.employment_income
                                }}
                              </b-checkbox>
                            </b-field>
                          </div>
                          <div class="column is-one-two">
                            <b-field>
                              <b-checkbox
                                class="en-style"
                                native-value="Investment Proceeds"
                                v-model="sourceofincomePreview"
                              >
                                {{
                                  $root.selectedLanguageData.investment_proceeds
                                }}
                              </b-checkbox>
                            </b-field>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-one-two">
                            <b-field>
                              <b-checkbox
                                class="en-style"
                                native-value="Gifts"
                                v-model="sourceofincomePreview"
                              >
                                {{ $root.selectedLanguageData.gifts }}
                              </b-checkbox>
                            </b-field>
                          </div>
                          <div class="column is-one-two">
                            <b-field>
                              <b-checkbox
                                class="en-style"
                                native-value="Inheritance"
                                v-model="sourceofincomePreview"
                              >
                                {{ $root.selectedLanguageData.inheritance }}
                              </b-checkbox>
                            </b-field>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-one-two">
                            <b-field>
                              <b-checkbox
                                class="en-style"
                                native-value="Commission Income"
                                v-model="sourceofincomePreview"
                              >
                                {{
                                  $root.selectedLanguageData.commission_income
                                }}
                              </b-checkbox>
                            </b-field>
                          </div>
                          <div class="column is-one-two">
                            <b-field>
                              <b-checkbox
                                class="en-style"
                                native-value="Sale of Property / Asset"
                                v-model="sourceofincomePreview"
                              >
                                {{
                                  $root.selectedLanguageData.sale_of_property
                                }}
                              </b-checkbox>
                            </b-field>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-one-two">
                            <b-field>
                              <b-checkbox
                                class="en-style"
                                native-value="Family Remittance"
                                v-model="sourceofincomePreview"
                              >
                                {{
                                  $root.selectedLanguageData.family_remittance
                                }}
                              </b-checkbox>
                            </b-field>
                          </div>
                          <div class="column is-one-two">
                            <b-field>
                              <b-checkbox
                                class="en-style"
                                native-value="Donation"
                                v-model="sourceofincomePreview"
                              >
                                {{ $root.selectedLanguageData.donation }}
                              </b-checkbox>
                            </b-field>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-half">
                            <b-field>
                              <b-checkbox
                                class="en-style"
                                native-value="Other"
                                @click.native="
                                  kycFormStore.formData.specified_source_of_income = ``
                                "
                                v-model="sourceofincomePreview"
                              >
                                {{ $root.selectedLanguageData.other }}
                              </b-checkbox>
                            </b-field>
                          </div>
                        </div>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </div>
                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            kycFormStore.formData.source_of_income =
                              sourceofincomePreview;
                            isSourceofincomeActive = true;
                            isSourceofincomeModalActive = false;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>
                  </div>
                </b-modal>
                <!-- other source of income -->
                <div
                  class="mt-5"
                  v-if="
                    kycFormStore.formData.source_of_income.includes('Other') ||
                    kycFormStore.formData.specified_source_of_income
                  "
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      specifiedsourceofincomePreview.trim() === '' &&
                      !isSourceOfIncomeInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.please_specify
                      }}<span class="has-text-danger">*</span></template
                    >
                    <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="text"
                          disabled
                          v-model="specifiedsourceofincomePreview"
                          @focus="
                            () => {
                              isSourceOfIncomeInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isSourceOfIncomeInputFocus = false;
                            }
                          "
                        />
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      v-if="
                        kycFormStore.formData.specified_source_of_income !==
                        specifiedsourceofincomePreview
                      "
                      @click="
                        () => {
                          kycFormStore.formData.specified_source_of_income =
                            specifiedsourceofincomePreview;
                          isEmpAddLine2Active = true;
                        }
                      "
                      >{{ $root.selectedLanguageData.save }}
                    </b-button>
                  </div>

                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isEmpAddLine2Active"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>
                <!-- other source of income -->
                <!-- hr -->
                <hr
                  style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
                />
                <!-- Branch -->
                <div class="columns is-4 is-variable">
                  <div class="column is-half">
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          branchPreview === '' && !isBranchInputFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                        class="mb-0"
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.preferred_branch
                          }}<span class="has-text-danger">*</span></template
                        >

                        <b-select
                          expanded
                          rounded
                          v-model="branchPreview"
                          disabled
                          :class="
                            branchPreview === '' && !isBranchInputFocus
                              ? 'arrow-up-before'
                              : 'arrow-up'
                          "
                          @focus="
                            () => {
                              isBranchInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isBranchInputFocus = false;
                            }
                          "
                        >
                          <option
                            v-for="branch in kycFormStore.formDataSupport
                              .branchesArray"
                            :key="branch.id"
                            :value="branch.id"
                          >
                            {{ branch.branch_name }}
                          </option>
                        </b-select>
                      </b-field>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        v-if="kycFormStore.formData.branch !== branchPreview"
                        @click="
                          () => {
                            kycFormStore.formData.branch = branchPreview;
                            isBranchActive = true;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>

                    <b-message
                      auto-close
                      size="is-medium"
                      type="is-success"
                      rounded
                      has-icon
                      v-model="isBranchActive"
                      >{{ $root.selectedLanguageData.saved }}
                    </b-message>
                  </div>
                </div>
                <!-- reason_for_opening_an_account -->
                <div
                  class="mt-3"
                  v-if="kycFormStore.formData.reason_for_opening_an_account"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      reasonforopeninganaccountPreview.trim() === '' &&
                      !isAccOpenResonInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      ><span
                        class="hi"
                        :style="{
                          'text-align': 'center',
                          'text-justify': 'inter-word',
                          'font-size': '13px',
                          'word-break': 'break-all',
                        }"
                        >{{
                          $root.selectedLanguageData
                            .reason_for_opening_an_account
                        }}</span
                      >
                      <span class="has-text-danger">*</span></template
                    >

                    <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          v-model="reasonforopeninganaccountPreview"
                          disabled
                          @focus="
                            () => {
                              isAccOpenResonInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isAccOpenResonInputFocus = false;
                            }
                          "
                        ></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                  <div
                    class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                  >
                    <b-button
                      class="is-primary is-rounded save_button"
                      v-if="
                        kycFormStore.formData.reason_for_opening_an_account !==
                        reasonforopeninganaccountPreview
                      "
                      @click="
                        () => {
                          kycFormStore.formData.reason_for_opening_an_account =
                            reasonforopeninganaccountPreview;
                          isReasonActive = true;
                        }
                      "
                      >{{ $root.selectedLanguageData.save }}
                    </b-button>
                  </div>

                  <b-message
                    auto-close
                    size="is-medium"
                    type="is-success"
                    rounded
                    has-icon
                    v-model="isReasonActive"
                    >{{ $root.selectedLanguageData.saved }}
                  </b-message>
                </div>
                <!-- hr -->
                <hr
                  style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
                />

                <!-- account selection start -->
                <div>
                  <b-loading
                    :is-full-page="false"
                    v-model="productListLoading"
                    :can-cancel="true"
                  ></b-loading>
                </div>

                <div
                  v-if="!productListLoading"
                  style="page-break-before: always"
                >
                  <ProductSelectionPreviewComponent
                    v-for="(productDetail, index) in kycFormStore.formData
                      .product_selection"
                    :product-selection="productDetail"
                    :key="index"
                  ></ProductSelectionPreviewComponent>
                </div>

                <!-- account selection end -->

                <!-- sms -->
                <div class="is-flex is-justify-content-space-between">
                  <div>
                    <label
                      >{{ $root.selectedLanguageData.sms
                      }}<span class="has-text-danger">*</span></label
                    >
                  </div>
                  <div>
                    <div
                      id="sms-toggle-buttons"
                      class="toggle-button toggle-change has-text-centered"
                      style="
                        background-color: #f9f9f9;
                        opacity: 0.5;
                        cursor: not-allowed;
                      "
                    >
                      <div
                        id="sms-yes-button"
                        class="yes yes-active is-size-5 toggle-common"
                        @click="smsYesClick()"
                      >
                        {{ $root.selectedLanguageData.yes }}
                      </div>
                      <div
                        id="sms-no-button"
                        class="no no-active is-size-5 toggle-common"
                        @click="
                          () => {
                            // smsNoClick();
                          }
                        "
                      >
                        {{ $root.selectedLanguageData.no }}
                      </div>
                    </div>
                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      v-if="kycFormStore.formData.sms_service !== smsService"
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            kycFormStore.formData.sms_service = smsService;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <!-- ebanking  -->
                <div class="is-flex is-justify-content-space-between mt-5">
                  <div>
                    <label
                      >{{ $root.selectedLanguageData.ebanking
                      }}<span class="has-text-danger">*</span></label
                    >
                  </div>
                  <div>
                    <div
                      id="e-banking-toggle-buttons"
                      class="toggle-button toggle-change has-text-centered"
                      style="
                        background-color: #f9f9f9;
                        opacity: 0.5;
                        cursor: not-allowed;
                      "
                    >
                      <div
                        id="e-banking-yes-button"
                        class="yes yes-active is-size-5 toggle-common"
                        @click="eBankingYesClick()"
                      >
                        {{ $root.selectedLanguageData.yes }}
                      </div>
                      <div
                        id="e-banking-no-button"
                        class="no no-active is-size-5 toggle-common"
                        @click="
                          () => {
                            // eBankingNoClick()
                          }
                        "
                      >
                        {{ $root.selectedLanguageData.no }}
                      </div>
                    </div>
                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      v-if="
                        kycFormStore.formData.e_banking_service !==
                        eBankingService
                      "
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            kycFormStore.formData.e_banking_service =
                              eBankingService;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <!-- Virtual Wallet -->
                <div class="is-flex is-justify-content-space-between mt-5">
                  <div>
                    <label
                      >{{ $root.selectedLanguageData.virtualWallet
                      }}<span class="has-text-danger">*</span></label
                    >
                  </div>
                  <div>
                    <div
                      id="virtual-wallet-toggle-buttons"
                      class="toggle-button toggle-change has-text-centered"
                      style="
                        background-color: #f9f9f9;
                        opacity: 0.5;
                        cursor: not-allowed;
                      "
                    >
                      <div
                        id="virtual-wallet-yes-button"
                        class="yes yes-active is-size-5 toggle-common"
                        @click="virtualWalletYesClick()"
                      >
                        {{ $root.selectedLanguageData.yes }}
                      </div>
                      <div
                        id="virtual-wallet-no-button"
                        class="no no-active is-size-5 toggle-common"
                        @click="
                          () => {
                            // virtualWalletNoClick()
                          }
                        "
                      >
                        {{ $root.selectedLanguageData.no }}
                      </div>
                    </div>
                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      v-if="
                        kycFormStore.formData.virtual_wallet_service !==
                        virtualWalletService
                      "
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            kycFormStore.formData.virtual_wallet_service =
                              virtualWalletService;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <!-- Estatement -->
                <div class="is-flex is-justify-content-space-between mt-5">
                  <div>
                    <label
                      >{{ $root.selectedLanguageData.estatement
                      }}<span class="has-text-danger">*</span></label
                    >
                  </div>
                  <div>
                    <div
                      id="estatement-toggle-buttons"
                      class="toggle-button toggle-change has-text-centered"
                      style="
                        background-color: #f9f9f9;
                        opacity: 0.5;
                        cursor: not-allowed;
                      "
                    >
                      <div
                        id="estatement-yes-button"
                        class="yes yes-active is-size-5 toggle-common"
                        @click="estatementYesClick()"
                      >
                        {{ $root.selectedLanguageData.yes }}
                      </div>
                      <div
                        id="estatement-no-button"
                        class="no no-active is-size-5 toggle-common"
                        @click="
                          () => {
                            // estatementNoClick()
                          }
                        "
                      >
                        {{ $root.selectedLanguageData.no }}
                      </div>
                    </div>
                    <div
                      class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      v-if="
                        kycFormStore.formData.estatement_service !==
                        eStatementService
                      "
                    >
                      <b-button
                        class="is-primary is-rounded save_button"
                        @click="
                          () => {
                            kycFormStore.formData.estatement_service =
                              eStatementService;
                          }
                        "
                        >{{ $root.selectedLanguageData.save }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <!-- Debit Card* -->
                <div
                  v-if="
                    GlobalFunctions.CheckDebitCardSectionVisible(kycFormStore)
                  "
                >
                  <div class="is-flex is-justify-content-space-between mt-5">
                    <div>
                      <label
                        >{{ $root.selectedLanguageData.debitCard
                        }}<span class="has-text-danger"></span
                      ></label>
                    </div>
                    <div>
                      <div
                        id="debit_card-toggle-buttons"
                        class="toggle-button toggle-change has-text-centered"
                        style="
                          background-color: #f9f9f9;
                          opacity: 0.5;
                          cursor: not-allowed;
                        "
                      >
                        <!-- @click="debitCardYesClick()" -->
                        <div
                          id="debit_card-yes-button"
                          class="yes yes-active is-size-5 toggle-common"
                        >
                          {{ $root.selectedLanguageData.yes }}
                        </div>
                        <div
                          id="debit_card-no-button"
                          disabled
                          class="no no-active is-size-5 toggle-common"
                          @click="
                            () => {
                              // debitCardNoClick();
                            }
                          "
                        >
                          {{ $root.selectedLanguageData.no }}
                        </div>
                      </div>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        v-if="
                          kycFormStore.formData.debit_card_service !==
                          debitCardService
                        "
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          @click="
                            () => {
                              kycFormStore.formData.debit_card_service =
                                debitCardService;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                  <!-- POS Required* -->
                  <div v-if="kycFormStore.formData.debit_card_service">
                    <div class="is-flex is-justify-content-space-between mt-5">
                      <div>
                        <label
                          >{{ $root.selectedLanguageData.posRequest
                          }}<span class="has-text-danger">*</span></label
                        >
                      </div>

                      <div class="mr-2">
                        <b-field>
                          <!--                  @click.native="isPosrequiredModalActive = true"-->
                          <b-radio
                            v-if="kycFormStore.formData.pos_required === true"
                            class="en-style"
                            checked
                            disabled
                          >
                            {{ $root.selectedLanguageData.yes }}
                          </b-radio>
                          <b-radio
                            v-if="kycFormStore.formData.pos_required === false"
                            class="en-style"
                            checked
                            disabled
                          >
                            {{ $root.selectedLanguageData.no }}
                          </b-radio>
                        </b-field>
                      </div>
                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isPosrequiredActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                    <b-modal v-model="isPosrequiredModalActive" :width="540">
                      <div class="box">
                        <div class="modal-close-button">
                          <button
                            class="delete mb-2"
                            aria-label="close"
                            @click="
                              () => {
                                isPosrequiredModalActive = false;
                              }
                            "
                          ></button>
                        </div>
                        <div class="mt-5">
                          <label
                            >{{ $root.selectedLanguageData.posRequest
                            }}<span class="has-text-danger">*</span></label
                          >
                          <ValidationProvider
                            :rules="{ required: true }"
                            v-slot="{ errors }"
                          >
                            <div class="columns">
                              <div class="column is-one-two">
                                <div class="columns">
                                  <div class="column is-one-two">
                                    <b-field>
                                      <b-radio
                                        class="en-style"
                                        name="POS Required"
                                        :native-value="true"
                                        v-model="posrequiredPreview"
                                      >
                                        {{ $root.selectedLanguageData.yes }}
                                      </b-radio>
                                    </b-field>
                                  </div>
                                  <div class="column is-one-two">
                                    <b-field>
                                      <b-radio
                                        class="en-style"
                                        name="POS Required"
                                        :native-value="false"
                                        v-model="posrequiredPreview"
                                      >
                                        {{ $root.selectedLanguageData.no }}
                                      </b-radio>
                                    </b-field>
                                  </div>
                                </div>
                              </div>
                              <div class="column is-one-two"></div>
                            </div>
                            <span class="has-text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>

                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            @click="
                              () => {
                                kycFormStore.formData.pos_required =
                                  posrequiredPreview;
                                isPosrequiredActive = true;
                                isPosrequiredModalActive = false;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>
                      </div>
                    </b-modal>
                    <div
                      class="mt-4 mb-5"
                      v-if="
                        kycFormStore.formData
                          .account_to_be_linked_for_pos_transaction
                      "
                    >
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <b-field
                          label-position="inside"
                          :custom-class="
                            accounttobelinkedforPosPreview === '' &&
                            !isPosTransactionInputFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                          class="mb-0"
                        >
                          <template #label
                            >{{
                              $root.selectedLanguageData
                                .account_to_be_linked_for_pos
                            }}<span class="has-text-danger">*</span></template
                          >

                          <b-select
                            expanded
                            disabled
                            rounded
                            v-model="accounttobelinkedforPosPreview"
                            :class="
                              accounttobelinkedforPosPreview === '' &&
                              !isPosTransactionInputFocus
                                ? 'arrow-up-before'
                                : 'arrow-up'
                            "
                            @focus="
                              () => {
                                isPosTransactionInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPosTransactionInputFocus = false;
                              }
                            "
                          >
                            <option
                              v-for="account in GlobalFunctions.GetOnlyLKRAccounts(
                                kycFormStore
                              )"
                              :key="account"
                              :value="account"
                            >
                              {{ account }}
                            </option>
                          </b-select>
                        </b-field>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData
                              .account_to_be_linked_for_pos_transaction !==
                            accounttobelinkedforPosPreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.account_to_be_linked_for_pos_transaction =
                                accounttobelinkedforPosPreview;
                              isAccLinkedActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isAccLinkedActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                    <div
                      style="margin-top: 14px"
                      v-if="
                        kycFormStore.formData.facility_debit_card_customer_name
                      "
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          debitcardcustomernamePreview.trim() === '' &&
                          !isCustomerNameInputFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          ><span class="hi">{{
                            $root.selectedLanguageData
                              .name_to_be_printed_on_card
                          }}</span>
                          <span class="has-text-danger">*</span></template
                        >

                        <p class="control has-icons-right">
                          <ValidationProvider
                            name="name"
                            :rules="validateName()"
                            v-slot="{ errors }"
                          >
                            <b-input
                              rounded
                              disabled
                              v-model="debitcardcustomernamePreview"
                              @focus="
                                () => {
                                  isCustomerNameInputFocus = true;
                                }
                              "
                              @blur="
                                () => {
                                  isCustomerNameInputFocus = false;
                                }
                              "
                            ></b-input>
                            <span class="has-text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </p>
                      </b-field>
                      <div
                        class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                      >
                        <b-button
                          class="is-primary is-rounded save_button"
                          v-if="
                            kycFormStore.formData
                              .facility_debit_card_customer_name !==
                            debitcardcustomernamePreview
                          "
                          @click="
                            () => {
                              kycFormStore.formData.facility_debit_card_customer_name =
                                debitcardcustomernamePreview;
                              isDebitCardCustomerNameActive = true;
                            }
                          "
                          >{{ $root.selectedLanguageData.save }}
                        </b-button>
                      </div>

                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isDebitCardCustomerNameActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                    <!-- Collection Details -->
                    <div
                      class="mt-5"
                      v-if="kycFormStore.formData.collection_details"
                    >
                      <label
                        >{{ $root.selectedLanguageData.collectionDetails
                        }}<span class="has-text-danger">*</span></label
                      >
                      <div
                        v-if="
                          kycFormStore.formData.collection_details === `Branch`
                        "
                      >
                        <b-radio class="en-style" checked disabled>
                          {{ $root.selectedLanguageData.branch }}
                        </b-radio>
                      </div>
                      <!--                @click="isCollectiondetailsModalActive = true"-->
                      <div
                        v-if="
                          kycFormStore.formData.collection_details ===
                          `Residence Address`
                        "
                      >
                        <b-radio class="en-style" checked disabled>
                          {{ $root.selectedLanguageData.resident }}
                        </b-radio>
                      </div>
                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isCollectiondetailsActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                    </div>
                    <b-modal
                      v-model="isCollectiondetailsModalActive"
                      :width="540"
                    >
                      <div class="box">
                        <div class="modal-close-button">
                          <button
                            class="delete mb-2"
                            aria-label="close"
                            @click="
                              () => {
                                isCollectiondetailsModalActive = false;
                              }
                            "
                          ></button>
                        </div>
                        <div class="mt-5">
                          <label
                            >{{ $root.selectedLanguageData.collectionDetails
                            }}<span class="has-text-danger">*</span></label
                          >
                          <ValidationProvider
                            :rules="{ required: true }"
                            v-slot="{ errors }"
                          >
                            <div class="columns">
                              <div class="column">
                                <b-field>
                                  <b-radio
                                    class="en-style"
                                    name="Collection Details "
                                    native-value="Branch"
                                    v-model="collectiondetailsPreview"
                                  >
                                    {{ $root.selectedLanguageData.branch }}
                                  </b-radio>
                                </b-field>
                              </div>
                              <div class="column">
                                <b-field>
                                  <b-radio
                                    class="en-style"
                                    name="Collection Details "
                                    native-value="Residence Address"
                                    v-model="collectiondetailsPreview"
                                  >
                                    {{ $root.selectedLanguageData.resident }}
                                  </b-radio>
                                </b-field>
                              </div>
                            </div>
                            <span class="has-text-danger">{{ errors[0] }}</span>
                          </ValidationProvider>
                        </div>

                        <div
                          class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                        >
                          <b-button
                            class="is-primary is-rounded save_button"
                            @click="
                              () => {
                                kycFormStore.formData.collection_details =
                                  collectiondetailsPreview;
                                isCollectiondetailsActive = true;
                                isCollectiondetailsModalActive = false;
                              }
                            "
                            >{{ $root.selectedLanguageData.save }}
                          </b-button>
                        </div>
                      </div>
                    </b-modal>
                    <!-- Pin Collection Details -->
                    <div
                      class="mt-3"
                      v-if="kycFormStore.formData.pin_collection_details"
                    >
                      <label
                        >{{ $root.selectedLanguageData.pincollectionDetails
                        }}<span class="has-text-danger">*</span></label
                      >
                      <b-field>
                        <!--                  @click.native="isPinCollectiondetailsModalActive = true"-->
                        <b-radio
                          v-if="
                            kycFormStore.formData.pin_collection_details ===
                            `Branch(for PIN collection)`
                          "
                          class="en-style"
                          disabled
                          checked
                        >
                          {{ $root.selectedLanguageData.branch }}
                        </b-radio>
                        <b-radio
                          v-if="
                            kycFormStore.formData.pin_collection_details ===
                            `Residence Address(for PIN collection)`
                          "
                          class="en-style"
                          checked
                          disabled
                        >
                          {{ $root.selectedLanguageData.resident }}
                        </b-radio>
                      </b-field>
                      <b-message
                        auto-close
                        size="is-medium"
                        type="is-success"
                        rounded
                        has-icon
                        v-model="isPinCollectiondetailsActive"
                        >{{ $root.selectedLanguageData.saved }}
                      </b-message>
                      <b-modal
                        v-model="isPinCollectiondetailsModalActive"
                        :width="540"
                      >
                        <div class="box">
                          <div class="modal-close-button">
                            <button
                              class="delete mb-2"
                              aria-label="close"
                              @click="
                                () => {
                                  isPinCollectiondetailsModalActive = false;
                                }
                              "
                            ></button>
                          </div>

                          <div class="mt-5" style="page-break-after: always">
                            <label
                              >{{
                                $root.selectedLanguageData.pincollectionDetails
                              }}<span class="has-text-danger">*</span></label
                            >
                            <ValidationProvider
                              :rules="{ required: true }"
                              v-slot="{ errors }"
                            >
                              <div class="columns">
                                <div class="column">
                                  <b-field>
                                    <b-radio
                                      class="en-style"
                                      name="PIN Collection Details"
                                      native-value="Branch(for PIN collection)"
                                      v-model="pincollectiondetailsPreview"
                                    >
                                      {{ $root.selectedLanguageData.branch }}
                                    </b-radio>
                                  </b-field>
                                </div>
                                <div class="column">
                                  <b-field>
                                    <b-radio
                                      class="en-style"
                                      name="PIN Collection Details"
                                      native-value="Residence Address(for PIN collection)"
                                      v-model="pincollectiondetailsPreview"
                                    >
                                      {{ $root.selectedLanguageData.resident }}
                                    </b-radio>
                                  </b-field>
                                </div>
                              </div>
                              <span class="has-text-danger">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                          </div>

                          <div
                            class="is-flex is-justify-content-flex-end is-align-content-flex-end"
                          >
                            <b-button
                              class="is-primary is-rounded save_button"
                              @click="
                                () => {
                                  kycFormStore.formData.pin_collection_details =
                                    pincollectiondetailsPreview;
                                  isPinCollectiondetailsActive = true;
                                  isPinCollectiondetailsModalActive = false;
                                }
                              "
                              >{{ $root.selectedLanguageData.save }}
                            </b-button>
                          </div>
                        </div>
                      </b-modal>
                    </div>
                  </div>
                </div>
                <hr
                  style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
                />
                <div
                  class="columns mt-2 is-7 is-variable"
                  style="page-break-after: always"
                >
                  <div class="column">
                    <ValidationProvider
                      name="Issue Date"
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="'custom_float_label_date'"
                      >
                        <template #label
                          >{{
                            $root.selectedLanguageData
                              .preferred_date_for_video_call
                          }}<span class="has-text-danger">*</span></template
                        >
                        <p class="control has-icons-right">
                          <b-datepicker
                            placeholder="DD/MM/YY"
                            :unselectable-days-of-week="[0, 6]"
                            disabled
                            :max-date="maxDate"
                            :years-range="[-100, 100]"
                            :unselectable-dates="unselectableDates"
                            rounded
                            v-model="
                              kycFormStore.formData
                                .preferred_date_for_video_call
                            "
                            @input="getTimeSlots()"
                          >
                          </b-datepicker>
                        </p>
                      </b-field>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>

                  <!--              time-->
                  <div class="column">
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          kycFormStore.formData.preferred_time_for_video_call.trim() ===
                            '' && !isTimeInputFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                        class="mb-0"
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.preferred_time
                          }}<span class="has-text-danger">*</span></template
                        >

                        <b-select
                          expanded
                          rounded
                          disabled
                          v-model="
                            kycFormStore.formData.preferred_time_for_video_call
                          "
                          :class="
                            kycFormStore.formData
                              .preferred_time_for_video_call === '' &&
                            !isTimeInputFocus
                              ? 'arrow-up-before'
                              : 'arrow-up'
                          "
                          @focus="
                            () => {
                              isTimeInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isTimeInputFocus = false;
                            }
                          "
                        >
                          <option
                            v-for="time_slot in time_slots"
                            :key="time_slot.time"
                            :value="time_slot.time"
                            :disabled="time_slot.disable"
                          >
                            {{ time_slot.time }}
                          </option>
                        </b-select>
                      </b-field>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <hr
                  style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5"
                />

                <div style="page-break-before">
                  <div
                    v-if="
                      kycFormStore.imageURLs.nic_front_image ||
                      kycFormStore.imageURLs.nic_back_image
                    "
                  >
                    <label>
                      {{ $root.selectedLanguageData.uploadimagesofNIC }}
                    </label>
                    <div class="columns is-7 is-variable">
                      <div
                        class="column is-one-two"
                        v-if="kycFormStore.imageURLs.nic_front_image"
                      >
                        <label
                          >{{ $root.selectedLanguageData.nicfrontsideimage
                          }}<span class="has-text-danger">*</span>
                        </label>
                        <!-- if it's editable we have to add below function -->
                        <!-- @click="
                      {
                        nicFrontEdit = !nicFrontEdit;
                      }
                    " -->
                        <div class="preview_image_box">
                          <b-image
                            :src="
                              kycFormStore.imageURLs.nic_front_image === ''
                                ? previewImagePlaceholder
                                : kycFormStore.imageURLs.nic_front_image
                            "
                            placeholder="../assets/images/icons/camera.png"
                            responsive
                            disabled
                          ></b-image>
                        </div>
                        <div v-if="nicFrontEdit">
                          <image-capture-preview-component
                          @on-captured="onNicCaptured" :crop_ratio=9/14
                          file_name="nic_front_image"
                          :selectedImageURL="kycFormStore.imageURLs.nic_front_image"
                          :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                          :captureButtonLabel="$root.selectedLanguageData.capture"/>
                        </div>
                      </div>
                      <div
                        class="column is-one-two"
                        v-if="kycFormStore.imageURLs.nic_back_image"
                      >
                        <label
                          >{{ $root.selectedLanguageData.nicbacksideimage
                          }}<span class="has-text-danger">*</span>
                        </label>
                        <!-- @click="
                      {
                        nicRearEdit = !nicRearEdit;
                      }
                    " -->
                        <div class="preview_image_box">
                          <b-image
                            :src="
                              kycFormStore.imageURLs.nic_back_image === ''
                                ? previewImagePlaceholder
                                : kycFormStore.imageURLs.nic_back_image
                            "
                            placeholder="../assets/images/icons/camera.png"
                            responsive
                          ></b-image>
                        </div>
                        <div v-if="nicRearEdit">
                          <image-capture-preview-component
                          @on-captured="onNicBackCaptured" :crop_ratio=14/9
                          file_name="nic_back_image"
                          :selectedImageURL="kycFormStore.imageURLs.nic_back_image"
                          :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                          :captureButtonLabel="$root.selectedLanguageData.capture"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- passport Image -->
                <div style="page-break-before: always">
                  <div
                    v-if="
                      kycFormStore.imageURLs.passport_image ||
                      kycFormStore.imageURLs.passport_image_2
                    "
                    class="mt-5"
                  >
                    <label>
                      {{ $root.selectedLanguageData.uploadimagesofpassport }}
                    </label>
                    <div class="columns is-7 is-variable">
                      <div
                        class="column is-one-two"
                        v-if="kycFormStore.imageURLs.passport_image"
                      >
                        <label
                          >{{ $root.selectedLanguageData.bio_page_img
                          }}<span
                            class="has-text-danger"
                            v-if="
                              kycFormStore.formData.residentship ===
                              `Out of Sri Lanka`
                            "
                            >*</span
                          >
                        </label>
                        <!-- @click="
                      {
                        passportOneEdit = !passportOneEdit;
                      }
                    " -->
                        <div class="preview_image_box">
                          <b-image
                            :src="
                              kycFormStore.imageURLs.passport_image === ''
                                ? previewImagePlaceholder
                                : kycFormStore.imageURLs.passport_image
                            "
                            placeholder="../assets/images/icons/camera.png"
                            responsive
                          ></b-image>
                        </div>
                        <div v-if="passportOneEdit">
                          <image-capture-preview-component
                          @on-captured="onPassportCaptured" :crop_ratio=16/10
                          file_name="passport_image"
                          :selectedImageURL="kycFormStore.imageURLs.passport_image"
                          :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                          :captureButtonLabel="$root.selectedLanguageData.capture"/>
                        </div>
                      </div>
                      <div
                        class="column is-one-two"
                        v-if="kycFormStore.imageURLs.passport_image_2"
                      >
                        <label
                          >{{ $root.selectedLanguageData.alteration_page_image
                          }}<span
                            class="has-text-danger"
                            v-if="
                              kycFormStore.formData.residentship ===
                              `Out of Sri Lanka`
                            "
                            >*</span
                          >
                        </label>
                        <!-- @click="
                      {
                        passportTwoEdit = !passportTwoEdit;
                      }
                    " -->
                        <div class="preview_image_box">
                          <b-image
                            :src="
                              kycFormStore.imageURLs.passport_image_2 === ''
                                ? previewImagePlaceholder
                                : kycFormStore.imageURLs.passport_image_2
                            "
                            placeholder="../assets/images/icons/camera.png"
                            responsive
                          ></b-image>
                        </div>
                        <div v-if="passportTwoEdit">
                          <image-capture-preview-component
                          @on-captured="onPassportSecoundCaptured"
                          :crop_ratio=16/10 file_name="passport_image"
                          :selectedImageURL="kycFormStore.imageURLs.passport_image_2"
                          :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                          :captureButtonLabel="$root.selectedLanguageData.capture"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- entry_stamp_page -->
                <div style="page-break-before: always">
                  <div
                    v-if="kycFormStore.imageURLs.stamp_page_image"
                    class="mt-3"
                  >
                    <label
                      >{{ $root.selectedLanguageData.entry_stamp_page_image
                      }}<span
                        class="has-text-danger"
                        v-if="
                          kycFormStore.formData.residentship ===
                          `Out of Sri Lanka`
                        "
                        >*</span
                      >
                    </label>
                    <!-- @click="
                      () => {
                        stamppageEdit = !stamppageEdit;
                      }
                    " -->
                    <div class="preview_image_box">
                      <b-image
                        :src="
                          kycFormStore.imageURLs.stamp_page_image === ''
                            ? previewImagePlaceholder
                            : kycFormStore.imageURLs.stamp_page_image
                        "
                        placeholder="../assets/images/icons/camera.png"
                        responsive
                      ></b-image>
                    </div>
                    <div v-if="stamppageEdit">
                      <image-capture-preview-component
                      class="imageCaptureComponent"
                      @on-captured="onStampPageCaptured" :crop_ratio=16/9
                      file_name="passport_image"
                      :selectedImageURL="kycFormStore.imageURLs.stamp_page_image"
                      :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                      :captureButtonLabel="$root.selectedLanguageData.capture"/>
                    </div>
                  </div>
                </div>

                <!-- visa image -->
                <div class="column is-one-two">
                  <div v-if="kycFormStore.imageURLs.visa_image" class="mt-3">
                    <label
                      >{{ $root.selectedLanguageData.visa_image
                      }}<span
                        class="has-text-danger"
                        v-if="
                          kycFormStore.formData.residentship ===
                          `Out of Sri Lanka`
                        "
                        >*</span
                      >
                    </label>

                    <div class="preview_image_box">
                      <b-image
                        :src="
                          kycFormStore.imageURLs.visa_image === ''
                            ? previewImagePlaceholder
                            : kycFormStore.imageURLs.visa_image
                        "
                        placeholder="../assets/images/icons/camera.png"
                        responsive
                      ></b-image>
                    </div>
                  </div>
                </div>
                <!-- selfieimage -->
                <div>
                  <div class="columns mt-1 is-7 is-variable">
                    <div class="column is-one-two">
                      <label
                        >{{ $root.selectedLanguageData.uploadyourselfieimage
                        }}<span class="has-text-danger">*</span>
                      </label>
                      <!-- @click="
                    {
                      selfieEdit = !selfieEdit;
                    }
                  " -->
                      <div class="preview_image_box">
                        <b-image
                          :src="
                            kycFormStore.imageURLs.selfie_image === ''
                              ? previewImagePlaceholder
                              : kycFormStore.imageURLs.selfie_image
                          "
                          placeholder="../assets/images/icons/camera.png"
                          responsive
                        ></b-image>
                      </div>
                      <div v-if="selfieEdit">
                        <image-capture-preview-component
                        class="imageCaptureComponent"
                        @on-captured="onSelfieCaptured" :crop_ratio=1/1
                        file_name="selfie_image"
                        :selectedImageURL="kycFormStore.imageURLs.selfie_image"
                        :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                        :captureButtonLabel="$root.selectedLanguageData.capture"/>
                      </div>
                    </div>
                    <div class="column is-one-two">
                      <label
                        >{{ $root.selectedLanguageData.uploadyoursignatureimage
                        }}<span class="has-text-danger">*</span>
                      </label>
                      <!-- @click="
                    {
                      signatureEdit = !signatureEdit;
                    }
                  " -->
                      <div class="preview_image_box">
                        <b-image
                          :src="
                            kycFormStore.imageURLs.sign_image === ''
                              ? previewImagePlaceholder
                              : kycFormStore.imageURLs.sign_image
                          "
                          placeholder="../assets/images/icons/camera.png"
                          responsive
                        ></b-image>
                      </div>
                      <div v-if="signatureEdit">
                        <image-capture-preview-component
                        class="imageCaptureComponent"
                        @on-captured="onSignatureCaptured" :crop_ratio=7/4
                        file_name="sign_image"
                        :selectedImageURL="kycFormStore.imageURLs.sign_image"
                        :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                        :captureButtonLabel="$root.selectedLanguageData.capture"/>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- proofofaddress -->
                <div>
                  <div v-if="kycFormStore.imageURLs.addr_proof_doc">
                    <label
                      >{{ $root.selectedLanguageData.uploadyourproofofaddress
                      }}<span class="has-text-danger">*</span>
                    </label>
                    <!-- @click="
                  () => {
                    addressEdit = !addressEdit;
                  }
                " -->
                    <div class="preview_image_box">
                      <b-image
                        :src="
                          kycFormStore.imageURLs.addr_proof_doc === ''
                            ? previewImagePlaceholder
                            : kycFormStore.imageURLs.addr_proof_doc
                        "
                        placeholder="../assets/images/icons/camera.png"
                        responsive
                      ></b-image>
                    </div>
                    <div v-if="addressEdit">
                      <image-capture-preview-component
                      class="imageCaptureComponent"
                      @on-captured="onAddressProofCaptured" :crop_ratio=16/9
                      file_name="addr_proof_doc"
                      :selectedImageURL="kycFormStore.imageURLs.addr_proof_doc"
                      :uploadButtonLabel="$root.selectedLanguageData.clickToUpload"
                      :captureButtonLabel="$root.selectedLanguageData.capture"/>
                    </div>
                  </div>
                </div>
              </section>
            </vue-html2pdf>
          </div>
        </div>
      </div>
    </ValidationObserver>
  </div>
</template>
<script>
// import logopage from "./CompanyTopLogo.vue";
import imagePlaceholder from "../assets/images/icons/camera.png";
import imageCapturePreviewComponent from "@/components/supports/ImageCapturePreviewComponent";
import VueHtml2pdf from "vue-html2pdf";

// import {ApiRequestManager} from "@/utils/networkManager/apiRequestManager";
import { useKycFormStore } from "@/stores/KycFormStore";
import NetworkManager from "@/utils/networkManager";
import { BuefyHelper } from "@/heplers/buefyHelper";
// import GetHelp from "./GetHelp.vue";
import { GlobalFunctions } from "@/heplers/globalFunctions";
import ProductSelectionPreviewComponent from "@/components/supports/ProductSelectionPreviewComponent.vue";
// import TermsAndContionTemplate from "@/components/supports/TermsAndConditionTemplate.vue";

export default {
  /*
   * ===================================================================================================================
   * expose kyc form pinia store to the component
   */
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },

  components: {
    // TermsAndContionTemplate,
    // logopage,
    VueHtml2pdf,
    imageCapturePreviewComponent,
    // GetHelp,
    ProductSelectionPreviewComponent,
  },
  data: function () {
    return {
      criclePlus : require("@/assets/images/icons/Circle-plus.png"),
      circleMinus : require("@/assets/images/icons/CircleMinus.png"),
      termsandconditionContent: "",
      productListLoading:
        this.kycFormStore.formDataSupport.productList.length <= 0,
      regex: {
        old_nic: /^([0-9]{9}[Xx||Vv])$/,
        passport: /^[0-9A-Za-z]{4,18}$/,
        new_nic: /[0-9]{12}/,
      },
      isTermsTouched: false,
      time_slots: [],
      unselectableDates: [],
      unselectableDatesArray: [],

      iconString: "",
      userCode: "",
      count: 0,
      isCountryCodeTouched: false,
      isMobileNumberTouched: false,
      isOfficeNumberTouched: false,
      countryCodeArray: [],

      countThird: 0,
      isOfficeNumberInputFocus: false,
      officeIconString: "",
      userOfficeCode: "",
      isOfficeCountryCodeTouched: false,

      maxDate: new Date(),

      continueBtnLoading: false,
      isTittleInputFocus: false,
      isMobileNumberInputFocus: false,
      isFullNameInputFocus: false,
      isCitizenInputFocus: false,
      isNicInputFocus: false,
      isNicIssueInputFocus: false,
      isPassportInputFocus: false,
      isPassportIssueInputFocus: false,
      isPassportExpiryFocus: false,
      isPerAddLine1Focus: false,
      isPerAddLine2Focus: false,
      isPerAddLine3Focus: false,
      isPerAddTownFocus: false,
      isPerAddPostalCodeFocus: false,
      isPerAddDistrictFocus: false,
      isPerAddCountryFocus: false,
      isCorAddDistrictFocus: false,
      isCorAddCountryFocus: false,
      isCorAddLine1Focus: false,
      isCorAddLine2Focus: false,
      isCorAddLine3Focus: false,
      isCorAddTownFocus: false,
      isSecEmailIdInputFocus: false,
      isCorAddPostalCodeFocus: false,
      isNatureOfBussinessInputFocus: false,
      isEmploymentStatusFocus: false,
      isEmployerNameInputFocus: false,
      isEmAddLine1Focus: false,
      isEmAddLine2Focus: false,
      isEmAddLine3Focus: false,
      isEmAddTownFocus: false,
      isEmAddPostalCodeFocus: false,
      isEmAddDistrictFocus: false,
      isEmAddCountryFocus: false,
      isTaxFileNumberInputFocus: false,
      isPoliticallyExposedNameInputFocus: false,
      isPoliticallyExposedDesignationInputFocus: false,
      isMaidenNameInputFocus: false,
      isEmailIdInputFocus: false,
      isSourceOfIncomeInputFocus: false,
      previewImagePlaceholder: imagePlaceholder,
      isBranchInputFocus: false,
      isAccOpenResonInputFocus: false,
      isCurrencyFocus: false,
      isSpecifiedPurposeInputFocus: false,
      isInitialDepositInputFocus: false,
      isShow: false,
      isVisible: false,
      isSecondShow: false,
      isSecondCurrencyFocus: false,
      isSecondSpecifiedPurposeInputFocus: false,
      isSecondInitialDepositInputFocus: false,
      isThirdCurrencyFocus: false,

      smsToggleButton: "",
      smsYesButton: "",
      smsNoButton: "",
      eBankingToggleButton: "",
      eBankingYesButton: "",
      eBankingNoButton: "",
      virtualWalletToggleButton: "",
      virtualWalletYesButton: "",
      virtualWalletNoButton: "",
      estatementToggleButton: "",
      estatementYesButton: "",
      estatementNoButton: "",
      debitCardToggleButton: "",
      debitCardYesButton: "",
      debitCardNoButton: "",
      isPosTransactionInputFocus: false,
      isCustomerNameInputFocus: false,
      countriesArray: [],
      lang: "en",

      isActive: false,
      isNicActive: false,
      isTittleActive: false,
      isCitizenActive: false,
      isPassportActive: false,
      isPerAddLine1Active: false,
      isPerAddLine2Active: false,
      isPerAddLine3Active: false,
      isPerAddTownActive: false,
      isPerAddPostalCodeActive: false,
      isPerAddDistrictActive: false,
      isPerAddCountryActive: false,
      isCorAddLine1Active: false,
      isCorAddLine2Active: false,
      isCorAddLine3Active: false,
      isCorAddTownActive: false,
      isCorAddPostalCodeActive: false,
      isCorAddDistrictActive: false,
      isCorAddCountryActive: false,
      isEmailActive: false,
      isMobileActive: false,
      isMaidenNameActive: false,
      isEmploymentStatusActive: false,
      isNatureOfBusinessActive: false,
      isEmployerNameActive: false,
      isEmpAddLine1Active: false,
      isEmpAddLine2Active: false,
      isEmpAddLine3Active: false,
      isEmpAddTownActive: false,
      isEmpAddPostalCodeActive: false,
      isEmpAddDistrictActive: false,
      isEmpAddCountryActive: false,
      isTaxFileNumActive: false,
      isPoliticallyExposedNameActive: false,
      isPoliticallyExposedPositionActive: false,
      isBranchActive: false,
      isReasonActive: false,
      isSpecifiedPurposeofOpeningActive: false,
      isInitialdepositActive: false,
      isAccLinkedActive: false,
      isDebitCardCustomerNameActive: false,
      isMaritalstatusActive: false,
      isGenderActive: false,
      isStatusOfResidentsActive: false,
      isTaxpayerActive: false,
      isPoliticallyexposedActive: false,
      isPurposeofopeningActive: false,
      isanticipatedvolActive: false,
      isPosrequiredActive: false,
      isSourceofincomeActive: false,
      isAccountOneActive: false,
      isCurrencyActive: false,
      isCollectiondetailsActive: false,
      isPinCollectiondetailsActive: false,

      isSecondPoliticallyExposedNameInputFocus: false,
      isTimeInputFocus: false,
      isSecondPoliticallyExposedDesignationInputFocus: false,
      isPoliticallyExposedRelativeNameInputFocus: false,
      isPoliticallyExposedRelativeDesignationInputFocus: false,
      isSecondPoliticallyExposedRelativeNameInputFocus: false,
      isSecondPoliticallyExposedRelativeDesignationInputFocus: false,
      isPoliticallyExposedRelativePositionInputFocus: false,

      isSecondPoliticallyExposedNameActive: false,
      isSecondPoliticallyExposedPositionActive: false,

      secondpoliticallyexposednamePreview: "",
      secondpoliticallyexposeddesignationPreview: "",

      politicallyexposedrelativePreview: "",
      politicallyexposedrelativepositionPreview: "",
      politicallyexposedrelativeActive: false,
      politicallyexposedrelativepositionActive: false,

      secondpoliticallyexposedrelativePreview: "",
      secondpoliticallyexposedrelativeActive: false,
      secondpoliticallyexposedrelativepositionActive: false,
      secondpoliticallyexposedrelativepositionPreview: "",

      isAccountTwoActive: false,
      isCurrencyActiveSecond: false,
      isCurrencyActiveThird: false,
      isCollectiondetailsActiveSecond: false,
      isPinCollectiondetailsActiveSecond: false,
      isSecondPurposeofopeningActive: false,
      isanticipatedvolActiveSecond: false,
      isSecondSpecifiedPurposeofOpeningActive: false,
      isAccountThirdActive: false,
      isAccountThirdModalActive: false,
      thirdsavingaccounttypePreview: "",
      initialdepositPreviewSecond: "",
      isInitialdepositActiveSecond: false,

      isGenderModalActive: false,
      isMaritalstatusModalActive: false,
      isStatusOfResidentsModalActive: false,
      isTaxpayerModalActive: false,
      isPoliticallyexposedModalActive: false,
      isPurposeofopeningModalActive: false,
      isSecondPurposeofopeningModalActive: false,
      issecondanticipatedvolModalActive: false,
      isanticipatedvolModalActive: false,
      isPosrequiredModalActive: false,
      isSourceofincomeModalActive: false,
      isAccountOneModalActive: false,
      isAccountSecondModalActive: false,
      isCollectiondetailsModalActive: false,
      isTelActive: false,
      isPinCollectiondetailsModalActive: false,
      isCurrencyInputFocus: false,
      isCurrencyInputFocusSecond: false,
      isCurrencyInputFocusThird: false,

      isanticipatedvolModalActiveThird: false,
      isanticipatedvolActiveThird: false,
      anticipatedvolPreviewThird: "",

      thirdinitialdepositPreview: "",
      isThirdInitialdepositActive: false,
      isThirdInitialDepositInputFocus: false,

      fullnamePreview: "",
      customernicPreview: "",
      titlePreview: "",
      citizenshipPreview_1: "",
      customerpassportnoPreview: "",
      permanentaddressline1Preview: "",
      permanentaddressline2Preview: "",
      permanentaddressline3Preview: "",
      permanentaddresstownPreview: "",
      permanentaddresspostalcodePreview: "",
      permanentaddressdistrictPreview: "",
      permanentaddresscountryPreview: "",
      correspondentaddressline1Preview: "",
      correspondentaddressline2Preview: "",
      correspondentaddressline3Preview: "",
      correspondentaddresstownPreview: "",
      correspondentaddresspostalcodePreview: "",
      correspondentaddressdistrictPreview: "",
      correspondentaddresscountryPreview: "",
      emailPreview: "",
      maidennamePreview: "",
      employmentstatusPreview: "",
      natureofbusinessPreview: "",
      employernamePreview: "",
      employeraddressline1Preview: "",
      employeraddressline2Preview: "",
      employeraddressline3Preview: "",
      employeraddresstownPreview: "",
      employeraddresspostalcodePreview: "",
      employeraddressdistrictPreview: "",
      employeraddresscountryPreview: "",
      taxfilenumPreview: "",
      politicallyexposednamePreview: "",
      politicallyexposedpositionPreview: "",
      branchPreview: "",
      reasonforopeninganaccountPreview: "",
      specifiedpurposeofopeningPreview: "",
      initialdepositPreview: "",
      accounttobelinkedforPosPreview: "",
      debitcardcustomernamePreview: "",
      genderPreview: "",
      statusofresidentsPreview: "",
      areyoutaxpayerPreview: "",
      politicallyexposedPreview: "",
      purposeofopeningPreview: "",
      anticipatedvolPreview: "",
      secondanticipatedvolPreview: "",
      posrequiredPreview: "",
      specifiedsourceofincomePreview: "",

      sourceofincomePreview: [],
      collectiondetailsPreview: "",
      pincollectiondetailsPreview: "",

      currencyPreview: "",
      currencyPreviewSecond: "",
      currencyPreviewThird: "",
      savingaccounttypePreview: "",
      secondsavingaccounttypePreview: "",
      secondspecifiedpurposeofopeningPreview: "",

      isThirdSpecifiedPurposeofOpeningActive: false,
      thirdspecifiedpurposeofopeningPreview: "",
      isThirdSpecifiedPurposeInputFocus: false,

      purposeofopeningPreviewThird: "",
      isPurposeofopeningModalActiveThird: false,
      isThirdPurposeofopeningActive: false,

      nicFrontEdit: false,
      nicRearEdit: false,
      passportOneEdit: false,
      passportTwoEdit: false,
      addressEdit: false,
      stamppageEdit: false,
      signatureEdit: false,
      selfieEdit: false,
      smsService: false,
      eBankingService: false,
      virtualWalletService: false,
      eStatementService: false,
      debitCardService: false,
      flagshow: false,

      tempInitialDeposit1: "",
      tempInitialDeposit2: "",
      tempInitialDeposit3: "",
      officeCountryCodeEdit: "",

      telephoneNoEdit: "",
      officeShortNameEdit: "",
      isMobileNumberStartsWithZero: false,
      isOfficeNumberStartsWithZero: false,
      isMobileNumberValid: false,
      isOfficeNumberValid: true,
      isReadMoreContent: false,
      termsChecked: false,
    };
  },
  watch: {
    "kycFormStore.productImages.nic_front_image": function () {
      if (this.kycFormStore.productImages.nic_front_image) {
        this.kycFormStore.imageURLs.nic_front_image = URL.createObjectURL(
          this.kycFormStore.productImages.nic_front_image
        );
      }
    },
    "kycFormStore.productImages.nic_back_image": function () {
      if (this.kycFormStore.productImages.nic_back_image) {
        this.kycFormStore.imageURLs.nic_back_image = URL.createObjectURL(
          this.kycFormStore.productImages.nic_back_image
        );
      }
    },
    "kycFormStore.productImages.passport_image": function () {
      if (this.kycFormStore.productImages.passport_image) {
        this.kycFormStore.imageURLs.passport_image = URL.createObjectURL(
          this.kycFormStore.productImages.passport_image
        );
      }
    },
    "kycFormStore.productImages.passport_image_2": function () {
      if (this.kycFormStore.productImages.passport_image_2) {
        this.kycFormStore.imageURLs.passport_image_2 = URL.createObjectURL(
          this.kycFormStore.productImages.passport_image_2
        );
      }
    },
    "kycFormStore.productImages.selfie_image": function () {
      if (this.kycFormStore.productImages.selfie_image) {
        this.kycFormStore.imageURLs.selfie_image = URL.createObjectURL(
          this.kycFormStore.productImages.selfie_image
        );
      }
    },
    "kycFormStore.productImages.sign_image": function () {
      if (this.kycFormStore.productImages.sign_image) {
        this.kycFormStore.imageURLs.sign_image = URL.createObjectURL(
          this.kycFormStore.productImages.sign_image
        );
      }
    },
    "kycFormStore.productImages.visa_proof_doc": function () {
      if (this.kycFormStore.productImages.visa_proof_doc) {
        this.kycFormStore.imageURLsvisa_proof_doc = URL.createObjectURL(
          this.kycFormStore.productImages.visa_proof_doc
        );
      }
    },
    "kycFormStore.productImages.addr_proof_doc": function () {
      if (this.kycFormStore.productImages.addr_proof_doc) {
        this.kycFormStore.imageURLs.addr_proof_doc = URL.createObjectURL(
          this.kycFormStore.productImages.addr_proof_doc
        );
      }
    },
    "kycFormStore.formData.addressProofSameAsNic": function () {
      if (this.kycFormStore.formData.addressProofSameAsNic) {
        this.kycFormStore.productImages.addr_proof_doc =
          this.kycFormStore.productImages.nic_back_image;
      } else {
        this.kycFormStore.productImages.addr_proof_doc = null;
        this.kycFormStore.imageURLs.addr_proof_doc = "";
      }
    },
  },
  methods: {
    // preferde time
    termsConditionScroll: function () {
      const comp = this;
      comp.isReadMoreContent = true;
      setTimeout(() => {
        comp.$refs.tcScroll.scrollTop = comp.$refs.tcScroll.scrollHeight;
      }, 500);
      // var objDiv = document.getElementById("tcScroll");
      // objDiv.scrollTop = 500;
    },

    checkAllTerms: function () {
      const comp = this;
      // eslint-disable-next-line no-empty
      if (
        !this.kycFormStore.formData.read_terms_condition1 &&
        !this.kycFormStore.formData.read_terms_condition2
      ) {
        comp.termsConditionScroll();
      }
    },

    getTimeSlots: function () {
      const comp = this;
      const data = {
        today: true,
      };
      if (
        comp.kycFormStore.formData.preferred_date_for_video_call.toDateString() !=
        new Date().toDateString()
      ) {
        data.today = false;
      }
      NetworkManager.apiRequest(
        "api/KycForm/getVideoCallDateAndTime",
        data,
        function (response) {
          if (response.statusCode == 200) {
            comp.time_slots = response.data.time_slots;
            comp.unselectableDatesArray = response.data.leave_dates;
            comp.unselectableDatesArray.forEach((element) => {
              comp.unselectableDates.push(new Date(element));
            });
          } else {
            BuefyHelper.showToastMessage(
              "Failed to get video call date and time",
              "is-danger"
            );
          }
        }
      );
    },
    validatePurpose: function () {
      const obj = {
        required: true,
        validatePurpose: true,
        min: 3,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    initialDepositValidation() {
      const obj = {
        numberRange: { min: 1 },
      };
      return obj;
    },
    mobileNumValidate: function () {
      if (this.kycFormStore.formData.countryCode === "+94") {
        const srilankanMobileRegEx = /^([7][0||1||2||4||5||6||7||8][0-9]{7})$/;

        this.isMobileNumberValid = srilankanMobileRegEx.test(
          this.kycFormStore.formData.mobileNumber
        );
      } else {
        this.isMobileNumberValid = /^\d+$/.test(
          this.kycFormStore.formData.mobileNumber
        );
      }
    },

    officeNumValidate: function () {
      if (this.officeCountryCodeEdit === "+94") {
        const srilankanMobileRegEx = /^([7][0||1||2||4||5||6||7||8][0-9]{7})$/;

        this.isOfficeNumberValid = srilankanMobileRegEx.test(
          this.officeNumberEdit
        );
      } else {
        this.isOfficeNumberValid = /^\d+$/.test(this.officeNumberEdit);
      }
    },

    changeSelection() {
      this.iconString = `fi fi-${this.kycFormStore.formData.country_short_name.toLowerCase()}`;
    },
    changeOfficeSelection() {
      this.officeIconString = `fi fi-${this.kycFormStore.formData.office_country_short_name.toLowerCase()}`;
    },

    calPhoneNumber() {
      if (this.kycFormStore.formData.countryCode === "+94") {
        console.log("LK Mobile number ---------------");
        const tempMobileNumber = this.kycFormStore.formData.mobileNumber.trim();
        if (tempMobileNumber[0] === "0") {
          this.isMobileNumberStartsWithZero = true;
        } else {
          this.isMobileNumberStartsWithZero = false;
        }
      } else {
        this.isMobileNumberStartsWithZero = false;
      }

      this.kycFormStore.formData.contact_no = `${this.kycFormStore.formData.countryCode}${this.kycFormStore.formData.mobileNumber}`;
      console.log(
        "Contact Number Formatted : " + this.kycFormStore.formData.contact_no
      );
    },
    // calOfficeContactNumber() {
    //   if (this.kycFormStore.formData.officeNumber) {
    //     this.kycFormStore.formData.telephone_no = `${this.kycFormStore.formData.office_country_code}${this.kycFormStore.formData.officeNumber}`;
    //   }
    // },

    updateFormData: function () {
      const comp = this;
      comp.continueBtnLoading = true;
      comp.generatePDF();
      comp.$router.push("verificationPage");
      comp.continueBtnLoading = false;
      // ApiRequestManager.updateLastStep(comp.kycFormStore.formData.landing_token,"7","/verificationPage",comp.kycFormStore.formData, function(status){
      //     if(status){
      //       comp.$router.push("verificationPage")
      //       comp.continueBtnLoading = false;
      //     }
      //     else{
      //       comp.continueBtnLoading = false;
      //     }
      // })
    },
    // image
    onNicCaptured: function (e) {
      // alert(e)
      this.kycFormStore.productImages.nic_front_image = e;
      console.log(
        "NIC front image : " + this.kycFormStore.productImages.nic_front_image
      );
      this.nicFrontEdit = false;
    },

    onNicBackCaptured: function (e) {
      this.kycFormStore.productImages.nic_back_image = e;
      console.log(
        "NIC back image : " + this.kycFormStore.productImages.nic_back_image
      );
      this.nicRearEdit = false;
    },

    onSelfieCaptured: function (e) {
      this.kycFormStore.productImages.selfie_image = e;
      console.log(
        "Selfie image : " + this.kycFormStore.productImages.selfie_image
      );
      this.selfieEdit = false;
    },

    onSignatureCaptured: function (e) {
      this.kycFormStore.productImages.sign_image = e;
      console.log(
        "Signature image : " + this.kycFormStore.productImages.sign_image
      );
      this.signatureEdit = false;
    },
    onAddressProofCaptured: function (e) {
      this.kycFormStore.productImages.addr_proof_doc = e;
      console.log(
        "Address proof image : " +
          this.kycFormStore.productImages.addr_proof_doc
      );
      this.addressEdit = false;
    },
    onStampPageCaptured: function (e) {
      this.kycFormStore.productImages.stamp_page_image = e;
      console.log(
        "Passport image : " + this.kycFormStore.productImages.stamp_page_image
      );
      this.stamppageEdit = false;
    },
    onPassportCaptured: function (e) {
      this.kycFormStore.productImages.passport_image = e;
      console.log(
        "Passport image : " + this.kycFormStore.productImages.passport_image
      );
      this.passportOneEdit = false;
    },

    onPassportSecoundCaptured: function (e) {
      this.kycFormStore.productImages.passport_image_2 = e;
      console.log(
        "Passport image : " + this.kycFormStore.productImages.passport_image_2
      );
      this.passportTwoEdit = false;
    },
    onVisaProofCaptured: function (e) {
      this.kycFormStore.productImages.visa_proof_doc = e;
      console.log(
        "Visa proof image : " + this.kycFormStore.productImages.visa_proof_doc
      );
      this.addressEdit = false;
    },
    // image
    resetNationalityValue() {
      this.kycFormStore.formData.permanent_address_district = "";
      this.kycFormStore.formData.permanent_address_country = "";
      this.kycFormStore.formData.correspondent_address_district = "";
      this.kycFormStore.formData.correspondent_address_country = "";
      this.kycFormStore.formData.employer_address_district = "";
      this.kycFormStore.formData.employer_address_country = "";
    },
    validateName: function () {
      const obj = {
        required: true,
        min: 3,
        alphaSpaces: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    validateNotRequiredName: function () {
      const obj = {
        alphaSpaces: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    passportValidateObject() {
      let obj = {
        required: true,
        notMatchRegex: [this.regex.old_nic, this.regex.new_nic],
        regex: this.regex.passport,
      };
      return obj;
    },
    visible() {
      this.isShow = true;
    },
    visibleHide() {
      this.isShow = false;
      this.kycFormStore.formData.second_politically_exposed_name = "";
      this.secondpoliticallyexposeddesignationPreview = "";
      this.secondpoliticallyexposednamePreview = "";

      this.kycFormStore.formData.second_politically_exposed_position = "";
    },
    visibleRelative() {
      this.isVisible = true;
    },
    relativeHide() {
      this.isVisible = false;
      this.kycFormStore.formData.second_politically_exposed_relative = "";
      this.kycFormStore.formData.second_politically_exposed_relative_position =
        "";
      this.secondpoliticallyexposedrelativepositionPreview = "";
      this.secondpoliticallyexposedrelativePreview = "";
    },

    scrollToBottom() {
      console.log("call scroll functionxsa");
      this.$refs.modalContent.scrollTop = this.$refs.modalContent.scrollHeight;
    },

    // smsYesClick() {
    //   if (!this.smsService) {
    //     this.smsToggleButton.classList.add("toggle-change");
    //     this.smsYesButton.classList.add("yes");
    //     this.smsYesButton.classList.add("yes-active");
    //     this.smsNoButton.classList.remove("no");
    //     this.smsNoButton.classList.remove("no-active");
    //     this.smsService = true;
    //   }
    // },

    // smsNoClick() {
    //   if (this.smsService) {
    //     this.smsToggleButton.classList.remove("toggle-change");
    //     this.smsYesButton.classList.remove("yes");
    //     this.smsYesButton.classList.remove("yes-active");
    //     this.smsNoButton.classList.add("no");
    //     this.smsNoButton.classList.add("no-active");
    //     this.smsService = false;
    //   }
    // },

    // eBankingYesClick() {
    //   if (!this.eBankingService) {
    //     this.eBankingToggleButton.classList.add("toggle-change");
    //     this.eBankingYesButton.classList.add("yes");
    //     this.eBankingYesButton.classList.add("yes-active");
    //     this.eBankingNoButton.classList.remove("no");
    //     this.eBankingNoButton.classList.remove("no-active");
    //     this.eBankingService = true;
    //   }
    // },

    // eBankingNoClick() {
    //   if (this.eBankingService) {
    //     this.eBankingToggleButton.classList.remove("toggle-change");
    //     this.eBankingYesButton.classList.remove("yes");
    //     this.eBankingYesButton.classList.remove("yes-active");
    //     this.eBankingNoButton.classList.add("no");
    //     this.eBankingNoButton.classList.add("no-active");
    //     this.eBankingService = false;
    //   }
    // },

    // virtualWalletYesClick() {
    //   if (!this.virtualWalletService) {
    //     this.virtualWalletToggleButton.classList.add("toggle-change");
    //     this.virtualWalletYesButton.classList.add("yes");
    //     this.virtualWalletYesButton.classList.add("yes-active");
    //     this.virtualWalletNoButton.classList.remove("no");
    //     this.virtualWalletNoButton.classList.remove("no-active");
    //     this.virtualWalletService = true;
    //   }
    // },

    // virtualWalletNoClick() {
    //   if (this.virtualWalletService) {
    //     this.virtualWalletToggleButton.classList.remove("toggle-change");
    //     this.virtualWalletYesButton.classList.remove("yes");
    //     this.virtualWalletYesButton.classList.remove("yes-active");
    //     this.virtualWalletNoButton.classList.add("no");
    //     this.virtualWalletNoButton.classList.add("no-active");
    //     this.virtualWalletService = false;
    //   }
    // },

    // estatementYesClick() {
    //   if (!this.eStatementService) {
    //     this.estatementToggleButton.classList.add("toggle-change");
    //     this.estatementYesButton.classList.add("yes");
    //     this.estatementYesButton.classList.add("yes-active");
    //     this.estatementNoButton.classList.remove("no");
    //     this.estatementNoButton.classList.remove("no-active");
    //     this.eStatementService = true;
    //   }
    // },

    // estatementNoClick() {
    //   if (this.eStatementService) {
    //     this.estatementToggleButton.classList.remove("toggle-change");
    //     this.estatementYesButton.classList.remove("yes");
    //     this.estatementYesButton.classList.remove("yes-active");
    //     this.estatementNoButton.classList.add("no");
    //     this.estatementNoButton.classList.add("no-active");
    //     this.eStatementService = false;
    //   }
    // },
    debitCardYesClick() {
      if (!this.debitCardService) {
        this.debitCardToggleButton.classList.add("toggle-change");
        this.debitCardYesButton.classList.add("yes");
        this.debitCardYesButton.classList.add("yes-active");
        this.debitCardNoButton.classList.remove("no");
        this.debitCardNoButton.classList.remove("no-active");
        this.debitCardService = true;
      }
    },

    debitCardNoClick() {
      if (this.debitCardService) {
        this.debitCardToggleButton.classList.remove("toggle-change");
        this.debitCardYesButton.classList.remove("yes");
        this.debitCardYesButton.classList.remove("yes-active");
        this.debitCardNoButton.classList.add("no");
        this.debitCardNoButton.classList.add("no-active");
        this.debitCardService = false;
      }
    },
    resetPoliticalValue() {
      this.isVisible = false;
      this.isShow = false;
      this.kycFormStore.formData.politically_exposed_name = "";
      this.kycFormStore.formData.politically_exposed_position = "";
      this.kycFormStore.formData.second_politically_exposed_name = "";
      this.kycFormStore.formData.second_politically_exposed_position = "";
      this.kycFormStore.formData.politically_exposed_relative = "";
      this.kycFormStore.formData.politically_exposed_relative_position = "";
      this.kycFormStore.formData.second_politically_exposed_relative = "";
      this.kycFormStore.formData.second_politically_exposed_relative_position =
        "";
    },

    officeNumberSaveEdit() {
      if (this.officeNumberEdit) {
        this.kycFormStore.formData.telephone_no = this.telephoneNoEdit;
      } else {
        this.kycFormStore.formData.telephone_no = "";
        this.telephoneNoEdit = "";
      }

      // this.kycFormStore.formData.officeNumber = this.officeNumberEdit;
      this.kycFormStore.formData.office_country_short_name =
        this.officeShortNameEdit;
      this.kycFormStore.formData.office_country_code =
        this.officeCountryCodeEdit;
    },

    /**
     * This function can be used to fetch the relevant product list to customer
     */
    getProductList() {
      const comp = this;

      const requestBody = {
        is_sl_resident: !GlobalFunctions.CheckCustomerIsOutOfSriLanka(
          comp.kycFormStore
        ),
        age: comp.kycFormStore.formData.age,
        gender: comp.kycFormStore.formData.gender.toLowerCase(),
      };

      NetworkManager.apiRequest(
        "api/KycForm/getProductList",
        requestBody,
        function (response) {
          console.log(response);
          if (response.statusCode === 200) {
            comp.kycFormStore.formDataSupport.productList =
              response.data.products;
            comp.productListLoading = false;
          } else {
            console.log(response.data.message);
            BuefyHelper.showToastMessage(
              "Unable to get product list",
              "is-danger"
            );
          }
        }
      );
    },

    // async generatePDF() {
    //   const comp = this;
    //   console.log("call generate  pdf");

    //   const pdfElement = document.getElementById("review_pdf");

    //   html2canvas(pdfElement).then(function (canvas) {
    //     const img = canvas.toDataURL("image/jpeg", 0.8);

    //     var imgWidth = 210;
    //     var pageHeight = 420;
    //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
    //     var heightLeft = imgHeight;

    //     var doc = new jsPDF("p", "mm", "a3");

    //     var position = 0;

    //     doc.addImage(img, "PNG", 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;

    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       doc.addPage();
    //       doc.addImage(img, "PNG", 0, position, imgWidth, imgHeight);
    //       heightLeft -= pageHeight;
    //     }

    //     const filename = comp.kycFormStore.formData.customer_nic;
    //     // Save the PDF
    //     //doc.save(filename + "_detail.pdf");

    //     const pdfBlob = doc.output("blob");
    //     console.log(pdfBlob + "blob created");
    //     // Create a File object from the Blob
    //     const generated_file = new File([pdfBlob], filename + "_detail.pdf", {
    //       type: "application/pdf",
    //     });

    //     comp.kycFormStore.detailPDF.viewDetailPdf = generated_file;
    //     const customerEMail = comp.kycFormStore.formData.email;

    //     console.log(customerEMail);

    //     console.log("generate pdf end");
    //   });
    // },

    generatePDF() {
      // Get the HTML content of the component
      console.log("Generating PDF");
      this.$refs.html2Pdf.generatePdf();
    },
    onProgress(progress) {
      this.progress = progress;
      console.log(`PDF generation progress: ${progress}%`);
    },
    startPagination() {
      console.log(`PDF has started pagination`);
    },
    hasPaginated() {
      console.log(`PDF has been paginated`);
    },
    async beforeDownload() {
      console.log(`On Before PDF Generation`);
      //  await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
      //          	const totalPages = pdf.internal.getNumberOfPages()
      //          	for (let i = 1; i <= totalPages; i++) {
      //          		pdf.setPage(i)
      //          		pdf.setFontSize(10)
      //          		pdf.setTextColor(150)
      //          		pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
      //          	}
      //          }).save()
    },
    hasDownloaded(blobPdf) {
      console.log(`PDF has downloaded`);
      this.pdfDownloaded = true;
      console.log(blobPdf);
      //this.goback();
      //window.close();
    },
  },

  mounted() {
    console.log("====================");
    console.log(this.$refs);
    console.log("====================");
    if (this.kycFormStore.formDataSupport.productList.length === 0) {
      // product list is not available, so load product list
      this.getProductList();
    }

    // setTimeout(() => {
    //   this.generatePDF();
    // }, 3000);

    this.getTimeSlots();

    // politically_exposed
    if (
      this.kycFormStore.formData.second_politically_exposed_name ||
      this.kycFormStore.formData.second_politically_exposed_position
    ) {
      this.isShow = true;
    }
    // politically_Relative
    if (
      this.kycFormStore.formData.second_politically_exposed_relative ||
      this.kycFormStore.formData.second_politically_exposed_relative_position
    ) {
      this.isVisible = true;
    }
    for (const country of this.kycFormStore.formDataSupport.countriesArray) {
      this.countryCodeArray.push(`${country.code} ${country.dial_code}`);
    }
    // mobile

    const tempCountryCodeName =
      this.kycFormStore.formDataSupport.countriesArray.find(
        (country) =>
          country.code === this.kycFormStore.formData.country_short_name
      );
    this.userCode = this.countryCodeArray.find(
      (hello) =>
        hello ===
        `${tempCountryCodeName.code} ${this.kycFormStore.formData.countryCode}`
    );

    this.changeSelection();
    this.mobileNumValidate();
    // mobile
    // telephoneNumber
    // if (this.tempOfficeCode) {
    const tempOfficeCountryCodeName = this.kycFormStore.formData
      .office_country_short_name
      ? this.kycFormStore.formDataSupport.countriesArray.find(
          (countryCode) =>
            countryCode.code ===
            this.kycFormStore.formData.office_country_short_name
        )
      : "";

    let tempOfficeCode = tempOfficeCountryCodeName
      ? tempOfficeCountryCodeName.code +
        " " +
        this.kycFormStore.formData.office_country_code
      : "";

    this.userOfficeCode = tempOfficeCode
      ? this.countryCodeArray.find((hello) => hello === tempOfficeCode)
      : "";
    this.changeOfficeSelection();
    // }

    // telephoneNumber
    // sms
    this.smsToggleButton = document.getElementById("sms-toggle-buttons");
    this.smsYesButton = document.getElementById("sms-yes-button");
    this.smsNoButton = document.getElementById("sms-no-button");
    if (this.kycFormStore.formData.sms_service) {
      this.smsNoButton.classList.remove("no");
      this.smsNoButton.classList.remove("no-active");
    } else {
      this.smsYesButton.classList.remove("yes");
      this.smsYesButton.classList.remove("yes-active");
      this.smsToggleButton.classList.remove("toggle-change");
    }
    // e-banking
    this.eBankingToggleButton = document.getElementById(
      "e-banking-toggle-buttons"
    );
    this.eBankingYesButton = document.getElementById("e-banking-yes-button");
    this.eBankingNoButton = document.getElementById("e-banking-no-button");
    if (this.kycFormStore.formData.e_banking_service) {
      this.eBankingNoButton.classList.remove("no");
      this.eBankingNoButton.classList.remove("no-active");
    } else {
      this.eBankingYesButton.classList.remove("yes");
      this.eBankingYesButton.classList.remove("yes-active");
      this.eBankingToggleButton.classList.remove("toggle-change");
    }
    // virtual-wallet
    this.virtualWalletToggleButton = document.getElementById(
      "virtual-wallet-toggle-buttons"
    );
    this.virtualWalletYesButton = document.getElementById(
      "virtual-wallet-yes-button"
    );
    this.virtualWalletNoButton = document.getElementById(
      "virtual-wallet-no-button"
    );
    if (this.kycFormStore.formData.virtual_wallet_service) {
      this.virtualWalletNoButton.classList.remove("no");
      this.virtualWalletNoButton.classList.remove("no-active");
    } else {
      this.virtualWalletYesButton.classList.remove("yes");
      this.virtualWalletYesButton.classList.remove("yes-active");
      this.virtualWalletToggleButton.classList.remove("toggle-change");
    }
    // estatement
    this.estatementToggleButton = document.getElementById(
      "estatement-toggle-buttons"
    );
    this.estatementYesButton = document.getElementById("estatement-yes-button");
    this.estatementNoButton = document.getElementById("estatement-no-button");
    if (this.kycFormStore.formData.estatement_service) {
      this.estatementNoButton.classList.remove("no");
      this.estatementNoButton.classList.remove("no-active");
    } else {
      this.estatementYesButton.classList.remove("yes");
      this.estatementYesButton.classList.remove("yes-active");
      this.estatementToggleButton.classList.remove("toggle-change");
    }

    // debit_card_service
    if (GlobalFunctions.CheckDebitCardSectionVisible(this.kycFormStore)) {
      this.debitCardToggleButton = document.getElementById(
        "debit_card-toggle-buttons"
      );
      this.debitCardYesButton = document.getElementById(
        "debit_card-yes-button"
      );
      this.debitCardNoButton = document.getElementById("debit_card-no-button");
      if (this.kycFormStore.formData.debit_card_service) {
        this.debitCardNoButton.classList.remove("no");
        this.debitCardNoButton.classList.remove("no-active");
      } else {
        this.debitCardYesButton.classList.remove("yes");
        this.debitCardYesButton.classList.remove("yes-active");
        this.debitCardToggleButton.classList.remove("toggle-change");
      }
    }

    this.fullnamePreview = this.kycFormStore.formData.full_name;
    this.customernicPreview = this.kycFormStore.formData.customer_nic;
    this.titlePreview = this.kycFormStore.formData.title;
    this.citizenshipPreview_1 = this.kycFormStore.formData.citizenship_1;
    this.customerpassportnoPreview =
      this.kycFormStore.formData.customer_passport_no;
    this.permanentaddressline1Preview =
      this.kycFormStore.formData.permanent_address_line1;
    this.permanentaddressline2Preview =
      this.kycFormStore.formData.permanent_address_line2;
    this.permanentaddressline3Preview =
      this.kycFormStore.formData.permanent_address_line3;
    this.permanentaddresstownPreview =
      this.kycFormStore.formData.permanent_address_town;
    this.permanentaddresspostalcodePreview =
      this.kycFormStore.formData.permanent_address_postal_code;
    this.permanentaddressdistrictPreview =
      this.kycFormStore.formData.permanent_address_district;
    this.permanentaddresscountryPreview =
      this.kycFormStore.formData.permanent_address_country;
    this.correspondentaddressline1Preview =
      this.kycFormStore.formData.correspondent_address_line1;
    this.correspondentaddressline2Preview =
      this.kycFormStore.formData.correspondent_address_line2;
    this.correspondentaddressline3Preview =
      this.kycFormStore.formData.correspondent_address_line3;
    this.correspondentaddresstownPreview =
      this.kycFormStore.formData.correspondent_address_town;
    this.correspondentaddresspostalcodePreview =
      this.kycFormStore.formData.correspondent_address_postal_code;
    this.correspondentaddressdistrictPreview =
      this.kycFormStore.formData.correspondent_address_district;
    this.correspondentaddresscountryPreview =
      this.kycFormStore.formData.correspondent_address_country;
    this.emailPreview = this.kycFormStore.formData.email;
    this.maidennamePreview = this.kycFormStore.formData.maiden_name;
    this.employmentstatusPreview = this.kycFormStore.formData.employment_status;
    this.natureofbusinessPreview =
      this.kycFormStore.formData.nature_of_business;
    this.employernamePreview = this.kycFormStore.formData.employer_name;
    this.employeraddressline1Preview =
      this.kycFormStore.formData.employer_address_line1;
    this.employeraddressline2Preview =
      this.kycFormStore.formData.employer_address_line2;
    this.employeraddressline3Preview =
      this.kycFormStore.formData.employer_address_line3;
    this.employeraddresstownPreview =
      this.kycFormStore.formData.employer_address_town;
    this.employeraddresspostalcodePreview =
      this.kycFormStore.formData.employer_address_postal_code;
    this.employeraddressdistrictPreview =
      this.kycFormStore.formData.employer_address_district;
    this.employeraddresscountryPreview =
      this.kycFormStore.formData.employer_address_country;
    this.taxfilenumPreview = this.kycFormStore.formData.tax_file_num;
    this.politicallyexposednamePreview =
      this.kycFormStore.formData.politically_exposed_name;
    this.politicallyexposedpositionPreview =
      this.kycFormStore.formData.politically_exposed_position;
    this.branchPreview = this.kycFormStore.formData.branch;
    this.reasonforopeninganaccountPreview =
      this.kycFormStore.formData.reason_for_opening_an_account;
    this.specifiedpurposeofopeningPreview =
      this.kycFormStore.formData.specified_purpose_of_opening;
    this.initialdepositPreview =
      this.kycFormStore.formData.initial_deposit.toLocaleString();
    this.accounttobelinkedforPosPreview =
      this.kycFormStore.formData.account_to_be_linked_for_pos_transaction;
    this.debitcardcustomernamePreview =
      this.kycFormStore.formData.facility_debit_card_customer_name;
    this.genderPreview = this.kycFormStore.formData.gender;
    this.maritalStatusPreview = this.kycFormStore.formData.marital_status;
    this.statusofresidentsPreview =
      this.kycFormStore.formData.status_of_residence;
    this.areyoutaxpayerPreview = this.kycFormStore.formData.are_you_a_taxpayer;
    this.politicallyexposedPreview =
      this.kycFormStore.formData.politically_exposed;
    this.purposeofopeningPreview =
      this.kycFormStore.formData.purpose_of_opening;
    this.anticipatedvolPreview = this.kycFormStore.formData.anticipated_vol;
    this.secondanticipatedvolPreview =
      this.kycFormStore.formData.second_anticipated_vol;
    this.posrequiredPreview = this.kycFormStore.formData.pos_required;
    this.sourceofincomePreview = this.kycFormStore.formData.source_of_income;
    this.savingaccounttypePreview =
      this.kycFormStore.formData.saving_account_type;
    this.secondsavingaccounttypePreview =
      this.kycFormStore.formData.second_saving_account_type;
    this.secondpurposeofopeningPreview =
      this.kycFormStore.formData.second_purpose_of_opening;
    this.currencyPreview = this.kycFormStore.formData.currency_type;
    this.currencyPreviewSecond =
      this.kycFormStore.formData.second_currency_type;
    this.currencyPreviewThird = this.kycFormStore.formData.third_currency_type;
    this.pincollectiondetailsPreview =
      this.kycFormStore.formData.pin_collection_details;
    this.collectiondetailsPreview =
      this.kycFormStore.formData.collection_details;
    this.smsService = this.kycFormStore.formData.sms_service;
    this.eBankingService = this.kycFormStore.formData.e_banking_service;
    this.virtualWalletService =
      this.kycFormStore.formData.virtual_wallet_service;
    this.eStatementService = this.kycFormStore.formData.estatement_service;
    this.debitCardService = this.kycFormStore.formData.debit_card_service;
    this.initialdepositPreviewSecond =
      this.kycFormStore.formData.second_initial_deposit.toLocaleString();
    this.purposeofopeningPreviewThird =
      this.kycFormStore.formData.third_purpose_of_opening;
    this.thirdspecifiedpurposeofopeningPreview =
      this.kycFormStore.formData.third_specified_purpose_of_opening;
    this.anticipatedvolPreviewThird =
      this.kycFormStore.formData.third_anticipated_vol;
    this.thirdinitialdepositPreview =
      this.kycFormStore.formData.third_initial_deposit.toLocaleString();
    this.secondspecifiedpurposeofopeningPreview =
      this.kycFormStore.formData.second_specified_purpose_of_opening;

    this.secondpoliticallyexposednamePreview =
      this.kycFormStore.formData.second_politically_exposed_name;
    this.specifiedsourceofincomePreview =
      this.kycFormStore.formData.specified_source_of_income;
    this.politicallyexposedrelativePreview =
      this.kycFormStore.formData.politically_exposed_relative;
    this.politicallyexposedrelativepositionPreview =
      this.kycFormStore.formData.politically_exposed_relative_position;
    this.secondpoliticallyexposedrelativePreview =
      this.kycFormStore.formData.second_politically_exposed_relative;
    this.secondpoliticallyexposedrelativepositionPreview =
      this.kycFormStore.formData.second_politically_exposed_relative_position;

    this.secondpoliticallyexposeddesignationPreview =
      this.kycFormStore.formData.second_politically_exposed_position;

    this.tempInitialDeposit1 = Number(
      this.initialdepositPreview.trim().replaceAll(`,`, ``)
    );
    this.tempInitialDeposit2 = Number(
      this.initialdepositPreviewSecond.trim().replaceAll(`,`, ``)
    );
    this.tempInitialDeposit3 = Number(
      this.thirdinitialdepositPreview.trim().replaceAll(`,`, ``)
    );
    this.politicallyexposedPreview =
      this.kycFormStore.formData.politically_exposed;

    setTimeout(() => {
      this.generatePDF();
    }, 3000);
  },
  computed: {
    filteredDataArr() {
      return this.countryCodeArray.filter((option) => {
        return option.toLowerCase().indexOf(this.userCode.toLowerCase()) >= 0;
      });
    },
    officeFilteredDataArr() {
      return this.countryCodeArray.filter((option) => {
        return (
          option.toLowerCase().indexOf(this.userOfficeCode.toLowerCase()) >= 0
        );
      });
    },

    GlobalFunctions() {
      return GlobalFunctions;
    },
  },
};
</script>
<style scoped>
/* ul li::before {
  content: "\2022";
  color: Red;
  font-weight: bold;
  display: inline-block;
} */

.verticalLine {
  border-right: 0.92px solid #1b1b1b;
}

.round-image-box {
  border-radius: 50%;
  border: 2px solid #04c3fc;
  width: 210px;
  height: 199px;
}

.preview_image {
  border-radius: 50%;
  border: 0.1px solid #231f20 !important;
  padding: 1px;
  background-color: #f9f9f9;
}

.toggle-button {
  display: flex;
  border: 1px solid black;
  max-width: 200px;
  justify-content: flex-end;
  border-radius: 500px;
}

.toggle-change {
  justify-content: flex-start;
}

.toggle-button:hover {
  cursor: pointer;
}

.no-active,
.yes-active {
  background-color: #04c3fc;
  color: white;
}

.no,
.yes {
  border-radius: 500px;
}

.toggle-common {
  padding: 0 22px;
}

.modal-content-container {
  max-height: 600px; /* Set an appropriate fixed height for the modal content */
  overflow-y: auto; /* Enable scrolling for the modal content */
}

.modal-card-foot {
  position: sticky;
  bottom: 0;
}
</style>
