<template>
  <div v-if="!isLoading">
    <div>
      <ValidationObserver>
        <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
    <logopage /></transition>
    <transition appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
    >
      <img
        src="../assets/images/icons/Arrow.png"
        alt="Arrow"
        width="40px"
        @click="$router.go(-1)"
        style="cursor: pointer"
      />
    </transition>

      <div class="columns is-centered mb-0">
        <div
          v-if="$root.selectedLanguage === `en`"
          class="column"
          style="text-align: center"
        ></div>
      </div>

      <div class="form-body mt-3">
        <transition appear
                @before-enter="$animations.beforeEnterBelow"
                @enter="$animations.enterBelow"
    >
        <div class="box form-padding pb-5" style="padding-top: 0% !important">            
              <p class="has-text-centered  pt-4 mb-4 astric-font-size">
                <span class="has-text-danger  mr-2">{{
                  $root.selectedLanguageData.note
                }}</span><b class="has-text-danger">*</b><span class="text-transparency">
               Indicates mandatory fields that are required</span>
              </p>
          <div class="mb-5 columns">
            <div
              class="column is-narrow has-text-centered"
              style="padding-block: 0px !important"
            >
              <img v-if="kycFormStore.kyc_form_data.application.selfie_attachment.attachment_type !== 'pdf'"
                :src="kycFormStore.kyc_form_data.application.selfie_attachment.attachment_url"
                class="round-image-box"
              />
              <img v-else
                :src="pdfPlaceholder"
                class="round-image-box"
              />
            </div>
            <div
              class="preview-img-space column"
              style="padding-block: 0px !important"
            >
              <p
                :style="{
                  'font-size': '1.1rem',
                  'font-family': 'Gotham-Bold',
                  'letter-spacing': '0.2px',
                }"
              >
                {{kycFormStore.kyc_form_data.application.full_name.customer_input}}
              </p>

              <hr
                :style="{
                  'background-color': '#231F20',
                  height: '0.3px',
                  'margin-top': '0',
                  'padding-top': '0',
                  'margin-bottom': '0',
                  'padding-bottom': '0',
                  opacity: '0.5',
                }"
              />
              <p style="font-size: 0.9rem" class="mt-2">
                <img
                  src="../assets/images/icons/calll.webp"
                  alt="call.img"
                  width="4%"
                  class="mr-1"
                /><span class="pr-4 verticalLine">{{
                  kycFormStore.kyc_form_data.application.mobile_number
                    .customer_input
                }}</span>
                <img
                  src="../assets/images/icons/emaill.webp"
                  alt="email.img"
                  width="4%"
                  class="ml-3 mr-1"
                />{{kycFormStore.kyc_form_data.application.email.customer_input}}
              </p>
            </div>
          </div>
          <!-- Full Name -->
          <div class="columns is-3 is-variable mt-1">
            <div class="column is-one-third">
              <InputLabel
                required="true"
                label="Title"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.title.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                required="true"
                label="Title"
                :value="kycFormStore.kyc_form_data.application.title.call_agent_input"
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.title.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
            <div class="column">
              <InputLabel
                required="true"
                label="Full Name (As per the NIC / EIC / PP)"
                readonly="true"

                rounded
                :value="kycFormStore.kyc_form_data.application.full_name.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                required="true"
                label="Full Name (As per the NIC / EIC / PP)"
                rounded
                :value="kycFormStore.kyc_form_data.application.full_name.call_agent_input"
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.full_name.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
          </div>
          <!-- full name end -->

          <div class="columns is-4 is-variable mt-1">
            <!-- citizenship -->
            <div class="column">
              <div>
                <InputLabel
                  label="Citizenship"
                  required="true"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.citizenship.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Citizenship"
                  required="true"
                  :value="kycFormStore.kyc_form_data.application.citizenship.call_agent_input"
                  styles="mt-5"
                  :hide="!kycFormStore.kyc_form_data.application.citizenship.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <!-- Residentship -->
            <div  class="column">
                <InputLabel
                  label="Residentship"
                  required="true"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.residentship.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Residentship"
                  required="true"
                  :value="kycFormStore.kyc_form_data.application.residentship.call_agent_input"
                  styles="mt-5"
                  :hide="!kycFormStore.kyc_form_data.application.residentship.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
          </div>

          <div class="columns is-4 is-variable mt-1">
            <!-- nationality -->
            <div class="column is-two-thirds">
              <InputLabel
                label="Nationality"
                required="true"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.nationality.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Nationality"
                required="true"
                :value="kycFormStore.kyc_form_data.application.nationality.call_agent_input"
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.nationality.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
            <div class="column ">
              <!-- date of birth -->
              <InputLabel
                label="Date of Birth"
                required="true"
                readonly="true"
                :value="
                  getFormattedDateTime(kycFormStore.kyc_form_data.application.date_of_birth.customer_input)
                "
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Date of Birth"
                required="true"
                :value="
                  getFormattedDateTime(kycFormStore.kyc_form_data.application.date_of_birth.call_agent_input)
                "
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.date_of_birth.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
          </div>

          <!-- NIC -->
          <div class="columns is-4 is-variable mt-1">
            <div class="column is-two-thirds">
              <div>
                <InputLabel
                  required="true"
                  label="NIC / EIC Number"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.nic_eic_number.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  required="true"
                  label="NIC / EIC Number"
                  :value="kycFormStore.kyc_form_data.application.nic_eic_number.call_agent_input"
                  styles="mt-5"
                  :hide="!kycFormStore.kyc_form_data.application.nic_eic_number.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>

            <div class="column">
              <InputLabel
              required="true"
                label="NIC Issue Date"
                readonly="true"
                :value="getFormattedDateTime(kycFormStore.kyc_form_data.application.nic_eic_issue_date.customer_input)"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                required="true"
                label="NIC Issue Date"
                :value="getFormattedDateTime(kycFormStore.kyc_form_data.application.nic_eic_issue_date.call_agent_input)"
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.nic_eic_issue_date.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
          </div>
          <!-- Nic End -->

          <!-- passport_number -->
          <div
            class="columns is-3 is-variable mt-1"
            v-if="
              kycFormStore.kyc_form_data.application.passport_number
                .customer_input != ''
            "
          >
            <div class="column is-two-fifths">
              <InputLabel
                required="true"
                label="Passport Number"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.passport_number.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                required="true"
                label="Passport Number"
                :value="kycFormStore.kyc_form_data.application.passport_number.call_agent_input"
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.passport_number.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>

            <div class="column">
              <InputLabel
                required="true"
                label="Passport Issue Date "
                reaDonly="true"
                :value="
                  getFormattedDateTime(
                    kycFormStore.kyc_form_data.application.passport_issue_date.customer_input
                  )
                "
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                required="true"
                label="Passport Issue Date"
                :value="
                  getFormattedDateTime(
                    kycFormStore.kyc_form_data.application.passport_issue_date.call_agent_input
                  )
                "
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.passport_issue_date.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>

            <div class="column">
              <InputLabel
                required="true"
                label="Passport Expire Date"
                readonly="true"
                :value="getFormattedDateTime(kycFormStore.kyc_form_data.application.passport_expire_date.customer_input)"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                required="true"
                label="Passport Expire Date"
                :value=" getFormattedDateTime(kycFormStore.kyc_form_data.application.passport_expire_date.call_agent_input)"
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.passport_expire_date.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
          </div>
          <!-- passport end -->

          <div class="columns is-7 is-variable">
            <div class="column">
              <!-- gender -->
              <div>
                <InputLabel
                  required="true"
                  label="Gender"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.gender.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  required="true"
                  label="Gender"
                  :value="kycFormStore.kyc_form_data.application.gender.call_agent_input"
                  styles="mt-5"
                  :hide="!kycFormStore.kyc_form_data.application.gender.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <div class="column">
              <!-- marital_status -->
              <div>
                <InputLabel
                  label="Marital Status"
                  required="true"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.maritial_status.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Marital Status"
                  required="true"
                  :value="kycFormStore.kyc_form_data.application.maritial_status.call_agent_input"
                  styles="mt-5"
                  :hide="!kycFormStore.kyc_form_data.application.maritial_status.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
          </div>

          <hr style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5" />
          <!-- permanent_address -->
          <div>
            <label>{{ $root.selectedLanguageData.permanent_address }}</label>
            <div class="columns is-4 is-variable">
              <div class="column is-two-fifths">
                <InputLabel placeholder=" -- No data -- "
                label="Building Number"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.building_number.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Building Number"
                :value="kycFormStore.kyc_form_data.application.building_number.call_agent_input"
                :hide="!kycFormStore.kyc_form_data.application.building_number.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
              </div>
              <div class="column">
                <InputLabel placeholder=" -- No data -- "
                label="Building Name"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.building_name.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Building Name"
                :value="kycFormStore.kyc_form_data.application.building_name.call_agent_input"
                :hide="!kycFormStore.kyc_form_data.application.building_name.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
              </div>
            </div>
            <!-- second line -->
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-half">
                <InputLabel placeholder=" -- No data -- "
                  label="Street"
                  readonly="true"
                  required="true"
                  :value="kycFormStore.kyc_form_data.application.street.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Street"
                  required="true"
                  :value="kycFormStore.kyc_form_data.application.street.call_agent_input"
                  :hide="!kycFormStore.kyc_form_data.application.street.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column is-half">
                <InputLabel placeholder=" -- No data -- "
                  label="Street 2"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.street_2.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Street 2"
                  :value="kycFormStore.kyc_form_data.application.street_2.call_agent_input"
                  :hide="!kycFormStore.kyc_form_data.application.street_2.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-half">
                <InputLabel placeholder=" -- No data -- "
                label="Town"
                required="true"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.town.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Town"
                :value="kycFormStore.kyc_form_data.application.town.call_agent_input"
                :hide="!kycFormStore.kyc_form_data.application.town.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
              </div>
              <div class="column is-half">
                <!-- third_line -->
                <div>
                  <InputLabel placeholder=" -- No data -- "
                    label="Postal Code"
                    readonly="true"
                    :value="kycFormStore.kyc_form_data.application.postal_code.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputFieldVue
                    label="Postal Code"
                    :value="kycFormStore.kyc_form_data.application.postal_code.call_agent_input"
                    :hide="!kycFormStore.kyc_form_data.application.postal_code.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
            </div>
            
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-half">
                <InputLabel placeholder=" -- No data -- "
                label="District"
                required="true"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.district.customer_input"
                class="non-interactable-input"
                />
                <AgentInputFieldVue
                label="District"
                required="true"
                :value="kycFormStore.kyc_form_data.application.district.call_agent_input"
                :hide="!kycFormStore.kyc_form_data.application.district.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
                />
              </div>
              <!-- country -->
              <div class="column is-half">
                <InputLabel placeholder=" -- No data -- "
                label="Country"
                readonly="true"
                required="true"
                :value="kycFormStore.kyc_form_data.application.country.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Country"
                :value="kycFormStore.kyc_form_data.application.country.call_agent_input"
                :hide="!kycFormStore.kyc_form_data.application.country.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
              </div>
            </div>
          </div>
          <div
            class="mt-5 mb-5 flex-start align"
            style="justify-content: flex-start !important"
            v-if="kycFormStore.kyc_form_data.application.is_correspondence_address_differs_with_the_permanent_address">
            <b-field>
              <b-checkbox :value="true" disabled></b-checkbox>
              <div>
                <p class="en-style">
                  {{
                    $root.selectedLanguageData.correspondence_address_differs
                  }}
                </p>
              </div>
            </b-field>
          </div>

          <!-- correspondence_address -->
          <div class="mb-5 mt-5">
            <label>{{
              $root.selectedLanguageData.correspondence_address
            }}</label>
            <div class="columns is-4 is-variable">
              <div class="column is-two-fifths">
                <div>
                  <InputLabel placeholder=" -- No data -- "
                  label="Building Number"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_building_number.customer_input"
                  class="non-interactable-input"
                />
                  <AgentInputFieldVue
                  label="Building Number"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_building_number.call_agent_input"
                  :hide="!kycFormStore.kyc_form_data.application.correspondence_address_building_number.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
                </div>
              </div>
              <div class="column">
                <InputLabel placeholder=" -- No data -- "
                  label="Building Name"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_building_name.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Building Name"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_building_name.call_agent_input"
                  :hide="!kycFormStore.kyc_form_data.application.correspondence_address_building_name.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <!-- second line -->
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-half">
                <InputLabel placeholder=" -- No data -- "
                  required="true"
                  label="Street"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_street.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Street"
                  required="true"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_street.call_agent_input"
                  :hide="!kycFormStore.kyc_form_data.application.correspondence_address_street.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column is-half">
                <InputLabel placeholder=" -- No data -- "
                  label="Street 2"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_street_2.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Street 2"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_street_2.call_agent_input"
                  :hide="!kycFormStore.kyc_form_data.application.correspondence_address_street_2.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
            <!-- third_line -->
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-half">
                <InputLabel placeholder=" -- No data -- "
                  label="Town"
                  required = true
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_town.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Town"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_town.call_agent_input"
                  :hide="!kycFormStore.kyc_form_data.application.correspondence_address_town.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
              <div class="column is-half">
                <div>
                  <InputLabel placeholder=" -- No data -- "
                    label="Postal Code"
                    readonly="true"
                    :value="kycFormStore.kyc_form_data.application.correspondence_address_postal_code.customer_input "
                    class="non-interactable-input"
                  />
                  <AgentInputFieldVue
                    label="Postal Code"
                    :value="kycFormStore.kyc_form_data.application.correspondence_address_postal_code.call_agent_input"
                    :hide="!kycFormStore.kyc_form_data.application.correspondence_address_postal_code.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
            </div>
            <div class="columns is-7 is-variable mt-1">
              <div class="column is-half">
                <InputLabel placeholder=" -- No data -- "
                label="District"
                readonly="true"
                required= "true"
                :value="kycFormStore.kyc_form_data.application.correspondence_address_district.customer_input"
                class="non-interactable-input"
                />
                <AgentInputFieldVue
                label="District"
                :value="kycFormStore.kyc_form_data.application.correspondence_address_district.call_agent_input"
                :hide="!kycFormStore.kyc_form_data.application.correspondence_address_district.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
                />
              </div>
              <!-- country -->
              <div class="column is-half">
                <InputLabel placeholder=" -- No data -- "
                  label="Country"
                  required="true"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_country.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Country"
                  :value="kycFormStore.kyc_form_data.application.correspondence_address_country.call_agent_input "
                  :hide="!kycFormStore.kyc_form_data.application.correspondence_address_country.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
          </div>

          <!-- status_of_residence -->
          <div class="mt-4">
            <InputLabel placeholder=" -- No data -- "
              label="Status of Permanant Residence"
              required="true"
              readonly="true"
              :value="GlobalFunctions.getStatusOfResidenceDisplayValue(kycFormStore.kyc_form_data.application.state_of_residence.customer_input)"
              class="non-interactable-input"
            />
            <AgentInputFieldVue
              label="Status of Permanant Residence"
              required="true"
              :value="GlobalFunctions.getStatusOfResidenceDisplayValue(kycFormStore.kyc_form_data.application.state_of_residence.call_agent_input)"
              styles="mt-5"
              :hide="!kycFormStore.kyc_form_data.application.state_of_residence.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
          </div>

          <!-- mobile Number -->
          <div class="columns mt-4">
            <div class="column is-half">
              <InputLabel placeholder=" -- No data -- "
                label="Primary Mobile Number"
                required="true"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.mobile_number.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Primary Mobile Number"
                required="true"
                :value="kycFormStore.kyc_form_data.application.mobile_number.call_agent_input"
                :hide="!kycFormStore.kyc_form_data.application.mobile_number.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
            <div class="column" v-if="kycFormStore.kyc_form_data.application.telephone_number.customer_input || kycFormStore.kyc_form_data.application.telephone_number.call_agent_input">
                <InputLabel placeholder=" -- No data -- "
                  label="Mobile/Telephone Number"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.telephone_number.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Mobile/Telephone Number"
                  :value="kycFormStore.kyc_form_data.application.telephone_number.call_agent_input"
                  :hide="!kycFormStore.kyc_form_data.application.telephone_number.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
          </div>

          <!-- email_address -->
          <div class="mt-4">
            <div>

            </div>
            <InputLabel placeholder=" -- No data -- "
              label="Primary Email ID"
              required="true"
              readonly="true"
              :value="kycFormStore.kyc_form_data.application.email.customer_input"
              class="non-interactable-input"
            />
            <AgentInputFieldVue
              label="Primary Email ID"
              required="true"
              :value="kycFormStore.kyc_form_data.application.email.call_agent_input"
              :hide="!kycFormStore.kyc_form_data.application.email.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            <div v-if="kycFormStore.kyc_form_data.application.secondary_email.customer_input || kycFormStore.kyc_form_data.application.secondary_email.call_agent_input">
              <!-- additional email address -->
              <InputLabel
                placeholder=" -- No data -- "
                label="Additional Email"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.secondary_email.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Additional Email"
                :value="kycFormStore.kyc_form_data.application.secondary_email.call_agent_input"
                :hide="!kycFormStore.kyc_form_data.application.secondary_email.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
          </div>
          <!-- maiden_name -->
          <div class="mt-5">
            <InputLabel placeholder=" -- No data -- "
              label="Mother’s Maiden Name"
              required="true"
              readonly="true"
              :value="kycFormStore.kyc_form_data.application.mothers_maiden_name.customer_input"
              styles="mt-5"
              class="non-interactable-input"
            />
            <AgentInputFieldVue
              label="Mother’s Maiden Name"
              required="true"
              :value="kycFormStore.kyc_form_data.application.mothers_maiden_name.call_agent_input"
              styles="mt-5"
              :hide="!kycFormStore.kyc_form_data.application.mothers_maiden_name.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
          </div>

          <hr style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5" />

          <!-- employment_status -->
          <div class="columns">
            <div class="column is-half">
              <div>
                <InputLabel v-if="kycFormStore.kyc_form_data.application.employment_status.customer_input"
                  label="Employment Status"
                  readonly="true"
                  required="true"
                  :value="kycFormStore.kyc_form_data.application.employment_status.customer_input"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Employment Status"
                  required="true"
                  :value="kycFormStore.kyc_form_data.application.employment_status.call_agent_input"
                  styles="mt-5"
                  :hide="!kycFormStore.kyc_form_data.application.employment_status.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
              </div>
            </div>
          </div>

          <!-- <div v-if="
              kycFormStore.kyc_form_data.application.employment_status.customer_input === `Full-Time` ||
              kycFormStore.kyc_form_data.application.employment_status.customer_input === `Part Time` ||
              kycFormStore.kyc_form_data.application.employment_status.customer_input === `Self-Employed` ||
              kycFormStore.kyc_form_data.application.employment_status.customer_input === `Probation`
            "
          > -->
          <div
              v-if="
                 kycFormStore.kyc_form_data.application.employment_status.is_changed_by_call_agent
                  ?  kycFormStore.kyc_form_data.application.employment_status.call_agent_input ===  'Self-Employed' ||
                  kycFormStore.kyc_form_data.application.employment_status.call_agent_input === 'Freelance'
                  :  kycFormStore.kyc_form_data.application.employment_status.customer_input === 'Freelance' ||
                  kycFormStore.kyc_form_data.application.employment_status.customer_input === 'Self-Employed'"
            >
            <!-- profession_nature_of_business -->
            <div class="mt-5">
            <InputLabel
              label="Profession / Nature of Business"
              readonly="true"
              placeholder=" -- No data -- "
              :value="kycFormStore.kyc_form_data.application.profession_nature_of_business.customer_input"
              styles="mt-4"
              class="non-interactable-input"
            />
            <AgentInputFieldVue
              label="Profession / Nature of Business"
              :value="kycFormStore.kyc_form_data.application.profession_nature_of_business.call_agent_input"
              styles="mt-5"
              :hide="!kycFormStore.kyc_form_data.application.profession_nature_of_business.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            </div>
          </div>
            <!-- Employer / Business Name -->
            <div
              class="mt-5"
              v-if="
                kycFormStore.kyc_form_data.application.employer.customer_input
              "
            >
            <InputLabel v-if="kycFormStore.kyc_form_data.application.employer.customer_input"
              label="Employer /  Business Name"
              readonly="true"
              :value="kycFormStore.kyc_form_data.application.employer.customer_input"
              styles="mt-5"
              class="non-interactable-input"
            />
            <AgentInputFieldVue
              label="Employer /  Business Name"
              :value="kycFormStore.kyc_form_data.application.employer.call_agent_input"
              styles="mt-5"
              :hide="!kycFormStore.kyc_form_data.application.employer.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            </div>
            <div class="mt-5" v-if="kycFormStore.formData.employer">
              <InputLabel
                    label="Employer /  Business Name"
                    readonly="true"
                    :value="kycFormStore.kyc_form_data.application.employer.customer_input"
                    styles="mt-5"
                    class="non-interactable-input"
                    />
                <AgentInputFieldVue
                    label="Employer /  Business Name"
                    :value="kycFormStore.kyc_form_data.application.employer.call_agent_input"
                    styles="mt-5"
                    :hide="!kycFormStore.kyc_form_data.application.employer.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                    />
            </div>
            <!-- BusinessAddress -->
            <div
              class="mt-5"
              v-if="kycFormStore.kyc_form_data.application.employment_status.is_changed_by_call_agent?
                  kycFormStore.kyc_form_data.application.employment_status.call_agent_input === 'Full-Time' ||
                  kycFormStore.kyc_form_data.application.employment_status.call_agent_input === 'Part Time' ||
                  kycFormStore.kyc_form_data.application.employment_status.call_agent_input === 'Self-Employed' ||
                  kycFormStore.kyc_form_data.application.employment_status.call_agent_input === 'Probation':
                  kycFormStore.kyc_form_data.application.employment_status.customer_input === 'Full-Time' ||
                  kycFormStore.kyc_form_data.application.employment_status.customer_input === 'Part Time' ||
                  kycFormStore.kyc_form_data.application.employment_status.customer_input === 'Self-Employed' ||
                  kycFormStore.kyc_form_data.application.employment_status.customer_input === 'Probation'
            "
            >
              <label>{{
                $root.selectedLanguageData.employer_business_address
              }}</label>
              <div class="columns is-4 is-variable">
                <div class="column is-two-fifths">
                  <div>
                    <InputLabel placeholder=" -- No data -- "
                      label="Building Number"
                      readonly="true"
                      :value="kycFormStore.kyc_form_data.application.emp_building_number.customer_input"
                      class="non-interactable-input"
                    />
                    <AgentInputFieldVue  placeholder=" -- No data -- "
                      label="Building Number"
                      :value="kycFormStore.kyc_form_data.application.emp_building_number.call_agent_input"
                      :hide="!kycFormStore.kyc_form_data.application.emp_building_number.is_changed_by_call_agent"
                      readonly="true"
                      class="non-interactable-input"
                    />
                  </div>
                </div>
                <div class="column">
                  <InputLabel  placeholder=" -- No data -- "
                    label="Building Name"
                    readonly="true"
                    :value="kycFormStore.kyc_form_data.application.emp_building_name.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputFieldVue
                    label="Building Name"
                    :value="kycFormStore.kyc_form_data.application.emp_building_name.call_agent_input"
                    :hide="!kycFormStore.kyc_form_data.application.emp_building_name.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <!-- second line -->
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <div>
                    <InputLabel  placeholder=" -- No data -- "
                      label="Street"
                      required=true
                      readonly="true"
                      :value="kycFormStore.kyc_form_data.application.emp_street.customer_input"
                      class="non-interactable-input"
                    />
                    <AgentInputFieldVue
                      label="Street"
                      :value="kycFormStore.kyc_form_data.application.emp_street.call_agent_input"
                      :hide="!kycFormStore.kyc_form_data.application.emp_street.is_changed_by_call_agent"
                      readonly="true"
                      class="non-interactable-input"
                    />
                  </div>
                </div>
                <div class="column is-half">
                  <InputLabel placeholder=" -- No data -- "
                    label="Town"
                    required=true
                    readonly="true"
                    :value="kycFormStore.kyc_form_data.application.emp_town.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputFieldVue
                    label="Town"
                    :value="kycFormStore.kyc_form_data.application.emp_town.call_agent_input"
                    :hide="!kycFormStore.kyc_form_data.application.emp_town.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <!-- third_line -->
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <div>
                    <InputLabel  placeholder=" -- No data -- "
                      label="Postal Code"
                      readonly="true"
                      :value="kycFormStore.kyc_form_data.application.emp_postal_code.customer_input"
                      class="non-interactable-input"
                    />
                    <AgentInputFieldVue
                      label="Postal Code"
                      :value="kycFormStore.kyc_form_data.application.emp_postal_code.call_agent_input"
                      :hide="!kycFormStore.kyc_form_data.application.emp_postal_code.is_changed_by_call_agent"
                      readonly="true"
                      class="non-interactable-input"
                    />
                  </div>
                </div>
                <div class="column is-half">
                  <InputLabel  placeholder=" -- No data -- "
                    label="District"
                    readonly="true"
                    required= "true"
                    :value="kycFormStore.kyc_form_data.application.emp_district.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputFieldVue
                    label="District"
                    :value="kycFormStore.kyc_form_data.application.emp_district.call_agent_input"
                    :hide="!kycFormStore.kyc_form_data.application.emp_district.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <!-- country -->
              <div class="columns is-7 is-variable mt-1">
                <div class="column">
                  <InputLabel
                    label="Country"
                    required="true"
                    readonly="true"
                    :value="kycFormStore.kyc_form_data.application.emp_country.customer_input"
                    class="non-interactable-input"
                  />
                </div>
                <div class="column">
                  <AgentInputFieldVue
                    label="Country"
                    :value="kycFormStore.kyc_form_data.application.emp_country.call_agent_input"
                    :hide="!kycFormStore.kyc_form_data.application.emp_country.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
            </div>


          <div
            v-if="
              kycFormStore.kyc_form_data.application.employment_status
                .customer_input === `Freelance`
            "
            class="mt-4"
          >
            <label
              >{{ $root.selectedLanguageData.what_other_platforms
              }}<span class="has-text-danger">*</span></label
            >
            <div style="margin-top: 10px" class="columns work-list">
              <div
                class="work-list-item"
                v-for="work in kycFormStore.kyc_form_data.application
                  .other_platforms_working_on.customer_input"
                :key="work"
              >
                <b-field>
                  <b-checkbox class="en-style" :value="true" disabled>
                    {{ work }}
                  </b-checkbox>
                </b-field>
              </div>
            </div>
            <div
              v-if="
                kycFormStore.kyc_form_data.application
                  .other_platforms_working_on_other_description
                  .customer_input !== null
              "
            >
              <b-field label-position="inside">
                <template #label
                  >{{ $root.selectedLanguageData.please_specify
                  }}<span class="has-text-danger">*</span></template
                >
                <p class="control has-icons-right">
                  <b-input
                    rounded
                    type="text"
                    disabled
                    v-model="
                      kycFormStore.kyc_form_data.application.other_platforms_working_on_other_description.customer_input
                    "
                  />
                </p>
              </b-field>
            </div>
          </div>

          <!-- hr -->
          <hr style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5" />
          <p style="font-weight: 600; font-size: 17px" class="mb-2">
            {{ $root.selectedLanguageData.tax_details_info }}
          </p>
          <!-- are_you_a_taxpayer -->
          <div>
            <InputLabel
                label="Are You a Taxpayer?"
                required="true"
                readonly="true"
                :value="
                  kycFormStore.kyc_form_data.application.are_you_a_tax_payer.customer_input ? 'Yes' : 'No'
                "
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Are You a Taxpayer?"
                :value="
                  kycFormStore.kyc_form_data.application.are_you_a_tax_payer.call_agent_input ? 'Yes' : 'No'
                "
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.are_you_a_tax_payer.is_changed_by_call_agent"
                required="true"
                readonly="true"
                class="non-interactable-input"
              />
          </div>

          <!-- are_you_a_taxpayer === `Yes` -->
          <div
            class="mt-4"
            v-if="kycFormStore.kyc_form_data.application.are_you_a_tax_payer.customer_input ||
                  kycFormStore.kyc_form_data.application.are_you_a_tax_payer.call_agent_input
            "
          >
            <InputLabel
              label="Tax File Number"
              placeholder=" -- No data -- "
              required="true"
              readonly="true"
              :value="kycFormStore.kyc_form_data.application.tax_file_numer.customer_input"
              class="non-interactable-input"
              />
            <AgentInputFieldVue
              label="Tax File Number"
              :value="kycFormStore.kyc_form_data.application.tax_file_numer.call_agent_input"
              :hide="!kycFormStore.kyc_form_data.application.tax_file_numer.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
              />
          </div>
          <!-- hr -->

          <!-- hr -->
          <hr style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5" />

          <div>
            <InputLabel
                label="Are You or Your Close Relative a Politically Exposed Person?"
                readonly="true"
                required="true"
                :value="GlobalFunctions.getPepSelectionDisplayValue(kycFormStore.kyc_form_data.application.pep_declaration)"
                styles="mt-4"
                class="non-interactable-input"
            />

            <AgentInputFieldVue
                v-if="GlobalFunctions.showAgentChangedPepSelection(kycFormStore.kyc_form_data.application.pep_declaration)"
                label="Are You or Your Close Relative a Politically Exposed Person?"
                :value="GlobalFunctions.getPepSelectionDisplayValue(kycFormStore.kyc_form_data.application.pep_declaration,true)"
                styles="mt-5"
                readonly="true"
                required="true"
                class="non-interactable-input"
            />
          </div>

          <!-- politically_exposed_person_name -->
          <div v-if="!kycFormStore.kyc_form_data.application.pep_declaration.customer_input.not_politically_exposed">
            <div
              v-for="person in kycFormStore.kyc_form_data.application
                .pep_declaration.customer_input.pep_person_details"
              :key="person.name"
            >
              <div class="mt-4" v-if="person.name != ''">
                <InputLabel
                  v-if="person.name !== ''"
                  label="Name of PEP"
                  required="true"
                  readonly="true"
                  :value="person.name"
                  class="non-interactable-input"
                />
              </div>
              <!-- designation -->
              <div class="mt-5" v-if="person.designation != ''">
                <InputLabel
                  v-if="person.designation !== ''"
                  label="Designation of PEP"
                  required="true"
                  readonly="true"
                  :value="person.designation"
                  class="non-interactable-input"
                />
              </div>
            </div>
          </div>
          <div v-if="kycFormStore.kyc_form_data.application.pep_declaration.is_changed_by_call_agent">
            <div
              v-for="(pep_detail,index) in kycFormStore.kyc_form_data.application.pep_declaration.call_agent_input.pep_person_details"
              :key="index"
            >
              <div class="mt-4">
                <AgentInputFieldVue
                    v-if="GlobalFunctions.showAgentChangedPepDetail(kycFormStore.kyc_form_data.application.pep_declaration,index,'name')"
                    label="Name"
                    :value="pep_detail.name"
                    styles="mt-5"
                    :hide="!kycFormStore.kyc_form_data.application.pep_declaration.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                />
              </div>
              <!-- designation -->
              <div class="mt-5" >
                <AgentInputFieldVue
                    v-if="GlobalFunctions.showAgentChangedPepDetail(kycFormStore.kyc_form_data.application.pep_declaration,index, 'designation')"
                    label="Designation"
                    :value="pep_detail.designation"
                    styles="mt-5"
                    :hide="!kycFormStore.kyc_form_data.application.pep_declaration.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                />
              </div>
            </div>
          </div>

          <!-- hr -->
          <hr style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5" />
          <!-- source_of_income -->
          <div>
            <DisplayBulletList class="non-interactable-input" label="Source of Funds" :items="kycFormStore.kyc_form_data.application.source_of_funds.customer_input" :isAgent="false"/>
            <DisplayBulletList class="non-interactable-input" v-if="kycFormStore.kyc_form_data.application.source_of_funds.call_agent_input != null" label="Source of Funds" :items="kycFormStore.kyc_form_data.application.source_of_funds.call_agent_input" :isAgent="true"/>
          </div>

          <!-- other source of income -->
          <div class="mt-5">
            <InputLabel
              v-if="kycFormStore.kyc_form_data.application.source_of_funds_other.customer_input"
              label="Please Specify"
              readonly="true"
              :value="kycFormStore.kyc_form_data.application.source_of_funds_other_description.customer_input"
              styles="mt-4"
              class="non-interactable-input"
            />
            <AgentInputFieldVue
              label="Please Specify"
              :value="kycFormStore.kyc_form_data.application.source_of_funds_other_description.call_agent_input"
              styles="mt-5"
              :hide="!kycFormStore.kyc_form_data.application.source_of_funds_other_description.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
          </div>

          <!-- hr -->
          <hr style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5" />
          <!-- Branch -->
         <div class="columns is-4 is-variable">
           <div class="column is-half">
             <InputLabel v-if="kycFormStore.kyc_form_data.customer_branch.branch_name"
               label="Preferred Branch"
               readonly="true"
               :value="kycFormStore.kyc_form_data.customer_branch.branch_name"
               styles="mt-4"
               class="non-interactable-input"
             />
             <AgentInputFieldVue
               v-if="kycFormStore.kyc_form_data.agent_branch != null"
               label="Preferred Branch"
               :value="kycFormStore.kyc_form_data.agent_branch.branch_name"
               styles="mt-5"
               :hide="!kycFormStore.kyc_form_data.application.branch_id.is_changed_by_call_agent"
               readonly="true"
               class="non-interactable-input"
             />
           </div>
         </div>
          <!-- reason_for_opening_an_account -->
          <div class="mt-3" >
          <InputLabel
            v-if="kycFormStore.kyc_form_data.application.branch_comment.customer_input != ''"
            label=" Reason for opening an account in a branch that is located far from the permanent address"
            readonly="true"
            required="true"
            :value="kycFormStore.kyc_form_data.application.branch_comment.customer_input"
            styles="mt-4"
            class="non-interactable-input"
          />

          <AgentInputFieldVue
            v-if="kycFormStore.kyc_form_data.application.branch_comment.is_changed_by_call_agent"
            label="Reason for opening an account in a branch that is located far from the permanent address"
            :value="kycFormStore.kyc_form_data.application.branch_comment.call_agent_input"
            styles="mt-5"
            readonly="true"
            required="true"
            class="non-interactable-input"
          />
          </div>
          <!-- hr -->
          <hr style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5" />

          <!-- account selection start -->
          <div>
            <!-- <ProductShowComponent
              v-for="(productDetail, index) in kycFormStore.kyc_form_data
                .products"
              :product-selection="productDetail"
              :key="index"
            ></ProductShowComponent> -->
            <div v-for="product in kycFormStore.kyc_form_data.products" :key="product.id">
              <p>
                Product Selection -
                <span>{{
                  product.account_type.is_changed_by_call_agent
                    ? product.account_type.call_agent_input
                    : product.account_type.customer_input
                }}</span>
              </p>
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <InputLabel  v-if="product.currency_type.customer_input"
                    label="Currency"
                    readonly="true"
                    :value="product.currency_type.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputFieldVue
                    label="Currency"
                    :value="product.currency_type.call_agent_input"
                    styles="mt-5"
                    :hide="!product.currency_type.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <div class="columns is-7 is-variable">
                <!-- saving_account_type -->
                <div class="column">
                  <InputLabel v-if="product.account_type.customer_input"
                    label="Account"
                    readonly="true"
                    :value="product.account_type.customer_input"
                    class="non-interactable-input"
                  />
                  <AgentInputFieldVue
                    label="Account"
                    :value="product.account_type.call_agent_input"
                    styles="mt-5"
                    :hide="!product.account_type.is_changed_by_call_agent"
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
                <!-- Purpose of Account Opening -->
              </div>
              <div class="columns is-7 is-variable">
                <div class="column">
                  <!-- purpose of opening account - customer inputs -->

                  <DisplayBulletList class="non-interactable-input" label="Purpose of Account Opening" :items="product.purpose_of_opening_customer_inputs" :isAgent="false"/>

                  <!-- purpose of opening account - agent inputs -->
                  <DisplayBulletList class="non-interactable-input" v-if="product.purpose_of_opening_account.is_changed_by_call_agent" label="Purpose of Account Opening" :items="product.purpose_of_opening_agent_inputs" :isAgent="true"/>

                  <!-- OTHER SPECIFIED  -->
                  <InputLabel
                    v-if="
                      product.purpose_of_opening_other_specify_customer_inputs !==
                      ''
                    "
                    label="Please Specify the Purpose"
                    readonly="true"
                    :value="
                      product.purpose_of_opening_other_specify_customer_inputs
                    "
                    styles="mt-4"
                    class="non-interactable-input"
                  />
                  <AgentInputFieldVue
                    label="Please Specify the Purpose"
                    :value="
                      product.purpose_of_opening_other_specify_agent_inputs
                    "
                    styles="mt-5"
                    :hide="
                      product.purpose_of_opening_other_specify_agent_inputs == ''
                    "
                    readonly="true"
                    class="non-interactable-input"
                  />
                </div>
              </div>
              <!-- Anticipated Volumes of Deposits from above Sources -->

              <InputLabel  v-if="product.anticipated_volume.customer_input"
                label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
                readonly="true"
                required="true"
                :value="product.anticipated_volume.customer_input"
                styles="mt-4"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Anticipated Volumes of Deposits from declared Sources (Per Month)"
                :value="product.anticipated_volume.call_agent_input"
                styles="mt-5"
                :hide="!product.anticipated_volume.is_changed_by_call_agent"
                readonly="true"
                required="true"
                class="non-interactable-input"
              />
              <!-- Initial Deposit -->
              <div v-if="product.currency_type.customer_input === 'LKR'">
                <InputLabel v-if="product.initial_deposit_amount.customer_input"
                  label="Initial Deposit"
                  readonly="true"
                  required="true"
                  :value="product.initial_deposit_amount.customer_input"
                  styles="mt-5"
                  class="non-interactable-input"
                />
              </div>

              <div v-if="product.currency_type.customer_input != 'LKR'">
                <InputLabel v-if="product.initial_deposit_amount.customer_input"
                  label="Initial Deposit"
                  readonly="true"
                  :value="product.initial_deposit_amount.customer_input"
                  styles="mt-5"
                  class="non-interactable-input"
                />
              </div>

              <AgentInputFieldVue
                label="Initial Deposit"
                :value="product.initial_deposit_amount.call_agent_input"
                styles="mt-5"
                :hide="!product.initial_deposit_amount.is_changed_by_call_agent"
                readonly="true"
                required="true"
                class="non-interactable-input"
              />
            </div>
          </div>
          <!-- account selection end -->

          <div class="is-flex is-justify-content-space-between mt-5">
            <!-- sms -->
            <div class="column is-half">
              <InputLabel
                label="SMS Alerts"
                readonly="true"
                required="true"
                :value="kycFormStore.kyc_form_data.application.vas_sms_alert.customer_input ? 'Yes' : 'No'"
                class="non-interactable-input"
              />
            </div>
            <!-- ebanking  -->
            <div class="column is-half">
              <InputLabel
                label="Online Banking"
                readonly="true"
                required="true"
                :value="kycFormStore.kyc_form_data.application.vas_online_banking.customer_input ? 'Yes' : 'No'"
                class="non-interactable-input"
              />
            </div>
          </div>
          <div class="is-flex is-justify-content-space-between">
            <!-- Virtual Wallet -->
            <div class="column is-half"  v-if="kycFormStore.kyc_form_data.application.residentship.customer_input === 'Sri Lanka'">
              <InputLabel
                label="Virtual Wallet"
                readonly="true"
                required="true"
                :value="kycFormStore.kyc_form_data.application.vas_virtual_wallet.customer_input ? 'Yes' : 'No'"
                class="non-interactable-input"
              />
            </div>
            <!-- Estatement -->
            <div class="column is-half">
              <InputLabel
                label="E-Statement"
                readonly="true"
                required="true"
                :value="kycFormStore.kyc_form_data.application.vas_estatement.customer_input ? 'Yes' : 'No'"
                class="non-interactable-input"
              />
            </div>
          </div>

          <!-- Debit Card* -->
          <div class="is-flex is-justify-content-space-between" v-if="GlobalFunctions.checkDebitCardSectionVisible1(kycFormStore.kyc_form_data.application.residentship, kycFormStore.kyc_form_data.products)">
            <div class=column>
              <InputLabel
                  label="Debit Card"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.vas_debit_card.customer_input ? 'Yes' : 'No'"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Debit Card"
                  :value="kycFormStore.kyc_form_data.application.vas_debit_card.call_agent_input ? 'Yes' : 'No'"
                  styles="mt-5"
                  :hide="!kycFormStore.kyc_form_data.application.vas_debit_card.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
            </div>
            <div class=column v-if="kycFormStore.kyc_form_data.application.vas_debit_card.customer_input || kycFormStore.kyc_form_data.application.vas_debit_card.is_changed_by_call_agent">
              <InputLabel
                  label="Point of Sale (POS) Required"
                  required="true"
                  readonly="true"
                  :value="kycFormStore.kyc_form_data.application.vas_debit_card_pos_required.customer_input? 'Yes': 'No'"
                  class="non-interactable-input"
                />
                <AgentInputFieldVue
                  label="Point of Sale (POS) Required"
                  required="true"
                  :value="kycFormStore.kyc_form_data.application.vas_debit_card_pos_required.call_agent_input? 'Yes': 'No'"
                  :hide="!kycFormStore.kyc_form_data.application.vas_debit_card_pos_required.is_changed_by_call_agent"
                  readonly="true"
                  class="non-interactable-input"
                />
            </div>
          </div>
          <div v-if="kycFormStore.kyc_form_data.application.vas_debit_card.customer_input === true ||
            kycFormStore.kyc_form_data.application.vas_debit_card.is_changed_by_call_agent">
            <!-- POS Required* -->

            <div
              class="mt-5"
              v-if="kycFormStore.kyc_form_data.application.vas_debit_card_pos_required.customer_input ||
              kycFormStore.kyc_form_data.application.vas_debit_card_pos_account.is_changed_by_call_agent
              "
            >
              <InputLabel v-if="kycFormStore.kyc_form_data.application.vas_debit_card_pos_account.customer_input"
                label="Account to be Linked for Point of Sale (POS) Transaction"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.vas_debit_card_pos_account.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                label="Account to be Linked for Point of Sale (POS) Transaction"
                :value="kycFormStore.kyc_form_data.application.vas_debit_card_pos_account.call_agent_input"
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.vas_debit_card_pos_account.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />

            </div>
            <div class="mt-5"
              v-if="kycFormStore.kyc_form_data.application.vas_debit_card_name.customer_input != '' ||
              kycFormStore.kyc_form_data.application.vas_debit_card_name.is_changed_by_call_agent
              "
            >
            <InputLabel v-if="kycFormStore.kyc_form_data.application.vas_debit_card_name.customer_input"
              label="Name to be Printed on Card"
              readonly="true"
              :value="kycFormStore.kyc_form_data.application.vas_debit_card_name.customer_input"
              styles="mt-5"
              class="non-interactable-input"
            />
            <AgentInputFieldVue
              label="Name to be Printed on Card"
              :value="kycFormStore.kyc_form_data.application.vas_debit_card_name.call_agent_input"
              styles="mt-5"
              :hide="!kycFormStore.kyc_form_data.application.vas_debit_card_name.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
              <div
                class="is-flex is-justify-content-flex-end is-align-content-flex-end"
              ></div>
            </div>

            <!-- Collection Details -->
            <div class="mt-5"
              v-if="kycFormStore.kyc_form_data.application.vas_debit_card.customer_input ||
              kycFormStore.kyc_form_data.application.vas_debit_card_collection_details.is_changed_by_call_agent
              "
            >
              <InputLabel v-if="kycFormStore.kyc_form_data.application.vas_debit_card_collection_details.customer_input"
                label="Card Delivery Details"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.vas_debit_card_collection_details.customer_input"
                class="non-interactable-input"
              />
              <AgentInputFieldVue
                v-if="kycFormStore.kyc_form_data.application.vas_debit_card_collection_details.is_changed_by_call_agent"
                label="Card Delivery Details"
                :value="kycFormStore.kyc_form_data.application.vas_debit_card_collection_details.call_agent_input"
                styles="mt-5"
                :hide="!kycFormStore.kyc_form_data.application.vas_debit_card_collection_details.is_changed_by_call_agent"
                readonly="true"
                class="non-interactable-input"
              />
            </div>
            <br />
            <!-- Pin Collection Details -->
            <div
              class="mt-3"
              v-if="kycFormStore.kyc_form_data.application.vas_debit_card.customer_input"
            >
            <InputLabel v-if="kycFormStore.kyc_form_data.application.vas_debit_card_pin_collection_details.customer_input"
              label="PIN Delivery Details"
              readonly="true"
              :value="kycFormStore.kyc_form_data.application.vas_debit_card_pin_collection_details.customer_input"
              class="non-interactable-input"
            />
            <AgentInputFieldVue
              v-if="kycFormStore.kyc_form_data.application.vas_debit_card_pin_collection_details.is_changed_by_call_agent"
              label="PIN Delivery Details"
              :value="kycFormStore.kyc_form_data.application.vas_debit_card_pin_collection_details.call_agent_input"
              styles="mt-5"
              :hide="!kycFormStore.kyc_form_data.application.vas_debit_card_pin_collection_details.is_changed_by_call_agent"
              readonly="true"
              class="non-interactable-input"
            />
            </div>
          </div>
          <hr style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5" />
          <div class="columns mt-2 is-7 is-variable">
            <div class="column">
              <InputLabel
                label="Preferred Date"
                readonly="true"
                :value="getFormattedDateTime(kycFormStore.kyc_form_data.application.video_call_date)"
                class="non-interactable-input"
              />
            </div>
            <!--              time-->
            <div class="column">
              <InputLabel
                label="Preferred Time"
                readonly="true"
                :value="kycFormStore.kyc_form_data.application.video_call_time"
                class="non-interactable-input"
              />
            </div>
          </div>
        <hr style="background-color: #1b1b1b; height: 1.8px; opacity: 0.5" />

        <div class="columns is-7 is-variable">
          <div class="column">
            <ImageBox v-if="kycFormStore.kyc_form_data.application.nic_front_attachment.attachment_type !== 'pdf'"
              label="User uploaded NIC front image"
              :imageName="kycFormStore.kyc_form_data.application.nic_front_attachment.attachment_url"
              styles="mt-3"
            />
            <ImageBox v-else
              label="User uploaded NIC front image"
              :imageName="pdfPlaceholder"
              styles="mt-3"
            />
          </div>
        </div>
        <div class="columns is-7 is-variable">
          <div class="column">
            <ImageBox v-if="kycFormStore.kyc_form_data.application.nic_back_attachment.attachment_type !== 'pdf'"
              label="User uploaded NIC rear image"
              :imageName="kycFormStore.kyc_form_data.application.nic_back_attachment.attachment_url"
              styles="mt-3"
            />
            <ImageBox v-else
              label="User uploaded NIC rear image"
              :imageName="pdfPlaceholder"
              styles="mt-3"
            />
          </div>
        </div>
          <div class="columns is-7 is-variable" v-if="kycFormStore.kyc_form_data.application.passport_bio_page_attachment.attachment_type != ''">
            <div class="column">
              <ImageBox  v-if="kycFormStore.kyc_form_data.application.passport_bio_page_attachment.attachment_type !== 'pdf'"
                  label="User uploaded Passport bio page image"
                  :imageName="kycFormStore.kyc_form_data.application.passport_bio_page_attachment.attachment_url"
                  styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded Passport bio page image"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
          </div>

          <div class="columns is-7 is-variable" v-if="kycFormStore.kyc_form_data.application.passport_alteration_attachment.attachment_type !== ''">
            <div class="column">
              <ImageBox  v-if="kycFormStore.kyc_form_data.application.passport_alteration_attachment.attachment_type !== 'pdf'"
                  label="User uploaded Passport alteration page image"
                  :imageName="kycFormStore.kyc_form_data.application.passport_alteration_attachment.attachment_url"
                  styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded Passport alteration page image"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
          </div>

          <div class="columns is-7 is-variable" v-if="kycFormStore.kyc_form_data.application.passport_entry_stamp_attachment.attachment_type != ''">
            <div class="column">
              <ImageBox v-if="kycFormStore.kyc_form_data.application.passport_entry_stamp_attachment.attachment_type !== 'pdf'"
                  label="User uploaded Passport entry stamp page image"
                  :imageName="kycFormStore.kyc_form_data.application.passport_entry_stamp_attachment.attachment_url"
                  styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded Passport entry stamp page image"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
          </div>

          <div class="columns is-7 is-variable" v-if="kycFormStore.kyc_form_data.application.visa_attachment.attachment_type  != ''">
            <div class="column">
              <ImageBox  v-if="kycFormStore.kyc_form_data.application.visa_attachment.attachment_type !== 'pdf'"
                  label="User uploaded Visa image"
                  :imageName="kycFormStore.kyc_form_data.application.visa_attachment.attachment_url"
                  styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded Visa image"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
          </div>

          <div class="columns is-7 is-variable">
            <div class="column">
              <ImageBox  v-if="kycFormStore.kyc_form_data.application.selfie_attachment.attachment_type !== 'pdf'"
                  label="User uploaded selfie image"
                  :imageName="kycFormStore.kyc_form_data.application.selfie_attachment.attachment_url"
                  styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded selfie image"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
          </div>

          <div class="columns is-7 is-variable">
            <div class="column">
              <ImageBox v-if="kycFormStore.kyc_form_data.application.signature_image !== ''"
                  label="User uploaded Signature image"
                  :imageName="kycFormStore.kyc_form_data.application.signature_image"
                  styles="mt-3"
              />
            </div>

          </div>

          <div class="columns is-7 is-variable">
            <div class="column">
              <ImageBox v-if="kycFormStore.kyc_form_data.application.address_proof_attachment.attachment_type !== 'pdf'"
                  label="User uploaded address proof document"
                  :imageName="kycFormStore.kyc_form_data.application.address_proof_attachment.attachment_url"
                  styles="mt-3"
              />
              <ImageBox v-else
                label="User uploaded address proof document"
                :imageName="pdfPlaceholder"
                styles="mt-3"
              />
            </div>
          </div>

        </div>
      </transition>
      </div>
    
      </ValidationObserver>
      
    </div>

    <!-- <GetHelp/> -->
  </div>
</template>
<script>
import logopage from "./CompanyTopLogo.vue";
// import {ApiRequestManager} from "@/utils/networkManager/apiRequestManager";
import { useKycFormStore } from "@/stores/KycFormStore";
import DisplayBulletList from "./supports/DisplayBulletList.vue"
// import GetHelp from "./GetHelp.vue";

import NetworkManager from "@/utils/networkManager";
import { GlobalFunctions } from "@/heplers/globalFunctions";
// import ProductShowComponent from "@/components/supports/ProductsShowComponent.vue";
import InputLabel from "./supports/InputLabel.vue";
import ImageBox from "./supports/ImageBox.vue";
import AgentInputFieldVue from "./supports/AgentInputField.vue";
import { SetDateTimeManger } from "@/heplers/setdateTimeManager";
import { PiniaStoreHelper } from "@/heplers/piniaStoreHelper";
import { tokenGenerator } from "@/heplers/tokenGenerator";
export default {
  /*
   * ===================================================================================================================
   * expose kyc form pinia store to the component
   */
  name: "ShowSubmittedApplication",
  setup() {
    const kycFormStore = useKycFormStore();
    const piniaStoreHelper = PiniaStoreHelper;
    return { kycFormStore, piniaStoreHelper};
  },

  components: {
    logopage,
    InputLabel,
    ImageBox,
    AgentInputFieldVue,
    DisplayBulletList,
    // ProductShowComponent,
    // GetHelp,
  },
  data: function () {
    return {
      pdfPlaceholder: require("@/assets/images/icons/pdf.png"),
      previousRoute:null,
      isLoading : true,
      productListLoading:
        this.kycFormStore.formDataSupport.productList.length <= 0,
      regex: {
        old_nic: /^([0-9]{9}[Xx||Vv])$/,
        passport: /^[0-9A-Za-z]{4,18}$/,
        new_nic: /[0-9]{12}/,
      },

    };
  },

  beforeRouteEnter(to, from, next) {
    if(tokenGenerator.validateToken())
    {
      next()
    }
    else{
      next({path:"/applicationStatusCheck"})
    }
  },

  methods: {
    getFormattedDateTime(date) {
      return SetDateTimeManger.formatCustomerInputDates(date);
    },
    GetApplicationData: function () {
      const comp = this;
      const data = { reference_number: comp.kycFormStore.kyc_reference_no };


      NetworkManager.apiRequest(
        "api/KycForm/getApplicationById",
        data,
        function (e) {
          if (e.statusCode === 200) {
            console.log("getApplicationById ==>", e);
            comp.kycFormStore.kyc_form_data.application = e.data.kycApplication;
            comp.kycFormStore.kyc_form_data.videoCallDetail = e.data.VideoCallDetail;
            //console.log("comp.kycFormStore.kyc_form_data.videoCallDetail",comp.kycFormStore.kyc_form_data.videoCallDetail)
            comp.kycFormStore.kyc_form_data.products = e.data.ProductSelection;
            comp.kycFormStore.kyc_form_data.customer_branch = e.data.CustomerBranch;
            comp.kycFormStore.kyc_form_data.agent_branch = e.data.AgentBranch;
            console.log("APPLICATION : ",comp.kycFormStore.kyc_form_data)
            console.log("products network : ",e.data.productSelection)
            console.log("products local : ", comp.kycFormStore.kyc_form_data.products)
            comp.isLoading = false
          }
        },false,true);
    },
  },

  mounted() {
    if(this.kycFormStore.formData.customer_nic_eic_pp != '')
    {
      this.GetApplicationData();
    }
    else{
      console.log("NO NIC!!")
      this.$router.push('/')
    }
    console.log("comp.kycFormStore.formData.customer_nic_eic_pp",this.kycFormStore.formData.customer_nic_eic_pp)
    
  },
  computed: {
    GlobalFunctions() {
      return GlobalFunctions;
    },
    
  },
};
</script>
<style>


.verticalLine {
  border-right: 0.92px solid #1b1b1b;
}

.round-image-box {
  border-radius: 50%;
  border: 2px solid #04c3fc;
  width: 210px;
  height: 199px;
}

.preview_image {
  border-radius: 50%;
  border: 0.1px solid #231f20 !important;
  padding: 1px;
  background-color: #f9f9f9;
}

.toggle-button {
  display: flex;
  border: 1px solid black;
  max-width: 200px;
  justify-content: flex-end;
  border-radius: 500px;
}

.toggle-change {
  justify-content: flex-start;
}

.toggle-button:hover {
  cursor: pointer;
}

.no-active,
.yes-active {
  background-color: #04c3fc;
  color: white;
}

.no,
.yes {
  border-radius: 500px;
}

.toggle-common {
  padding: 0 22px;
}
.non-interactable-input {
  pointer-events: none;
}
.coer img {
  margin: 0 auto;
  display: block;
}
</style>
