export default class AgentDetails {
    id
    user_name

    constructor() {
        this.id  = 0
        this.user_name = ""
    }
}

