
const PiniaStoreHelper = {

    /**
     * =================================================================================================================
     * This function can be used to clear local storage values
     * @param storage pinia storage
     */
    clearLocalStorage(storage){
        console.log("=======================")
        console.log("Clearing local storage")
        console.log("Storage id to clear : "+storage.$id)

        // clear local storage if it exists by storage id
        if(localStorage.getItem(storage.$id)){
            // reset pinia storage
            storage.$reset()

            // reomve localstorage
            localStorage.removeItem(storage.$id)
            console.log("local storage cleared")
        }
        else {
            console.warn("no storage found for the given storage id")
        }
    }
}

export {PiniaStoreHelper}