<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
      <logopage
    /></transition>
    <div class="columns is-centered mb-0">
      <transition
        appear
        @before-enter="$animations.beforeEnterFade"
        @enter="$animations.enterFade"
      >
        <div
          v-if="$root.selectedLanguage === `en`"
          class="column"
          style="text-align: center"
        >
          <img
            src="../assets/images/header-img/personal-detail-img.png"
            alt="personal-detail-img"
            class="page-header-img"
          /></div
      ></transition>
    </div>
    <transition
      appear
      @before-enter="$animations.beforeEnterFade"
      @enter="$animations.enterFade"
    >
      <div class="columns is-centered mb-0">
        <div class="column is-half pt-0 ps-5" style="text-align: center">
          <p class="subhead-line is-size-4" style="line-height: 20px">
            <img
              :src=contactImage
              alt="contact-img"
              width="13%"
            />
          </p>

          <p class="headline-font" style="line-height: 42px">
            {{ $root.selectedLanguageData.personal_details }}
          </p>
          <p class="specific-line">
            {{ $root.selectedLanguageData.lets_get_to_know_you }}
          </p>
        </div>
      </div></transition
    >
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="">
        <div class="form-body mt-3">
          <div class="box form-padding pb-5" style="padding-top: 0% !important">
            <p class="has-text-centered pt-4 mb-4 astric-font-size">
              <span class="has-text-danger mr-2">{{
                $root.selectedLanguageData.note
              }}</span
              ><b class="has-text-danger">*</b
              ><span class="text-transparency">
                Indicates mandatory fields that are required</span
              >
            </p>
            <div class="columns is-1 is-variable mt-1">
              <div class="column is-one-quarter">
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.title.trim() === '' &&
                      !isTittleInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.title
                      }}<span class="has-text-danger">*</span></template
                    >

                    <b-select
                      rounded
                      v-model="kycFormStore.formData.title"
                      :class="
                        kycFormStore.formData.title === '' &&
                        !isTittleInputFocus
                          ? 'arrow-up-before'
                          : 'arrow-up'
                      "
                      @focus="
                        () => {
                          isTittleInputFocus = true;
                        }
                      "
                      @blur="
                        () => {
                          isTittleInputFocus = false;
                        }
                      "
                    >
                      <option value="Dr">
                        {{ $root.selectedLanguageData.dr }}
                      </option>
                      <option value="Master">
                        {{ $root.selectedLanguageData.master }}
                      </option>
                      <option value="Professor">
                        {{ $root.selectedLanguageData.professor }}
                      </option>
                      <option value="Rev">
                        {{ $root.selectedLanguageData.rev }}
                      </option>
                      <option value="Mr">
                        {{ $root.selectedLanguageData.mr }}
                      </option>
                      <option value="Mrs">
                        {{ $root.selectedLanguageData.mrs }}
                      </option>
                      <option value="Ms">
                        {{ $root.selectedLanguageData.ms }}
                      </option>
                      <option value="Miss">
                        {{ $root.selectedLanguageData.miss }}
                      </option>
                    </b-select>
                  </b-field>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="column">
                <b-field
                  label-position="inside"
                  :custom-class="
                    kycFormStore.formData.full_name.trim() === '' &&
                    !isFullNameInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label
                    >{{ $root.selectedLanguageData.full_name }}
                    {{ $root.selectedLanguageData.as_per_the_nic
                    }}<span class="has-text-danger">*</span></template
                  >

                  <p class="control has-icons-right">
                    <ValidationProvider
                      name="Full Name"
                      :rules="validateName()"
                      v-slot="{ errors }"
                    >
                      <b-input
                        rounded
                        type="textarea"
                        maxlength="146"
                        rows="2"
                        cols="48"
                        v-model="kycFormStore.formData.full_name"
                        @focus="
                          () => {
                            isFullNameInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isFullNameInputFocus = false;
                          }
                        "
                      />
                      <!-- <b-input
                          rounded
                          type="text"
                          v-model="kycFormStore.formData.full_name"
                          @focus="
                          () => {
                            isFullNameInputFocus = true;
                          }
                        "
                          @blur="
                          () => {
                            isFullNameInputFocus = false;
                          }
                        "
                      /> -->
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <span class="icon is-small is-right">
                      <img
                        :src=contactImage
                        alt="contact-name-img"
                        width="25"
                        height="25"
                        style="opacity: calc(60%)"
                      />
                    </span>
                  </p>
                </b-field>
              </div>
            </div>

            <!--Nic -->
            <div class="columns is-4 is-variable mt-1">
              <div class="column is-two-thirds">
                <div>
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.customer_nic.trim() === '' &&
                      !isNicInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.nic_eic_num
                      }}<span class="has-text-danger">*</span></template
                    >

                    <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="{
                          required: true,
                          multiRegex: [/^([0-9]{9}[Xx||Vv])$/, /^[0-9]{12}$/],
                        }"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          v-model="kycFormStore.formData.customer_nic"
                          disabled
                          @focus="
                            () => {
                              isNicInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isNicInputFocus = false;
                            }
                          "
                        ></b-input>
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/nic.png"
                          alt="Call-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>
              </div>

              <div class="column">
                <ValidationProvider
                  name="Issue Date"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="'custom_float_label_date'"
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.issue_date
                      }}<span class="has-text-danger">*</span></template
                    >
                    <p class="control has-icons-right">
                      <b-datepicker
                        class="date-picking"
                        rounded
                        :max-date="maxDate"
                        :min-date="nicMinDate"
                        :years-range="[-100, 100]"
                        v-model="kycFormStore.formData.nic_issue_date"
                        placeholder="Select a Date"
                      >
                      </b-datepicker>
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/Calendar.png"
                          alt="Calendar-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>

                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <p class="mb-4 mt-5 note-line">
              <span class="has-text-danger has-text-weight-semibold">{{
                $root.selectedLanguageData.note
              }}</span>
              {{ $root.selectedLanguageData.nic_note }}
            </p>
            <!-- passport_number -->

            <div
              class="columns is-3 is-variable mt-1"
              v-if="kycFormStore.formData.customer_passport_no"
            >
              <div class="column is-two-fifths">
                <b-field
                  label-position="inside"
                  :custom-class="
                    kycFormStore.formData.customer_passport_no.trim() === '' &&
                    !isPassportInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label
                    >{{ $root.selectedLanguageData.passport_number
                    }}<span class="has-text-danger">*</span></template
                  >

                  <p class="control has-icons-right">
                    <ValidationProvider
                      :rules="passportValidateObject()"
                      v-slot="{ errors }"
                    >
                      <b-input
                        rounded
                        v-model="kycFormStore.formData.customer_passport_no"
                        disabled
                        @focus="
                          () => {
                            isPassportInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isPassportInputFocus = false;
                          }
                        "
                      ></b-input>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <span class="icon is-small is-right">
                      <img
                        src="../assets/images/icons/nic.png"
                        alt="Call-img"
                        width="25"
                        height="25"
                      />
                    </span>
                  </p>
                </b-field>
              </div>

              <div class="column">
                <b-field
                  label-position="inside"
                  :custom-class="'custom_float_label_date'"
                >
                  <template #label
                    >{{ $root.selectedLanguageData.issue_date
                    }}<span class="has-text-danger">*</span></template
                  >

                  <p class="control has-icons-right">
                    <b-datepicker
                      rounded
                      :max-date="maxDate"
                      :min-date="passportMinDate"
                      :years-range="[-100, 100]"
                      v-model="kycFormStore.formData.passport_issue_date"
                      :locale="undefined"
                      placeholder="Select a Date"
                      class="date-picking"
                    >
                    </b-datepicker>

                    <span class="icon is-small is-right">
                      <img
                        src="../assets/images/icons/Calendar.png"
                        alt="Calendar-img"
                        width="25"
                        height="25"
                      />
                    </span>
                  </p>
                </b-field>
              </div>

              <div class="column">
                <b-field
                  label-position="inside"
                  :custom-class="'custom_float_label_date'"
                >
                  <template #label
                    >{{ $root.selectedLanguageData.expiry_date
                    }}<span class="has-text-danger">*</span></template
                  >

                  <p class="control has-icons-right">
                    <b-datepicker
                      rounded
                      v-model="kycFormStore.formData.passport_expiry_date"
                      :min-date="passportExpMinDate"
                      :years-range="[-100, 100]"
                      placeholder="Select a Date"
                      class="date-picking"
                    >
                    </b-datepicker>

                    <span class="icon is-small is-right">
                      <img
                        src="../assets/images/icons/Calendar.png"
                        alt="Calendar-img"
                        width="25"
                        height="25"
                      />
                    </span>
                  </p>
                </b-field>
              </div>
            </div>

            <div class="columns is-4 is-variable mt-1">
              <div class="column is-two-thirds">
                <div>
                  <ValidationProvider
                    name="Citizenship"
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.citizenship_1.trim() === '' &&
                        !isCitizenInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.nationality
                        }}<span class="has-text-danger">*</span></template
                      >

                      <b-select
                        style="width: 100%"
                        expanded
                        rounded
                        v-model="kycFormStore.formData.citizenship_1"
                        :class="
                          kycFormStore.formData.citizenship_1 === '' &&
                          !isCitizenInputFocus
                            ? 'arrow-up-before'
                            : 'arrow-up'
                        "
                        @click.native="changeAddressFields()"
                        @focus="
                          () => {
                            isCitizenInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isCitizenInputFocus = false;
                          }
                        "
                      >
                        <option value="Sri Lankan">
                          {{ $root.selectedLanguageData.srilankan }}
                        </option>
                        <option value="Sri Lankan with Dual Citizenship">
                          {{
                            $root.selectedLanguageData
                              .sriLankanWithDualCitizenship
                          }}
                        </option>
                        <!-- <option value="Foreign National">
                          {{ $root.selectedLanguageData.foreignNational }}
                        </option>
                        <option value="Foreign National with Dual Citizenship">
                          {{
                            $root.selectedLanguageData
                              .foreignNationalWithDualCitizenship
                          }}
                        </option> -->
                      </b-select>
                    </b-field>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="column">
                <ValidationProvider
                  name="date of birth"
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="'custom_float_label_date'"
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.date_of_birth
                      }}<span class="has-text-danger">*</span></template
                    >

                    <p class="control has-icons-right">
                      <b-datepicker
                        placeholder="DD/MM/YY"
                        rounded
                        :max-date="maxDob"
                        :min-date="dobMinDate"
                        :years-range="[-100, 100]"
                        v-model="kycFormStore.formData.date_of_birth"
                      >
                      </b-datepicker>

                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/Calendar.png"
                          alt="Calendar-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div>
              <label
                >{{ $root.selectedLanguageData.gender
                }}<span class="has-text-danger">*</span></label
              >
              <div class="columns">
                <div class="column is-one-two">
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns is-mobile two-column-parent">
                      <div class="column is-one-two">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="gender"
                            native-value="Male"
                            v-model="kycFormStore.formData.gender"
                          >
                            {{ $root.selectedLanguageData.male }}
                          </b-radio>
                        </b-field>
                      </div>
                      <div class="column is-one-two">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="gender"
                            native-value="Female"
                            v-model="kycFormStore.formData.gender"
                          >
                            {{ $root.selectedLanguageData.female }}
                          </b-radio>
                        </b-field>
                      </div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="column is-one-two"></div>
              </div>
            </div>
            <!-- marital_status-->
            <div class="mt-1">
              <label
                >{{ $root.selectedLanguageData.marital_status
                }}<span class="has-text-danger">*</span></label
              >
              <div class="columns">
                <div class="column is-one-two">
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns is-mobile two-column-parent">
                      <div class="column is-one-two">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="marital_status"
                            native-value="Married"
                            v-model="kycFormStore.formData.marital_status"
                          >
                            {{ $root.selectedLanguageData.married }}
                          </b-radio>
                        </b-field>
                      </div>
                      <div class="column is-one-two">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="marital_status"
                            native-value="Unmarried"
                            v-model="kycFormStore.formData.marital_status"
                          >
                            {{ $root.selectedLanguageData.single }}
                          </b-radio>
                        </b-field>
                      </div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div class="column is-one-two"></div>
              </div>
            </div>
          </div>

          <!-- contactDetails -->

          <div class="box form-padding py-5">
            <p class="sub_headings has-text-centered mb-4">
              {{ $root.selectedLanguageData.contact_details }}
            </p>
            <div>
              <label>{{ $root.selectedLanguageData.permanent_address }}</label>
              <div class="columns is-4 is-variable mt-1">
                <div class="column is-two-fifths">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.permanent_address_line1.trim() ===
                        '' && !isPerAddLine1Focus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.building_number }}
                    </template>
                    <p class="control has-icons-right">
                      <b-input
                        rounded
                        type="text"
                        v-model="kycFormStore.formData.permanent_address_line1"
                        @focus="
                          () => {
                            isPerAddLine1Focus = true;
                          }
                        "
                        @blur="
                          () => {
                            isPerAddLine1Focus = false;
                          }
                        "
                      />
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/bi_building.png"
                          alt="contact-name-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>
                <div class="column">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.permanent_address_line2.trim() ===
                        '' && !isPerAddLine2Focus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.building_name }}
                    </template>
                    <p class="control has-icons-right">
                      <b-input
                        rounded
                        type="text"
                        v-model="kycFormStore.formData.permanent_address_line2"
                        @focus="
                          () => {
                            isPerAddLine2Focus = true;
                          }
                        "
                        @blur="
                          () => {
                            isPerAddLine2Focus = false;
                          }
                        "
                      />
                      <span class="icon is-small is-right">
                        <img
                          :src=editImage
                          alt="contact-name-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>
              </div>
              <!-- second line -->
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <div>
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.permanent_address_line3.trim() ===
                          '' && !isPerAddLine3Focus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.street
                        }}<span class="has-text-danger">*</span></template
                      >

                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="Street"
                          :rules="validateStreet()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData.permanent_address_line3
                            "
                            @focus="
                              () => {
                                isPerAddLine3Focus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPerAddLine3Focus = false;
                              }
                            "
                          />
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <span class="icon is-small is-right">
                          <img
                            :src=locationIcon
                            alt="contact-name-img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                </div>
                <!-- street 2 -->
                <div class="column is-half">
                  <div>
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.street_2.trim() ===
                          '' && !isStreetTwoFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.street2
                        }}</template
                      >

                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="Street"
                          :rules="validateNonReqText()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData.street_2
                            "
                            @focus="
                              () => {
                                isStreetTwoFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isStreetTwoFocus = false;
                              }
                            "
                          />
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <span class="icon is-small is-right">
                          <img
                            :src=locationIcon
                            alt="contact-name-img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                </div>                 
              </div>
              <!-- third_line -->
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.permanent_address_town.trim() ===
                        '' && !isPerAddTownFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.town
                      }}<span class="has-text-danger">*</span>
                    </template>

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="Town"
                        :rules="validateStreet()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="text"
                          v-model="kycFormStore.formData.permanent_address_town"
                          @focus="
                            () => {
                              isPerAddTownFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isPerAddTownFocus = false;
                            }
                          "
                        />
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          :src=locationIcon
                          alt="contact-name-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>
                <div class="column is-half">
                  <div>
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.permanent_address_postal_code.trim() ===
                          '' && !isPerAddPostalCodeFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.postal_code }}
                      </template>
                      <p class="control has-icons-right">
                        <b-input
                          rounded
                          type="text"
                          v-model="
                            kycFormStore.formData.permanent_address_postal_code
                          "
                          @focus="
                            () => {
                              isPerAddPostalCodeFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isPerAddPostalCodeFocus = false;
                            }
                          "
                        />
                        <span class="icon is-small is-right">
                          <img
                            :src=editImage
                            alt="contact-name-img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                </div>               
              </div>
              <!-- Country -->
              <div class="columns is-7 is-variable mt-1">
                <div
                  class="column is-half"
                  v-if="kycFormStore.formData.residentship === `Sri Lanka`"
                >
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        permanentaddressdistrict.trim() === '' &&
                        !isPerAddDistrictFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.district
                        }}<span class="has-text-danger">*</span></template
                      >
                      <!-- district auto complete -->

                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          @focus="
                            () => {
                              isPerAddDistrictFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isPerAddDistrictFocus = false;
                            }
                          "
                          :data="districtFilteredDataArray"
                          v-model="permanentaddressdistrict"
                          placeholder=""
                          icon=""
                          open-on-focus
                          @select="
                            (option) => {
                              if (option) {
                                kycFormStore.formData.permanent_address_district =
                                  option;
                                permanentaddressdistrictcount = -1;
                              } else {
                                kycFormStore.formData.permanent_address_district = ``;
                              }
                            }
                          "
                          @input="
                            () => {
                              ++permanentaddressdistrictcount;
                              if (!permanentaddressdistrict) {
                                isPermanentAddressDistrictTouched = false;
                                kycFormStore.formData.permanent_address_district = ``;
                              } else if (
                                kycFormStore.formData.permanent_address_district
                              ) {
                                if (permanentaddressdistrictcount > 1) {
                                  isPermanentAddressDistrictTouched = true;
                                  kycFormStore.formData.permanent_address_district = ``;
                                }
                              } else if (permanentaddressdistrictcount > 0) {
                                isPermanentAddressDistrictTouched = true;
                                kycFormStore.formData.permanent_address_district = ``;
                              }
                            }
                          "
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoComleteDropDownIcon
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>                     
                    </b-field>
                    <span
                      class="has-text-danger"
                      v-if="
                        isPermanentAddressDistrictTouched &&
                        !kycFormStore.formData.permanent_address_district
                      "
                      >You have to select a valid district</span
                    >
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <!-- non srilankan district -->

                <div
                  class="column is-half"
                  v-if="kycFormStore.formData.residentship !== `Sri Lanka`"
                >
                  <div v-if="permanentaddresscountry === `Sri Lanka`">
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          permanentaddressdistrict.trim() === '' &&
                          !isPerAddDistrictFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                        class="mb-0"
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.district
                          }}<span class="has-text-danger">*</span></template
                        >
                        <!-- district auto complete -->

                        <p class="control has-icons-right">
                          <b-autocomplete
                            rounded
                            @focus="
                              () => {
                                isPerAddDistrictFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPerAddDistrictFocus = false;
                              }
                            "
                            :data="districtFilteredDataArray"
                            v-model="permanentaddressdistrict"
                            placeholder=""
                            icon=""
                            open-on-focus
                            @select="
                              (option) => {
                                if (option) {
                                  kycFormStore.formData.permanent_address_district =
                                    option;
                                  permanentaddressdistrictcount = -1;
                                } else {
                                  kycFormStore.formData.permanent_address_district = ``;
                                }
                              }
                            "
                            @input="
                              () => {
                                ++permanentaddressdistrictcount;
                                if (!permanentaddressdistrict) {
                                  isPermanentAddressDistrictTouched = false;
                                  kycFormStore.formData.permanent_address_district = ``;
                                } else if (
                                  kycFormStore.formData
                                    .permanent_address_district
                                ) {
                                  if (permanentaddressdistrictcount > 1) {
                                    isPermanentAddressDistrictTouched = true;
                                    kycFormStore.formData.permanent_address_district = ``;
                                  }
                                } else if (permanentaddressdistrictcount > 0) {
                                  isPermanentAddressDistrictTouched = true;
                                  kycFormStore.formData.permanent_address_district = ``;
                                }
                              }
                            "
                          >
                            <template #empty>No results found</template>
                          </b-autocomplete>
                          <span class="icon is-small is-right mx-2">
                            <img
                              :src=autoComleteDropDownIcon
                              alt="Stroke-img"
                              width="15"
                              height="15"
                            />
                          </span>
                        </p>                      
                      </b-field>
                      <span
                        class="has-text-danger"
                        v-if="
                          isPermanentAddressDistrictTouched &&
                          !kycFormStore.formData.permanent_address_district
                        "
                        >You have to select a valid district</span
                      >
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div v-if="permanentaddresscountry != `Sri Lanka`">
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          kycFormStore.formData.permanent_address_district.trim() ===
                            '' && !isPerAddDistrictFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.district
                          }}<span class="has-text-danger">*</span>
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData.permanent_address_district
                            "
                            @focus="
                              () => {
                                isPerAddDistrictFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPerAddDistrictFocus = false;
                              }
                            "
                          />
                          <span class="icon is-small is-right">
                            <img
                              :src=editImage
                              alt="contact-name-img"
                              width="25"
                              height="25"
                            />
                          </span>
                        </p>
                      </b-field>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="column is-half">
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        permanentaddresscountry.trim() === '' &&
                        !isPerAddCountryFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.country
                        }}<span class="has-text-danger">*</span></template
                      >

                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          v-model="permanentaddresscountry"
                          open-on-focus
                          :data="countryFilteredDataArray"
                          @select="
                            (option) => {
                              if (option) {
                                kycFormStore.formData.permanent_address_country =
                                  option.name;
                                permanentaddresscountry = option.name;
                                permanentaddresscountrycount = -1;
                              } else {
                                kycFormStore.formData.permanent_address_country = ``;
                                permanentaddresscountry = ``;
                              }
                            }
                          "
                          @input="
                            () => {
                              noEnrolementCountries(permanentaddresscountry);
                              ++permanentaddresscountrycount;
                              if (!permanentaddresscountry) {
                                isPermanentAddressCountryTouched = false;
                                kycFormStore.formData.permanent_address_country = ``;
                              } else if (countryFilteredDataArray.includes(permanentaddresscountry)) {
                                isPermanentAddressCountryTouched = false;
                                kycFormStore.formData.permanent_address_country =
                                  permanentaddresscountry;
                              } else if (
                                kycFormStore.formData.permanent_address_country
                              ) {
                                if (permanentaddresscountrycount > 1) {
                                  isPermanentAddressCountryTouched = true;
                                  kycFormStore.formData.permanent_address_country = ``;
                                }
                              } else if (permanentaddresscountrycount > 0) {
                                isPermanentAddressCountryTouched = true;
                                kycFormStore.formData.permanent_address_country = ``;
                              }
                            }
                          "
                          @focus="
                            () => {
                              isPerAddCountryFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isPerAddCountryFocus = false;
                            }
                          "
                          placeholder=""
                          icon=""
                          field="name"
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoComleteDropDownIcon
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>
                    <span
                      class="has-text-danger"
                      v-if="
                        isPermanentAddressCountryTouched &&
                        !kycFormStore.formData.permanent_address_country
                      "
                      >You have to select a valid country</span
                    >
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <div
              class="mt-5 mb-5 flex-start align"
              style="justify-content: flex-start !important"
            >
              <b-field>
                <b-checkbox
                  v-model="
                    kycFormStore.formData
                      .correspondence_address_differ_with_permanent_address
                  "
                  :disabled="
                    kycFormStore.formData.residentship !== `Sri Lanka`
                      ? !kycFormStore.formData.permanent_address_line3 ||
                        !kycFormStore.formData.permanent_address_country ||
                        kycFormStore.formData.permanent_address_line3.trim() ===
                          `` ||
                        kycFormStore.formData.permanent_address_country.trim() ===
                          ``
                      : !kycFormStore.formData.permanent_address_line3 ||
                        !kycFormStore.formData.permanent_address_district ||
                        !kycFormStore.formData.permanent_address_country ||
                        kycFormStore.formData.permanent_address_line3.trim() ===
                          `` ||
                        kycFormStore.formData.permanent_address_district.trim() ===
                          `` ||
                        kycFormStore.formData.permanent_address_country.trim() ===
                          ``
                  "
                  @input="resetCorrespondenceAdd"
                ></b-checkbox>

                <div>
                  <p class="note-line">
                    {{
                      $root.selectedLanguageData.correspondence_address_differs
                    }}
                  </p>
                </div>
              </b-field>
            </div>

            <!-- correspondence_address -->

            <div
              class="mb-5"
              v-if="
                kycFormStore.formData
                  .correspondence_address_differ_with_permanent_address
              "
            >
              <label>{{
                $root.selectedLanguageData.correspondence_address
              }}</label>
              <div class="columns is-4 is-variable mt-1">
                <div class="column is-two-fifths">
                  <div>
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.correspondent_address_line1.trim() ===
                          '' && !isCorAddLine1Focus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.building_number }}
                      </template>
                      <p class="control has-icons-right">
                        <b-input
                          rounded
                          type="text"
                          v-model="
                            kycFormStore.formData.correspondent_address_line1
                          "
                          @focus="
                            () => {
                              isCorAddLine1Focus = true;
                            }
                          "
                          @blur="
                            () => {
                              isCorAddLine1Focus = false;
                            }
                          "
                        />
                        <span class="icon is-small is-right">
                          <img
                            src="../assets/images/icons/bi_building.png"
                            alt="contact-name-img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                </div>
                <div class="column">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.correspondent_address_line2.trim() ===
                        '' && !isCorAddLine2Focus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.building_name }}
                    </template>
                    <p class="control has-icons-right">
                      <b-input
                        rounded
                        type="text"
                        v-model="
                          kycFormStore.formData.correspondent_address_line2
                        "
                        @focus="
                          () => {
                            isCorAddLine2Focus = true;
                          }
                        "
                        @blur="
                          () => {
                            isCorAddLine2Focus = false;
                          }
                        "
                      />
                      <span class="icon is-small is-right">
                        <img
                          :src=editImage
                          alt="contact-name-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>
              </div>
              <!-- second line -->
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <div>
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.correspondent_address_line3.trim() ===
                          '' && !isCorAddLine3Focus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.street
                        }}<span class="has-text-danger">*</span></template
                      >

                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="Street"
                          :rules="validateStreet()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData.correspondent_address_line3
                            "
                            @focus="
                              () => {
                                isCorAddLine3Focus = true;
                              }
                            "
                            @blur="
                              () => {
                                isCorAddLine3Focus = false;
                              }
                            "
                          />
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <span class="icon is-small is-right">
                          <img
                            :src=locationIcon
                            alt="contact-name-img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                </div>
                <!-- street 2 -->
                <div class="column is-half">
                  <div>
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.correspondence_address_street_2.trim() ===
                          '' && !isCorStreetFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.street2
                        }}</template
                      >

                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="Street"
                          :rules="validateNonReqText()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData.correspondence_address_street_2
                            "
                            @focus="
                              () => {
                                isCorStreetFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isCorStreetFocus = false;
                              }
                            "
                          />
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <span class="icon is-small is-right">
                          <img
                            :src=locationIcon
                            alt="contact-name-img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                </div>
                
              </div>
              <!-- third_line -->
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.correspondent_address_town.trim() ===
                        '' && !isCorAddTownFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.town
                      }}<span class="has-text-danger">*</span>
                    </template>
                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="Town"
                        :rules="validateStreet()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="text"
                          v-model="
                            kycFormStore.formData.correspondent_address_town
                          "
                          @focus="
                            () => {
                              isCorAddTownFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isCorAddTownFocus = false;
                            }
                          "
                        />
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          :src=locationIcon
                          alt="contact-name-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>
                <div class="column is-half">
                  <div>
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.correspondent_address_postal_code.trim() ===
                          '' && !isCorAddPostalCodeFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.postal_code }}
                      </template>
                      <p class="control has-icons-right">
                        <b-input
                          rounded
                          type="text"
                          v-model="
                            kycFormStore.formData
                              .correspondent_address_postal_code
                          "
                          @focus="
                            () => {
                              isCorAddPostalCodeFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isCorAddPostalCodeFocus = false;
                            }
                          "
                        />
                        <span class="icon is-small is-right">
                          <img
                            :src=editImage
                            alt="contact-name-img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                </div>                
              </div>
              <!-- country -->
              <div class="columns is-7 is-variable mt-1">
                <div
                  class="column is-half"
                  v-if="kycFormStore.formData.residentship === `Sri Lanka`"
                >
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        correspondentaddressdistrict.trim() === '' &&
                        !isCorAddDistrictFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.district
                        }}<span class="has-text-danger">*</span>
                      </template>

                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          @focus="
                            () => {
                              isCorAddDistrictFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isCorAddDistrictFocus = false;
                            }
                          "
                          v-model="correspondentaddressdistrict"
                          open-on-focus
                          :data="correspondentDistrictFilteredDataArray"
                          placeholder=""
                          icon=""
                          @select="
                            (option) => {
                              if (option) {
                                kycFormStore.formData.correspondent_address_district =
                                  option;
                                correspondentaddressdistrictcount = -1;
                              } else {
                                kycFormStore.formData.correspondent_address_district = ``;
                              }
                            }
                          "
                          @input="
                            () => {
                              ++correspondentaddressdistrictcount;
                              if (!correspondentaddressdistrict) {
                                isCorrespondentAddressDistrictTouched = false;
                                kycFormStore.formData.correspondent_address_district = ``;
                              } else if (
                                kycFormStore.formData
                                  .correspondent_address_district
                              ) {
                                if (correspondentaddressdistrictcount > 1) {
                                  isCorrespondentAddressDistrictTouched = true;
                                  kycFormStore.formData.correspondent_address_district = ``;
                                }
                              } else if (
                                correspondentaddressdistrictcount > 0
                              ) {
                                isCorrespondentAddressDistrictTouched = true;
                                kycFormStore.formData.correspondent_address_district = ``;
                              }
                            }
                          "
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoComleteDropDownIcon
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>
                    <span
                      class="has-text-danger"
                      v-if="
                        isCorrespondentAddressDistrictTouched &&
                        !kycFormStore.formData.correspondent_address_district
                      "
                      >You have to select a valid district</span
                    ><span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <!-- non srilankan district -->

                <div
                  class="column is-half"
                  v-if="kycFormStore.formData.residentship !== `Sri Lanka`"
                >
                  <div v-if="correspondentaddresscountry === `Sri Lanka`">
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          correspondentaddressdistrict.trim() === '' &&
                          !isCorAddDistrictFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                        class="mb-0"
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.district
                          }}<span class="has-text-danger">*</span>
                        </template>

                        <p class="control has-icons-right">
                          <b-autocomplete
                            rounded
                            @focus="
                              () => {
                                isCorAddDistrictFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isCorAddDistrictFocus = false;
                              }
                            "
                            v-model="correspondentaddressdistrict"
                            open-on-focus
                            :data="correspondentDistrictFilteredDataArray"
                            placeholder=""
                            icon=""
                            @select="
                              (option) => {
                                if (option) {
                                  kycFormStore.formData.correspondent_address_district =
                                    option;
                                  correspondentaddressdistrictcount = -1;
                                } else {
                                  kycFormStore.formData.correspondent_address_district = ``;
                                }
                              }
                            "
                            @input="
                              () => {
                                ++correspondentaddressdistrictcount;
                                if (!correspondentaddressdistrict) {
                                  isCorrespondentAddressDistrictTouched = false;
                                  kycFormStore.formData.correspondent_address_district = ``;
                                } else if (
                                  kycFormStore.formData
                                    .correspondent_address_district
                                ) {
                                  if (correspondentaddressdistrictcount > 1) {
                                    isCorrespondentAddressDistrictTouched = true;
                                    kycFormStore.formData.correspondent_address_district = ``;
                                  }
                                } else if (
                                  correspondentaddressdistrictcount > 0
                                ) {
                                  isCorrespondentAddressDistrictTouched = true;
                                  kycFormStore.formData.correspondent_address_district = ``;
                                }
                              }
                            "
                          >
                            <template #empty>No results found</template>
                          </b-autocomplete>
                          <span class="icon is-small is-right mx-2">
                            <img
                              :src=autoComleteDropDownIcon
                              alt="Stroke-img"
                              width="15"
                              height="15"
                            />
                          </span>
                        </p>
                      </b-field>
                      <span
                        class="has-text-danger"
                        v-if="
                          isCorrespondentAddressDistrictTouched &&
                          !kycFormStore.formData.correspondent_address_district
                        "
                        >You have to select a valid district</span
                      ><span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div v-if="correspondentaddresscountry != `Sri Lanka`">
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          kycFormStore.formData.correspondent_address_district.trim() ===
                            '' && !isCorAddDistrictFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.district
                          }}<span class="has-text-danger">*</span>
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData
                                .correspondent_address_district
                            "
                            @focus="
                              () => {
                                isCorAddDistrictFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isCorAddDistrictFocus = false;
                              }
                            "
                          />
                          <span class="icon is-small is-right">
                            <img
                              :src=editImage
                              alt="contact-name-img"
                              width="25"
                              height="25"
                            />
                          </span>
                        </p>
                      </b-field>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                <div class="column is-half">
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        correspondentaddresscountry.trim() === '' &&
                        !isCorAddCountryFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.country
                        }}<span class="has-text-danger">*</span></template
                      >

                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          v-model="correspondentaddresscountry"
                          open-on-focus
                          :data="correspondentCountryFilteredDataArray"
                          @focus="
                            () => {
                              isCorAddCountryFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isCorAddCountryFocus = false;
                            }
                          "
                          placeholder=""
                          icon=""
                          field="name"
                          @select="
                            (option) => {
                              if (option) {
                                kycFormStore.formData.correspondent_address_country =
                                  option.name;
                                correspondentaddresscountry = option.name;
                                correspondentaddresscountrycount = -1;
                              } else {
                                kycFormStore.formData.correspondent_address_country = ``;
                                correspondentaddresscountry = ``;
                              }
                            }
                          "
                          @input="
                            () => {
                              noEnrolementCountries(correspondentaddresscountry);
                              ++correspondentaddresscountrycount;
                              if (!correspondentaddresscountry) {
                                isCorrespondentAddressCountryTouched = false;
                                kycFormStore.formData.correspondent_address_country = ``;
                              } else if (correspondentCountryFilteredDataArray.includes(correspondentaddresscountry)) {
                                isCorrespondentAddressCountryTouched = false;
                                kycFormStore.formData.correspondent_address_country =
                                  correspondentaddresscountry;
                              } else if (
                                kycFormStore.formData
                                  .correspondent_address_country
                              ) {
                                if (correspondentaddresscountrycount > 1) {
                                  isCorrespondentAddressCountryTouched = true;
                                  kycFormStore.formData.correspondent_address_country = ``;
                                }
                              } else if (correspondentaddresscountrycount > 0) {
                                isCorrespondentAddressCountryTouched = true;
                                kycFormStore.formData.correspondent_address_country = ``;
                              }
                            }
                          "
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoComleteDropDownIcon
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>
                    <span
                      class="has-text-danger"
                      v-if="
                        isCorrespondentAddressCountryTouched &&
                        !kycFormStore.formData.correspondent_address_country
                      "
                      >You have to select a valid country</span
                    ><span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <!-- status_of_residence -->
            <div>
              <label
                >{{ $root.selectedLanguageData.status_of_residence
                }}<span class="has-text-danger">*</span></label
              >
              <ValidationProvider
                :rules="{ required: true }"
                v-slot="{ errors }"
              >
                <div class="columns two-column-parent">
                  <div class="column">
                    <b-field>
                      <b-radio
                        class="en-style"
                        name="status_of_residence"
                        native-value="House Holder"
                        v-model="kycFormStore.formData.status_of_residence"
                      >
                        {{ $root.selectedLanguageData.house_holder }}
                      </b-radio>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field>
                      <b-radio
                        class="en-style"
                        name="status_of_residence"
                        native-value="Living With Parents"
                        v-model="kycFormStore.formData.status_of_residence"
                      >
                        {{ $root.selectedLanguageData.living_with_parents }}
                      </b-radio>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field>
                      <b-radio
                        class="en-style"
                        name="status_of_residence"
                        native-value="Renter"
                        v-model="kycFormStore.formData.status_of_residence"
                      >
                        {{ $root.selectedLanguageData.renter }}
                      </b-radio>
                    </b-field>
                  </div>
                </div>
                <span class="has-text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <!-- mobile  number -->
            <div class="columns mb-5 is-7 is-variable">
              <div class="column is-two-fifths">
                <div>
                  <section>
                    <b-field>
                      <p class="control has-icons-right">
                        <b-autocomplete
                          size="is-medium"
                          maxlength="4"
                          v-model="userCode"
                          icon-pack="fi"
                          :icon="iconString"
                          open-on-focus
                          :data="filteredDataArr"
                          rounded
                          @input="
                            () => {
                              ++count;
                              if (count === 2) {
                                kycFormStore.formData.countryCode = ``;
                                isCountryCodeTouched = true;
                              }
                            }
                          "
                          @select="
                            (option) => {
                              noEnrolementCountriesNumber(option)
                              if (option) {
                                kycFormStore.formData.countryCode = `+${
                                  option.split(`+`)[1]
                                }`;
                                kycFormStore.formData.country_short_name =
                                  option.split(' ')[0];
                              } else {
                                kycFormStore.formData.countryCode = ``;
                              }

                              if (kycFormStore.formData.countryCode) {
                                changeSelection();
                                calPhoneNumber();
                              }
                            }
                          "
                        >
                        </b-autocomplete>
                        <span class="icon is-small is-right mr-2 mt-1">
                          <img
                            :src=autoComleteDropDownIcon
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                        <span
                          class="has-text-danger"
                          v-if="
                            isCountryCodeTouched &&
                            !kycFormStore.formData.countryCode
                          "
                          >You have to select a valid country code</span
                        >
                      </p>
                    </b-field>
                  </section>
                </div>
              </div>
              <div class="column">
                <b-field
                  label-position="inside"
                  :custom-class="
                    kycFormStore.formData.mobileNumber.trim() === '' &&
                    !isMobileNumberInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label
                    >{{ $root.selectedLanguageData.mobile_number
                    }}<span class="has-text-danger">*</span></template
                  >

                  <p class="control has-icons-right">
                    <ValidationProvider
                      name="Primary Mobile Number"
                      :rules="{ required: true, onlyNumbers: true }"
                      v-slot="{ errors }"
                    >
                      <b-input
                        v-model="kycFormStore.formData.mobileNumber"
                        @focus="
                          () => {
                            isMobileNumberInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isMobileNumberInputFocus = false;
                          }
                        "
                        @input="
                          () => {
                            isMobileNumberTouched = true;
                            mobileNumValidate();
                            calPhoneNumber();
                          }
                        "
                        rounded
                      ></b-input>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                      <span
                        class="has-text-danger"
                        v-if="isMobileNumberTouched && !isMobileNumberValid"
                        >Please enter a valid Mobile Number.
                      </span>
                      <span
                        class="has-text-danger"
                        v-if="isMobileNumberStartsWithZero"
                        >For LK - please enter your mobile number without the 0</span
                      >
                    </ValidationProvider>
                    <span class="icon is-small is-right">
                      <img
                        src="../assets/images/icons/Call.png"
                        alt="Call-img"
                        width="25"
                        height="25"
                        style="opacity: calc(60%)"
                      />
                    </span>
                  </p>
                </b-field>
              </div>
            </div>

            <!-- officeNumber -->
            <div class="columns mb-5 is-7 is-variable">
              <div class="column is-two-fifths">
                <div>
                  <section>
                    <b-field>
                      <p class="control has-icons-right">
                        <b-autocomplete
                          size="is-medium"
                          v-model="userOfficeCode"
                          maxlength="4"
                          open-on-focus
                          style="font-size: 2px !important"
                          placeholder="Select Country"
                          :data="officeFilteredDataArr"
                          rounded
                          icon-pack="fi"
                          :icon="officeIconString"
                          @input="
                            () => {
                              ++countThird;
                              if (countThird === 2) {
                                kycFormStore.formData.office_country_code = ``;
                                isOfficeCountryCodeTouched = true;
                              }
                            }
                          "
                          @select="
                            (option) => {
                              if (option) {
                                kycFormStore.formData.office_country_code = `${
                                  option.split(` `)[1]
                                }`;
                                kycFormStore.formData.office_country_short_name =
                                  option.split(' ')[0];
                              } else {
                                kycFormStore.formData.office_country_code = ``;
                                kycFormStore.formData.office_country_short_name = ``;
                                officeIconString = ``;
                              }

                              if (kycFormStore.formData.office_country_code) {
                                changeOfficeSelection();
                                calOfficeContactNumber();
                              }
                            }
                          "
                        >
                        </b-autocomplete>
                        <span class="icon is-small is-right mr-2 mt-1">
                          <img
                            :src=autoComleteDropDownIcon
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>

                        <span
                          class="has-text-danger"
                          v-if="
                            isOfficeCountryCodeTouched &&
                            !kycFormStore.formData.office_country_code &&
                            userOfficeCode
                          "
                          >You have to select a valid country code</span
                        >
                      </p>
                    </b-field>
                  </section>
                </div>
              </div>
              <div class="column">
                <b-field
                  label-position="inside"
                  :custom-class="
                    kycFormStore.formData.officeNumber.trim() === '' &&
                    !isOfficeNumberInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label
                    >{{ $root.selectedLanguageData.office_num }}
                  </template>

                  <p class="control has-icons-right">
                    <ValidationProvider
                      name="Mobile/Telephone Number"
                      :rules="{ onlyNumbers: true }"
                      v-slot="{ errors }"
                    >
                      <b-input
                        type="tel"
                        maxlength="15"
                        v-model="kycFormStore.formData.officeNumber"
                        @input="
                          () => {
                            isOfficeNumberTouched = true;
                            officeNumValidate();
                            calOfficeContactNumber();
                          }
                        "
                        @focus="
                          () => {
                            isOfficeNumberInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isOfficeNumberInputFocus = false;
                          }
                        "
                        rounded
                      ></b-input>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                      <span
                        class="has-text-danger"
                        v-if="
                          isOfficeNumberTouched &&
                          !isOfficeNumValid &&
                          kycFormStore.formData.officeNumber
                        "
                        >Please enter a valid Mobile/Telephone Numbers.
                      </span>
                      <span
                        class="has-text-danger"
                        v-if="isTelNumberStartsWithZero"
                        >For LK - please enter you LK Mobile/Telephone number without the 0</span
                      >
                    </ValidationProvider>
                    <span class="icon is-small is-right">
                      <img
                        src="../assets/images/icons/Call.png"
                        alt="Call-img"
                        width="25"
                        height="25"
                        style="opacity: calc(60%)"
                      />
                    </span>
                  </p>
                </b-field>
              </div>
            </div>
            <div class="mt-4">
              <b-field
                label-position="inside"
                :custom-class="
                  kycFormStore.formData.email.trim() === '' &&
                  !isEmailIdInputFocus
                    ? 'custom-float-label-initial'
                    : 'custom-float-label'
                "
              >
                <template #label
                  >{{ $root.selectedLanguageData.email_address
                  }}<span class="has-text-danger">*</span></template
                >
                <p class="control has-icons-right">
                  <ValidationProvider
                    :rules="{
                      email: true,
                      required: true,
                      lessOrEqualLength: 256,
                    }"
                    name="Primary Email ID"
                    v-slot="{ errors }"
                  >
                    <b-input
                      rounded
                      v-model="kycFormStore.formData.email"
                      @focus="
                        () => {
                          isEmailIdInputFocus = true;
                        }
                      "
                      @blur="
                        () => {
                          isEmailIdInputFocus = false;
                        }
                      "
                      @input="emailValidation()"
                    ></b-input>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <span class="icon is-small is-right">
                    <img
                      src="../assets/images/icons/Message.png"
                      alt="Call-img"
                      width="25"
                      height="25"
                    />
                  </span>
                </p>
              </b-field>
            </div>
            <p class="mb-4 mt-5 note-line">
              <span class="has-text-danger has-text-weight-semibold">{{
                $root.selectedLanguageData.note
              }}</span>
              {{ $root.selectedLanguageData.personali_info_note }}
            </p>
            <div class="mt-5">
              <b-field
                label-position="inside"
                :custom-class="
                  kycFormStore.formData.secondary_email.trim() === '' &&
                  !isSecEmailIdInputFocus
                    ? 'custom-float-label-initial'
                    : 'custom-float-label'
                "
              >
                <template #label>{{
                  $root.selectedLanguageData.aditional_email
                }}</template>
                <p class="control has-icons-right">
                  <ValidationProvider
                    :rules="{
                      email: true,
                      lessOrEqualLength: 256,
                    }"
                    name="Primary Email ID"
                    v-slot="{ errors }"
                  >
                    <b-input
                      rounded
                      v-model="kycFormStore.formData.secondary_email"
                      @focus="
                        () => {
                          isSecEmailIdInputFocus = true;
                        }
                      "
                      @blur="
                        () => {
                          isSecEmailIdInputFocus = false;
                        }
                      "
                      @input="secondaryEmailValidation()"
                    ></b-input>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <span class="icon is-small is-right">
                    <img
                      src="../assets/images/icons/Message.png"
                      alt="Call-img"
                      width="25"
                      height="25"
                    />
                  </span>
                </p>
              </b-field>
            </div>
            <div class="mt-5">
              <b-field
                label-position="inside"
                :custom-class="
                  kycFormStore.formData.maiden_name.trim() === '' &&
                  !isMaidenNameInputFocus
                    ? 'custom-float-label-initial'
                    : 'custom-float-label'
                "
              >
                <template #label
                  >{{ $root.selectedLanguageData.maiden_name
                  }}<span class="has-text-danger">*</span></template
                >

                <p class="control has-icons-right">
                  <ValidationProvider
                    name="Mother's Maiden Name"
                    :rules="validateName()"
                    v-slot="{ errors }"
                  >
                    <b-input
                      rounded
                      v-model="kycFormStore.formData.maiden_name"
                      type="name"
                      @focus="
                        () => {
                          isMaidenNameInputFocus = true;
                        }
                      "
                      @blur="
                        () => {
                          isMaidenNameInputFocus = false;
                        }
                      "
                    ></b-input>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                  <span class="icon is-small is-right">
                    <img
                      :src=contactImage
                      alt="Call-img"
                      width="25"
                      height="25"
                      style="opacity: calc(60%)"
                    />
                  </span>
                </p>
              </b-field>
            </div>
          </div>

          <div class="box form-padding py-5">
            <p class="sub_headings has-text-centered mb-4">
              {{ $root.selectedLanguageData.employment_details }}
            </p>
            <div class="columns is-7 is-variable">
              <div class="column is-half">
                <div>
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.employment_status === '' &&
                        !isEmploymentStatusFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.employment_status
                        }}<span class="has-text-danger">*</span></template
                      >

                      <b-select
                        expanded
                        rounded
                        v-model="kycFormStore.formData.employment_status"
                        :class="
                          kycFormStore.formData.employment_status === '' &&
                          !isEmploymentStatusFocus
                            ? 'arrow-up-before'
                            : 'arrow-up'
                        "
                        @focus="
                          () => {
                            isEmploymentStatusFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isEmploymentStatusFocus = false;
                          }
                        "
                        @input="resetEmpAddress"
                      >
                        <option
                          v-for="status in $root.selectedLanguageData
                            .employmentStatusList"
                          :key="status.value"
                          :value="status"
                        >
                          {{ status.langTranslation }}
                        </option>
                      </b-select>
                    </b-field>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>

            <!-- profession_nature_of_business -->
            <transition
              appear
              @before-enter="$animations.beforeEnterAbove"
              @enter="$animations.enterAbove"
            >
              <div
                class="mt-5"
                v-if="
                  kycFormStore.formData.employment_status.value ===
                    `Freelance` ||
                  kycFormStore.formData.employment_status.value ===
                    `Self-Employed`
                "
              >
                <b-field
                  label-position="inside"
                  :custom-class="
                    kycFormStore.formData.nature_of_business.trim() === '' &&
                    !isNatureOfBussinessInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label
                    >{{
                      $root.selectedLanguageData.profession_nature_of_business
                    }}<span
                      class="has-text-danger"
                      v-if="
                        kycFormStore.formData.employment_status.value ===
                        `Freelance`
                      "
                      >*</span
                    >
                  </template>
                  <p class="control has-icons-right">
                    <ValidationProvider
                      :rules="{
                        required:
                          kycFormStore.formData.employment_status.value ===
                          `Freelance`
                            ? true
                            : false,
                      }"
                      v-slot="{ errors }"
                    >
                      <b-input
                        rounded
                        v-model="kycFormStore.formData.nature_of_business"
                        @focus="
                          () => {
                            isNatureOfBussinessInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isNatureOfBussinessInputFocus = false;
                          }
                        "
                      ></b-input>
                      <span class="icon is-small is-right">
                        <img
                          src="../assets/images/icons/Message.png"
                          alt="Call-img"
                          width="25"
                          height="25"
                        />
                      </span>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </p>
                </b-field>
              </div>
            </transition>
            <!-- Employer / Business Name -->
            <transition
              appear
              @before-enter="$animations.beforeEnterAbove"
              @enter="$animations.enterAbove"
            >
              <div
                class="mt-5"
                v-if="
                  kycFormStore.formData.employment_status.value ===
                    `Full-Time` ||
                  kycFormStore.formData.employment_status.value ===
                    `Part Time` ||
                  kycFormStore.formData.employment_status.value ===
                    `Self-Employed` ||
                  kycFormStore.formData.employment_status.value === `Probation`
                "
              >
                <b-field
                  label-position="inside"
                  :custom-class="
                    kycFormStore.formData.employer_name.trim() === '' &&
                    !isEmployerNameInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label
                    >{{
                      $root.selectedLanguageData.employer_business
                    }}<span class="has-text-danger">*</span>
                  </template>
                  <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                      <b-input
                        rounded
                        v-model="kycFormStore.formData.employer_name"
                        @focus="
                          () => {
                            isEmployerNameInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isEmployerNameInputFocus = false;
                          }
                        "
                      ></b-input>
                      <span class="icon is-small is-right">
                        <img
                          :src=contactImage
                          alt="Call-img"
                          width="25"
                          height="25"
                          style="opacity: calc(60%)"
                        />
                      </span>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </p>
                </b-field></div
            ></transition>
            <transition
              appear
              @before-enter="$animations.beforeEnterAbove"
              @enter="$animations.enterAbove"
            >
              <div
                v-if="
                  kycFormStore.formData.employment_status.value === `Freelance`
                "
                class="mt-5"
              >
                <label
                  >{{ $root.selectedLanguageData.frelance_employer_business
                  }}<span class="has-text-danger">*</span></label
                >
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="columns is-mobile two-column-parent">
                    <div class="column">
                      <b-field>
                        <b-radio
                          class="en-style"
                          name="are_you_a_freelancer"
                          :native-value="true"
                          v-model="kycFormStore.formData.are_you_a_freelancer"
                        >
                          {{ $root.selectedLanguageData.yes }}
                        </b-radio>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-radio
                          class="en-style"
                          name="are_you_a_freelancer"
                          :native-value="false"
                          @click.native="
                            kycFormStore.formData.working_platforms = []
                          "
                          v-model="kycFormStore.formData.are_you_a_freelancer"
                        >
                          {{ $root.selectedLanguageData.no }}
                        </b-radio>
                      </b-field>
                    </div>
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div></transition
            >
            <transition
              appear
              @before-enter="$animations.beforeEnterAbove"
              @enter="$animations.enterAbove"
            >
              <div v-if="kycFormStore.formData.are_you_a_freelancer">
                <label
                  >{{ $root.selectedLanguageData.what_other_platforms
                  }}<span class="has-text-danger">*</span></label
                >

                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="columns work-list">
                    <div
                      class="work-list-item"
                      v-for="work in $root.selectedLanguageData.workeList"
                      :key="work.value"
                    >
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          v-model="kycFormStore.formData.working_platforms"
                          :native-value="work"
                          @change.native="platformChange"
                        >
                          {{ work.langTranslation }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div></transition
            >
            <transition
              appear
              @before-enter="$animations.beforeEnterAbove"
              @enter="$animations.enterAbove"
            >
              <div
                class="mt-4"
                v-if="
                  kycFormStore.formData.working_platforms.find(
                    (search) => search.value === `Other`
                  )
                "
              >
                <b-field
                  label-position="inside"
                  :custom-class="
                    kycFormStore.formData.specified_working_platforms.trim() ===
                      '' && !isWorkingPlatformInputFocus
                      ? 'custom-float-label-initial'
                      : 'custom-float-label'
                  "
                >
                  <template #label
                    >{{ $root.selectedLanguageData.please_specify
                    }}<span class="has-text-danger">*</span></template
                  >
                  <p class="control has-icons-right">
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-input
                        rounded
                        type="text"
                        v-model="
                          kycFormStore.formData.specified_working_platforms
                        "
                        @focus="
                          () => {
                            isWorkingPlatformInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isWorkingPlatformInputFocus = false;
                          }
                        "
                      />
                      <span class="icon is-small is-right">
                        <img
                          :src=editImage
                          alt="edit-img"
                          width="25"
                          height="25"
                        /> </span
                      ><span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </p>
                </b-field>
              </div></transition
            >
            <!-- BusinessAddress -->

            <div
              class="mt-5"
              v-if="
                kycFormStore.formData.employment_status.value === `Full-Time` ||
                kycFormStore.formData.employment_status.value === `Part Time` ||
                kycFormStore.formData.employment_status.value ===
                  `Self-Employed` ||
                kycFormStore.formData.employment_status.value === `Probation`
              "
            >
              <label>{{
                $root.selectedLanguageData.employer_business_address
              }}</label>
              <div class="columns is-4 is-variable mt-1">
                <div class="column is-two-fifths">
                  <div>
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.employer_address_line1.trim() ===
                          '' && !isEmAddLine1Focus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.building_number }}
                      </template>
                      <p class="control has-icons-right">
                        <b-input
                          rounded
                          type="text"
                          v-model="kycFormStore.formData.employer_address_line1"
                          @focus="
                            () => {
                              isEmAddLine1Focus = true;
                            }
                          "
                          @blur="
                            () => {
                              isEmAddLine1Focus = false;
                            }
                          "
                        />
                        <span class="icon is-small is-right">
                          <img
                            src="../assets/images/icons/bi_building.png"
                            alt="contact-name-img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                </div>
                <div class="column">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.employer_address_line2.trim() ===
                        '' && !isEmAddLine2Focus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.building_name }}
                    </template>
                    <p class="control has-icons-right">
                      <b-input
                        rounded
                        type="text"
                        v-model="kycFormStore.formData.employer_address_line2"
                        @focus="
                          () => {
                            isEmAddLine2Focus = true;
                          }
                        "
                        @blur="
                          () => {
                            isEmAddLine2Focus = false;
                          }
                        "
                      />
                      <span class="icon is-small is-right">
                        <img
                          :src=editImage
                          alt="contact-name-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>
              </div>
              <!-- second line -->
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <div>
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.employer_address_line3.trim() ===
                          '' && !isEmAddLine3Focus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.street
                        }}<span class="has-text-danger">*</span>
                      </template>
                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="Street"
                          :rules="{
                            notStartsWith: ` `,
                            notEndsWith: ` `,
                            notConsecutive: `  `,
                            required: true,
                          }"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData.employer_address_line3
                            "
                            @focus="
                              () => {
                                isEmAddLine3Focus = true;
                              }
                            "
                            @blur="
                              () => {
                                isEmAddLine3Focus = false;
                              }
                            "
                          />

                          <span class="icon is-small is-right">
                            <img
                              :src=locationIcon
                              alt="contact-name-img"
                              width="25"
                              height="25"
                            />
                          </span>

                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </p>
                    </b-field>
                  </div>
                </div>
                <div class="column is-half">
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.employer_address_town.trim() ===
                        '' && !isEmAddTownFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.town
                      }}<span class="has-text-danger">*</span>
                    </template>
                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="Town"
                        :rules="validateStreet()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="text"
                          v-model="kycFormStore.formData.employer_address_town"
                          @focus="
                            () => {
                              isEmAddTownFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isEmAddTownFocus = false;
                            }
                          "
                        />
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          :src=locationIcon
                          alt="contact-name-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>
              </div>
              <!-- third_line -->
              <div class="columns is-7 is-variable mt-1">
                <div class="column is-half">
                  <div>
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.employer_address_postal_code.trim() ===
                          '' && !isEmAddPostalCodeFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.postal_code }}
                      </template>
                      <p class="control has-icons-right">
                        <b-input
                          rounded
                          type="text"
                          v-model="
                            kycFormStore.formData.employer_address_postal_code
                          "
                          @focus="
                            () => {
                              isEmAddPostalCodeFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isEmAddPostalCodeFocus = false;
                            }
                          "
                        />
                        <span class="icon is-small is-right">
                          <img
                            :src=editImage
                            alt="contact-name-img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                </div>
                <div
                  class="column is-half"
                  v-if="
                    kycFormStore.formData.residentship === `Sri Lanka` ||
                    kycFormStore.formData.employer_address_country ===
                      'Sri Lanka'
                  "
                >
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        employeraddressdistrict.trim() === '' &&
                        !isEmAddDistrictFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.district
                        }}<span class="has-text-danger">*</span>
                      </template>
                      <p class="control has-icons-right">
                        <b-autocomplete
                          rounded
                          v-model="employeraddressdistrict"
                          open-on-focus
                          :data="empDistrictFilteredDataArray"
                          @focus="
                            () => {
                              isEmAddDistrictFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isEmAddDistrictFocus = false;
                            }
                          "
                          placeholder=""
                          icon=""
                          @select="
                            (option) => {
                              if (option) {
                                kycFormStore.formData.employer_address_district =
                                  option;
                                employeraddressdistrictcount = -1;
                              } else {
                                kycFormStore.formData.employer_address_district = ``;
                              }
                            }
                          "
                          @input="
                            () => {
                              ++employeraddressdistrictcount;
                              if (!employeraddressdistrict) {
                                isEmployerAddressDistrictTouched = false;
                                kycFormStore.formData.employer_address_district = ``;
                              } else if (
                                kycFormStore.formData.employer_address_district
                              ) {
                                if (employeraddressdistrictcount > 1) {
                                  isEmployerAddressDistrictTouched = true;
                                  kycFormStore.formData.employer_address_district = ``;
                                }
                              } else if (employeraddressdistrictcount > 0) {
                                isEmployerAddressDistrictTouched = true;
                                kycFormStore.formData.employer_address_district = ``;
                              }
                            }
                          "
                        >
                          <template #empty>No results found</template>
                        </b-autocomplete>
                        <span class="icon is-small is-right mx-2">
                          <img
                            :src=autoComleteDropDownIcon
                            alt="Stroke-img"
                            width="15"
                            height="15"
                          />
                        </span>
                      </p>
                    </b-field>
                    <span
                      class="has-text-danger"
                      v-if="
                        isEmployerAddressDistrictTouched &&
                        !kycFormStore.formData.employer_address_district
                      "
                      >You have to select a valid district</span
                    ><span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <!-- non resident district field -->
                <div
                  class="column is-half"
                  v-if="
                    kycFormStore.formData.residentship !== `Sri Lanka` &&
                    kycFormStore.formData.employer_address_country !=
                      'Sri Lanka'
                  "
                >
                  <div>
                    <ValidationProvider
                      :rules="{ required: true }"
                      v-slot="{ errors }"
                    >
                      <b-field
                        label-position="inside"
                        :custom-class="
                          kycFormStore.formData.employer_address_district.trim() ===
                            '' && !isEmAddDistrictFocus
                            ? 'custom-float-label-initial'
                            : 'custom-float-label'
                        "
                      >
                        <template #label
                          >{{ $root.selectedLanguageData.district
                          }}<span class="has-text-danger">*</span>
                        </template>
                        <p class="control has-icons-right">
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData.employer_address_district
                            "
                            @focus="
                              () => {
                                isEmAddDistrictFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isEmAddDistrictFocus = false;
                              }
                            "
                          />
                          <span class="icon is-small is-right">
                            <img
                              :src=editImage
                              alt="contact-name-img"
                              width="25"
                              height="25"
                            />
                          </span>
                        </p>
                      </b-field>
                      <span class="has-text-danger">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="mt-1 columns is-7 is-variable">
                <!-- country -->
                <div class="column is-half">
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      employeraddresscountry.trim() === '' &&
                      !isEmAddCountryFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    class="mb-0"
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.country }}
                      <span class="has-text-danger">*</span>
                    </template>
                    <p class="control has-icons-right">
                      <b-autocomplete
                        rounded
                        v-model="employeraddresscountry"
                        open-on-focus
                        :data="empCountryFilteredDataArray"
                        @focus="
                          () => {
                            isEmAddCountryFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isEmAddCountryFocus = false;
                          }
                        "
                        placeholder=""
                        icon=""
                        field="name"
                        @select="
                          (option) => {
                            if (option) {
                              kycFormStore.formData.employer_address_country =
                                option.name;
                              employeraddresscountry = option.name;
                              isEployerAddressCountryTouched = false;
                              employeraddresscountrycount = -1;
                            } else {
                              kycFormStore.formData.employer_address_country = ``;
                              employeraddresscountry = ``;
                            }
                          }
                        "
                        @input="
                          () => {
                            noEnrolementCountries(employeraddresscountry);
                            ++employeraddresscountrycount;
                            if (!employeraddresscountry) {
                              isEployerAddressCountryTouched = false;
                              kycFormStore.formData.employer_address_country = ``;
                            } else if (empCountryFilteredDataArray.includes(employeraddresscountry)) {
                              isEployerAddressCountryTouched = false;
                              kycFormStore.formData.employer_address_country =
                                employeraddresscountry;
                            } else if (
                              kycFormStore.formData.employer_address_country
                            ) {
                              if (employeraddresscountrycount > 1) {
                                isEployerAddressCountryTouched = true;
                                kycFormStore.formData.employer_address_country = ``;
                              }
                            } else if (employeraddresscountrycount > 0) {
                              isEployerAddressCountryTouched = true;
                              kycFormStore.formData.employer_address_country = ``;
                            }
                          }
                        "
                      >
                        <template #empty>No results found</template>
                      </b-autocomplete>
                      <span class="icon is-small is-right mx-2">
                        <img
                          :src=autoComleteDropDownIcon
                          alt="Stroke-img"
                          width="15"
                          height="15"
                        />
                      </span>
                    </p>
                  </b-field>
                  <span
                    class="has-text-danger"
                    v-if="
                      isEployerAddressCountryTouched &&
                      !kycFormStore.formData.employer_address_country
                    "
                    >You have to select a valid country</span
                  >
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
                </div>
              </div>
            </div>
          </div>
          <transition
            appear
            @before-enter="$animations.beforeEnterBelow"
            @enter="$animations.enterBelow"
          >
            <div class="box form-padding py-5">
              <p class="sub_headings has-text-centered mb-4">
                {{ $root.selectedLanguageData.tax_details }}
              </p>
              <p style="font-weight: 600; font-size: 17px" class="mb-2">
                {{ $root.selectedLanguageData.tax_details_info }}
              </p>
              <!-- are_you_a_taxpayer -->
              <div>
                <label
                  >{{ $root.selectedLanguageData.are_you_a_taxpayer
                  }}<span class="has-text-danger">*</span></label
                >
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="columns is-mobile two-column-parent">
                    <div class="column is-one-two">
                      <b-field>
                        <b-radio
                          class="en-style"
                          name="are_you_a_tax_payer"
                          :native-value="true"
                          v-model="kycFormStore.formData.are_you_a_taxpayer"
                        >
                          {{ $root.selectedLanguageData.yes }}
                        </b-radio>
                      </b-field>
                    </div>
                    <div class="column is-one-two">
                      <b-field>
                        <b-radio
                          class="en-style"
                          name="are_you_a_tax_payer"
                          @click.native="
                            kycFormStore.formData.tax_file_num = ``
                          "
                          :native-value="false"
                          v-model="kycFormStore.formData.are_you_a_taxpayer"
                        >
                          {{ $root.selectedLanguageData.no }}
                        </b-radio>
                      </b-field>
                    </div>
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <transition
                appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
              >
                <div
                  class="mt-4"
                  v-if="kycFormStore.formData.are_you_a_taxpayer === true"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.tax_file_num.trim() === '' &&
                      !isTaxFileNumberInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.tax_file_num
                      }}<span class="has-text-danger">*</span>
                    </template>
                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="Tax File Number"
                        :rules="{ min_value: 1, required: true }"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="number"
                          :min="1"
                          v-model="kycFormStore.formData.tax_file_num"
                          @focus="
                            () => {
                              isTaxFileNumberInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isTaxFileNumberInputFocus = false;
                            }
                          "
                        />
                        <span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          :src=editImage
                          alt="edit-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field></div
              ></transition></div
          ></transition>
          <transition
            appear
            @before-enter="$animations.beforeEnterBelow"
            @enter="$animations.enterBelow"
          >
            <div class="box form-padding py-5">
              <p class="sub_headings has-text-centered mb-4">
                {{ $root.selectedLanguageData.pep_declaration }}
              </p>
              <div>
                <label
                  >{{
                    $root.selectedLanguageData
                      .relative_a_politically_exposed_person
                  }}<span class="has-text-danger">*</span></label
                >
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="columns two-column-parent">
                    <div class="column">
                      <b-field>
                        <b-radio
                          class="en-style"
                          @input="resetPoliticalValue()"
                          name="Are You or Your close Relative a Politically Exposed Person"
                          native-value="im_politically_exposed"
                          v-model="kycFormStore.formData.politically_exposed"
                        >
                          {{
                            $root.selectedLanguageData.im_politically_exposed
                          }}
                        </b-radio>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-radio
                          class="en-style"
                          @input="resetPoliticalValue()"
                          name="Are You or Your close Relative a Politically Exposed Person"
                          native-value="my_close_relative_politically_exposed"
                          v-model="kycFormStore.formData.politically_exposed"
                        >
                          {{
                            $root.selectedLanguageData
                              .my_close_relative_politically_exposed
                          }}
                        </b-radio>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field>
                        <b-radio
                          class="en-style"
                          name="Are You or Your close Relative a Politically Exposed Person"
                          native-value="No"
                          @input="resetPoliticalValue()"
                          v-model="kycFormStore.formData.politically_exposed"
                        >
                          {{ $root.selectedLanguageData.no }}
                        </b-radio>
                      </b-field>
                    </div>
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <!-- im_politically_exposed -->
              <transition
                appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
              >
                <div
                  v-if="
                    kycFormStore.formData.politically_exposed ===
                    `im_politically_exposed`
                  "
                >
                  <!-- politically_exposed_person_name -->
                  <div class="mt-4">
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.politically_exposed_name.trim() ===
                          '' && !isPoliticallyExposedNameInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.name
                        }}<span class="has-text-danger">*</span>
                      </template>

                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="Name of PEP 01"
                          :rules="validateNotRequiredName()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData.politically_exposed_name
                            "
                            @focus="
                              () => {
                                isPoliticallyExposedNameInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPoliticallyExposedNameInputFocus = false;
                              }
                            "
                          />
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <span class="icon is-small is-right">
                          <img
                            :src=contactImage
                            alt="contact-img"
                            width="25"
                            height="25"
                            style="opacity: calc(60%)"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                  <!-- designation -->
                  <div class="mt-5">
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.politically_exposed_position.trim() ===
                          '' && !isPoliticallyExposedDesignationInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.designation
                        }}<span class="has-text-danger">*</span>
                      </template>
                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="Designation of PEP 01"
                          :rules="validateNotRequiredName()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData.politically_exposed_position
                            "
                            @focus="
                              () => {
                                isPoliticallyExposedDesignationInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPoliticallyExposedDesignationInputFocus = false;
                              }
                            "
                          />
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <span class="icon is-small is-right">
                          <img
                            :src=editImage
                            alt="Edit_img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                  <transition
                    appear
                    @before-enter="$animations.beforeEnterAbove"
                    @enter="$animations.enterAbove"
                  >
                    <div
                      class="icon-text mt-5"
                      :style="{
                        cursor: 'pointer',
                        color: '#F57E82',
                        'line-height': '20px',
                        'letter-spacing': '0.7px',
                      }"
                    >
                      <p
                        v-if="!isShow"
                        @click="visible()"
                        class="is-flex is-flex-direction-row is-align-content-center"
                      >
                        <span class="icon has-text-info">
                          <img
                            :src=criclePlus
                            alt="Circle_Plus image"
                          />
                        </span>
                        <span class="is-size-6-mobile mt-1">{{
                          $root.selectedLanguageData.add_another_person
                        }}</span>
                      </p>
                    </div></transition
                  >
                  <transition
                    appear
                    @before-enter="$animations.beforeEnterAbove"
                    @enter="$animations.enterAbove"
                  >
                    <div v-if="isShow">
                      <div
                        @click="
                          () => {
                            visibleHide();
                          }
                        "
                      >
                        <span
                          class="icon has-text-info mr-1"
                          :style="{
                            cursor: 'pointer',
                            color: '#F57E82',
                            'line-height': '20px',
                            'letter-spacing': '0.7px',
                          }"
                        >
                          <img
                            :src=circleMinus
                            alt="CircleMinus.png"
                          />
                        </span>
                        <span
                          :style="{
                            cursor: 'pointer',
                            color: '#F57E82',
                            'line-height': '20px',
                            'letter-spacing': '0.7px',
                          }"
                          class="is-size-6-mobile mt-1"
                          >{{
                            $root.selectedLanguageData.add_another_person
                          }}</span
                        >
                      </div>
                      <!-- politically_exposed_person_name -->
                      <div class="mt-4">
                        <b-field
                          label-position="inside"
                          :custom-class="
                            kycFormStore.formData.second_politically_exposed_name.trim() ===
                              '' && !isSecondPoliticallyExposedNameInputFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.name
                            }}<span class="has-text-danger">*</span>
                          </template>

                          <p class="control has-icons-right">
                            <ValidationProvider
                              name="Name of PEP 02"
                              :rules="validateNotRequiredName()"
                              v-slot="{ errors }"
                            >
                              <b-input
                                rounded
                                type="text"
                                v-model="
                                  kycFormStore.formData
                                    .second_politically_exposed_name
                                "
                                @focus="
                                  () => {
                                    isSecondPoliticallyExposedNameInputFocus = true;
                                  }
                                "
                                @blur="
                                  () => {
                                    isSecondPoliticallyExposedNameInputFocus = false;
                                  }
                                "
                              />
                              <span class="has-text-danger">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                            <span class="icon is-small is-right">
                              <img
                                :src=contactImage
                                alt="contact-img"
                                width="25"
                                height="25"
                                style="opacity: calc(60%)"
                              />
                            </span>
                          </p>
                        </b-field>
                      </div>
                      <!-- designation -->
                      <div class="mt-5">
                        <b-field
                          label-position="inside"
                          :custom-class="
                            kycFormStore.formData.second_politically_exposed_position.trim() ===
                              '' &&
                            !isSecondPoliticallyExposedDesignationInputFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.designation
                            }}<span class="has-text-danger">*</span>
                          </template>
                          <p class="control has-icons-right">
                            <ValidationProvider
                              name="Designation of PEP 02"
                              :rules="validateNotRequiredName()"
                              v-slot="{ errors }"
                            >
                              <b-input
                                rounded
                                type="text"
                                v-model="
                                  kycFormStore.formData
                                    .second_politically_exposed_position
                                "
                                @focus="
                                  () => {
                                    isSecondPoliticallyExposedDesignationInputFocus = true;
                                  }
                                "
                                @blur="
                                  () => {
                                    isSecondPoliticallyExposedDesignationInputFocus = false;
                                  }
                                "
                              />
                              <span class="has-text-danger">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                            <span class="icon is-small is-right">
                              <img
                                :src=editImage
                                alt="Edit_img"
                                width="25"
                                height="25"
                              />
                            </span>
                          </p>
                        </b-field>
                      </div></div
                  ></transition></div
              ></transition>

              <!-- my_close_relative_politically_exposed -->
              <transition
                appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
              >
                <div
                  v-if="
                    kycFormStore.formData.politically_exposed ===
                    `my_close_relative_politically_exposed`
                  "
                >
                  <!-- politically_exposed_person_name -->
                  <div class="mt-4">
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.politically_exposed_relative.trim() ===
                          '' && !isPoliticallyExposedRelativeNameInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.name
                        }}<span class="has-text-danger">*</span>
                      </template>

                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="Name of PEP 01"
                          :rules="validateNotRequiredName()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData.politically_exposed_relative
                            "
                            @focus="
                              () => {
                                isPoliticallyExposedRelativeNameInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPoliticallyExposedRelativeNameInputFocus = false;
                              }
                            "
                          />
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <span class="icon is-small is-right">
                          <img
                            :src=contactImage
                            alt="contact-img"
                            width="25"
                            height="25"
                            style="opacity: calc(60%)"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                  <!-- designation -->
                  <div class="mt-5">
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData.politically_exposed_relative_position.trim() ===
                          '' &&
                        !isPoliticallyExposedRelativeDesignationInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                    >
                      <template #label
                        >{{ $root.selectedLanguageData.designation
                        }}<span class="has-text-danger">*</span>
                      </template>
                      <p class="control has-icons-right">
                        <ValidationProvider
                          name="Designation of PEP 01"
                          :rules="validateNotRequiredName()"
                          v-slot="{ errors }"
                        >
                          <b-input
                            rounded
                            type="text"
                            v-model="
                              kycFormStore.formData
                                .politically_exposed_relative_position
                            "
                            @focus="
                              () => {
                                isPoliticallyExposedRelativeDesignationInputFocus = true;
                              }
                            "
                            @blur="
                              () => {
                                isPoliticallyExposedRelativeDesignationInputFocus = false;
                              }
                            "
                          />
                          <span class="has-text-danger">{{ errors[0] }}</span>
                        </ValidationProvider>
                        <span class="icon is-small is-right">
                          <img
                            :src=editImage
                            alt="Edit_img"
                            width="25"
                            height="25"
                          />
                        </span>
                      </p>
                    </b-field>
                  </div>
                  <transition
                    appear
                    @before-enter="$animations.beforeEnterAbove"
                    @enter="$animations.enterAbove"
                  >
                    <div
                      class="icon-text mt-5"
                      :style="{
                        cursor: 'pointer',
                        color: '#04c3fc',
                        'line-height': '20px',
                        'letter-spacing': '0.7px',
                      }"
                    >
                      <p
                        v-if="!isVisible"
                        @click="visibleRelative()"
                        class="is-flex is-flex-direction-row is-align-content-center"
                      >
                        <span class="icon has-text-info">
                          <img
                            :src=criclePlus
                            alt="Circle_Plus image"
                          />
                        </span>
                        <span class="is-size-6-mobile mt-1">{{
                          $root.selectedLanguageData.add_another_person
                        }}</span>
                      </p>
                    </div></transition
                  >
                  <transition
                    appear
                    @before-enter="$animations.beforeEnterAbove"
                    @enter="$animations.enterAbove"
                  >
                    <div v-if="isVisible">
                      <div
                        @click="
                          () => {
                            relativeHide();
                          }
                        "
                      >
                        <span
                          class="icon has-text-info mr-1"
                          :style="{
                            cursor: 'pointer',
                            color: '#04c3fc',
                            'line-height': '20px',
                            'letter-spacing': '0.7px',
                          }"
                        >
                          <img
                            :src=circleMinus
                            alt="CircleMinus.png"
                          />
                        </span>
                        <span
                          :style="{
                            cursor: 'pointer',
                            color: '#04c3fc',
                            'line-height': '20px',
                            'letter-spacing': '0.7px',
                          }"
                          class="is-size-6-mobile mt-1"
                          >{{
                            $root.selectedLanguageData.add_another_person
                          }}</span
                        >
                      </div>
                      <!-- politically_exposed_person_name -->
                      <div class="mt-4">
                        <b-field
                          label-position="inside"
                          :custom-class="
                            kycFormStore.formData.second_politically_exposed_relative.trim() ===
                              '' &&
                            !isSecondPoliticallyExposedRelativeNameInputFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.name
                            }}<span class="has-text-danger">*</span>
                          </template>

                          <p class="control has-icons-right">
                            <ValidationProvider
                              name="Name of PEP 02"
                              :rules="validateNotRequiredName()"
                              v-slot="{ errors }"
                            >
                              <b-input
                                rounded
                                type="text"
                                v-model="
                                  kycFormStore.formData
                                    .second_politically_exposed_relative
                                "
                                @focus="
                                  () => {
                                    isSecondPoliticallyExposedRelativeNameInputFocus = true;
                                  }
                                "
                                @blur="
                                  () => {
                                    isSecondPoliticallyExposedRelativeNameInputFocus = false;
                                  }
                                "
                              />
                              <span class="has-text-danger">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                            <span class="icon is-small is-right">
                              <img
                                :src=contactImage
                                alt="contact-img"
                                width="25"
                                height="25"
                                style="opacity: calc(60%)"
                              />
                            </span>
                          </p>
                        </b-field>
                      </div>
                      <!-- designation -->
                      <div class="mt-5">
                        <b-field
                          label-position="inside"
                          :custom-class="
                            kycFormStore.formData.second_politically_exposed_relative_position.trim() ===
                              '' &&
                            !isSecondPoliticallyExposedRelativeDesignationInputFocus
                              ? 'custom-float-label-initial'
                              : 'custom-float-label'
                          "
                        >
                          <template #label
                            >{{ $root.selectedLanguageData.designation
                            }}<span class="has-text-danger">*</span>
                          </template>
                          <p class="control has-icons-right">
                            <ValidationProvider
                              name="Designation of PEP 02"
                              :rules="validateNotRequiredName()"
                              v-slot="{ errors }"
                            >
                              <b-input
                                rounded
                                type="text"
                                v-model="
                                  kycFormStore.formData
                                    .second_politically_exposed_relative_position
                                "
                                @focus="
                                  () => {
                                    isSecondPoliticallyExposedRelativeDesignationInputFocus = true;
                                  }
                                "
                                @blur="
                                  () => {
                                    isSecondPoliticallyExposedRelativeDesignationInputFocus = false;
                                  }
                                "
                              />
                              <span class="has-text-danger">{{
                                errors[0]
                              }}</span>
                            </ValidationProvider>
                            <span class="icon is-small is-right">
                              <img
                                :src=editImage
                                alt="Edit_img"
                                width="25"
                                height="25"
                              />
                            </span>
                          </p>
                        </b-field>
                      </div></div
                  ></transition></div
              ></transition></div
          ></transition>
          <transition
            appear
            @before-enter="$animations.beforeEnterBelow"
            @enter="$animations.enterBelow"
          >
            <div class="box form-padding py-5">
              <p class="sub_headings has-text-centered mb-4">
                {{ $root.selectedLanguageData.financial_detail }}
              </p>
              <!-- source_of_income -->
              <div>
                <label
                  >{{ $root.selectedLanguageData.source_of_funds
                  }}<span class="has-text-danger">*</span></label
                >
                <ValidationProvider
                  :rules="{ required: true }"
                  v-slot="{ errors }"
                >
                  <div class="columns">
                    <div class="column is-one-two">
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          native-value="Employment Income"
                          v-model="kycFormStore.formData.source_of_income"
                        >
                          {{ $root.selectedLanguageData.employment_income }}
                        </b-checkbox>
                      </b-field>
                    </div>
                    <div class="column is-one-two">
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          native-value="Investment Proceeds"
                          v-model="kycFormStore.formData.source_of_income"
                        >
                          {{ $root.selectedLanguageData.investment_proceeds }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-one-two">
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          native-value="Gifts"
                          v-model="kycFormStore.formData.source_of_income"
                        >
                          {{ $root.selectedLanguageData.gifts }}
                        </b-checkbox>
                      </b-field>
                    </div>
                    <div class="column is-one-two">
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          native-value="Inheritance"
                          v-model="kycFormStore.formData.source_of_income"
                        >
                          {{ $root.selectedLanguageData.inheritance }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-one-two">
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          native-value="Commission Income"
                          v-model="kycFormStore.formData.source_of_income"
                        >
                          {{ $root.selectedLanguageData.commission_income }}
                        </b-checkbox>
                      </b-field>
                    </div>
                    <div class="column is-one-two">
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          native-value="Sale of Property / Asset"
                          v-model="kycFormStore.formData.source_of_income"
                        >
                          {{ $root.selectedLanguageData.sale_of_property }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-one-two">
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          native-value="Family Remittance"
                          v-model="kycFormStore.formData.source_of_income"
                        >
                          {{ $root.selectedLanguageData.family_remittance }}
                        </b-checkbox>
                      </b-field>
                    </div>
                    <div class="column is-one-two">
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          native-value="Donation"
                          v-model="kycFormStore.formData.source_of_income"
                        >
                          {{ $root.selectedLanguageData.donation }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-half">
                      <b-field>
                        <b-checkbox
                          class="en-style"
                          native-value="Other"
                          v-model="kycFormStore.formData.source_of_income"
                          @click.native="
                            kycFormStore.formData.specified_source_of_income = ``
                          "
                        >
                          {{ $root.selectedLanguageData.other }}
                        </b-checkbox>
                      </b-field>
                    </div>
                  </div>
                  <span class="has-text-danger">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <transition
                appear
                @before-enter="$animations.beforeEnterAbove"
                @enter="$animations.enterAbove"
              >
                <div
                  class="mt-4"
                  v-if="
                    kycFormStore.formData.source_of_income.includes('Other')
                  "
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.specified_source_of_income.trim() ===
                        '' && !isSourceOfIncomeInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                  >
                    <template #label
                      >{{ $root.selectedLanguageData.please_specify
                      }}<span class="has-text-danger">*</span></template
                    >
                    <p class="control has-icons-right">
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          type="text"
                          v-model="
                            kycFormStore.formData.specified_source_of_income
                          "
                          @focus="
                            () => {
                              isSourceOfIncomeInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isSourceOfIncomeInputFocus = false;
                            }
                          "
                        />
                        <span class="icon is-small is-right">
                          <img
                            :src=editImage
                            alt="edit-img"
                            width="25"
                            height="25"
                          /> </span
                        ><span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </p>
                  </b-field>
                </div></transition
              >
            </div></transition
          >

          <!-- button -->
          <transition
            appear
            @before-enter="$animations.beforeEnterBelow"
            @enter="$animations.enterBelow"
          >
            <div class="is-flex is-flex-direction-row-reverse mt-2 mb-4">
              <b-button
                class="is-primary is-rounded specific-continue-button-en py-5"
                @click="
                  () => {
                    setCorrespondenceAddress();
                    isModalActive = true;
                  }
                "
                :disabled="
                  invalid || isOneAddressForeign ||
                  !isOfficeNumValid || countryError ||
                  (kycFormStore.formData.residentship === `Sri Lanka` &&
                    !kycFormStore.formData.permanent_address_district) ||
                  !kycFormStore.formData.permanent_address_country ||
                  (kycFormStore.formData
                    .correspondence_address_differ_with_permanent_address &&
                    !kycFormStore.formData.correspondent_address_district &&
                    kycFormStore.formData.residentship === `Sri Lanka`) ||
                  (kycFormStore.formData
                    .correspondence_address_differ_with_permanent_address &&
                    !kycFormStore.formData.correspondent_address_country) ||
                  (kycFormStore.formData.residentship === `Sri Lanka` &&
                    employeraddressdistrict &&
                    !kycFormStore.formData.employer_address_district) ||
                  isEployerAddressCountryTouched ||
                  (kycFormStore.formData.residentship !== `Sri Lanka` &&
                    kycFormStore.formData.permanent_address_country ===
                      'Sri Lanka' &&
                    kycFormStore.formData.correspondent_address_country ===
                      'Sri Lanka') ||
                  isMobileNumberStartsWithZero ||
                  !isMobileNumberValid ||
                  (isCountryCodeTouched &&
                    !kycFormStore.formData.countryCode) ||
                  (kycFormStore.formData.customer_passport_no != '' &&
                    (kycFormStore.formData.passport_issue_date == null ||
                      kycFormStore.formData.passport_expiry_date == null))
                "
                >{{ $root.selectedLanguageData.continue }}
              </b-button>
              <b-button
                class="is-rounded specific-back-button-en py-5"
                @click="$router.push('userInfo')"
                >{{ $root.selectedLanguageData.back }}
              </b-button>
            </div></transition
          >
        </div>
      </form>
    </ValidationObserver>
    <!-- <GetHelp /> -->
    <b-modal v-model="isModalActive" scroll="keep" :width="540">
      <transition
        appear
        @before-enter="$animations.beforeEnterAbove"
        @enter="$animations.enterAbove"
      >
        <div class="model_box mt-3 mx-2">
          <div
            class="has-text-centered"
            style="transition-timing-function: ease-in-out"
          >
            <div class="modal-close-button is-pulled-right mb-6">
              <button
                class="delete"
                aria-label="close"
                @click="isModalActive = false"
              ></button>
            </div>
            <div>
              <img
                :src=completedImage
                responsive
                class="complete_img"
              />

              <p class="hero-subhead-line is-uppercase">
                {{ $root.selectedLanguageData.awesome }}
              </p>
              <div style="font-size: 20px; line-height: 22px" class="mt-2">
                {{ $root.selectedLanguageData.you_have_completed
                }}<span
                  style="font-family: Gotham-Medium"
                  class="mx-2 has-text-primary"
                  >60%</span
                >{{ $root.selectedLanguageData.of_the_form }}
              </div>
            </div>
            <div style="margin-inline: 14%">
              <b-button
                type="is-primary"
                class="py-5 mt-5"
                expanded
                rounded
                :loading="continueBtnLoading"
                @click="updateFormData()"
                >{{ $root.selectedLanguageData.continue }}
              </b-button>
            </div>
          </div>
        </div></transition
      >
    </b-modal>
  </div>
</template>
<script>
import logopage from "./CompanyTopLogo.vue";
import { BuefyHelper } from "@/heplers/buefyHelper";
// import GetHelp from "./GetHelp.vue";

import { useKycFormStore } from "@/stores/KycFormStore";
import { GlobalFunctions } from "@/heplers/globalFunctions";

export default {
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },
  components: {
    logopage,
    // GetHelp,
  },
  data() {
    return {
      completedImage :require('@/assets/images/icons/complete.png'),
      criclePlus : require("@/assets/images/icons/Circle-plus.png"),
      circleMinus : require("@/assets/images/icons/CircleMinus.png"),
      editImage : require("@/assets/images/icons/Edit.png"),
      contactImage : require("@/assets/images/icons/contact.png"),
      autoComleteDropDownIcon : require("@/assets/images/icons/Stroke-3.png"),
      locationIcon : require("@/assets/images/icons/Location.png"),
      countryError: false,
      maxDob: new Date(),
      isOneAddressForeign:false,
      count: 0,
      isShow: false,
      isVisible: false,
      continueBtnLoading: false,
      isModalActive: false,
      isTittleInputFocus: false,
      isFullNameInputFocus: false,
      isCitizenInputFocus: false,
      isNicInputFocus: false,
      isNicIssueInputFocus: false,
      isPassportInputFocus: false,
      isPassportIssueInputFocus: false,
      isPassportExpiryFocus: false,
      isPerAddLine1Focus: false,
      isPerAddLine2Focus: false,
      isPerAddLine3Focus: false,
      isStreetTwoFocus: false,
      isPerAddTownFocus: false,
      isPerAddPostalCodeFocus: false,
      isPerAddDistrictFocus: false,
      isPerAddCountryFocus: false,
      isCorAddDistrictFocus: false,
      isCorAddCountryFocus: false,
      isCorAddLine1Focus: false,
      isCorAddLine2Focus: false,
      isCorAddLine3Focus: false,
      isCorStreetFocus: false,
      isCorAddTownFocus: false,
      isWorkingPlatformInputFocus: false,
      isCorAddPostalCodeFocus: false,
      isNatureOfBussinessInputFocus: false,
      isEmploymentStatusFocus: false,
      isEmployerNameInputFocus: false,
      isEmAddLine1Focus: false,
      isEmAddLine2Focus: false,
      isEmAddLine3Focus: false,
      isEmAddTownFocus: false,
      isEmAddPostalCodeFocus: false,
      isEmAddDistrictFocus: false,
      isEmAddCountryFocus: false,
      isTaxFileNumberInputFocus: false,
      isPoliticallyExposedNameInputFocus: false,
      isPoliticallyExposedDesignationInputFocus: false,
      isSecondPoliticallyExposedNameInputFocus: false,
      isSecondPoliticallyExposedDesignationInputFocus: false,
      isPoliticallyExposedRelativeNameInputFocus: false,
      isPoliticallyExposedRelativeDesignationInputFocus: false,
      isSecondPoliticallyExposedRelativeNameInputFocus: false,
      isSecondPoliticallyExposedRelativeDesignationInputFocus: false,
      isMaidenNameInputFocus: false,
      isEmailIdInputFocus: false,
      isSecEmailIdInputFocus: false,
      isSourceOfIncomeInputFocus: false,
      maxDate: new Date(),
      minDate: new Date(),
      countriesArray: [],
      lang: "en",
      regex: {
        old_nic: /^([0-9]{9}[Xx||Vv])$/,
        passport: /^[0-9A-Za-z]{4,18}$/,
        new_nic: /[0-9]{12}/,
      },

      date: new Date(),

      countryCodeArray: [],
      isMobileNumberInputFocus: false,
      isOfficeNumberInputFocus: false,
      isResidentCountryCodeTouched: false,
      residentIconString: "",
      count2: 0,
      userResidentCode: "",
      userOfficeCode: "",
      isOfficeCountryCodeTouched: false,
      countThird: 0,
      officeIconString: "",
      isCountryCodeTouched: false,
      isMobileNumberTouched: false,
      isMobileNumberStartsWithZero: false,

      userCode: "",
      iconString: "",
      isMobileNumberValid: false,

      isOfficeNumberTouched: false,
      isOfficeNumValid: true,
      isTelNumberStartsWithZero: false,

      name: "",

      permanentaddressdistrictcount: 0,
      isPermanentAddressDistrictTouched: false,
      permanentaddressdistrict: "",
      permanentaddresscountrycount: 0,
      isPermanentAddressCountryTouched: false,
      permanentaddresscountry: "",
      correspondentaddressdistrictcount: 0,
      isCorrespondentAddressDistrictTouched: false,
      correspondentaddressdistrict: "",
      correspondentaddresscountrycount: 0,
      isCorrespondentAddressCountryTouched: false,
      correspondentaddresscountry: "",
      employeraddressdistrictcount: 0,
      isEmployerAddressDistrictTouched: false,
      employeraddressdistrict: "",
      employeraddresscountrycount: 0,
      isEployerAddressCountryTouched: false,
      employeraddresscountry: "",
      nicMinDate: new Date(),
      passportMinDate: new Date(),
      passportExpMinDate: new Date(),
      dobMinDate: new Date(),
      today: new Date(),
      // showTaxSection : false,
    };
  },
  methods: {
    // checkForResidency(){
    //   if(this.kycFormStore.formData.residentship != "Sri Lanka")
    //   {
    //     this.showTaxSection = false;
    //     this.kycFormStore.formData.are_you_a_taxpayer = false
    //   }
    //   else
    //   {
    //     this.showTaxSection = true;
    //   }
    // },
    setCorrespondenceAddress() {
      if (
        !this.kycFormStore.formData
          .correspondence_address_differ_with_permanent_address
      ) {
        this.kycFormStore.formData.correspondent_address_line1 =
          this.kycFormStore.formData.permanent_address_line1;
        this.kycFormStore.formData.correspondent_address_line2 =
          this.kycFormStore.formData.permanent_address_line2;
        this.kycFormStore.formData.correspondent_address_line3 =
          this.kycFormStore.formData.permanent_address_line3;
        this.kycFormStore.formData.correspondent_address_town =
          this.kycFormStore.formData.permanent_address_town;
        this.kycFormStore.formData.correspondent_address_postal_code =
          this.kycFormStore.formData.permanent_address_postal_code;
        this.kycFormStore.formData.correspondent_address_district =
          this.kycFormStore.formData.permanent_address_district;
        this.kycFormStore.formData.correspondent_address_country =
          this.kycFormStore.formData.permanent_address_country;
        this.kycFormStore.formData.correspondence_address_street_2 =
          this.kycFormStore.formData.street_2 ;
      }
    },
    // showPageRefreshWarning(event) {
    //   event.preventDefault();
    //   event.returnValue = "Data will be lost if you leave the page, are you sure?";
    // },
    checkBothAddress() {
      if (
        this.kycFormStore.formData.correspondence_address_differ_with_permanent_address
      ) {
        if (
          this.kycFormStore.formData.permanent_address_line1 ===
            this.kycFormStore.formData.correspondent_address_line1 &&
          this.kycFormStore.formData.permanent_address_line2 ===
            this.kycFormStore.formData.correspondent_address_line2 &&
          this.kycFormStore.formData.permanent_address_line3 ===
            this.kycFormStore.formData.correspondent_address_line3 &&
          this.kycFormStore.formData.permanent_address_town ===
            this.kycFormStore.formData.correspondent_address_town &&
          this.kycFormStore.formData.permanent_address_postal_code ===
            this.kycFormStore.formData.correspondent_address_postal_code &&
          this.kycFormStore.formData.permanent_address_district ===
            this.kycFormStore.formData.correspondent_address_district &&
          this.kycFormStore.formData.permanent_address_country ===
            this.kycFormStore.formData.correspondent_address_country && 
          this.kycFormStore.formData.street_2 ===
          this.kycFormStore.formData.correspondence_address_street_2
        ) {
          const params = {
            type: "is-danger",
            message:
              "Corresponding Address cannot be same as Permenent Address",
            duration: 5000,
          };
          this.$buefy.toast.open(params);
        }
      }
    },

    noEnrolementCountriesNumber(option){
      console.log("selected option = ", option)
      if(option == "MM +95" || option == "KP +850" || option == "IR +98" )
      {
        BuefyHelper.showToastMessage("Sorry! Selected country is not allowed for digital onboarding.","is-danger")
        this.countryError =true;
      }
      else
      {
        this.countryError =false;
      }
    },
    noEnrolementCountries(option){
      console.log("selected option = ", option)
      if(option == "Myanmar" || option == "Korea, Democratic People's Republic of Korea" || option == "Iran, Islamic Republic of Persian Gulf" )
      {
        BuefyHelper.showToastMessage("Sorry! Selected country is not allowed for digital onboarding.","is-danger")
        this.countryError =true;
      }
      else
      {
        this.countryError =false;
      }
    },
    checkAddressCountry() {
      if (this.kycFormStore.formData.residentship !== `Sri Lanka`) {
        if ((this.kycFormStore.formData.permanent_address_country ===  "Sri Lanka" || this.permanentaddresscountry == "Sri Lanka"))
        {
          if(this.kycFormStore.formData.correspondence_address_differ_with_permanent_address === false){
            const params = {
              type: "is-danger",
              message:
                "At least one of the addresses should be a foreign address",
              duration: 5000,
            };
            this.isOneAddressForeign = true
            this.$buefy.toast.open(params);
          }
          if(this.kycFormStore.formData.correspondence_address_differ_with_permanent_address === true){
            if(this.kycFormStore.formData.correspondent_address_country === "Sri Lanka" || this.correspondentaddresscountry === "Sri Lanka" )
            {
              const params = {
                type: "is-danger",
                message:
                  "At least one of the addresses should be a foreign address",
                duration: 5000,
              };
              this.isOneAddressForeign = true
              this.$buefy.toast.open(params);
            }
            else{
              this.isOneAddressForeign = false
            }
          }
        }
        else
        {
          this.isOneAddressForeign = false
        }
      }
    },

    platformChange() {
      if (
        !this.kycFormStore.formData.working_platforms.find(
          (search) => search.value === `Other`
        )
      ) {
        this.kycFormStore.formData.specified_working_platforms = "";
      }
    },
    changeAddressFields() {
      const comp = this;
      if (comp.kycFormStore.formData.permanent_address_country == "") {
        if (comp.kycFormStore.formData.residentship === "Sri Lanka") {
          comp.permanentaddresscountry = "Sri Lanka";
          comp.kycFormStore.formData.permanent_address_country = "Sri Lanka";
        } else {
          comp.permanentaddresscountry = "";
          comp.kycFormStore.formData.permanent_address_country = "";
        }
      }
    },

    mobileNumValidate: function () {
      if (this.kycFormStore.formData.countryCode === "+94") {
        const srilankanMobileRegEx = /^([7][0||1||2||4||5||6||7||8][0-9]{7})$/;

        this.isMobileNumberValid = srilankanMobileRegEx.test(
          this.kycFormStore.formData.mobileNumber
        );
      } else {
        const foreignMobileRegEx = /^[0-9]{0,15}$/;
        this.isMobileNumberValid = foreignMobileRegEx.test(
          this.kycFormStore.formData.mobileNumber
        );
      }
    },
    officeNumValidate: function () {
      if (this.kycFormStore.formData.officeNumber.trim()) {
        if (this.kycFormStore.formData.office_country_code === "+94") {
          const srilankanTelRegEx =
            /^(?:)?(?:(11|21|23|24|25|26|27|31|32|33|34|35|36|37|38|41|45|47|51|52|54|55|57|63|65|66|67|81|91)(0|2|3|4|5|7|9)|7(0|1|2|4|5|6|7|8)\d)\d{6}$/;

          this.isOfficeNumValid = srilankanTelRegEx.test(
            this.kycFormStore.formData.officeNumber
          );
        } else {
          const foreignMobileRegEx = /^[0-9]{0,15}$/;
          this.isOfficeNumValid = foreignMobileRegEx.test(
            this.kycFormStore.formData.officeNumber
          );
        }
      } else {
        this.isOfficeNumValid = true;
      }
    },

    resetEmpAddress() {
      if (
        (this.kycFormStore.formData.employment_status.value === `Full-Time` ||
          this.kycFormStore.formData.employment_status.value === `Part Time` ||
          this.kycFormStore.formData.employment_status.value ===
            `Self-Employed` ||
          this.kycFormStore.formData.employment_status.value === `Probation`) &&
        this.kycFormStore.formData.residentship === "Sri Lanka"
      ) {
        this.employeraddresscountry = "Sri Lanka";
        this.kycFormStore.formData.employer_address_country = "Sri Lanka";
      } else {
        this.employeraddresscountry = "";
        this.kycFormStore.formData.employer_address_country = "";
      }
      if (
        this.kycFormStore.formData.employment_status.value !== `Full-Time` &&
        this.kycFormStore.formData.employment_status.value !== `Part Time` &&
        this.kycFormStore.formData.employment_status.value !==
          `Self-Employed` &&
        this.kycFormStore.formData.employment_status.value !== `Probation` &&
        this.kycFormStore.formData.employment_status.value !== `Freelance`
      ) {
        this.kycFormStore.formData.nature_of_business = "";
      }
      if (
        this.kycFormStore.formData.employment_status.value !== `Full-Time` &&
        this.kycFormStore.formData.employment_status.value !== `Part Time` &&
        this.kycFormStore.formData.employment_status.value !==
          `Self-Employed` &&
        this.kycFormStore.formData.employment_status.value !== `Probation`
      ) {
        this.kycFormStore.formData.employer_name = "";
      }
      if (this.kycFormStore.formData.employment_status.value !== `Freelance`) {
        this.kycFormStore.formData.are_you_a_freelancer = null;
        this.kycFormStore.formData.working_platforms = [];
        this.kycFormStore.formData.specified_working_platforms = "";
      }
    },

    resetCorrespondenceAdd() {
      if (
        this.kycFormStore.formData.residentship === "Sri Lanka" &&
        this.kycFormStore.formData
          .correspondence_address_differ_with_permanent_address
      ) {
        this.correspondentaddresscountry = "Sri Lanka";
        this.kycFormStore.formData.correspondent_address_country = "Sri Lanka";
      } else {
        this.correspondentaddresscountry = "";
        this.kycFormStore.formData.correspondent_address_country = "";
      }
      this.kycFormStore.formData.correspondent_address_line1 = "";
      this.kycFormStore.formData.correspondent_address_line2 = "";
      this.kycFormStore.formData.correspondent_address_line3 = "";
      this.kycFormStore.formData.correspondent_address_town = "";
      this.kycFormStore.formData.correspondent_address_postal_code = "";
      this.kycFormStore.formData.correspondent_address_district = "";
      this.kycFormStore.formData.correspondence_address_street_2 = "";      
      this.correspondentaddressdistrict = "";
    },
    visible() {
      this.isShow = true;
    },
    visibleHide() {
      this.isShow = false;
      this.kycFormStore.formData.second_politically_exposed_name = "";
      this.kycFormStore.formData.second_politically_exposed_position = "";
    },
    visibleRelative() {
      this.isVisible = true;
    },
    relativeHide() {
      this.isVisible = false;
      this.kycFormStore.formData.second_politically_exposed_relative = "";
      this.kycFormStore.formData.second_politically_exposed_relative_position =
        "";
    },
    compare(a, b) {
      if (a.name_en < b.name_en) {
        return -1;
      }
      if (a.name_en > b.name_en) {
        return 1;
      }
      return 0;
    },
    resetPoliticalValue() {
      this.isVisible = false;
      this.isShow = false;
      this.kycFormStore.formData.politically_exposed_name = "";
      this.kycFormStore.formData.politically_exposed_position = "";
      this.kycFormStore.formData.second_politically_exposed_name = "";
      this.kycFormStore.formData.second_politically_exposed_position = "";
      this.kycFormStore.formData.politically_exposed_relative = "";
      this.kycFormStore.formData.politically_exposed_relative_position = "";
      this.kycFormStore.formData.second_politically_exposed_relative = "";
      this.kycFormStore.formData.second_politically_exposed_relative_position =
        "";
    },
    validateStreet: function () {
      const obj = {
        required: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    validateNonReqText: function () {
      const obj = {      
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    validateName: function () {
      const obj = {
        required: true,
        min: 3,
        alphaSpaces: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    validateNotRequiredName: function () {
      const obj = {
        alphaSpaces: true,
        required: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    passportValidateObject() {
      let obj = {
        required: true,
        notMatchRegex: [this.regex.old_nic, this.regex.new_nic],
        regex: this.regex.passport,
      };
      return obj;
    },
    calResidentialContactNumber() {
      this.kycFormStore.formData.contact_no_resident = `${this.kycFormStore.formData.resident_country_code}${this.kycFormStore.formData.residentNumber}`;
    },
    calOfficeContactNumber() {
      if (this.kycFormStore.formData.office_country_code === "+94") {
        console.log("LK Mobile number ---------------");
        const tempTelNumber = this.kycFormStore.formData.officeNumber.trim();
        if (tempTelNumber[0] === "0") {
          this.isTelNumberStartsWithZero = true;
        } else {
          this.isTelNumberStartsWithZero = false;
        }
      } else {
        this.isTelNumberStartsWithZero = false;
      }

      if (this.kycFormStore.formData.officeNumber) {
        this.kycFormStore.formData.telephone_no = `${this.kycFormStore.formData.office_country_code}${this.kycFormStore.formData.officeNumber}`;
      } else {
        this.kycFormStore.formData.telephone_no = ``;
      }
      this.officeNumValidate();
    },
    emailValidation() {
      this.kycFormStore.formData.email =
        this.kycFormStore.formData.email.toLowerCase();
    },

    secondaryEmailValidation() {
      this.kycFormStore.formData.secondary_email =
        this.kycFormStore.formData.secondary_email.toLowerCase();
    },

    changeResidetSelection() {
      this.residentIconString = `fi fi-${this.kycFormStore.formData.resident_country_short_name.toLowerCase()}`;
      console.log("resident icon : " + this.residentIconString);
    },

    changeOfficeSelection() {
      this.officeIconString = `fi fi-${this.kycFormStore.formData.office_country_short_name.toLowerCase()}`;
    },

    changeSelection() {
      this.iconString = `fi fi-${this.kycFormStore.formData.country_short_name.toLowerCase()}`;
    },

    calPhoneNumber() {
      if (this.kycFormStore.formData.countryCode === "+94") {
        console.log("LK Mobile number ---------------");
        const tempMobileNumber = this.kycFormStore.formData.mobileNumber.trim();
        if (tempMobileNumber[0] === "0") {
          this.isMobileNumberStartsWithZero = true;
        } else {
          this.isMobileNumberStartsWithZero = false;
        }
      } else {
        this.isMobileNumberStartsWithZero = false;
      }
      this.kycFormStore.formData.contact_no = `${this.kycFormStore.formData.countryCode}${this.kycFormStore.formData.mobileNumber}`;
      console.log(
        "Contact Number Formatted : " + this.kycFormStore.formData.contact_no
      );
    },

    updateFormData: function () {
      const comp = this;
      comp.continueBtnLoading = true;
      if (!this.kycFormStore.formData.officeNumber) {
        this.kycFormStore.formData.office_country_code = ``;
        this.kycFormStore.formData.office_country_short_name = ``;
        this.officeIconString = ``;
        this.kycFormStore.formData.telephone_no = ``;
      }
      if (!this.kycFormStore.formData.office_country_code) {
        this.kycFormStore.formData.officeNumber = ``;
        this.kycFormStore.formData.telephone_no = ``;
      }

      // check email or mobile number is changed by the customer depend on SL or non SL customer
      if (
        GlobalFunctions.CheckCustomerIsOutOfSriLanka(comp.kycFormStore) &&
        (comp.kycFormStore.formData.contact_no !==
          comp.kycFormStore.formData.verified_data.otp.verified_mobile_number ||
          comp.kycFormStore.formData.email !==
            comp.kycFormStore.formData.verified_data.otp.verified_email)
      ) {
        // non SL customer with changed mobile or email
        console.log(
          "email or mobile number is changed by customer - personal info (NON SL)"
        );

        comp.continueBtnLoading = false;
        comp.$router.push({
          path: "/enterEmailOtp",
          query: { next: "/accountSelection", previous: "/personalDetail" },
        });
      } else if (
        !GlobalFunctions.CheckCustomerIsOutOfSriLanka(comp.kycFormStore) &&
        comp.kycFormStore.formData.contact_no !==
          comp.kycFormStore.formData.verified_data.otp.verified_mobile_number
      ) {
        // sri lankan customer with changed mobile number
        console.log(
          "email or mobile number is changed by customer - personal info (SL)"
        );

        comp.continueBtnLoading = false;
        comp.$router.push({
          path: "/enterOtp",
          query: { next: "/accountSelection", previous: "/personalDetail" },
        });
      } else {
        // email or mobile is not changed by the customer (default flow)
        // update last step and navigate to next route

        GlobalFunctions.UpdateSecondLastStep(
          comp.kycFormStore,
          comp.$router,
          () => {
            comp.continueBtnLoading = false;
          }
        );

      }
    },
  },
  computed: {
    console: () => console,
    filteredDataArr() {
      console.log("computed called -------------------");
      console.log("computed user code : " + this.userCode);
      this.noEnrolementCountriesNumber(this.userCode)
      return this.countryCodeArray.filter((option) => {
        return option.toLowerCase().indexOf(this.userCode.toLowerCase()) >= 0;
      });
    },

    officeFilteredDataArr() {
      console.log("computed called -------------------");
      console.log("computed userOfficeCode code : " + this.userOfficeCode);
      this.noEnrolementCountriesNumber(this.userOfficeCode)
      return this.countryCodeArray.filter((option) => {
        return (
          option.toLowerCase().indexOf(this.userOfficeCode.toLowerCase()) >= 0
        );
      });
    },
    districtFilteredDataArray() {
      return this.kycFormStore.formDataSupport.districtsArray.filter(
        (option) => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.permanentaddressdistrict.toLowerCase()) >= 0
          );
        }
      );
    },
    correspondentDistrictFilteredDataArray() {
      return this.kycFormStore.formDataSupport.districtsArray.filter(
        (option) => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.correspondentaddressdistrict.toLowerCase()) >= 0
          );
        }
      );
    },
    countryFilteredDataArray() {
      this.console.log("computed permanentaddresscountry : ",this.permanentaddresscountry)
      this.noEnrolementCountries(this.permanentaddresscountry)
      return this.kycFormStore.formDataSupport.countriesArray.filter(
        (option) => {
          return (
            option.name
              .toString()
              .toLowerCase()
              .indexOf(this.permanentaddresscountry.toLowerCase()) >= 0
          );
        }
      );
    },
    correspondentCountryFilteredDataArray() {
      this.noEnrolementCountries(this.correspondentaddresscountry)
      return this.kycFormStore.formDataSupport.countriesArray.filter(
        (option) => {
          return (
            option.name
              .toString()
              .toLowerCase()
              .indexOf(this.correspondentaddresscountry.toLowerCase()) >= 0
          );
        }
      );
    },
    empCountryFilteredDataArray() {
      this.noEnrolementCountries(this.employeraddresscountry)
      return this.kycFormStore.formDataSupport.countriesArray.filter(
        (option) => {
          return (
            option.name
              .toString()
              .toLowerCase()
              .indexOf(this.employeraddresscountry.toLowerCase()) >= 0
          );
        }
      );
    },
    empDistrictFilteredDataArray() {
      return this.kycFormStore.formDataSupport.districtsArray.filter(
        (option) => {
          return (
            option
              .toString()
              .toLowerCase()
              .indexOf(this.employeraddressdistrict.toLowerCase()) >= 0
          );
        }
      );
    },
  },

  watch: {
    // "kycFormStore.formData.residentship":function(){
    //   this.checkForResidency();
    // },
    "kycFormStore.formData.correspondence_address_differ_with_permanent_address" :function(){
      this.checkAddressCountry();
    },
    "kycFormStore.formData.permanent_address_country": function () {
      this.checkAddressCountry();
      this.checkBothAddress();
      // this.nonsrilankanpermantcountry();
    },
    "kycFormStore.formData.correspondent_address_country": function () {
      this.checkAddressCountry();
      this.checkBothAddress();
    },
    "kycFormStore.formData.permanent_address_line1": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.correspondent_address_line1": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.permanent_address_line2": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.correspondent_address_line2": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.permanent_address_line3": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.correspondent_address_line3": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.permanent_address_town": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.correspondent_address_town": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.permanent_address_postal_code": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.correspondent_address_postal_code": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.permanent_address_district": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.correspondent_address_district": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.street_2": function () {
      this.checkBothAddress();
    },
    "kycFormStore.formData.correspondence_address_street_2": function () {
      this.checkBothAddress();
    },
  },

  // beforeDestroy() {
  //   window.removeEventListener('beforeunload', this.showPageRefreshWarning);
  // },

  mounted() {
    // this.checkForResidency();
    console.log("DATA IN KYCFORMSTORE: ",this.kycFormStore)
    this.setCorrespondenceAddress();
    //window.addEventListener('beforeunload', this.showPageRefreshWarning);

    this.checkAddressCountry();
    this.checkBothAddress();
    this.changeAddressFields();
    // permanent_address_district
    if (this.kycFormStore.formData.permanent_address_district) {
      this.permanentaddressdistrict =
        this.kycFormStore.formData.permanent_address_district;
    } else {
      this.permanentaddressdistrict = ``;
    }
    // permanent_address_country
    if (this.kycFormStore.formData.permanent_address_country) {
      this.permanentaddresscountry =
        this.kycFormStore.formData.permanent_address_country;
    } else {
      this.permanentaddresscountry = ``;
    }
    // correspondent_address_district
    if (this.kycFormStore.formData.correspondent_address_district) {
      this.correspondentaddressdistrict =
        this.kycFormStore.formData.correspondent_address_district;
    } else {
      this.correspondentaddressdistrict = ``;
    }
    // correspondent_address_country
    if (this.kycFormStore.formData.correspondent_address_country) {
      this.correspondentaddresscountry =
        this.kycFormStore.formData.correspondent_address_country;
    } else {
      this.correspondentaddresscountry = ``;
    }
    // employer_address_district
    if (this.kycFormStore.formData.employer_address_district) {
      this.employeraddressdistrict =
        this.kycFormStore.formData.employer_address_district;
    } else {
      this.employeraddressdistrict = ``;
    }
    //employer_address_country
    if (this.kycFormStore.formData.employer_address_country) {
      this.employeraddresscountry =
        this.kycFormStore.formData.employer_address_country;
    } else {
      this.employeraddresscountry = ``;
    }
    this.mobileNumValidate();
    this.officeNumValidate();

    console.log("nic issue date type : ==============");
    console.log(this.kycFormStore.formData.nic_issue_date);
    console.log("passport date type : ==============");
    console.log(typeof this.kycFormStore.formData.passport_issue_date);

    // politically_exposed
    if (
      this.kycFormStore.formData.second_politically_exposed_name ||
      this.kycFormStore.formData.second_politically_exposed_position
    ) {
      this.isShow = true;
    }
    // politically_Relative
    if (
      this.kycFormStore.formData.second_politically_exposed_relative ||
      this.kycFormStore.formData.second_politically_exposed_relative_position
    ) {
      this.isVisible = true;
    }

    for (const country of this.kycFormStore.formDataSupport.countriesArray) {
      this.countryCodeArray.push(`${country.code} ${country.dial_code}`);
    }

    console.log(
      "short country code name : " +
        this.kycFormStore.formData.country_short_name
    );

    const tempCountryCodeName =
      this.kycFormStore.formDataSupport.countriesArray.find(
        (country) =>
          country.code === this.kycFormStore.formData.country_short_name
      );

    console.log("country filtered + " + JSON.stringify(tempCountryCodeName));

    let tempUserCode =
      tempCountryCodeName.code + " " + this.kycFormStore.formData.countryCode;
    this.userCode = this.countryCodeArray.find(
      (hello) => hello === tempUserCode
    );

    this.changeSelection();

    this.changeResidetSelection();

    const tempOfficeCountryCodeName = this.kycFormStore.formData
      .office_country_short_name
      ? this.kycFormStore.formDataSupport.countriesArray.find(
          (countryCode) =>
            countryCode.code ===
            this.kycFormStore.formData.office_country_short_name
        )
      : "";

    let tempOfficeCode = tempOfficeCountryCodeName
      ? tempOfficeCountryCodeName.code +
        " " +
        this.kycFormStore.formData.office_country_code
      : "";

    this.userOfficeCode = tempOfficeCode
      ? this.countryCodeArray.find((hello) => hello === tempOfficeCode)
      : "";

    this.changeOfficeSelection();

    // age,gender,dob cal
    if (this.kycFormStore.formData.customer_nic) {
      let year;
      let genderValue;
      let monthAndDayInDays;
      if (this.kycFormStore.formData.customer_nic.length === 10) {
        genderValue = this.kycFormStore.formData.customer_nic.slice(2, 5);
        monthAndDayInDays =
          parseInt(genderValue, 10) >= 500
            ? parseInt(genderValue - 500, 10)
            : parseInt(genderValue, 10);
        year = parseInt(
          `19${this.kycFormStore.formData.customer_nic.slice(0, 2)}`,
          10
        );
      } else if (this.kycFormStore.formData.customer_nic.length === 12) {
        genderValue = this.kycFormStore.formData.customer_nic.slice(4, 7);
        monthAndDayInDays =
          parseInt(genderValue, 10) >= 500
            ? parseInt(genderValue - 500, 10)
            : parseInt(genderValue, 10);
        year = parseInt(
          `${this.kycFormStore.formData.customer_nic.slice(0, 4)}`,
          10
        );
      }
      let month;
      let day;
      if (monthAndDayInDays <= 31) {
        month = "01";
        if (monthAndDayInDays < 10) {
          day = `0${monthAndDayInDays}`;
        } else {
          day = monthAndDayInDays;
        }
      } else if (monthAndDayInDays <= 60) {
        month = "02";
        day = monthAndDayInDays - 31;
      } else if (monthAndDayInDays <= 91) {
        month = "03";
        day = monthAndDayInDays - 60;
      } else if (monthAndDayInDays <= 121) {
        month = "04";
        day = monthAndDayInDays - 91;
      } else if (monthAndDayInDays <= 152) {
        month = "05";
        day = monthAndDayInDays - 121;
      } else if (monthAndDayInDays <= 182) {
        month = "06";
        day = monthAndDayInDays - 152;
      } else if (monthAndDayInDays <= 213) {
        month = "07";
        day = monthAndDayInDays - 182;
      } else if (monthAndDayInDays <= 244) {
        month = "08";
        day = monthAndDayInDays - 213;
      } else if (monthAndDayInDays <= 274) {
        month = "09";
        day = monthAndDayInDays - 244;
      } else if (monthAndDayInDays <= 305) {
        month = "10";
        day = monthAndDayInDays - 274;
      } else if (monthAndDayInDays <= 335) {
        month = "11";
        day = monthAndDayInDays - 305;
      } else {
        month = "12";
        day = monthAndDayInDays - 335;
      }

      //check date of birth feild is edit or not
      console.log(
        new Date(this.kycFormStore.formData.date_of_birth) == new Date(),
        this.kycFormStore.formData.date_of_birth,
        new Date(),
        "dob set start"
      );
      console.log(
        "check dob cal",
        !this.kycFormStore.isDateOfBirthSet,
        this.kycFormStore.formData.date_of_birth == "",
        this.kycFormStore.isNicChange
      );
      if (
        !this.kycFormStore.isDateOfBirthSet ||
        this.kycFormStore.formData.date_of_birth == "" ||
        this.kycFormStore.isNicChange
      ) {
        this.kycFormStore.formData.date_of_birth = new Date(
          `${year.toString()}-${month}-${day}`
        );
        this.kycFormStore.isDateOfBirthSet = true;
        this.kycFormStore.isNicChange = false;
      }

      if (
        !this.kycFormStore.isGenderSet ||
        this.kycFormStore.formData.gender == "" ||
        this.kycFormStore.isNicChange
      ) {
        const intGenderValue = parseInt(genderValue, 10);
        if (intGenderValue >= 500) {
          this.kycFormStore.formData.gender = "Female";
        } else {
          this.kycFormStore.formData.gender = "Male";
        }
        this.kycFormStore.isGenderSet = true;
        this.kycFormStore.isNicChange = false;
      }
      // calculate month difference from current date in time
      var month_diff =
        Date.now() - this.kycFormStore.formData.date_of_birth.getTime();
      // convert the calculated difference in date format
      var age_dt = new Date(month_diff);
      // extract year from date
      var b_year = age_dt.getUTCFullYear();
      // now calculate the age of the user
      this.kycFormStore.formData.age = Math.abs(b_year - 1970);
      // display the calculated age
      // document.write("Age of the date entered: " + age + " years");
      // console.log(age);

      this.nicMinDate = this.kycFormStore.formData.date_of_birth;
      this.passportMinDate = this.kycFormStore.formData.date_of_birth;
      //this.passportExpMinDate= this.kycFormStore.formData.date_of_birth;
      this.dobMinDate = new Date(
        this.today.getFullYear() - 120,
        this.today.getMonth(),
        this.today.getDate()
      );
    }
  },
};
</script>
<style scoped>
.complete_img {
  mix-blend-mode: darken;
  width: 250px;
}
.date-picking .b-datepicker-placeholder {
  font-size: 6px; /* Adjust the font size as needed */
}
</style>
