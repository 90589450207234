<template>
    <!-- list items -->
    <div :class="GetWrapperClass">
      <span style="font-family: Gotham-Light; font-weight: bold; color: black;" >{{label}}</span>
  
      <div style="margin-top: 10px">
        <div style="display: grid; margin-left: 10px; ">
          <ul :class="GetItemClass" >
            <li v-for="(item,index) in items" :key="index" >{{item}}</li>
          </ul>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "DisplayBulletList",
  
    props: {
      isAgent : {
        type : Boolean,
        required : true,
        default : false
      },
  
      items:{
        type: Array,
        required : true
      },
  
      label :{
        type : String,
        required : true
      }
  
    },
  
    computed : {
      GetWrapperClass(){
        const comp = this
        return comp.isAgent ? 'wrapper-agent' : 'wrapper-customer';
      },
  
      GetItemClass(){
        const comp = this
        return comp.isAgent ? 'item-agent' : 'item-customer';
      }
    },
  
  }
  </script>
  
  <style scoped>
  
    .wrapper-customer {
      margin-top : 10px;
      padding: 10px;
      border: 1px solid black;
      border-radius: 20px;
    }
  
    .wrapper-agent {
      margin-top: 10px;
      padding: 10px;
      border: 1px solid red;
      border-radius: 20px;
    }
  
    .item-customer {
      color: black;
    } 
  
    .item-agent {
      color: red;
    }
    ul li::before {
      content: "\2022";
      font-weight: bold;
      display: inline-block;
    }

  </style>