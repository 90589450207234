<template>
  <div>
    <transition
      appear
      @before-enter="$animations.beforeEnterLogoArea"
      @enter="$animations.enterLogoArea"
    >
      <logopage
    /></transition>
    <transition
      appear
      @before-enter="$animations.beforeEnterFade"
      @enter="$animations.enterFade"
    >
      <div class="columns is-centered mb-0">
        <div
          v-if="$root.selectedLanguage === `en`"
          class="column"
          style="text-align: center"
        >
          <img
            src="../assets/images/header-img/additional_service.png"
            alt="additional_service-img"
            class="page-header-img"
          />
        </div></div
    ></transition>
    <transition
      appear
      @before-enter="$animations.beforeEnterFade"
      @enter="$animations.enterFade"
    >
      <div class="columns is-centered mb-0">
        <div class="column is-half pt-2 ps-5" style="text-align: center">
          <p class="subhead-line is-size-4" style="line-height: 20px">
            <img
              src="../assets/images/icons/additional_service_hand.png"
              alt="additional_service_hand"
              width="15%"
            />
          </p>
          <p class="headline-font" style="line-height: 42px">
            {{ $root.selectedLanguageData.additional_services }}
          </p>
          <p class="specific-line">
            {{ $root.selectedLanguageData.hi }}
            <span
              :style="{
                'text-justify': 'inter-word',
                'word-break': 'break-all',
              }"
              >{{ kycFormStore.formData.calling_name || "Charith" }},</span
            >
            {{ $root.selectedLanguageData.required_services }}
          </p>
        </div>
      </div></transition
    >
    <ValidationObserver v-slot="{ invalid }">
      <form @submit.prevent="onSubmit">
        <div class="form-body mt-3">
          <div class="box form-padding pb-5" style="padding-top: 0% !important">
            <p class="has-text-centered pt-4 mb-4 astric-font-size">
              <span class="has-text-danger mr-2">{{
                $root.selectedLanguageData.note
              }}</span
              ><b class="has-text-danger">*</b
              ><span class="text-transparency">
                Indicates mandatory fields that are required</span
              >
            </p>
            <!-- sms -->
            <div class="is-flex is-justify-content-space-between">
              <div>
                <label
                  >{{ $root.selectedLanguageData.sms
                  }}<span class="has-text-danger">*</span></label
                >
              </div>
              <div>
                <div
                  id="sms-toggle-buttons"
                  class="toggle-button toggle-change has-text-centered"
                  style="
                    background-color: #f9f9f9;
                    opacity: 0.5;
                    cursor: not-allowed;
                  "
                >
                  <div
                    id="sms-yes-button"
                    class="yes yes-active is-size-5 toggle-common"
                    @click="smsYesClick()"
                  >
                    {{ $root.selectedLanguageData.yes }}
                  </div>
                  <div
                    id="sms-no-button"
                    class="no no-active is-size-5 toggle-common"
                    @click="
                      () => {
                        // smsNoClick();
                      }
                    "
                  >
                    {{ $root.selectedLanguageData.no }}
                  </div>
                </div>
              </div>
            </div>
            <!-- ebanking  -->
            <div class="is-flex is-justify-content-space-between mt-5">
              <div>
                <label
                  >{{ $root.selectedLanguageData.ebanking
                  }}<span class="has-text-danger">*</span></label
                >
              </div>
              <div>
                <div
                  id="e-banking-toggle-buttons"
                  class="toggle-button toggle-change has-text-centered"
                  style="
                    background-color: #f9f9f9;
                    opacity: 0.5;
                    cursor: not-allowed;
                  "
                >
                  <div
                    id="e-banking-yes-button"
                    class="yes yes-active is-size-5 toggle-common"
                    @click="eBankingYesClick()"
                  >
                    {{ $root.selectedLanguageData.yes }}
                  </div>
                  <div
                    id="e-banking-no-button"
                    class="no no-active is-size-5 toggle-common"
                    @click="
                      () => {
                        // eBankingNoClick();
                      }
                    "
                  >
                    {{ $root.selectedLanguageData.no }}
                  </div>
                </div>
              </div>
            </div>
            <!-- Virtual Wallet -->
            <div v-if="GlobalFunctions.CheckVirtualVaultSectionVisible(kycFormStore)">
              <div class="is-flex is-justify-content-space-between mt-5">
                <div>
                  <label
                    >{{ $root.selectedLanguageData.virtualWallet
                    }}<span class="has-text-danger">*</span></label
                  >
                </div>
                <div>
                  <div
                    id="virtual-wallet-toggle-buttons"
                    class="toggle-button toggle-change has-text-centered"
                    style="
                      background-color: #f9f9f9;
                      opacity: 0.5;
                      cursor: not-allowed;
                    "
                  >
                    <div
                      id="virtual-wallet-yes-button"
                      class="yes yes-active is-size-5 toggle-common"
                      @click="virtualWalletYesClick()"
                    >
                      {{ $root.selectedLanguageData.yes }}
                    </div>
                    <div
                      id="virtual-wallet-no-button"
                      class="no no-active is-size-5 toggle-common"
                      @click="
                        () => {
                         // virtualWalletNoClick();
                        }
                      "
                    >
                      {{ $root.selectedLanguageData.no }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Estatement -->
            <div class="is-flex is-justify-content-space-between mt-5">
              <div>
                <label
                  >{{ $root.selectedLanguageData.estatement
                  }}<span class="has-text-danger">*</span></label
                >
              </div>
              <div>
                <div
                  id="estatement-toggle-buttons"
                  class="toggle-button toggle-change has-text-centered"
                  style="
                    background-color: #f9f9f9;
                    opacity: 0.5;
                    cursor: not-allowed;
                  "
                >
                  <div
                    id="estatement-yes-button"
                    class="yes yes-active is-size-5 toggle-common"
                    @click="estatementYesClick()"
                  >
                    {{ $root.selectedLanguageData.yes }}
                  </div>
                  <div
                    id="estatement-no-button"
                    class="no no-active is-size-5 toggle-common"
                    @click="
                      () => {
                        // estatementNoClick();
                      }
                    "
                  >
                    {{ $root.selectedLanguageData.no }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Debit Card* -->
            <div
              v-if="GlobalFunctions.CheckDebitCardSectionVisible(kycFormStore)"
            >
              <div class="is-flex is-justify-content-space-between mt-5">
                <div>
                  <label
                    >{{ $root.selectedLanguageData.debitCard
                    }}<span class="has-text-danger">*</span></label
                  >
                </div>
                <div>
                  <div
                    id="debit_card-toggle-buttons"
                    class="toggle-button toggle-change has-text-centered"
                    style="background-color: #f9f9f9"
                  >
                    <div
                      id="debit_card-yes-button"
                      class="yes yes-active is-size-5 toggle-common"
                      @click="debitCardYesClick"
                    >
                      {{ $root.selectedLanguageData.yes }}
                    </div>
                    <div
                      id="debit_card-no-button"
                      class="no no-active is-size-5 toggle-common"
                      @click="
                        () => {
                          debitCardNoClick();
                        }
                      "
                    >
                      {{ $root.selectedLanguageData.no }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- if debit card Yes bellow section will appear -->
              <div v-if="kycFormStore.formData.debit_card_service">
                <!-- POS Required* -->
                <div class="mt-6">
                  <label
                    >{{ $root.selectedLanguageData.posRequest
                    }}<span class="has-text-danger">*</span></label
                  >

                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns">
                      <div class="column is-one-two">
                        <div class="columns">
                          <div class="column is-one-two">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="POS Required"
                                :native-value="true"
                                v-model="kycFormStore.formData.pos_required"
                              >
                                {{ $root.selectedLanguageData.yes }}
                              </b-radio></b-field
                            >
                          </div>
                          <div class="column is-one-two">
                            <b-field>
                              <b-radio
                                class="en-style"
                                name="POS Required"
                                :native-value="false"
                                v-model="kycFormStore.formData.pos_required"
                              >
                                {{ $root.selectedLanguageData.no }}
                              </b-radio></b-field
                            >
                          </div>
                        </div>
                      </div>
                      <div class="column is-one-two"></div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div
                  v-if="kycFormStore.formData.pos_required === true"
                  class="mb-5"
                >
                  <ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <b-field
                      label-position="inside"
                      :custom-class="
                        kycFormStore.formData
                          .account_to_be_linked_for_pos_transaction === '' &&
                        !isPosTransactionInputFocus
                          ? 'custom-float-label-initial'
                          : 'custom-float-label'
                      "
                      class="mb-0"
                    >
                      <template #label
                        >{{
                          $root.selectedLanguageData
                            .account_to_be_linked_for_pos
                        }}<span class="has-text-danger">*</span></template
                      >
                      <b-select
                        expanded
                        rounded
                        v-model="
                          kycFormStore.formData
                            .account_to_be_linked_for_pos_transaction
                        "
                        :class="
                          kycFormStore.formData
                            .account_to_be_linked_for_pos_transaction === '' &&
                          !isPosTransactionInputFocus
                            ? 'arrow-up-before'
                            : 'arrow-up'
                        "
                        @focus="
                          () => {
                            isPosTransactionInputFocus = true;
                          }
                        "
                        @blur="
                          () => {
                            isPosTransactionInputFocus = false;
                          }
                        "
                      >
                        <option
                          v-for="account in GlobalFunctions.GetOnlyLKRAccounts(
                            kycFormStore
                          )"
                          :key="account"
                          :value="account"
                        >
                          {{ account }}
                        </option></b-select
                      > </b-field
                    ><span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <div
                  style="margin-top: 26px"
                  v-if="kycFormStore.formData.debit_card_service === true"
                >
                  <b-field
                    label-position="inside"
                    :custom-class="
                      kycFormStore.formData.facility_debit_card_customer_name.trim() ===
                        '' && !isCustomerNameInputFocus
                        ? 'custom-float-label-initial'
                        : 'custom-float-label'
                    "
                    ><template #label
                      ><span class="hi">{{
                        $root.selectedLanguageData.name_to_be_printed_on_card
                      }}</span>
                      <span class="has-text-danger">*</span></template
                    >

                    <p class="control has-icons-right">
                      <ValidationProvider
                        name="Name to be printed on Card"
                        :rules="validateName()"
                        v-slot="{ errors }"
                      >
                        <b-input
                          rounded
                          maxlength="21"
                          v-model="
                            kycFormStore.formData
                              .facility_debit_card_customer_name
                          "
                          @focus="
                            () => {
                              isCustomerNameInputFocus = true;
                            }
                          "
                          @blur="
                            () => {
                              isCustomerNameInputFocus = false;
                            }
                          "
                        ></b-input
                        ><span class="has-text-danger">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <span class="icon is-small is-right">
                        <img
                          :src=editImage
                          alt="edit-img"
                          width="25"
                          height="25"
                        />
                      </span>
                    </p>
                  </b-field>
                </div>

                <!-- Collection Details -->
                <div>
                  <label
                    >{{ $root.selectedLanguageData.collectionDetails
                    }}<span class="has-text-danger">*</span></label
                  ><ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns">
                      <div class="column">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="Collection Details "
                            native-value="Branch"
                            v-model="kycFormStore.formData.collection_details"
                          >
                            {{ $root.selectedLanguageData.branch }}
                          </b-radio></b-field
                        >
                      </div>
                      <div class="column">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="Collection Details "
                            native-value="Residence Address"
                            v-model="kycFormStore.formData.collection_details"
                          >
                            {{ $root.selectedLanguageData.resident }}
                          </b-radio></b-field
                        >
                      </div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <!-- Pin Collection Details -->
                <div class="mt-3">
                  <label
                    >{{ $root.selectedLanguageData.pincollectionDetails
                    }}<span class="has-text-danger">*</span></label
                  ><ValidationProvider
                    :rules="{ required: true }"
                    v-slot="{ errors }"
                  >
                    <div class="columns">
                      <div class="column">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="PIN Collection Details"
                            native-value="Branch(for PIN collection)"
                            v-model="
                              kycFormStore.formData.pin_collection_details
                            "
                          >
                            {{ $root.selectedLanguageData.branch }}
                          </b-radio></b-field
                        >
                      </div>
                      <div class="column">
                        <b-field>
                          <b-radio
                            class="en-style"
                            name="PIN Collection Details"
                            native-value="Residence Address(for PIN collection)"
                            v-model="
                              kycFormStore.formData.pin_collection_details
                            "
                          >
                            {{ $root.selectedLanguageData.resident }}
                          </b-radio></b-field
                        >
                      </div>
                    </div>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </div>
            <div>
              <p class="mb-4 note-line mt-4">
                <span class="has-text-danger has-text-weight-semibold mr-2">{{
                  $root.selectedLanguageData.note
                }}</span>
                {{
                  $root.selectedLanguageData.value_added_services_bank_policy
                }}
              </p>
              <p class="mb-4 note-line">
                <span class="has-text-danger has-text-weight-semibold mr-2">{{
                  $root.selectedLanguageData.tariff_link
                }}</span
                ><a
                  href="https://www.PayMedia.lk/PayMedia-bank-tariff"
                  class="link"
                  style="
                    font-family: Gotham-Book;
                    color: #231f20;
                    text-decoration: underline;
                  "
                  target="_blank"
                  >www.PayMedia.lk/PayMedia-bank-tariff</a
                >
              </p>
            </div>
          </div>
          <!-- button -->
          <transition
            appear
            @before-enter="$animations.beforeEnterBelow"
            @enter="$animations.enterBelow"
          >
            <div class="is-flex is-flex-direction-row-reverse mt-2 mb-4">
              <b-button
                class="is-primary is-rounded specific-continue-button-en py-5"
                :loading="continueBtnLoading"
                @click="updateFormData()"
                :disabled="invalid || formerror"
                >{{ $root.selectedLanguageData.continue }}</b-button
              >
              <b-button
                class="is-rounded specific-back-button-en py-5"
                @click="$router.push('accountSelection')"
                >{{ $root.selectedLanguageData.back }}</b-button
              >
            </div></transition
          >
        </div>
      </form>
    </ValidationObserver>
    <!-- <GetHelp /> -->
  </div>
</template>
<script>
import logopage from "./CompanyTopLogo.vue";
import { ApiRequestManager } from "@/utils/networkManager/apiRequestManager";
import { useKycFormStore } from "@/stores/KycFormStore";
// import GetHelp from "./GetHelp.vue";
import { GlobalFunctions } from "@/heplers/globalFunctions";

export default {
  computed: {
    GlobalFunctions() {
      return GlobalFunctions;
    },
  },
  /*
   * ===================================================================================================================
   * expose kyc form pinia store to the component
   */
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },

  components: {
    logopage,
    // GetHelp,
  },
  data() {
    return {
      editImage : require("@/assets/images/icons/Edit.png"),
      continueBtnLoading: false,
      smsToggleButton: "",
      smsYesButton: "",
      smsNoButton: "",
      eBankingToggleButton: "",
      eBankingYesButton: "",
      eBankingNoButton: "",
      virtualWalletToggleButton: "",
      virtualWalletYesButton: "",
      virtualWalletNoButton: "",
      estatementToggleButton: "",
      estatementYesButton: "",
      estatementNoButton: "",
      debitCardToggleButton: "",
      debitCardYesButton: "",
      debitCardNoButton: "",
      isPosTransactionInputFocus: false,
      isCustomerNameInputFocus: false,
      formerror: false,
    };
  },
  watch: {
    "kycFormStore.formData.pin_collection_details"() {
      this.vasDeliveryaddressCheck();
    },
    "kycFormStore.formData.collection_details"() {
      this.vasDeliveryaddressCheck();
    },
  },
  methods: {
    vasDeliveryaddressCheck() {
      const comp = this;
      if (
        comp.kycFormStore.formData.pin_collection_details ==
          "Branch(for PIN collection)" &&
        comp.kycFormStore.formData.collection_details == "Branch"
      ) {
        comp.formerror = true;
        comp.$buefy.toast.open({
          duration: 5000,
          message: `PIN delivery details cannot be same as Card delivery details`,
          type: "is-danger",
        });
      } else if (
        comp.kycFormStore.formData.pin_collection_details ==
          "Residence Address(for PIN collection)" &&
        comp.kycFormStore.formData.collection_details == "Residence Address"
      ) {
        comp.formerror = true;
        comp.$buefy.toast.open({
          duration: 5000,
          message: `PIN delivery details cannot be same as Card delivery details`,
          type: "is-danger",
        });
      } else {
        comp.formerror = false;
      }
    },
    validateName: function () {
      const obj = {
        required: true,
        min: 3,
        alphaSpaces: true,
        notStartsWith: " ",
        notEndsWith: " ",
        notConsecutive: "  ",
      };
      return obj;
    },
    smsYesClick() {
      if (!this.kycFormStore.formData.sms_service) {
        this.smsToggleButton.classList.add("toggle-change");
        this.smsYesButton.classList.add("yes");
        this.smsYesButton.classList.add("yes-active");
        this.smsNoButton.classList.remove("no");
        this.smsNoButton.classList.remove("no-active");
        this.kycFormStore.formData.sms_service = true;
      }
    },

    smsNoClick() {
      if (this.kycFormStore.formData.sms_service) {
        this.smsToggleButton.classList.remove("toggle-change");
        this.smsYesButton.classList.remove("yes");
        this.smsYesButton.classList.remove("yes-active");
        this.smsNoButton.classList.add("no");
        this.smsNoButton.classList.add("no-active");
        this.kycFormStore.formData.sms_service = false;
      }
    },

    eBankingYesClick() {
      if (!this.kycFormStore.formData.e_banking_service) {
        this.eBankingToggleButton.classList.add("toggle-change");
        this.eBankingYesButton.classList.add("yes");
        this.eBankingYesButton.classList.add("yes-active");
        this.eBankingNoButton.classList.remove("no");
        this.eBankingNoButton.classList.remove("no-active");
        this.kycFormStore.formData.e_banking_service = true;
      }
    },

    eBankingNoClick() {
      if (this.kycFormStore.formData.e_banking_service) {
        this.eBankingToggleButton.classList.remove("toggle-change");
        this.eBankingYesButton.classList.remove("yes");
        this.eBankingYesButton.classList.remove("yes-active");
        this.eBankingNoButton.classList.add("no");
        this.eBankingNoButton.classList.add("no-active");
        this.kycFormStore.formData.e_banking_service = false;
      }
    },

    virtualWalletYesClick() {
      if (!this.kycFormStore.formData.virtual_wallet_service) {
        this.virtualWalletToggleButton.classList.add("toggle-change");
        this.virtualWalletYesButton.classList.add("yes");
        this.virtualWalletYesButton.classList.add("yes-active");
        this.virtualWalletNoButton.classList.remove("no");
        this.virtualWalletNoButton.classList.remove("no-active");
        this.kycFormStore.formData.virtual_wallet_service = true;
      }
    },

    virtualWalletNoClick() {
      if (this.kycFormStore.formData.virtual_wallet_service) {
        this.virtualWalletToggleButton.classList.remove("toggle-change");
        this.virtualWalletYesButton.classList.remove("yes");
        this.virtualWalletYesButton.classList.remove("yes-active");
        this.virtualWalletNoButton.classList.add("no");
        this.virtualWalletNoButton.classList.add("no-active");
        this.kycFormStore.formData.virtual_wallet_service = false;
      }
    },

    estatementYesClick() {
      if (!this.kycFormStore.formData.estatement_service) {
        this.estatementToggleButton.classList.add("toggle-change");
        this.estatementYesButton.classList.add("yes");
        this.estatementYesButton.classList.add("yes-active");
        this.estatementNoButton.classList.remove("no");
        this.estatementNoButton.classList.remove("no-active");
        this.kycFormStore.formData.estatement_service = true;
      }
    },

    estatementNoClick() {
      if (this.kycFormStore.formData.estatement_service) {
        this.estatementToggleButton.classList.remove("toggle-change");
        this.estatementYesButton.classList.remove("yes");
        this.estatementYesButton.classList.remove("yes-active");
        this.estatementNoButton.classList.add("no");
        this.estatementNoButton.classList.add("no-active");
        this.kycFormStore.formData.estatement_service = false;
      }
    },
    debitCardYesClick() {
      if (!this.kycFormStore.formData.debit_card_service) {
        this.debitCardToggleButton.classList.add("toggle-change");
        this.debitCardYesButton.classList.add("yes");
        this.debitCardYesButton.classList.add("yes-active");
        this.debitCardNoButton.classList.remove("no");
        this.debitCardNoButton.classList.remove("no-active");
        this.kycFormStore.formData.debit_card_service = true;
      }
    },

    debitCardNoClick() {
      if (this.kycFormStore.formData.debit_card_service) {
        this.debitCardToggleButton.classList.remove("toggle-change");
        this.debitCardYesButton.classList.remove("yes");
        this.debitCardYesButton.classList.remove("yes-active");
        this.debitCardNoButton.classList.add("no");
        this.debitCardNoButton.classList.add("no-active");
        this.kycFormStore.formData.debit_card_service = false;
      }
      this.kycFormStore.formData.pin_collection_details = "";
      this.kycFormStore.formData.collection_details = "";
      this.kycFormStore.formData.facility_debit_card_customer_name = "";
      this.kycFormStore.formData.account_to_be_linked_for_pos_transaction = "";
      // this.kycFormStore.formData.pos_required = "";
      this.kycFormStore.formData.pos_required = false;
    },
    updateFormData: function () {
      const comp = this;
      comp.continueBtnLoading = true;
      ApiRequestManager.updateLastStep(
        comp.kycFormStore.formData.landing_token,
        "4",
        "/vedioCallDetails",
        comp.kycFormStore.formData,
        function (status) {
          if (status) {
            comp.$router.push("vedioCallDetails");
            comp.continueBtnLoading = false;
          } else {
            comp.continueBtnLoading = false;
          }
        }
      );
    },
  },
  mounted() {
    this.vasDeliveryaddressCheck(),
      (this.kycFormStore.formDataSupport.selectedAccountArray = []);
    if (this.kycFormStore.formData.saving_account_type) {
      this.kycFormStore.formDataSupport.selectedAccountArray.push(
        this.kycFormStore.formData.saving_account_type
      );
    }
    if (this.kycFormStore.formData.second_saving_account_type) {
      this.kycFormStore.formDataSupport.selectedAccountArray.push(
        this.kycFormStore.formData.second_saving_account_type
      );
    }
    if (this.kycFormStore.formData.third_saving_account_type) {
      this.kycFormStore.formDataSupport.selectedAccountArray.push(
        this.kycFormStore.formData.third_saving_account_type
      );
    }

    // sms

    this.smsToggleButton = document.getElementById("sms-toggle-buttons");
    this.smsYesButton = document.getElementById("sms-yes-button");
    this.smsNoButton = document.getElementById("sms-no-button");
    if (this.kycFormStore.formData.sms_service) {
      this.smsNoButton.classList.remove("no");
      this.smsNoButton.classList.remove("no-active");
    } else {
      this.smsYesButton.classList.remove("yes");
      this.smsYesButton.classList.remove("yes-active");
      this.smsToggleButton.classList.remove("toggle-change");
    }
    // e-banking
    this.eBankingToggleButton = document.getElementById(
      "e-banking-toggle-buttons"
    );
    this.eBankingYesButton = document.getElementById("e-banking-yes-button");
    this.eBankingNoButton = document.getElementById("e-banking-no-button");
    if (this.kycFormStore.formData.e_banking_service) {
      this.eBankingNoButton.classList.remove("no");
      this.eBankingNoButton.classList.remove("no-active");
    } else {
      this.eBankingYesButton.classList.remove("yes");
      this.eBankingYesButton.classList.remove("yes-active");
      this.eBankingToggleButton.classList.remove("toggle-change");
    }

    if (GlobalFunctions.CheckVirtualVaultSectionVisible(this.kycFormStore)) {
      this.virtualWalletToggleButton = document.getElementById(
        "virtual-wallet-toggle-buttons"
      );
      this.virtualWalletYesButton = document.getElementById(
        "virtual-wallet-yes-button"
      );
      this.virtualWalletNoButton = document.getElementById(
        "virtual-wallet-no-button"
      );

      if (!this.kycFormStore.formData.virtual_wallet_service) {
        this.virtualWalletToggleButton.classList.remove("toggle-change");
        this.virtualWalletYesButton.classList.remove("yes");
        this.virtualWalletYesButton.classList.remove("yes-active");
        this.virtualWalletNoButton.classList.add("no");
        this.virtualWalletNoButton.classList.add("no-active");
      } else {
        this.virtualWalletToggleButton.classList.add("toggle-change");
        this.virtualWalletYesButton.classList.add("yes");
        this.virtualWalletYesButton.classList.add("yes-active");
        this.virtualWalletNoButton.classList.remove("no");
        this.virtualWalletNoButton.classList.remove("no-active");
      }
      // if (this.kycFormStore.formData.debit_card_service) {
      //   this.debitCardNoButton.classList.remove("no");
      //   this.debitCardNoButton.classList.remove("no-active");
      // } else {
      //   this.debitCardYesButton.classList.remove("yes");
      //   this.debitCardYesButton.classList.remove("yes-active");
      //   this.debitCardToggleButton.classList.remove("toggle-change");
      // }
    }
    // virtual-wallet
    // this.virtualWalletToggleButton = document.getElementById(
    //   "virtual-wallet-toggle-buttons"
    // );
    // this.virtualWalletYesButton = document.getElementById(
    //   "virtual-wallet-yes-button"
    // );
    // this.virtualWalletNoButton = document.getElementById(
    //   "virtual-wallet-no-button"
    // );
    // if (this.kycFormStore.formData.virtual_wallet_service) {
    //   this.virtualWalletNoButton.classList.remove("no");
    //   this.virtualWalletNoButton.classList.remove("no-active");
    // } else {
    //   this.virtualWalletYesButton.classList.remove("yes");
    //   this.virtualWalletYesButton.classList.remove("yes-active");
    //   this.virtualWalletToggleButton.classList.remove("toggle-change");
    // }
    // estatement
    this.estatementToggleButton = document.getElementById(
      "estatement-toggle-buttons"
    );
    this.estatementYesButton = document.getElementById("estatement-yes-button");
    this.estatementNoButton = document.getElementById("estatement-no-button");
    if (this.kycFormStore.formData.estatement_service) {
      this.estatementNoButton.classList.remove("no");
      this.estatementNoButton.classList.remove("no-active");
    } else {
      this.estatementYesButton.classList.remove("yes");
      this.estatementYesButton.classList.remove("yes-active");
      this.estatementToggleButton.classList.remove("toggle-change");
    }
    // debit_card_service
    if (GlobalFunctions.CheckDebitCardSectionVisible(this.kycFormStore)) {
      this.debitCardToggleButton = document.getElementById(
        "debit_card-toggle-buttons"
      );
      this.debitCardYesButton = document.getElementById(
        "debit_card-yes-button"
      );
      this.debitCardNoButton = document.getElementById("debit_card-no-button");
      console.log(this.kycFormStore.formData.debit_card_service, "Debit");

      if (!this.kycFormStore.formData.debit_card_service) {
        this.debitCardToggleButton.classList.remove("toggle-change");
        this.debitCardYesButton.classList.remove("yes");
        this.debitCardYesButton.classList.remove("yes-active");
        this.debitCardNoButton.classList.add("no");
        this.debitCardNoButton.classList.add("no-active");
      } else {
        this.debitCardToggleButton.classList.add("toggle-change");
        this.debitCardYesButton.classList.add("yes");
        this.debitCardYesButton.classList.add("yes-active");
        this.debitCardNoButton.classList.remove("no");
        this.debitCardNoButton.classList.remove("no-active");
      }
      // if (this.kycFormStore.formData.debit_card_service) {
      //   this.debitCardNoButton.classList.remove("no");
      //   this.debitCardNoButton.classList.remove("no-active");
      // } else {
      //   this.debitCardYesButton.classList.remove("yes");
      //   this.debitCardYesButton.classList.remove("yes-active");
      //   this.debitCardToggleButton.classList.remove("toggle-change");
      // }
    }
  },
};
</script>
<style>
.toggle-button {
  display: flex;
  border: 1px solid black;
  max-width: 200px;
  justify-content: flex-end;
  border-radius: 500px;
}

.toggle-change {
  justify-content: flex-start;
}

.toggle-button:hover {
  cursor: pointer;
}

.no-active,
.yes-active {
  background-color: #04c3fc;
  color: white;
}

.no,
.yes {
  border-radius: 500px;
}

.toggle-common {
  padding: 0 22px;
}
</style>
