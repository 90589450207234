<template>
  <div v-if="!hide" class="agent">
    <div :class="styles">
      <b-field
        label-position="inside"
        :custom-class="
          vModelVaribale.trim() === '' && !isLabelFocus
            ? 'custom-float-label-initial'
            : 'custom-float-label'
        "
      >
        <template #label>{{ label }}</template>

        <b-input
          v-model="vModelVaribale"
          :type="type ? type : `text`"
          :readonly="readonly"
          :disabled="disabled"
          @focus="
            () => {
              isLabelFocus = true;
            }
          "
          @blur="
            () => {
              isLabelFocus = false;
            }
          "
          @input="
            () => {
              sendInputData();
            }
          "
          rounded
          :password-reveal="type === `password` ? true : false"
          :maxlength="maxLength ? maxLength : ``"
        ></b-input>
      </b-field>
    </div>
  </div>
</template>
<script>
export default {
  name: "AgentInputLabel",
  props: ["label", "value", "styles", "type", "maxLength", "hide","disabled","readonly",],
  components: {},
  data() {
    return {
      isLabelFocus: false,
      vModelVaribale: this.value,
    };
  },
  methods: {
    sendInputData() {
      this.$emit("input", this.vModelVaribale);
    },
  },
};
</script>
<style>
.custom-float-label-initial {
  top: 0.93em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 1rem !important;
  transition: ease 0.3s;
}

.custom-float-label {
  top: 0.22em !important;
  font-family: "Gotham-Book";
  letter-spacing: 0.4px;
  font-size: 0.9rem !important;
  transition: ease 0.3s;
}
</style>
