/**
 * =====================================================================================================================
 * This can be use to define date time related functions
 *
 *  */
const DateTimeManger = {

    /**
     * This function can be used to ge formatted date and time
     * @param dateString date string
     * @returns {string} 15 February 2023 16:33:27 this type of output
     */
    formatDateTime(dateString) {
        const monthNames = ["January", "February", "March", "April", "May", "June","July", "August", "September", "October", "November", "December" ];

        var date = new Date(dateString)

        var hours = date.getHours()
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();

        minutes = minutes < 10 ? '0'+minutes : minutes;

        seconds = seconds >= 10 ? seconds : "0"+seconds;

        var strTime = hours + ':' + minutes + ':' + seconds;

        return  date.getDate() + " " +monthNames[date.getMonth()]+" "+ date.getFullYear() + " " + strTime;
    }
}

export {DateTimeManger}