<template>
  <div style="position: relative">
    <div class="columns mt-1 is-mobile col" style="margin-right: 0rem !important">
      <div class="column img-after" >
        <img
          :src=companyLogo
          alt="Company-ekyc-logo"
          class="company_kyc_logo"
        />
      </div>

      <!-- <div class="is-pulled-right Lang_Select">
        <span
          class="lang-button-style p-1 mr-3 language-button-bg"
          id="En"
          @click="changeLanguage(`en`)"
          >En</span
        >
        <span
          class="lang-button-style p-1 mr-3"
          id="Si"
          @click="changeLanguage(`si`)"
          >සිං</span
        >
        <span
          class="lang-button-style p-1 px-2 mr-3"
          id="Ta"
          @click="changeLanguage(`ta`)"
          >த
        </span>
      </div> -->
    </div>
  </div>
</template>
<script>
import { useKycFormStore } from "@/stores/KycFormStore";

export default {
  /*
   * ===================================================================================================================
   * expose kyc form pinia store to the component
   */
  setup() {
    const kycFormStore = useKycFormStore();
    return { kycFormStore };
  },
  data(){
    return {
      companyLogo : require("@/assets/images/company-logo.png"),
    }
  },

  methods: {
    changeLanguage(lang) {
      const EnElement = document.getElementById("En");
      const SiElement = document.getElementById("Si");
      const TaElement = document.getElementById("Ta");
      this.$root.selectedLanguage = lang;
      if (lang === "en") {
        EnElement.classList.add("language-button-bg");
        SiElement.classList.remove("language-button-bg");
        TaElement.classList.remove("language-button-bg");
        this.kycFormStore.formData.selLang = "En";
        this.$root.selectedLanguageData = this.$root.engLanguageData;
      } else if (lang === "si") {
        EnElement.classList.remove("language-button-bg");
        SiElement.classList.add("language-button-bg");
        TaElement.classList.remove("language-button-bg");
        this.kycFormStore.formData.selLang = "සිං";
        this.$root.selectedLanguageData = this.$root.sinLanguageData;
      } else if (lang === "ta") {
        EnElement.classList.remove("language-button-bg");
        SiElement.classList.remove("language-button-bg");
        TaElement.classList.add("language-button-bg");
        this.kycFormStore.formData.selLang = "த";
        this.$root.selectedLanguageData = this.$root.tamLanguageData;
      }
    },
  },
};
</script>
<style scoped>
@media only screen and (max-width: 336px) {
  .company_kyc_logo {
    width: 58%;
    margin-bottom: 2.5%;
    margin-left: 2%;
  }
  
}
@media only screen and (min-width: 336px) {
  .company_kyc_logo {
    width: 45%;
    margin-bottom: 2.5%;
    margin-left: 2%;
  }
}
/* Extra small devices (phones, 600px and down) */
@media (min-width: 494px) and (max-width: 600px) {
  .Lang_Select {
    margin-top: 28%;
  }
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .company_kyc_logo {
    width: 25%;
  }
  
  .Lang_Select {
    margin-top: 1.8%;
    margin-right: 3%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 769px) {
  .company_kyc_logo {
    width: 20%;
  }
  .img-after::after {
    content: "";
    position: absolute;
    width: 77%;
    height: 1px;
    background: #04c3fc;
    left: 19.8%;
    margin-top: 4.3%;
    z-index: -1;
  }
  .Lang_Select {
    margin-top: 1.8%;
    margin-right: 3%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .company_kyc_logo {
    width: 19%;
  }
  .img-after::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    background: #04c3fc;
    left: 16.8%;
    margin-top: 3.75%;
    z-index: -1;
  }
  .Lang_Select {
    margin-top: 1.8%;
    margin-right: 3%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .company_kyc_logo {
    width: 19%;
  }
  .img-after::after {
    content: "";
    position: absolute;
    width: 80%;
    height: 1px;
    background: #04c3fc;
    left: 16.8%;
    margin-top: 3.75%;
    z-index: -1;
  }
}
</style>
