<template>
  <div class="content">
    <div style="position:fixed; margin-left: 100%;" v-if="notClicked">
      <b-button v-on:click="notClicked = false" rounded @click="handleClick()" style="border-radius: 20px; height: 40px; width: 40px;" type="is-primary"> <i class="mdi mdi-arrow-down mdi-24px"></i></b-button>
    </div>
    <h4>APPLICATION </h4>
    These General Terms and Conditions (hereinafter referred to as the "Terms and Conditions" where the context admits
    or requires and which term or expression shall include the terms, conditions and rules for Current Accounts, Term
    Deposit Accounts and Savings Accounts (herein contained designated in Sri Lanka Rupees or otherwise) will apply to
    the Customer Accounts, dealings and transactions with PayMedia Bank PLC a Banking Company duly incorporated in the
    Republic of Sri Lanka and having its Registered Office at No. 73/5, Galle Road, Colombo 03 bearing Registration
    Number PQ 233 and carrying on a business of banking in the said Republic of Sri Lanka as a Licensed Commercial Bank
    within the meaning of the Banking Act No. 30 of 1988 and an approved credit agency under and for the purpose of the
    Mortgage Act and the Trust Receipts Ordinance (hereinafter called and referred to as "PayMedia" or "Bank") which term or
    expression as herein used where the context so requires or admits shall mean and include the said PayMedia Bank PLC and
    its successors. These terms and conditions shall "Mutatis Mutandis" (with the necessary changes in points of
    details) apply to all Customers, including but not limited to Individuals, Partnerships, Proprietorship,
    Co-Borrowers, Incorporated and Unincorporated bodies.<br><br>

    The Bank reserves the right to alter, amend or add to these Terms and Conditions, and such alteration, amendment or
    addition shall be deemed binding on all Customers. Every amendment to these Terms and Conditions shall be made
    available on the official website and communicated in an acceptable mode by the Bank.<br><br>

    By accepting these Terms and Conditions, the customer agrees to also abide by the customer's obligations towards the
    Bank mentioned in the Customer Charter issued by the Central Bank of Sri Lanka and periodic amendments issued to it.
    <br>
    <br>
    <h5>Interpretation:</h5>
    In this General Terms and Conditions, the words importing the singular number shall include the plural and vice
    versa, words importing the masculine gender shall include the feminine gender and words importing persons shall
    include corporations and companies. The headings are inserted for convenience only and shall not affect the
    construction of this document. Reference to any statutory provision, regulation, rule, order or direction shall
    include a reference to such provision, regulation, rule, order or direction as from time to time re-enacted,
    amended, extended, supplemented or replaced.<br>
    <br>
    <h4>BANK ACCOUNT </h4>
    <p>It is hereby agreed that the Bank shall be authorised to act on signed written instructions or signed written
      document drawn or accepted in accordance with the operating instruction given in the Account Opening Application
      Form (hereinafter referred to as the "Mandate") until such time as the customer shall give the Bank written notice
      to the contrary:</p>

    <ol type="a">
      <li>for making payments of a cheque, bill of exchange, promissory note, standing order, direct debit, issue of
        draft, mail and telegraphic transfer, purchase and sale of securities and foreign currency and giving effect to
        and any other instruction by debiting such account whether in credit or otherwise; and
      </li>
      <li>to accept any other instruction regarding such an account, including instructions for the closure of such an
        account.
      </li>
    </ol>
    <br>
    <br>
    <h4>CHEQUE BOOK</h4>
    <ol type="a">
      <li>The Bank may also issue a chequebook on a Current Account at its absolute discretion. Where a cheque book is
        issued, the customer shall be responsible for its safe custody at all times and notify the Bank forthwith if
        such chequebook or any cheque leaf contained therein is lost or stolen.
      </li>
      <li>The Bank at its absolute discretion, the Bank accepts from the customer any instruction to stop payment of a
        cheque (either orally or in writing) in case the customer has lost a chequebook or any cheque leaf. Written
        confirmation on the same day should follow any oral request.
      </li>
      <li>The Bank reserves the right not to issue a chequebook if there are excessive cheque returns.</li>
    </ol>
    <br>
    <br>

    <h4>OVERDRAFT</h4>
    <ol type="a">
      <li>A Customer shall be liable for any overdraft or other facilities arising in connection with any of his
        accounts with the Bank, and the customer hereby authorises the Bank explicitly to debit any such account with
        all or any interest (including penal interest) commission and other Banking charges, cost and expenses
        (including any legal cost) incurred in connection therewith at such rates as may be determined by the Bank from
        time to time in its absolute discretion. The customer shall also pay the Bank such amounts of money in the
        manner and at such times as may be required by the Bank in its absolute discretion.
      </li>
      <li>Where any amount of money is so credited to such account whilst an overdraft or other banking facilities in
        connection therewith is in force, the Bank shall firstly apply such amount to set - off any interest (including
        penal interest) payable until the interest due is paid in full. Any such amounts so credited shall be applied to
        set off the principal amount of any such overdraft or other banking facilities.
      </li>
    </ol>

    <br>
    <br>

    <h4>DEPOSITS AND WITHDRAWALS</h4>
    The laws in force in Sri Lanka currently govern deposit (s) and withdrawal(s). The Bank has the discretion to allow
    the withdrawal of monies at any branch in Sri Lanka, subject to the production of evidence of identity satisfactory
    to the Bank and on payment of any customary charges that the Bank may levy from time to time. If the Bank cannot
    verify a Customer's identity due to reasons such as but not limited to the wearing of caps, sunglasses, face
    coverings, etc., the customer agrees to remove these before processing the transaction. <br>
    <br>
    <h4>MAINTAINING ACCOUNTS</h4>

    <ol type="a">
      <li>
        Periodic Balance Statements
      </li>
      <p>Where any amount of money is so credited to such account whilst an overdraft or other banking facilities in
        connection therewith is in force, the Bank shall firstly apply such amount to set - off any interest (including
        penal interest) payable until the interest due is paid in full. Any such amounts so credited shall be applied to
        set off the principal amount of any such overdraft or other banking facilities.</p>
      <li>
        Time Allowed for Objection
      </li>
      <p>Any objection a Customer may have concerning the correctness or incompleteness of a periodic balance statement
        received by the customer must be raised promptly, but in any event, must be received by the Bank in writing on
        immediate receipt of such statement. In the absence of any objection within the period specified above, the
        periodic statement shall be deemed to be correct and binding.</p>

    </ol>


    <h4>JOINT ACCOUNTS AND PARTNERSHIP ACCOUNTS</h4>

    <ol type="a">
      <li>If a customer account is a joint account, the joint account holders shall be jointly and severally liable for
        any overdraft, loan or other credit facilities or accommodation which may be granted on such account together
        with all interest, commission and other banking charges and expenses.
      </li>
      <li>In the event of the death of one or more of the joint account holders, the Bank shall pay, deliver to or to
        the order of the surviving account holder or account holders all the money, security, deeds, documents, and
        other property (including safe deposit lockers and other contents) whatsoever or held by the Bank for, or
        standing to the credit of any account in the joint account holders' name, without referring to the legal heirs
        or representatives of the deceased joint account holder(s).
      </li>
      <li>The Bank shall be fully discharged of its obligations towards the joint account holders upon receiving the
        notice of death of one or more of the joint account holders and on payment of or delivery to or to the order of
        the surviving joint account holder(s) all monies, security deeds, documents and other properties whatsoever
        standing to the credit of or held by the Bank for any account whatsoever.
      </li>
    </ol>

    <br>
    <br>

    <h4>TERMS AND CONDITIONS FOR SAVINGS ACCOUNTS</h4>
    <ol type="a">
      <li>Deposits may be made at any branch of the Bank.</li>
      <li>Withdrawals may also be made at any such branch of the Bank subject to the provisions of these Terms and
        Conditions.
      </li>
      <li>An account which carries less than the required minimum balance and which is inactive for a period of more
        than 24 months may be closed by the Bank.
      </li>
      <li>Cheque(s)/Drafts and money orders may be accepted to the credit of Savings Accounts only at the Bank's sole
        discretion.
      </li>
      <li>Interest will be calculated on the daily balance and credited monthly, provided that the account has the
        required minimum balance on each such day of calculation.
      </li>
      <li>A Savings Account holder will be issued with a Passbook and the safety of which will be the account holder's
        responsibility. The Bank must be notified in writing without delay if the passbook is lost, stolen, or
        misplaced.
      </li>
      <li>Any money deposited or withdrawn will be recorded in the passbook by the Bank where applicable if the passbook
        is presented at the counter. An account holder shall carefully examine his passbook entries before leaving the
        Bank and satisfy himself that the entries made therein are correct. Any discrepancy must be notified to the Bank
        forthwith.
      </li>
      <li>The passbook should be presented at least once every six (6) months to have all transactions recorded therein.
        Transactions carried out prior to three (3) years from the date of presentation of the passbook will not be
        available for recording in the passbook. The Bank will not retain records of transactions exceeding the period
        of statutory requirement.
      </li>
      <li>The Bank reserves the right to mail by ordinary post, at its discretion, the statement of transactions not
        recorded in the passbook.
      </li>
      <li>The account holder can withdraw cash from an Automated Teller Machine (ATM) or at the Bank counter upon the
        production of the passbook and a correctly completed withdrawal form. A Savings Account Holder shall produce his
        passbook and the NIC with the correctly completed withdrawal form when making an over-the-counter withdrawal.
      </li>
      <li>Any transaction through an ATM is subject to the Terms and Conditions of using such a machine.</li>
      <li>Interest on savings accounts shall be accrued subject to applicable product features. In the event the
        subjective balances are not maintained, the account may not accrue any interest until the balances are being
        upgraded to the applicable balances.
      </li>
    </ol>
    <br>
    <br>

    <h4>TERMS AND CONDITIONS FOR CURRENT ACCOUNTS</h4>
    <ol type="a">
      <li>The respective branches of the PayMedia will declare the Bank's business hours.</li>
      <li>Interest will not be paid on any balance remaining in the Current account.</li>
      <li>A chequebook will be provided on payment of a nominal charge to the Current account.</li>
      <li>However, taxes and other charges levied by the Government of Sri Lanka and the Clearing House will be debited
        to the Current account.
      </li>
      <li>In using cheque(s), careful attention is required in respect of the following;
        <ol type="i">
          <li>An unauthorised person should not be allowed to access a chequebook.</li>
          <li>The Bank shall not be held responsible if a cheque is honoured on a forged signature due to the customer's
            negligence in securing the chequebook issued to the customer.
          </li>
          <li>In issuing a cheque, the amount for which it is drawn must be clearly written in words and figures, and no
            space shall be left facilitating the addition of any figure as the case may be or words thereafter.
          </li>
          <li>Should it become necessary to make any alteration on a cheque, such alteration must be authenticated with
            the full signature of the drawer.
          </li>
          <li>The Bank may decline to pay on any cheque bearing a date that is six (6) months or more prior to the date
            of presentation for payment.
          </li>
          <li>The Bank does not accept any responsibility if a post-dated cheque is inadvertently paid and debited to
            the customer's account. A post-dated cheque bears a date subsequent to the date it is presented for payment.
          </li>
          <li>The Bank is not bound to pay a cheque against unrealised proceeds.</li>
          <li>The Bank must be notified forthwith in writing in the event of a loss of a cheque leaf or the chequebook
            issued to a customer.
          </li>
        </ol>
      </li>
      <li>A customer must obtain the signature of an officer and Bank Stamp for cash deposit into an account on the
        relative counterfoil or receipt unless overprinted by the Bank's Cash Teller machine provided for this purpose.
      </li>
      <li>A customer withdrawing from an account must not draw in excess of the available balance unless a prior
        arrangement has been made with the Bank. In any event, the customer is liable to pay interest at the applicable
        rate for the sum paid in excess of the available cleared balance as of that date in his account.
      </li>
      <li>A customer should consider all the cheques that have been issued but have yet to be presented to the Bank for
        payment in determining the balance available for issuing a further cheque(s).
      </li>
      <li>The Bank will furnish to each Current Account Holder a statement of account at such intervals as may be
        requested by the customer or in the absence of an instruction by the customer; the statements shall be sent in
        such intervals as may be determined by the Bank. The statement of account should be carefully checked upon being
        received, and any discrepancy therein should be brought to the notice of the Bank immediately.
      </li>
      <li>The Bank will charge commissions, where applicable, for services provided. Further, the Bank will impose a
        charge on every cheque dishonoured or payment stopped by the account holder.
      </li>
      <li>The Bank will also record instructions received from a drawer to stop payment on a cheque. However, the Bank
        shall not be held responsible if it fails to follow such instructions.
      </li>
      <li>The Bank will close the account if the cheque returns exceed a specific number, which number will be advised
        by the Bank from time to time.
      </li>
    </ol>
    <br>
    <br>

    <h4>TERMS AND CONDITIONS FOR DEPOSIT ACCOUNTS</h4>
    <ol type="a">
      <li>The Bank shall be under no obligation to release to a Depositor the deposit or any part hereof during the term
        of the deposit, whether it is the original deposit or the renewed deposit.
      </li>
      <li>Any Term Deposit Certificate/Receipt issued by the Bank to a Depositor when making the deposit is not
        transferable.
      </li>
      <li>The expiry of the term or period of the deposit, whether the original deposit or the renewed deposit and the
        delivery to the Bank of the Certificate/ Receipt d u l y discharged, are conditions precedent to the release of
        any money lying in a term deposit.
        No interest will accrue after the expiry of the date of maturity of a deposit.
        Unless instructions to the contrary are given in writing by the depositor to the Bank at least seven (7) working
        days before the end of the current term of a deposit, the Bank has the right and authority. However, it shall
        not be obligatory for the Bank to do so, to renew the term deposit together with the accrued interest, if any,
        for a further term at the discretion of the Bank, subject to the terms and conditions herein contained or such
        other and different conditions the Bank may deem necessary.
      </li>
    </ol>
    <br>
    <br>
    <h4>COST OF BANKING SERVICES</h4>
    <ol type="a">
      <li><strong>Interest and Charges</strong><br>
        The customer shall pay interest on monies due to the Bank and charges on services provided by the Bank at such
        rate(s) and on such basis and at such times as shall be agreed in writing with the Bank from time to time or in
        the absence of such written agreement, as determined by the Bank.
      </li>

      <li><strong>Interest on Overdue Amounts</strong><br>
        The Bank shall be entitled at its discretion to charge a higher interest rate on amounts not paid when due or
        amounts overdrawn without prior agreement, and such interest may be compounded periodically as determined by the
        Bank.
      </li>

      <li><strong>Changes in Interest and Charges</strong><br>
        In the case of a variable interest rate facility, the interest rate will be adjusted in accordance with the
        terms of the respective agreement. Charges for services may be changed by the Bank at its reasonable discretion
        unless otherwise agreed in writing.
      </li>

      <li><strong>Costs and Expenses</strong><br>
        The customer shall pay or reimburse the Bank immediately upon demand. The Bank shall be entitled to debit any of
        the customer's accounts with all commissions and other charges usually charged by the Bank, whether or not
        previously notified to the Customer and all additional costs, charges and out-of-pocket and other expenses
        incurred by the Bank under these General Conditions, any special Conditions or any other agreement between the
        Bank and the customer, including legal expenses.
      </li>

      <li><strong>Taxes and Other Levies</strong><br>
        The customer shall also be liable to reimburse the Bank all Turnover Tax, National Security Levy, Goods and
        Services Tax or any other similar turnover-based tax, stamp duties or other levies, if applicable, which may be
        payable on the transactions carried out by the Bank with the customer.
      </li>
    </ol>
    <h4>FOREIGN CURRENCY ACCOUNTS/ TRANSACTIONS</h4>
    <ol type="a">
      <li>
        The opening of Foreign Currency Accounts(s) will require specific documentation as required by the Department of
        Exchange Control and/or like authority periodically. The specified documentation so required shall be provided
        by the customer before opening the Accounts at the Bank.
      </li>

      <li>
        The issue of traveller's cheques, foreign currency notes, outward remittances and inward remittances to and from
        Sri Lanka Rupee Account(s), Foreign currency transactions, including forward booking transactions and Foreign
        Currency Account(s) is generally governed by the Circulars issued by the Department of Exchange Control and/or
        such like authority, which may change from time to time without prior notice, for which the Bank will not be
        held liable or responsible.
      </li>

      <li>
        The risk associated with any account(s) denominated in foreign currency and/or foreign currency transactions,
        including forward booking transactions accordingly, accept that the customer is solely responsible for all such
        risks and any costs and expenses, howsoever arising, including without limitation, those arising from any
        international or domestic legal or regulatory restriction in respect of any such account(s) and/or foreign
        currency transactions and indemnify the Bank against any loss or damage arising out of such banking transaction.
        Withdrawals or dealings on any such account(s) are also subject to the currency available at the Bank's relevant
        branch. Conversion from one currency to another shall be at the rate of exchange as determined by the Bank (at
        its absolute discretion) periodically.
      </li>
    </ol>
    <br>
    <br>

    <h4>COMPLAINT HANDLING</h4>
    The customer shall have the right to make any complaint to the Bank verbally or in writing to the nearest branch or
    Bank's Call Centre or to the Head Office as published via the Bank's official website or any official publication or
    notice of the Bank. The Bank's Service Delivery Unit will monitor and follow up on all complaints. Upon receipt of a
    complaint, the recipient shall forward such complaints to the relevant department or division of the Bank for
    inquiry and further action. The Bank will respond to the customer on the complaint made within a reasonable period
    from receipt. In the event the Bank is unable to conclude the inquiry into the complaint within this period, then
    the Bank will, within such period, provide the customer with an interim response until such time the complaint will
    be finally responded. If the Bank is satisfied that the complaint is legitimate, then the Bank shall, in such final
    response, inform the customer of the steps taken to resolve the complaint. Customers are advised to seek recourse
    through the Financial Ombudsman or Courts if a complaint is not resolved satisfactorily.<br>
    <br>
    <h4>DEATH, INCAPACITY OR BANKRUPTCY</h4>
    On the death, incapacity or bankruptcy of the customer, the Bank is entitled to require the production of
    documentary evidence thereof (such as death certificates, letters of administration, probate, relevant court orders
    or any other relevant document equivalent thereof) in form and substance acceptable to the Bank before the Bank
    permits the withdrawal/ disposal/ release to or by any person who is designated as executor, administrator or other
    personal representatives of the customer or deceased, and trustee or receiver of assets or any purported beneficiary
    of the assets. If the Bank so requests, the original of any documentary evidence must be submitted with an English
    translation (where applicable) acceptable to the Bank. The death, incapacity or bankruptcy of any account holder
    shall not bind the Bank until it receives written notice of such fact from a source which it considers to be
    reliable. Upon receipt of the relevant notice, the Bank shall be entitled to freeze the account (s) until it
    receives the evidence mentioned above to its satisfaction.
    <br>
    <br>
    <h4>FOREIGN CURRENCY RISK DISCLOSURE</h4>
    The customer hereby declares that he is fully aware of the risks of losses involved in foreign currency investments
    and the customer is willing and able to assume the financial and other risks involved, and that the customer will in
    no way hold the Bank responsible for any loss which the customer may incur in connection therewith.
    <br>
    <br>
    <h4>SET-OFF AND LIEN</h4>
    <ol type="a">
      <li><strong>Agreement on Lien/Set-off</strong><br>
        The Customer and the Bank agree that the Bank acquires a lien and/or security right on any securities and
        properties which have come or may come into the possession of the Bank. The above is without prejudice to any
        general or Banker's lien or right of set-off or other rights to which the Bank may be entitled. The customer
        undertakes to do such act or sign such documents required by the Bank for the purpose herein.
      </li>

      <li><strong>Secured Claims</strong><br>
        The lien and security right serves to secure all existing, future and contingent claims arising from the banking
        relationship (whether arising under these Terms and Conditions, any Special Condition or in respect of any
        agreement or transaction, whether actual, future or contingent, as principal, surety or otherwise, and in
        whatever currency) which the Bank is entitled to against the customer.
      </li>

      <li><strong>Interest on Dividend Coupons</strong><br>
        Where the securities are subject to the Bank's lien/right, the customer is not entitled to demand delivery of
        the interest and dividend coupons pertaining to such securities.
      </li>
    </ol>
    <h4>BANKING SECRECY</h4>
    The Bank has a duty to maintain secrecy about any customer-related facts under the Common Law, and a similar
    obligation is imposed upon its officers under section 77 of the Banking Act No. 30 of 1988. However, the Bank may
    disclose information concerning a Customer or its transactions or accounts when it is required to do so by a Court
    of law or when it has been authorised by the customer or in the performance of its duties or to comply with any
    provision of law whether locally or internationally.<br>
    <br>
    <h4>DISCLOSURE</h4>
    <p>The customer hereby authorises the Bank to disclose any information relating to him or his transactions or
      accounts to:</p>
    <ol type="a">
      <li>Any guarantor of, or a third-party provider of security for, the customer or its account(s) with the Bank in
        respect of any banking facility or service provided by the Bank to the customer;
      </li>
      <li>Any actual or potential assignee, participant or contractual party in connection with any rights or
        obligations of the Bank in relation to the customer;
      </li>
      <li>Local and International regulators, Credit Information Bureaus and service providers of the Bank.</li>
    </ol>
    <br>
    <br>

    <h4>PRINCIPLES OF LIABILITY</h4>
    In performing its obligations, the Bank shall only be liable for its staff's wilful default or gross negligence. If
    the customer has contributed to the occurrence of the loss by the customer's fault (e.g. by violating the duties as
    mentioned in these Conditions), the principles of contributory negligence shall determine the extent to which the
    Bank and the Customer shall have to bear the loss.
    <br>
    <br>
    <h4>INDEMNITY</h4>
    The customer shall indemnify the Bank against any or all claims, demands, actions, damages, liabilities, costs,
    losses, expenses and other sums that the Bank may incur or suffer in connection with or arising from giving credit
    or other banking facilities to the customer or performing any banking service for the customer, and shall reimburse
    the Bank upon demand for any such payment which may be made or incurred by the Bank by reason thereof. The customer
    will indemnify the Bank for any loss incurred by the Bank resulting from the fact that the Bank, without any wilful
    default on its part, does not obtain knowledge of any restrictions of the customer's or his representative's legal
    capacity.

    <br>
    <br>
    <h4>PRESCRIPTION</h4>
    All monies due by the customer to the Bank in respect of a loan and interest thereon shall be recoverable from the
    customer notwithstanding the Prescription Ordinance (Cap. 68), and the customer agrees that he shall not plead the
    Prescription Ordinance (Cap. 68) as a bar to the Bank suing the customer for the recovery of the said monies.
    <br>
    <br>
    <h4>FORCE MAJEURE</h4>
    The Bank shall not be liable for any loss nor inability to perform, caused by or in connection with any event of
    force majeure, including but not limited to riot, war, natural disaster, any circumstance beyond the Bank's control
    or other occurrences for which the Bank is not responsible (e.g. strikes, lock-outs, traffic hold - ups, civil
    commotion, acts of domestic or foreign governmental or other authorities (whether de lure or de facto), requirements
    of any law or regulation, market disruption, or any act of learning settlement agency or central depository.
    <br>
    <br>
    <h4>INFORMATION AND NOTICES</h4>
    The customer shall notify the Bank without delay in writing of any changes in the customer's name, address and other
    particulars, as well as the termination of, or amendment to any powers of representation towards the Bank conferred
    on any person (in particular, a power of attorney) or the authority of any person, this notification duly also
    applies to any change to the particulars of the customer in any public register and also exists where the power of
    representation is recorded in such public register. Any termination thereof of any amendments thereto are entered in
    that register.
    All information regarding the trade, business or occupation and financial condition or any material change in
    respect thereto of the customer will be furnished by the customer to the Bank.
    Particulars of any litigation or potential litigation which may tend to affect the customer's financial capacity
    shall be disclosed by the customer to the Bank.
    The Bank is entitled at its discretion and without liability to send any notice, correspondence, instrument or
    document to the customer by ordinary post, hand, telex or facsimile or email (to the last known address, telex
    number or facsimile number or email address of the customer known to the Bank), and any such communication shall be
    deemed to have been received by the Customer two days after posting (if local), five days after posting (if
    overseas), at the time of transmission (if by telex or facsimile or email) or upon delivery (if by hand).

    <br>
    <br>
    <h4>TERMINATION</h4>

    Termination right of the Customer
    Unless the Bank and the Customer have otherwise agreed in writing to a termination provision, the customer may at
    any time, with reasonable prior written notice, terminate the contract as a whole or any particular contract arising
    out of the duly signed and accepted Mandate. Provided, however, that any such termination shall not affect the
    rights which have accrued to the Bank before such termination.
    <br>
    <br>
    <ol type="1">
      <li><strong>Termination right of the Customer</strong><br>
        Unless the Bank and the Customer have otherwise agreed in writing to a termination provision, the customer may
        at any time, with reasonable prior written notice, terminate the contract as a whole or any particular contract
        arising out of the duly signed and accepted Mandate. Provided, however, that any such termination shall not
        affect the rights which have accrued to the Bank before such termination.
      </li>

      <li><strong>Termination rights of the Bank</strong>
        <ol type="a">
          <li><strong>Termination upon Notice</strong><br>
            Upon giving notice, which in the Bank's opinion is reasonable, the Bank may terminate any contract as a
            whole or any particular contract arising out of the duly signed and accepted Mandate. Neither a term nor a
            termination provision has been agreed upon in writing. In determining the notice period, the Bank will,
            whenever practical, consider the known legitimate concerns of the customer.
          </li>

          <li><strong>Termination of loans and facilities with no fixed term</strong><br>
            Loans, or credit or banking facilities/commitments for which neither a fixed term nor a termination
            provision has been agreed in writing may be terminated at any time by the Bank at its discretion without
            prior notice.
          </li>

          <li><strong>Termination for reasonable cause without notice</strong><br>
            Termination of the business relationship as a whole or of a particular relation arising out of the duly
            signed and accepted Mandate, without notice, is permitted if there is reasonable cause which makes it
            unacceptable to the Bank to continue the business relationship after having given due consideration to the
            known legitimate concerns of the customer.
          </li>

          <li><strong>Settlement following termination</strong><br>
            Upon termination of the relationship or facilities, all amounts and obligations owing by the customer to the
            Bank shall become due and payable immediately on demand, and all foreign exchange and other derivative
            transactions between the Bank and the customer shall be terminated early at the Bank's discretion and become
            due and payable at the then market value as determined by the Bank. Regarding any outstanding future or
            contingent liability of the customer to the Bank, the customer shall immediately provide sufficient cash
            cover on such terms as the Bank may require.
          </li>

          <li><strong>Saving Rights</strong><br>
            Termination by the Bank of any contract as a whole or of a particular relation under any of the above
            mentioned provisions shall not affect the rights accrued to the Bank before such termination.
          </li>
        </ol>
      </li>
    </ol>
    <h4>ASSIGNMENT</h4>
    The Bank shall have the right to assign any or all of its rights hereunder to another party without obtaining prior
    approval from the customer.
    <br>
    <br>
    <h4>INVALIDITY OF PROVISIONS</h4>
    If at any time, any provision hereof becomes illegal, invalid or unenforceable in any respect, such illegality,
    invalidity or unenforceability shall not impair or affect the remaining provisions hereof.
    <br>
    <br>
    <h4>WAIVER</h4>
    No forbearance, neglect or waiver by the Bank of any of these Terms and Conditions regarding a particular instance
    or occurrence shall prejudice the Bank's rights thereafter to enforce the same strictly. A waiver by the Bank shall
    be effective only if it is in writing.
    <br>
    <br>
    <h4>JURISDICTION AND GOVERNING LAW </h4>
    <ol type="a">
      <li><strong>Place of Jurisdiction</strong><br>
        Without prejudice to the Bank's rights to proceed against the customer in any other country or any other Court,
        the customer irrevocably submits to the non-exclusive jurisdiction of the Court of Sri Lanka (The Bank may be
        sued only before the courts of Sri Lanka).
      </li>

      <li><strong>Substantive Law</strong><br>
        The business relationship, customer accounts, dealings and transactions envisaged herein and Mandate shall be
        governed by the Laws of Sri Lanka.
      </li>
    </ol>
    <br>
    <br>
    <h4>VALUE ADDED SERVICES</h4>
    All terms and conditions with applicable charges and related features are pertaining to value added services offered
    by the bank are available on the bank’s website for perusal and may change from time to time at the bank’s
    discretion by duly updating the website. Further, terms and conditions pertaining to any new services that the bank
    may introduce in the future will also be made available on the website.
    <br>
    <br>
    <h5>PayMedia Salary Partner - Terms and Conditions</h5>

    <ol>
      <li>I agree to remit a minimum net salary of Rs. 50,000.00 (rupees fifty thousand) to Rs. 149,999/- (rupees one
        hundred and forty-nine thousand nine hundred and ninety-nine) net salary per month (basic + fixed allowances –
        all deductions) through Current or Savings account maintained with PayMedia Bank PLC ("Bank") every month to be
        eligible for the services, privileges, and benefits associated with the PayMedia Salary Partner proposition.
      </li>
      <li>In the event I fail to remit the said minimum monthly salary as per above section 1 for three (3) consecutive
        months, the Bank may forfeit all services, privileges and benefits associated with the PayMedia Salary Partner
        proposition, and the Bank may consider me a regular banking customer.
      </li>
      <li>In the event of a forfeiture as given in section 2 above and in the event I wish to continue all
        facilities/value-added services which were offered to me under the PayMedia Salary Partner proposition, I understand
        and agree such shall be subject to Bank's standard tariff and standard terms and conditions pertaining to such
        all the services, privileges and benefits associated with the PayMedia Salary Partner proposition which may change
        from time to time.
      </li>
      <li>In the event of forfeiture, as given in section 2, I agree that all liabilities arising from utilising the
        services, privileges and benefits associated with the PayMedia Salary Partner proposition shall remain applicable
        and, therefore, should be regularly serviced by me.
      </li>
      <li>If an account was opened under the PayMedia Salary Partner proposition with a zero initial deposit, I agree to
        fund the account within 30 calendar days of account opening by remitting my net salary to the account mentioned
        above. I also understand, agree and accept that if the account is not funded with the salary remittance within
        30 calendar days of account opening, the Bank shall have the sole and absolute right and discretion to close the
        account without any notice to me.
      </li>
      <li>I agree and accept that my requests for services, privileges and benefits associated with the PayMedia Salary
        Partner proposition shall be subject to Bank's policies and procedures, and the Bank shall have the right to
        refuse such request at the sole discretion of the Bank.
      </li>
      <li>I understand, agree and accept that the Bank shall have the sole and absolute right and discretion to:
        <ol>
          <li>refuse to grant me, in part or in whole, the services, privileges, and benefits associated with the PayMedia
            Salary Partner proposition without assigning any reason or justification even if I meet all the eligibility
            criteria required for the grant of such services, privileges and benefits.
          </li>
          <li>at any time, withdraw and/or cancel any of the services, privileges and benefits granted to me under the
            PayMedia Salary Partner proposition without assigning any reason or justification thereof.
          </li>
          <li>modify, amend and/or change the services, privileges and benefits associated with the PayMedia Salary Partner
            proposition without any notice to me.
          </li>
          <li>modify, amend and/or change the eligibility criteria to be satisfied for the grant of services, privileges
            and benefits associated with the PayMedia Salary Partner proposition.
          </li>
          <li>discontinue, withdraw and/or cancel the PayMedia Salary Partner proposition with communication to me.</li>
        </ol>
      </li>
      <li>Overdraft, personal loans and credit card facilities offered to me under the PayMedia Salary Partner proposition
        shall be subject to standard credit evaluation and documentation process, which is applicable for the respective
        facility.
      </li>
      <li>All the facilities/value-added services offered under the PayMedia Salary Partner shall be subject to terms and
        conditions applicable to such facilities/value-added services unless expressly waived off under the PayMedia Salary
        Partner proposition.
      </li>
      <li>Deposits other than salary remittances can be made to the account as desired. However, cheques to the credit
        of savings accounts will only be accepted at the Bank's sole discretion.
      </li>
      <li>I understand, accept and agree that I may open a current account under the PayMedia Salary Partner proposition.
        However, in the event of three (3) cheque returns, the Bank may close the account by recovering any cheque
        return charges and/or any other fees and tariffs applicable to current accounts.
      </li>
      <li> I understand that I shall be guided by the marketing brochures, leaflets and other forms and documents of the
        Bank about the PayMedia Salary Partner proposition features.
      </li>
      <li>I understand, accept and agree to be bound by the terms and conditions and product features communicated
        through this document, and I also understand, acknowledge and agree that the same is subject to change without
        any notice to me.
      </li>
      <li>In addition to the terms and conditions contained herein, I understand I shall be bound by the general terms
        and conditions of the Bank issued to all account holders at the time of account opening. I accept and agree to
        be bound absolutely and equally by all the terms and conditions contained herein, irrespective of whether such
        terms and conditions are incorporated in the general terms and conditions.
      </li>
      <li>I shall have the right to discontinue PayMedia Salary Partner Services with written notice to the Bank.
      </li>
      <li> I acknowledge that I have read the PayMedia Salary Partner Terms and Conditions, which were explained to me in my
        language of convenience. As such, I am aware of the obligations, liabilities and rights mentioned herein and
        accept the foregoing Terms and Conditions.
      </li>
    </ol>
    <br>
    <br>
    <h5>PayMedia Salary Plus - Terms and Conditions</h5>

    <ol>
      <li>I agree to remit a minimum net salary (basic salary + fixed allowances) ranging from Rs. 25,000.00 (rupees
        twenty-five thousand) to Rs. 49,999.00 (rupees forty-nine thousand nine hundred and ninety-nine) through a
        current or savings account maintained with PayMedia Bank PLC ("Bank") every month to be eligible for the services,
        privileges, and benefits associated with the PayMedia Salary Plus proposition.
      </li>
      <li>In the event I fail to remit the said minimum monthly salary as per above section 1 for three (3) consecutive
        months, the Bank may forfeit all the services, privileges and benefits associated with the PayMedia Salary Plus
        proposition, and the Bank may consider me as a regular banking customer.
      </li>
      <li>In the event of a forfeiture as given in section 2 above and in the event I wish to continue all
        facilities/value-added services which were offered to me under the PayMedia Salary Plus proposition, I understand
        and agree such shall be subject to Bank's standard tariff and standard terms and conditions pertaining to such
        all the services, privileges and benefits associated with the PayMedia Salary Plus proposition which may change from
        time to time.
      </li>
      <li>In the event of forfeiture, as given in section 2, I agree that all liabilities arising due to the utilisation
        of the services, privileges and benefits associated with the PayMedia Salary Plus proposition shall remain
        applicable and, therefore, should be regularly serviced by me.
      </li>
      <li>If an account was opened under the PayMedia Salary Plus proposition with a zero initial deposit, I agree to fund
        the account within 30 calendar days of account opening by remitting my net salary to the aforementioned account.
        I also understand, agree and accept that if the account is not funded with the salary remittance within 30
        calendar days of account opening, the Bank shall have the sole and absolute right and discretion to close the
        account without any notice to me.
      </li>
      <li>I agree and accept that my requests for services, privileges and benefits associated with the PayMedia Salary Plus
        proposition shall be subject to Bank's policies and procedures, and the Bank shall have the right to refuse such
        request at the sole discretion of the Bank.
      </li>
      <li>I understand, agree and accept that the Bank shall have the sole and absolute right and discretion to:
        <ol>
          <li>refuse to grant me, in part or in whole, the services, privileges, and benefits associated with the PayMedia
            Salary Plus proposition without assigning any reason or justification even if I meet all the eligibility
            criteria required for the grant of such services, privileges and benefits.
          </li>
          <li>at any time, withdraw and/or cancel any of the services, privileges and benefits granted to me under the
            PayMedia Salary Plus proposition without assigning any reason or justification thereof.
          </li>
          <li>modify, amend and/or change the services, privileges and benefits associated with the PayMedia Salary Plus
            proposition without any notice to me.
          </li>
          <li>modify, amend and/or change the eligibility criteria to be satisfied for the grant of services, privileges
            and benefits associated with the PayMedia Salary Plus proposition.
          </li>
          <li>discontinue, withdraw and/or cancel the PayMedia Salary Plus proposition with communication to me.</li>
        </ol>
      </li>
      <li>Personal loans and credit card facilities offered to me under the PayMedia Salary Plus proposition shall be
        subject to a standard credit evaluation and documentation process applicable to the respective facility.
      </li>
      <li>All the facilities/value-added services offered under the PayMedia Salary Plus shall be subject to terms and
        conditions applicable to such facilities/value-added services unless expressly waived off under the PayMedia Salary
        Plus proposition.
      </li>
      <li>Deposits other than salary remittances can be made to the account as desired. However, cheques to the credit
        of savings accounts will only be accepted at the Bank's sole discretion.
      </li>
      <li>
        I understand, accept and agree that I may open a current account under the PayMedia Salary Plus proposition.
        However, in the event of three (3) cheque returns, the Bank may close the account by recovering any cheque
        return charges and/or any other fees and tariffs applicable to current accounts.
      </li>
      <li>
        I understand that I shall be guided by the marketing brochures, leaflets and other forms and documents of the
        Bank about the PayMedia Salary Plus proposition features.
      </li>
      <li>
        I understand, accept and agree to be bound by the terms and conditions and product features communicated through
        this document, and I also understand, acknowledge and agree that the same is subject to change without any
        notice to me.
      </li>
      <li>
        In addition to the terms and conditions contained herein, I understand I shall be bound by the general terms and
        conditions of the Bank issued to all account holders at the time of account opening. I accept and agree to be
        bound absolutely and equally by all the terms and conditions contained herein, irrespective of whether such
        terms and conditions are incorporated in the general terms and conditions.
      </li>
      <li>
        I shall have the right to discontinue PayMedia Salary Plus Services with written notice to the Bank.
      </li>
      <li>
        I acknowledge that I have read the PayMedia Salary Plus Terms and Conditions, which were explained to me in my
        language of convenience. As such, I am aware of the obligations, liabilities and rights mentioned herein and
        accept the foregoing Terms and Conditions.
      </li>
    </ol>
    <br>
    <br>
    <h5>PayMedia Prestige Proposition - Terms and Conditions</h5>
    <ol>
      <li>I agree that benefits associated with this PayMedia Prestige Proposition are offered under one or several of the
        conditions:
        <ol>
          <li>Holds a fixed deposit of Rs.2,500,000.00 or above (Rupees Two Million Five Hundred Thousand or above) with
            the Bank
          </li>
          <li>Maintains an average savings balance of Rs. 250,000.00 or above (Rupees Two Hundred and Fifty Thousand or
            above) for a period of 6 months or more in any savings account at the Bank
          </li>
          <li>Remits a minimum net remuneration of Rs.150,000.00 or above (Rupees One Hundred and Fifty Thousand or
            above) per month to any current or savings account at the Bank
          </li>
          <li>Has obtained a vehicle loan or a personal loan of Rs.3,500,000.00 or above (Rupees Three Million Five
            Hundred Thousand or above) from the Bank
          </li>
          <li>Has obtained a housing loan of Rs. 5,000,000 or above (Rupees Five Million or above) from the Bank</li>
        </ol>
      </li>
      <li>If I fail to comply with at least one of the conditions given in above sections 1.1 – 1.5, the Bank may
        forfeit all the services, privileges and benefits associated with the PayMedia Prestige Proposition, and the Bank
        may consider me as a regular banking customer.
      </li>
      <li>In the event of a forfeiture as given in section 2 above and in the event I wish to continue all
        facilities/value-added services which were offered to me under the PayMedia Prestige Proposition, I understand and
        agree such shall be subject to Bank's standard tariff and standard terms and conditions pertaining to such all
        the services, privileges and benefits associated with the PayMedia Prestige Proposition which may change from time
        to time.
      </li>
      <li>In the event of a forfeiture, as given in section 2, I agree that all liabilities which may have arisen due to
        the utilization of the services, privileges and benefits associated with the PayMedia Prestige Proposition shall
        remain applicable and, therefore, should be regularly serviced by me.
      </li>
      <li>If an account was opened under the PayMedia Prestige Proposition with a zero initial deposit, I agree to fund the
        account within 30 calendar days of account opening by remitting my net salary to the aforementioned account. I
        also understand, agree and accept that if the account is not funded with the salary remittance within 30
        calendar days of account opening, the Bank shall have the sole and absolute right and discretion to close the
        account without any notice to me.
      </li>
      <li>I agree and accept that my requests for services, privileges and benefits associated with the PayMedia Prestige
        Proposition shall be subject to Bank's policies and procedures, and the Bank shall have the right to refuse such
        request at the sole discretion of the Bank.
      </li>
      <li>I understand, agree and accept that the Bank shall have the sole and absolute right and discretion to:</li>
      <ol type="1">
        <li>refuse to grant me, in part or in whole, the services, privileges, and benefits associated with the PayMedia
          Prestige Proposition without assigning any reason or justification, even if I meet all the eligibility
          criteria required for the grant of such services, privileges and benefits.
        </li>
        <li>at any time, withdraw and/or cancel any of the services, privileges and benefits granted to me under the
          PayMedia Prestige Proposition without assigning any reason or justification thereof.
        </li>
        <li>modify, amend and/or change the services, privileges and benefits associated with the PayMedia Prestige
          Proposition without any notice to me.
        </li>
        <li>modify, amend and/or change the eligibility criteria to be satisfied for the grant of services, privileges
          and benefits associated with the PayMedia Prestige Proposition.
        </li>
        <li>discontinue, withdraw and/or cancel the PayMedia Prestige Proposition with communication to me.
        </li>
      </ol>
      <li>Personal loans, housing loans and credit card facilities offered to me under the PayMedia Prestige Proposition
        shall be subject to standard credit evaluation and documentation process, which is applicable for the respective
        facility.
      </li>
      <li>All the facilities/value-added services offered under the PayMedia Prestige Proposition shall be subject to terms
        and conditions applicable to such facilities/value-added services unless expressly waived off under the PayMedia
        Prestige Proposition.
      </li>
      <li>Deposits other than salary remittances can be made to the account as desired. However, cheques to the credit
        of savings accounts will only be accepted at the Bank's sole discretion.
      </li>
      <li>I understand, accept and agree that I may open a current account under the PayMedia Prestige Proposition. However,
        in the event of three (3) cheque returns, the Bank may close the account by recovering any cheque return charges
        and/or any other fees and tariffs applicable to current accounts.
      </li>
      <li>I understand that the marketing brochures, leaflets, and other forms and documents of the Bank about the PayMedia
        Prestige Proposition features shall guide me.
      </li>
      <li>I understand, accept and agree to be bound by the terms and conditions and product features communicated
        through this document, and I also understand, acknowledge and agree that the same is subject to change without
        any notice to me.
      </li>
      <li>If I need to obtain the airport drop-off service offered through the PayMedia Prestige Proposition, I understand
        and agree to inform the Bank's contact centre at least one business day before the flight date and request the
        service.
      </li>
      <li>In addition to the terms and conditions contained herein, I understand I shall be bound by the general terms
        and conditions of the Bank issued to all account holders at the time of account opening. I accept and agree to
        be bound absolutely and equally by all the terms and conditions contained herein, irrespective of whether such
        terms and conditions are incorporated in the general terms and conditions.
      </li>
      <li>I shall l have the right to discontinue PayMedia Prestige Proposition services with written notice to the Bank.
      </li>
      <li>I hereby acknowledge that we have read the PayMedia Prestige Proposition Terms and Conditions, and the same was
        explained to me in my language of convenience. As such, I am aware of the obligations, liabilities and rights
        mentioned herein and accept the foregoing Terms and Conditions.
      </li>
    </ol>
    <br>
    <br>
    <h5>PayMedia Pinnacle - Banking Terms & Conditions</h5>
    <ol type="1">
      <li><strong>Introduction</strong><br></li>
      <ol type="1">
        <li>PayMedia Pinnacle Banking Customers (“PayMedia Pinnacle Customers”) shall agree to the terms and conditions
          mentioned herein applicable to the use of all PayMedia Pinnacle Banking Services(“PayMedia Pinnacle Services”) that
          are available or to be available in the future.
        </li>
        <li>The eligibility criteria, charges and tariffs, privileges, benefits, features, special offerings and other
          terms and conditions contained herein are subject to the General Terms and Conditions of PayMedia Bank PLC
          (“Bank”) and all the other terms and conditions governing different products and services of the Bank.
        </li>
        <li>The Bank shall have the right to change, cancel, withdraw and/or annul the eligibility criteria, charges &
          tariff, special schemes, privileges, terms & conditions at any time at its sole discretion without prior
          notice or reason or without incurring any liability.
        </li>
      </ol>

      <li><strong>Eligibility Criteria</strong><br></li>
      <ol type="1">
        <li>The Bank shall, at its discretion, offer PayMedia Pinnacle Services to its Individual Customers who shall fulfil
          any one of the following eligibility criteria:
        </li>
        <ol type="a">
          <li>Maintaining deposits in excess of Rupees Ten Million (Rs.10,000,000/-) or its equivalent in any foreign
            currency in the form of deposits in Savings/Current/Time Deposit accounts;
          </li>
          <li>Remitting a minimum net remuneration of Rupees Four Hundred and Fifty thousand(Rs.450,000/- ) through a
            Current or Savings account maintained with the Bank
          </li>
          <li>An asset with a mortgage value of Rs Twenty Five Million (25,000,000/-)
          </li>
          <li>Immediate family members limited to the spouse and children who are minors("the PayMedia Pinnacle family ")of
            the PayMedia Pinnacle Customers who become eligible under 2.1( a) above, provided that each family member/s
            hold/s an Account with the Bank and fulfils below ;

            <ol type="a">
              <li>Spouse to hold deposits for LKR 5,000,000 and above in any account
              </li>
              <li>Children to hold a PayMedia Pinnacle Junior account with a deposit value of Rs.250,000/- in a Pinnacle
                Junior Account
              </li>
            </ol>
          </li>
        </ol>
      </ol>
      <li><strong>Minimum Balance Fee & Downgrading</strong><br></li>
      <ol type="1">
        <li>In the event a PayMedia Pinnacle Customer does not maintain any of the eligibility criteria specified under
          clauses 2(a) and 2 (b)or 2(c) above for a continuous period of three(03) months, the Bank shall charge without
          notice a Service Charge of Rupees Three Thousand (Rs.3000/-) per month thereafter.
        </li>
        <li>If a PayMedia Pinnacle Customer fails to maintain any eligibility criteria for a continuous period of 6 months,
          the Bank shall, without notice, withdraw the PayMedia Pinnacle services, privileges, preferential treatment and
          other benefits offered and convert all Pinnacle accounts to ordinary accounts.
        </li>
        <li>If any of the events mentioned in 3.1 and 3.2 occur, the Bank shall deactivate the special Credit and Debit
          Cards issued without notice. However, such customers may re-apply for an ordinary debit and credit card where
          the Bank may issue new cards at its discretion.
        </li>
      </ol>
      <li><strong>Charges & Tariffs</strong><br></li>
      <ol type="1">
        <li>PayMedia Pinnacle Tariff and the relevant charges applicable at the time shall be levied and shall be debited to
          the PayMedia Pinnacle customer's account without prior notice.
        </li>
        <li>All charges are subject to change/revision without prior notice.
        </li>
        <li>If the PayMedia Pinnacle Banking services are terminated, as mentioned in clause 5, the standard charges/tariff
          shall be applicable.
        </li>
      </ol>
      <li><strong>Termination of PayMedia Pinnacle services</strong><br></li>
      <ol type="1">
        <li>The Bank reserves the right to cancel/withdraw any of the PayMedia Pinnacle Services at the Bank's discretion,
          without prior notice and without assigning any reason hereto.
        </li>
        <li>The Pinnacle Customer shall have the right to discontinue PayMedia Pinnacle Services with written notice to the
          Bank.
        </li>
        <li>In both events mentioned in 5.1 and 5.2 above, the customer shall continue to be an ordinary account holder.
        </li>
      </ol>
      <br/>

      <li><strong>Pinnacle Advisory Services</strong><br></li>
      <ol type="1">
        <li>The Bank shall facilitate Advisory services in various professional areas by partnering with external
          advisory organisations from time to time.
        </li>
        <li>PayMedia Pinnacle Customers hereby agree and acknowledge that any Advisory services provided by external
          advisory organisations are subject to the terms and conditions of the said external advisory organisations and
          that the Bank shall not be responsible for any of the following events;
        </li>
        <ol type="a">
          <li>Any adverse effect on the value of the investments
          </li>
          <li>Accuracy and comprehensiveness of the material and information provided
          </li>
          <li>Any loss of profits, benefits or any other direct or indirect damages as a result of obtaining the
            advisory services.
          </li>
        </ol>
        <li>The Bank hereby advises the PayMedia Pinnacle Customer to independently seek opinion on any such advice granted.
        </li>
      </ol>
      <li><strong>“PayMedia Pinnacle Family”</strong><br></li>
      <ol type="1">
        <li>The admission of family members to "PayMedia Pinnacle Family" is subject to the following;</li>
        <ol type="a">
          <li>PayMedia Pinnacle Family members maintain eligible accounts with the Bank with due notification to the Bank of
            these accounts.
          </li>
          <li>The PayMedia Pinnacle family shall enjoy the PayMedia Pinnacle services as long as the PayMedia Pinnacle customer is
            within the eligibility criteria. The renewal shall be subject to the PayMedia Pinnacle customer meeting the
            eligibility criteria mentioned in clause 2 above.
          </li>
          <li>In the event Bank withdraws/revokes the PayMedia Pinnacle Services of the PayMedia Pinnacle Customer, PayMedia
            Pinnacle Services enjoyed by the “PayMedia Pinnacle family” shall be automatically withdrawn/revoked and such
            account holders shall be converted into ordinary account holders of the Bank.
          </li>
        </ol>
      </ol>
    </ol>
    <br>
    <br>

    <p>I/We hereby acknowledge that I/we have read the PayMedia Bank Pinnacle Banking Terms and Conditions and the same was
      explained to me/us in my/our language of convenience.</p>
    <p>As such I/we am/are aware of the obligations, liabilities and the rights mentioned herein and accept the
      foregoing Terms & Conditions.</p>

    <h5>Terms & Conditions for SMS Facility<br></h5>

    <ol type="1">
      <li>
        The Customer shall abide and be bound by the instructions and/or procedures of the Bank during the use of the
        Service. The Customer shall have no claim, resource, or remedy against the Bank for any loss, damage, and
        expense incurred by the Customer arising from any non-compliance by the Customer with such instructions and/or
        procedures.
      </li>

      <li>
        The Bank may choose to register customers through a written application provided to a PayMedia Bank branch or
        through verbal request made via the PayMedia Bank hotline 0112350000 upon customer information verification. It is
        the responsibility of the customer to inform the PayMedia bank of any changes to the contact information such as
        mobile number, e-mail address or postal address.
      </li>

      <li>
        The Customer shall not hold the Bank responsible or liable for any loss or damage which the Customer may incur
        or suffer directly or indirectly, arising out of or in connection with the Service, due to any reason whatsoever
        including but not limited to the viewing of the messages or use of the Service by any person other than the
        Customer, breakdown or malfunction of the computer, its terminal connection lines, data processing system or
        transmission line whether or not belonging to the Bank or any circumstances beyond the control of the Bank.
      </li>

      <li>
        The Bank is entitled to effect any changes to the Service, suspend and/or terminate the Service, and to vary the
        Service at any time at its sole and absolute discretion without prior notice and assigning any reason and
        without prejudice to any of its rights of action for any antecedent breach of these Terms and Conditions by the
        Customer.
      </li>

      <li>
        The Customer may request to terminate the use of the Service by giving the Bank seven (7) days written notice
        (subject to verification) or via the Call Center Hotline. If the Customer requires effecting any changes to the
        Service, the Customer is required to complete a new application form to that effect.
      </li>

      <li>
        The Customer shall inform the Bank by through the Call Centre in the event the mobile phone is lost, stolen,
        transferred, the number is changed and or the connection is discontinued.
      </li>

      <li>
        The Customer hereby irrevocably agrees and undertakes to indemnify the Bank and keep the Bank indemnified and
        saved harmless from time to time and at all times against any loss, costs, damages and expenses which the Bank
        may incur or sustain and against all actions, proceedings, claims and demands in connection with or in relation
        to or arising out of the use of the Service.
      </li>

      <li>
        By signing up for the SMS alerts services the customer agrees to receive communication and marketing material on
        various promotions and products offered by the bank.
      </li>

      <li>
        The Bank shall be entitled to vary any of the Terms and Conditions contained herein at any time without notice
        to the Customer which variation shall bind the Customer absolutely from the date on which it is expressed to
        take effect. The variation may be communicated to the Customer by letter and/or notice in the local press and/or
        by displaying on the Bank’s notice-boards and/or through statement messages. These Terms and Conditions shall be
        governed by and construed in accordance with the laws of Sri Lanka.
      </li>

      <li>
        The Sinhala and Tamil translations of these Terms are available on the website of the Bank (www.PayMedia.lk) and
        printed forms could be obtained from all offices and/or branch offices of the Bank.
      </li>
    </ol>

    <h5>Terms and Conditions for e-Statement /e- Advice, Services <br></h5>
    <ol type="1">
      <li>
        I/We hereby request and authorize the PayMedia Bank PLC (hereinafter referred to as the “Bank”) to provide me/us
        with bank e-statements and/or bank e-advices, relating to any account(s) now maintained or hereafter to be
        maintained by my/our company/firm/business at the Bank and/or any transaction entered into by my/our
        company/firm/business with or through the Bank, through the email addresses which I/we or the authorized
        signatory/ies to such bank account (authorized signatory/ies) may hereafter from time to time notify to the Bank
        in writing. <br><br>


        I/We understand and agree that the Bank may choose to register me/us through a written application provided to a
        PayMedia Bank branch or through verbal request made via the PayMedia Bank hotline 0112350000 upon customer information
        verification. It is my/our responsibility to inform PayMedia Bank of any changes to my/our contact information such
        as mobile number, e-mail address, or postal address.
      </li>
      <li>
        I/We understand and agree that transmission of information through electronic media such as email cannot be
        guaranteed to be error-free and/or uninterrupted due to the inherent nature of such transmission and I/we
        understand and agree that the Bank shall not be liable or responsible to me/us for any such errors and/or
        interruptions even if the same results in delay in delivery and/or non-delivery of any e-statements and/or
        e-advices to me/us. I/We shall inform the Bank of any errors in the e-statements/e-advices received by me/us.
        I/We also agree that the Bank shall not be liable for any change in the policy of the service providers that
        I/we use.
      </li>

      <li>
        I/We undertake and agree that I/we shall not permit or allow any unauthorized access and/or use of the email
        addresses which I/we have authorized by any third party/ies and the Bank shall not be liable and/or responsible
        to me/us for any unauthorized access including hacking and/or use of the email addresses which I/we have
        authorized and/or any information contained in any e-statements and or e-advices sent to me/us through the email
        addresses which I/we have authorized.
      </li>

      <li>
        I/we undertake and agree to keep any and all passwords requested by me/us and/or personal identification numbers
        (“PIN numbers”) assigned to me/us or the authorized signatory/ies by the Bank or generated by me/us or the
        authorized signatory/ies as provided by the Bank strictly confidential and not to disclose such passwords and/or
        PIN numbers to any unauthorized third parties. I/We acknowledge and agree that I/we shall be solely liable and
        responsible for any unauthorized access or use of such passwords and/or PIN numbers by any third parties.
      </li>

      <li>
        I/We agree and undertake that in the event any e-statements and/or e-advices are not received by me/us, I/we or
        the authorized signatory/ies shall inform the Bank of the non-receipt thereof and the Bank may at its discretion
        resend the e-statements or e-advices to me/us. However, I/we understand and agree that paper copies of
        e-statements and e-advices shall only be provided by the Bank on specific request and on payment of the relevant
        charges.
      </li>

      <li>
        I/We understand and agree that any delay in receipt or the non-receipt of any e-statements and/or e-advices
        shall not relieve me/us of any obligation to make any payment due from me/us to the Bank and/or any other party,
        in a timely manner on the due date, or of any obligation to do or perform any other act matter or deed
        whatsoever.
      </li>
      <li>
        I/We understand and agree that the Bank shall not be liable and/or responsible to me/us for any loss and/or
        damage howsoever caused which I/we may suffer or incur as a result of the Bank providing me/us with this e
        statement and/or e advice service including due to delay in receipt and/or non receipt of any e statement and/or
        e advice by me/us.
      </li>
      <li>
        I/We consent and agree to pay the Bank on demand all monies, fees, charges and expenses required for or
        incidental to the provision to me/us of the e-statement and/or e advice service. Further the Bank is hereby
        irrevocably authorized to settle such payments by debiting any of the account(s) maintained at the Bank by
        my/us.
      </li>
      <li>
        In consideration of the Bank providing me/usthe e statement and/or e advice service I/we undertake to keep the
        Bank indemnified and saved harmless at all times against all actions, proceedings, claims, loss, damage, costs
        and expenses which may be brought or made against the Bank or suffered or incurred by the Bank either directly
        or indirectly out of or in connection with the Bank providing me/us with the e-statement and/or e advice service
        and/or communicating with me/us in accordance therewith.
      </li>
      <li>
        I/we consent and agree that in the event I/we wish to terminate this service I/we or the authorized
        signatory/ies shall give written notice of termination to the Bank provided however the terms and conditions
        herein contained and revised from time to time shall remain in full force and effect unless and until the bank
        actually receives such notice and has a reasonable time (at least 21 days from the receipt of such notice) to
        act thereon and terminate this service. Provided further however that such termination will not relieve me/us of
        any liability or responsibility under these terms and conditions incurred prior to the effective date of such
        termination.
      </li>
      <li>
        The Bank shall be entitled at its sole and absolute discretion to withhold, suspend, cancel, withdraw or
        terminate this service at any time without notice to me/us and without assigning any reason. The Bank shall also
        be entitled to change the frequency of sending e-statements/e advices and change the e statement/e advice
        generation date at its discretion as well as in a format of choice by the Bank which may be changed from time to
        time at the discretion of the Bank.
      </li>

      <li>
        I/We agree that, paper bank statements/advices will not be sent in respect of any account now or hereafter to be
        maintained by me/us when e-statements/e advices are received . Without prejudice to the right of the Bank to
        discontinue the paper bank statements/advices at any time, the Bank may however, at its discretion, decide to
        provide such paper bank statements/advices to me/us, in addition to the e-statement and/or e advice service.
      </li>

      <li>
        I/We consent and agree that the Bank shall at any time be entitled to amend, supplement, or vary any of these
        terms and conditions in its absolute discretion, and such amendment, supplement, or variation shall be binding
        on me/us.
      </li>

      <li>
        I/We consent and agree that any notice to me/us may be given by the Bank as a narrative in or enclosed with the
        e-statement or e-advice or by publication in any newspaper in all three languages or by publication in any
        notice board at the Bank’s branches or in any other manner.
      </li>

      <li>
        I/We agree that e-statements/e-advices which are not meant for me/us shall be returned/advised to the contact
        point referred to in the body of the bank's receiving e-mail.
      </li>

      <li>
        I/We acknowledge that I/we can contact the call centre of the Bank on Telephone No. 2350000 for any
        clarification.
      </li>

      <li>
        I/We acknowledge that the Bank reserves the right to include any marketing material in the
        e-statements/e-advices.
      </li>

      <li>
        I/We acknowledge and agree that the email address from which the bank sends the e-statement/e-advice shall not
        be used by me/us to communicate with the Bank and that the Bank will not respond to such emails received from
        me/us.
      </li>

      <li>
        I/We acknowledge that the Bank shall at its discretion select the account to which the e-statements/e-advices
        facility is granted. In the event it is a joint account, the primary account holder will receive the said
        e-statement/e-advice. However, each of the joint holders shall be liable to the Bank.
      </li>

      <li>
        The terms and conditions herein contained shall be binding upon me/us and my/our successors, heirs, executors,
        administrators, or permitted assigns as the case may be.
      </li>

      <li>
        Except in the case where this application is executed by or on behalf of a company, my/our liability to the Bank
        hereunder shall be joint and several.
      </li>

      <li>
        These terms and conditions shall be construed in accordance with and governed by the laws of Sri Lanka.
      </li>

    </ol>

    <p>The word 'account' means current accounts, savings accounts, loans, leases, Fixed Deposits ,credit cards.
      The words 'I, We', Me, Us, My, Our would mean and include Persons, Clubs, Societies, Associations,
      Proprietorships, Partnerships and Companies incorporated by Companies Act No 07 of 2007.<br>
      I/We hereby confirm that the above terms and conditions together with details of the e statement and e advice
      service were given and explained to me/us before the signing hereof and I/we have read and/or understood the said
      details terms and conditions and agree and consent to be bound thereby.
    </p>
    <h5>Terms and Conditions for Online Banking <br></h5>
    <ol type="1">
      <li>
        <p><strong>Services</strong></p>
        <ol type="a">
          <li>
            <p>The PayMedia Bank PLC a Banking Company duly incorporated in Sri Lanka bearing company
              registration PQ 233 (the 'Bank' which expression shall, where the context allows or requires, include its
              successors and assigns) shall provide subject to these Terms and Conditions, online services and
              facilities from time to time, listed out in the Schedule hereto and as updated by the Bank and accepted by
              the Customer from time to time (the 'Services') through any Internet site established, operated and/or
              maintained by or on behalf of the Bank ('Internet Site') to enable customers to give instructions to and
              communicate with the Bank for the purposes of conducting banking, investment, financial and other
              transactions and dealings of various nature and obtaining services, products, information, goods,
              benefits, and privileges from the Bank.</p>
          </li>

          <li>
            <p>As part of the Services, the Bank may make available via any Internet Site financial, market, or other
              information and data ('Information') supplied by any person (each an 'Information Provider' which
              expression shall include any person who supplies any information to an Information Provider) and may
              provide reports compiled from Information in any form, medium, or means ('Reports').</p>
          </li>

          <li>
            <p>The Bank has the right to determine and vary from time to time the scope and type of the Services to be
              made available including, without limitation:</p>
            <ol type="i">
              <li>Expanding, modifying or reducing the Services at any time;</li>
              <li>Imposing and varying any restrictions on the use of the Services such as minimum and maximum daily
                limits with respect to the value of any transaction or dealing or any type of transactions or dealings
                which the Customer may conduct by using the Services; and
              </li>
              <li>Prescribing and changing the normal service hours during which the Services are available and any
                daily cut-off time for any type of Services or transactions. Any instruction of the Customer received by
                the Bank after any applicable daily cut-off time shall be deemed to be received on the next business
                day.
              </li>
            </ol>
          </li>

          <li>

            <p>The Bank may require the Customer to nominate or register specific account(s) for the purposes of the
              Services.</p>
          </li>
        </ol>
      </li>
      <li><p><strong>Governing Terms and Conditions</strong></p>
        The Services provide an additional means for the Customer to operate accounts, conduct transactions and dealings
        and obtain services, products, information, goods, benefits and privileges from the Bank as shall be made
        available from time to time. Transactions and dealings effected using the Services are subject to the General
        Terms and Conditions and the Bank's General Disclaimer and Internet Privacy Statement. All other terms and
        conditions governing the relevant accounts, transactions, dealings, services, products, information, goods,
        benefits or privileges shall continue to apply but where there is any discrepancy, these Terms and Conditions
        shall prevail for the purposes of the Services.
      </li>

      <li><p><strong>Use of the Services</strong></p>
        <ol type="a">
          <li>
            <p>To access the Services for the first time, the Customer is required to register online (as made
              permissible) or in such other manner as the Bank may from time to time specify and indicate his acceptance
              of all the terms and conditions governing the use of the Services and to provide such information as the
              Bank may reasonably specify for identifying the Customer.</p>
            <p>The Bank may choose to register customers through a written application provided to a PayMedia Bank branch or
              through verbal request made via the PayMedia Bank hotline 0112350000 upon customer information verification.
              It is the responsibility of the customer to inform the PayMedia bank of any changes to the contact information
              such as mobile number, e-mail address or postal address.”</p>
          </li>

          <li>
            <p>By registering to use the Services, the Customer warrants that all information provided by the Customer
              to the Bank in relation to the Services is true, complete and up-to-date.</p>
          </li>

          <li>
            <p>The Services are for the sole and exclusive use by the Customer.</p>
          </li>

          <li>
            <p>The Customer shall not use or knowingly allow any other person to use the Services, the Information
              and/or the Reports for or in connection with any illegal purpose or activity. The Customer shall notify
              the Bank as soon as practicable if he becomes aware of such use.</p>
          </li>

          <li>
            <p>Any exchange rate, interest rate, dealing rate and other prices and Information quoted by the Bank on the
              Internet Site(s) or otherwise in response to an online inquiry is for reference only and is not binding.
              Any rate, price, and information offered by the Bank for the purpose of the relevant transaction shall be
              binding on the Customer upon the Customer confirming his acceptance irrespective of any different rate,
              price or information quoted by the Bank.</p>
          </li>

          <li>
            <p>The Customer acknowledges that there may be a time lag or the non-receipt of instructions, information or
              communication by the bank consequent to communicating via the Internet.</p>
          </li>
        </ol>
      </li>

      <li><p><strong>User ID and Password</strong></p>
        <ol type="a">
          <li>
            <p>The Customer shall follow the guidance provided by the Bank online in designating the user identification
              code (the 'User ID') and the login password for identifying the Customer for the purposes of the
              Services.</p>
          </li>

          <li>
            <p>The Customer may change the Login Password at any time but any change shall be effective only if accepted
              by the Bank. The User ID cannot be changed by the Customer.</p>
          </li>

          <li>
            <p>The Bank may, in its sole discretion, require a Customer to use a Transaction Password to access the
              Services.</p>
          </li>

          <li>

            <p>The Customer shall act in good faith, exercise reasonable care and diligence in keeping the User ID, the
              Login Password, and the Transaction Password in secrecy. At no time and under no circumstances shall the
              Customer disclose the User ID, the Login Password, and/or the Transaction Password to any other
              person.</p>
          </li>

          <li>

            <p>The Customer shall be fully responsible for any accidental or unauthorized disclosure of the User ID, the
              Login Password, and/or the Transaction Password to any other person and shall bear the risks of the User
              ID, the Login Password, or the Transaction Password being used by unauthorized persons or for unauthorized
              purposes.</p>
          </li>

          <li>

            <p>Upon notice or suspicion of the User ID and/or the Login Password or the Transaction Password being
              disclosed to any unauthorized person or any unauthorized use of the Services being made, the Customer
              shall notify the Bank in person as soon as practicable or by telephone at such telephone number(s) as the
              Bank may from time to time prescribe (and the Bank may ask the Customer to confirm in writing any details
              given) and, until the Bank's actual receipt of such notification by a relevant officer of the Bank, the
              Customer shall remain responsible for any and all use of the Services by unauthorized persons or for
              unauthorized purposes.</p>
          </li>
        </ol>
      </li>
      <li><p><strong>Customer's Instructions </strong></p>
        <ol type="a">
          <li>

            <p>The Customer is required to enter the User ID, the Login Password, the Transaction Password, and any
              other identifiers as shall be prescribed by the Bank from time to time in order to log-on to the Services
              and give instructions to the Bank online. Instructions in connection with the Services shall not be
              considered to be received by the Bank unless they are given in such manner as the Bank may prescribe from
              time to time and until the Bank has actually received them.</p>
          </li>

          <li>
            <p>Any instruction given in connection with the Services quoting the User ID, the Login Password, the
              Transaction Password, and such other identifiers of the Customer as prescribed by the Bank, once given,
              may not be rescinded or withdrawn without the consent of the Bank. All such instructions given, as
              understood and acted on by the Bank in good faith, shall be irrevocable and binding on the Customer
              whether given by the Customer or by any other person purporting to be the Customer. The Bank shall be
              under no duty to verify the identity or authority of the person giving any such instruction or the
              authenticity of such instruction apart from verifying the User ID, the Login Password, the Transaction
              Password, and such other identifiers (if any) of the Customer.</p>
          </li>

          <li>

            <p>The Bank will be entitled not to act on an instruction insofar as it is in the Bank's opinion not
              practicable and reasonable to do so and/or not in accordance with its regular business practices and
              procedures.</p>
          </li>

          <li>

            <p>Advice or confirmation that an instruction has been received and/or a transaction has been effected
              through the Services will be provided by the Bank online. Such advice or confirmation shall be deemed to
              have been received by the Customer immediately after transmission and it is the duty of the Customer to
              check such advice or confirmation. It is also the duty of the Customer to inquire with the Bank if the
              Customer does not receive an advice or confirmation within the time usually required for a similar advice
              or confirmation to be received.</p>
          </li>

          <li>

            <p>Information relating to any account or transaction made available on the Internet Site(s) are for
              reference only. The Bank's records of such account and transaction shall be conclusive unless and until
              the contrary is established.</p>
          </li>
        </ol>
      </li>
      <li><p><strong>Fees</strong></p>
        <p>The Bank reserves the right to charge fees in relation to the use and/or termination of the Services and to
          revise such fees. The Bank shall determine and give reasonable notice to the Customer of the rate of any fee
          from time to time before they become effective which shall be binding on the Customer if the Customer
          continues to maintain or use the Services on or after the effective date. Fees may be collected from the
          Customer in such manner and at such intervals as the Bank may specify.</p>
      </li>
      <li><p><strong>Customer's Undertakings and Responsibilities</strong></p>
        <ol type="a">
          <li>

            <p>The Customer shall provide such information as the Bank may from time to time reasonably request for the
              purposes of providing the Services. The Customer shall also ensure that all information provided to the
              Bank is at all times accurate, complete, and up-to-date including, without limitation, the Customer's
              address and other contact details.</p>
          </li>

          <li>
            <p>The Customer authorizes the Bank to disclose and transfer information relating to the Customer, the
              Customer's account(s), and/or the transaction(s) executed by the Bank on the Customer's behalf to such
              person(s) as the Bank may be requested (whether or not compelled by law) to do so by any law, rules, or
              regulations of any competent jurisdiction in which the Bank executes transaction(s) on the Customer's
              behalf or any exchange, government, or regulatory authority in such jurisdiction.</p>
          </li>

          <li>

            <p>The Customer shall not, and shall not attempt to decompile, reverse-engineer, translate, convert, adapt,
              alter, modify, enhance, add to, delete or in any way tamper with, or gain access to, any part of the
              Services or any Internet Site or any software comprised in them.</p>
          </li>

          <li>

            <p>The Customer acknowledges that it is the responsibility of the Customer to determine independently market
              prices and rates for trading purposes through his usual trading channels, to verify any Information and/or
              Report before relying or acting on it and to seek independent professional advice on legal, tax, and other
              issues in connection with the use of the Services, the Information, and the Reports, these Terms and
              Conditions, and any transactions and dealings which may affect the Customer under all applicable laws.</p>
          </li>

          <li>

            <p>The Customer shall comply with all laws and regulations in connection with the use of the Services
              including, but not limited to, the provisions of the Prevention of the Money Laundering Act No 05 of 2006
              and the Exchange Control Act No 24 of 1953.</p>
          </li>
        </ol>
      </li>
      <li><p><strong>Information and Information Providers</strong></p>
        <ol type="a">
          <li>
            <p>The Customer acknowledges and agrees that the Information, the Reports, and their form, format, mode, or
              method of compilation, selection, configuration, presentation, and expression (collectively 'Confidential
              Information') are trade secrets and confidential and proprietary property of the Bank and the respective
              Information Providers.</p>
          </li>

          <li>
            <p>Unless expressly permitted by these Terms and Conditions, the Customer shall not, and shall not attempt
              to:</p>
            <ol type="i">
              <li>sell, transfer, disclose, assign, convey, lease, sub-license, share, loan, distribute, transmit,
                broadcast, cablecast, put in circulation, download, reproduce, duplicate, or otherwise provide or
                disseminate any Confidential Information in any form or by any means to any other person or commercially
                exploit any Confidential Information;
              </li>
              <li>remove, obliterate, erase, relocate, or modify in any way any proprietary marking on or appearing with
                the Confidential Information including, without limitation, any trademark or copyright notice; or
              </li>
              <li>incorporate or combine the Confidential Information with any other programs.</li>
            </ol>
          </li>

          <li>
            <p>The restrictions on disclosure shall not apply to any Confidential Information:</p>
            <ol type="i">
              <li>where its disclosure is compelled by law but only to the extent required by law and only after written
                notice of the requirement to disclose has been given by the Customer to the Bank; or
              </li>
              <li>where the Bank has expressly agreed in writing to its disclosure.</li>
            </ol>
          </li>

          <li>
            <p>The Customer agrees that all right, title, and interest in and relating to the Confidential Information
              and any and all related copyright, patent, trademark, service mark, proprietary property, trade secrets,
              and exclusive works are and shall remain the exclusive property of the Bank and the respective Information
              Providers. No right, title, or interest other than the right to access the Information and the Reports
              subject to these Terms and Conditions is conveyed or transferred to the Customer.</p>
          </li>

          <li>

            <p>An Information Provider may impose from time to time terms and conditions in relation to the availability
              of any Information supplied by it. Access to such Information by the Customer on or after the effective
              date, subject to prior notification, of such terms and conditions shall constitute the Customer's
              acceptance thereof.</p>
          </li>

          <li>

            <p>The Information and the Reports are made available for reference only and are not intended for trading or
              other purposes. Neither the Bank nor any Information Provider shall be considered an investment adviser to
              the Customer.</p>
          </li>

          <li>

            <p>Neither the Bank nor any Information Provider warrants, represents, or guarantees the sequence, accuracy,
              truth, reliability, adequacy, timeliness, or completeness of any of the Information or the Reports or
              whether it is fit for any purpose. Nor does either of them assume any liability (whether in tort or
              contract or otherwise) for any reliance on the Information or the Reports by the Customer or any other
              person.</p>
          </li>

          <li>

            <p>The Information will be made available as it is supplied and identifying the Information Provider
              directly supplying it to the Bank. The Bank does not endorse or express any comment on any Information
              supplied by any Information Provider nor assume any duty to check or verify any Information.</p>
          </li>

          <li>

            <p>No warranty, representation, or guarantee of any kind relating to the Information and/or the Reports is
              given or may be implied, and no employee or agent of the Bank or any Information Provider is authorized to
              give any such warranty, representation, or guarantee.</p>
          </li>
        </ol>
      </li>
      <li><p><strong>Liabilities of the Bank</strong></p>
        <ol type="a">
          <li>

            <p>The Bank will take reasonably practicable steps to ensure that its systems in connection with the
              Services are installed with adequate security designs and to control and manage the risks in operating the
              systems, taking into account any law, rules, regulations, guidelines, circulars, codes of conduct, and
              prevailing market practices which may be applicable to the Bank from time to time.</p>
          </li>

          <li>

            <p>The Bank shall only be liable for loss or misplacement of funds caused by unauthorized transactions
              conducted through the use of the Services only if there has been no negligence, fraud, or fault on the
              part of the Customer and it is as a result of:</p>
            <ol type="i">
              <li>a computer crime which should have been prevented by the risk control and management measures had the
                Bank adopted such measures in accordance with Clause 9(a);
              </li>
              <li>a human or system error of the Bank; or</li>
              <li>a missed or mis-directed payment caused by the gross negligence or willful default of the Bank, its
                officers, or employees.
              </li>
            </ol>
          </li>

          <li>

            <p>The Bank does not warrant or represent that the Services, the Information, and the Reports are free from
              viruses or other destructive features that may adversely affect the Customer's hardware, software, or
              equipment.</p>
          </li>

          <li>

            <p>Subject to Clause 9(b) above, the Bank or their respective officers or employees shall only be liable for
              their gross negligence or willful default and only to the extent of direct and reasonably foreseeable loss
              and damage (if any) arising directly and solely therefrom or the amount of the relevant transaction
              (whichever is less).</p>
            <p>The Bank does not assume any liability or responsibility to the Customer or any other person for the
              consequences arising from or in connection with:</p>
            <ol type="i">
              <li>use of the Services and/or access to any information as a result of such use by the Customer or any
                other person whether or not authorized;
              </li>
              <li>any interruption, interception, suspension, delay, loss, unavailability, mutilation, or other failure
                in providing the Services in transmitting instructions or information relating to the Services or in
                connecting with the Internet Site(s) caused by any acts, omissions, or circumstances beyond the
                reasonable control of the Bank;
              </li>
              <li>transmission and/or storage of any information and/or data relating to the Customer, the Services,
                and/or transactions or dealings conducted by the Customer pursuant to the Services through or in any
                system, equipment, or instrument of any communication network provider.
              </li>
            </ol>
          </li>

          <li>

            <p>In no event shall the Bank be liable to the Customer or any other person for any incidental, indirect,
              special, consequential, or exemplary damages including, without limitation, any loss of use, revenue,
              profits, or savings.</p>
          </li>
        </ol>
      </li>
      <li><p><strong>Liabilities of the Customer</strong></p>
        <ol type="a">
          <li>

            <p>Subject to Clause 9(b) above, the Customer shall be fully liable and responsible for all consequences
              arising from or in connection with use of the Services and/or access to any Information or Report or any
              other information as a result of such use by the Customer or any other person whether or not
              authorized.</p>
          </li>

          <li>

            <p>The Customer shall indemnify the Bank, and their respective officers and employees against all
              liabilities, claims, demands, losses, damages, costs, charges, and expenses of any kind (including,
              without limitation, legal fees on a full indemnity basis) which may be incurred by any of them and all
              actions or proceedings which may be brought by or against any of them in connection with the provision of
              the Services, the Information and/or the Reports or the exercise or preservation of the Bank's powers and
              rights under these Terms and Conditions, unless due to the gross negligence or willful default of the
              Bank.</p>
          </li>
        </ol>
      </li>
      <li><p><strong>Variation and Termination</strong></p>
        <ol type="a">
          <li>

            <p>The Bank may, at any time, without giving notice or reason, suspend or terminate all or any of the
              Services or their use by the Customer.</p>
          </li>

          <li>

            <p>The Customer may terminate the use of the Services at any time by giving the Bank prior written
              notice.</p>
          </li>

          <li>

            <p>Notwithstanding such suspension or termination, the Customer shall continue to be bound by these Terms
              and Conditions to the extent that they relate to any obligations or liabilities of the Customer which
              remain to be performed or discharged. Any provisions which, in order to give effect to the obligations and
              covenants contemplated by these Terms and Conditions, shall survive and remain in full force and effect
              upon suspension and/or termination of the Services.</p>
          </li>
        </ol>
      </li>
      <li><p><strong>Amendments</strong></p>
        <p>
          The Bank may revise these Terms and Conditions and/or introduce additional terms and conditions at any time
          and from time to time. Any revision and/or addition to these Terms and Conditions shall become effective
          subject to the Bank giving reasonable notice to the Customer which may be given by posting it on the Internet
          Site(s) or by display, advertisement or other means as the Bank thinks fit, and shall be binding on the
          Customer if the Customer continues to maintain or use the Services on or after the effective date of
          variation.
        </p>
      </li>
      <li><p><strong>Communication </strong></p>
        <ol type="a">
          <li>

            <p>The Bank shall be entitled to prescribe, from time to time, the form of notice (whether written or any
              other form) and the mode of communication with respect to each type of notice to be given pursuant to
              these Terms and Conditions.</p>
          </li>

          <li>

            <p>Communications delivered personally, sent by post, facsimile transmission, telex, or email shall be
              deemed to have been received by the Customer:
              <br>- (where delivered personally) at the time of personal delivery or on leaving it at the address last
              notified in writing by the Customer to the Bank,
              <br>- (where sent by post) 48 hours after posting if such address is in the Democratic Socialist Republic
              of Sri Lanka and seven days after posting if such address is outside the Democratic Socialist Republic of
              Sri Lanka,
              <br>- (where sent by facsimile transmission, telex, or email) immediately after transmitting to the
              facsimile or telex number or email address last notified in writing by the Customer to the Bank.
              Communications sent by the Customer to the Bank shall be treated as delivered to the Bank on the day of
              actual receipt.</p>
          </li>
        </ol>
      </li>
      <li><p><strong>Severability</strong></p>
        <p>
          Each of the provisions of these Terms and Conditions is severable and distinct from the others and, if at any
          time one or more of such provisions is or becomes illegal, invalid or unenforceable in any respect under the
          laws of any jurisdiction, the legality, validity or enforceability of the remaining provisions shall not be
          affected in any way.
        </p>
      </li>
      <li><p><strong>Force Majeure</strong></p>
        <p>
          The Bank shall not be liable to the Customer for any delay in performing or failure to perform any of its
          obligations under this Agreement which is caused by circumstances beyond its reasonable control, including,
          but not limited to the failure, malfunction or unavailability of telecommunications, data communications and
          computer systems and services, civil unrest, government action or strikes, lock-outs or any industrial action
          or trade disputes. Any delay or failure of this kind, will not be deemed to be a breach of this Agreement.
        </p>
      </li>
      <li><p><strong>Waiver</strong></p>
        <p>
          No act, delay or omission by the Bank shall affect its rights, powers and remedies under these Terms and
          Conditions or any further or other exercise of such rights, powers or remedies. The rights and remedies under
          these Terms and Conditions are cumulative and not exclusive of the rights and remedies provided by law.
        </p>
      </li>
      <li><p><strong>Miscellaneous</strong></p>
        <ol type="a">
          <li>

            <p>Each party agrees not to object to the admission of the records (including computer records) as evidence
              in legal proceedings on the basis that the evidence is maintained in electronic form.</p>
          </li>

          <li>

            <p>Where the account in question is a joint account, the reference in these Terms and Conditions to
              'Customer' shall be deemed to mean all and each of the joint account holders. All the Customers shall be
              bound by these Terms and Conditions and be jointly and severally liable for all the transactions and
              dealings effected using the Services.</p>
          </li>
        </ol>
      </li>
      <li><p><strong>Governing Law and Jurisdiction</strong></p>
        <ol type="a">
          <li>
            <p>The Services and these Terms and Conditions shall be governed by and construed in accordance with the
              laws of the Democratic Socialist Republic of Sri Lanka.</p>
          </li>

          <li>
            <p>Each of the Bank and the Customer submits to the non-exclusive jurisdiction of the Courts of the
              Democratic Socialist Republic of Sri Lanka.</p>
          </li>
        </ol>
      </li>
    </ol>

    <h2>Terms and Conditions for Virtual Wallet</h2>
    <p>These terms and conditions shall govern the use of Virtual Wallet Services provided by PayMedia Bank PLC, a company
      duly incorporated in the Democratic Socialist Republic of Sri Lanka and having its registered office at No. 73/5,
      Galle Road, Colombo 02, Sri Lanka (hereinafter referred to as the bank) to a Customer, as hereinafter defined.</p>

    <h3>Definitions</h3>
    <p>In this document and pertaining to the use of Virtual Wallet, unless otherwise specified: </p>
    <ol type="a">
      <li><strong>“Add from Bank Account”</strong> means the Customer utilizing the funds in a Bank Account to increase
        the Customer’s Virtual Wallet Account balance.
      </li>
      <li><strong>“Bank Account”</strong> means each bank account held by the Customer with the bank which the Customer
        has nominated to be linked to the Customer’s Virtual Wallet Account.
      </li>
      <li><strong>“Bill Payment”</strong> means the Customer making payments through the Customer’s Virtual Wallet
        Account to utility service providers that are registered with the bank.
      </li>
      <li><strong>“Customer”</strong> means a customer who chooses to utilize the functions available in the Virtual
        Wallet System.
      </li>
      <li><strong>“Third Party”</strong> means any other party who may or may not be benefited by the services of the
        Virtual Wallet System; however are not existing customers of the same.
      </li>
      <li><strong>“Daily Limit”</strong> means the maximum monetary value that can be spent from a single Virtual Wallet
        during one day, where a day is a period of 24 hours commencing from 00.00 as per Sri Lanka Standard Time.
      </li>
      <li><strong>“Merchants”</strong> means the suppliers of goods and/or services who choose to utilize the functions
        available in the Virtual Wallet System for the purposes of their business by registering with the bank.
      </li>
      <li><strong>“Mobile Number”</strong> means the mobile number that is provided by the Customer in the Virtual
        Wallet Agreement to the bank for the purpose of registration on the Virtual Wallet System.
      </li>
      <li><strong>“Purchases”</strong> means a Customer procuring goods and/or services from a Merchant which is paid
        from the Customer’s Virtual Wallet Account.
      </li>
      <li><strong>“Request Money”</strong> means requesting funds to a Customer’s Virtual Wallet Account from that of
        another Customer’s Virtual Wallet Account.
      </li>
      <li><strong>“Third Party Fund Transfers”</strong> means the sending of funds to mobile numbers that are not
        registered as Virtual Wallets at PayMedia Bank PLC.
      </li>
      <li><strong>“Gift Cards”</strong> means the sending of Virtual gift certificates via the Virtual Wallet to be
        utilized at authorized wallet merchants by registered and non registered Virtual Wallet users.
      </li>
      <li><strong>“Cardless Withdrawals”</strong> means the facility to withdraw money from the Virtual Wallet via
        selected PayMedia ATMs.
      </li>
      <li><strong>“Cardless Balance Inquiry”</strong> means the facility to check the balance of the PayMedia Virtual Wallet
        via selected PayMedia ATMs.
      </li>
      <li><strong>“Return to Bank Account”</strong> means transferring funds from a Customer’s Virtual Wallet Account to
        the Customer’s Bank Account.
      </li>
      <li><strong>“Send Money”</strong> means transferring credit from a Customer’s Virtual Wallet Account to another
        Customer’s Virtual Wallet Account.
      </li>
      <li><strong>“Top Up”</strong> means crediting funds directly to a Customer’s Virtual Wallet Account through
        Merchant Virtual Wallets, PayMedia bank counters, PayMedia alternate channels, 3rd party self service machines.
      </li>
      <li><strong>“Transaction Limit”</strong> means the maximum monetary value that can be spent for each type of
        Transaction from a single Virtual Wallet Account during one day, where a day is a period of 24 hours commencing
        from 00.00 as per Sri Lanka Standard Time.
      </li>
      <li><strong>“Virtual Wallet Account”</strong> means the access granted to a Customer or Merchant to the Virtual
        Wallet System to utilize the Virtual Wallet Services.
      </li>
      <li><strong>“Virtual Wallet Transactions”</strong> means the use of the Virtual Wallet System via the App, any
        machine or device (self service or otherwise) for financial or non-financial transactions.
      </li>
      <li><strong>“Virtual Wallet System”</strong> means the technological creation owned and administered by the bank
        and its associated partners in relation to the provision of the Virtual Wallet Services.
      </li>
      <li><strong>“Withdrawal”</strong> means the withdrawal of cash by a Customer from their Virtual Wallet Account at
        Merchant locations, PayMedia bank counters, alternate channels and3rd party channels.
      </li>
    </ol>

    <h3>The Virtual Wallet System</h3>
    <ol type="a">
      <li>
        <p>The Virtual Wallet system is designed to enable the Customer to enter into Transactions.</p>
      </li>
      <li>
        <p>The terms and conditions set out in this Virtual Wallet Agreement govern the provision of the Virtual
          Wallet Service by the bank to the Customer and the use of the same by the Customer.</p>
        <p>The Customer is advised to call the bank’s call-centre or access the bank’s website at <a
            href="https://www.PayMedia.lk">www.PayMedia.lk</a> for further information pertaining to the Virtual Wallet System.
          In particular, any amendments to these terms and conditions shall be published on such website and notified to
          the Customer via electronic message.</p>
      </li>
    </ol>

    <h3>Commencement of Virtual Wallet Services</h3>
    <p>The Customer who completes and submits a Virtual Wallet Agreement and Application would be entitled to commence
      using the Virtual Wallet Services upon acceptance, registration and activation of the same on the Virtual Wallet
      System by the bank. The Bank may choose to register customers through a written application provided to a PayMedia
      Bank branch, through verbal request made via the PayMedia Bank hotline 0112350000 upon customer information
      verification, or any other means that the bank deems fit from time to time. It is the responsibility of the
      customer to inform the PayMedia bank of any changes to the contact information such as mobile number, email address or
      postal address.</p>

    <h3>User ID and Passwords</h3>
    <ol type="a">
      <li>
        <p>The User ID will be the Mobile Number and there will be no further notification by the bank of the User ID to
          the Customer.</p>
      </li>
      <li>
        <p>Passwords will be dispatched automatically by the bank through short message services to the registered
          Mobile Number and, where applicable, through in-app notifications. The Virtual Wallet System will prompt the
          Customer to change the password at first log in.</p>
      </li>
      <li>
        <p>In the event that the Customer wishes to change their Mobile Number, they should make a formal request in the
          prescribed form requesting a change of the Virtual Wallet Account details and the User ID.</p>
      </li>
      <li>
        <p>The responsibility of ensuring the confidentiality of such details rests with the Customer.
        </p>
      </li>
      <li>
        <p>It is the responsibility of the customer to change the password when prompted to do so.</p>
      </li>
    </ol>

    <h3>Requirements to become a Customer</h3>
    <ol type="a">
      <li>
        <p>Be a resident of Sri Lanka;</p>
      </li>
      <li>
        <p>Hold a smart phone with a compatible operating system.<br>
          Hold a mobile connection from a local mobile service provider under own name. In the event that the mobile
          connection is in the name of a person other than the Customer, the Customer shall submit a Consent Letter from
          such person, where such person consents to the Customer availing of the Virtual Wallet Services using such
          mobile connection;</p>
      </li>
      <li>
        <p>Be over 18 years of age; and</p>
      </li>
      <li>
        <p>Must fulfill all anti-money laundering and “Know Your Customer” requirements of the bank.<br>
          Notwithstanding the satisfaction of the aforesaid requirements, the bank shall have the sole discretion in
          approving the registration of any person as a Customer.</p>
      </li>
    </ol>

    <h3>Transacting through Virtual Wallet</h3>
    <ol type="a">
      <li>
        <p>The Virtual Wallet Service should be used only for the purpose of completing domestic transactions in
          Sri Lanka Rupees.</p>
      </li>
      <li>
        <p>The Virtual Wallet Service should be used only for lawful and legitimate purposes.</p>
      </li>
      <li>
        <p>The bank has the right to monitor and supervise transactions that take place using the Virtual Wallet
          System and reserves the right to suspend the availability of the service without giving notice to the
          Customer.</p>
      </li>
      <li>
        <p>The bank has the right to report suspicious transactions to the Financial Intelligence Unit
          established under the Financial Transactions Reporting Act Number 6 of 2006 and any other law enforcement
          authorities and other regulators as the case may be.</p>
      </li>
      <li>
        <p>Customers will not be entitled to any interest or to any profit for the funds residing in their
          Virtual Wallet Accounts.</p>
      </li>
      <li>
        <p>Customers accept all responsibilities for Transactions processed through the Bank Accounts that are
          linked to the Virtual Wallet.</p>
      </li>
      <li>
        <p>Customers may give instructions in the prescribed manner to dispute transactions and the bank shall
          exercise reasonable endeavors to give effect to the same, subject however that the bank shall not be
          responsible for any loss which is caused to the Customer as a result of acting on the original
          instructions.</p>
      </li>
      <li>
        <p>Virtual Wallet Accounts will be utilized by Customers only for the purpose of completing personal
          transactions and no transactions with commercial intent or association will be carried out through a
          Customer’s Virtual Wallet Account.</p>
      </li>
      <li>
        <p>Customer shall be subject to and shall comply with the Transaction Limit and Daily Limit as may be
          imposed by the bank.</p>
      </li>
      <li>
        <p>The bank shall maintain the confidentiality of customer information of the Customers, subject to any
          requirements to comply with law.</p>
      </li>
      <li>
        <p>In the event that the bank is of the view that there are suspicious or unintended transactions
          credited to or debited from a Virtual Wallet Account, the bank reserves the right to reverse or suspend such
          transactions until the bank’s investigations into the same are completed.</p>
      </li>
      <li>
        <p>In the event customer utilizes the third party fund transfer facility and the recipient does not avail the
          funds within 14 days, the said funds will be reverted back to the sender on the 15th day subjected to a fee
          that is decided by the bank. Refer section 7. b) </p>
        <p>In the event customer utilizes the third party fund transfer facility and if funds are sent to an unintended
          mobile number and if the funds are availed by an unknown party, the bank will not be held responsible. No
          reversals will be undertaken by the bank.</p>
      </li>
      <li>
        <p>In the event a recipient of a gift card redeems it at a virtual wallet merchant the below conditions will
          apply</p>
        <ul>
          <li> If the value of the gift card is below the bill amount the total gift card will be redeemed and the
            amount remaining will be considered as a bank fee
          </li>
          <li>If the bill value is above the gift card value and the recipient has any other gift cards from the same
            merchant; the second gift card will be automatically utilized by the system
          </li>
          <li>In the event the bill value is above the gift card value and the customer does not have any other gift
            cards for the same merchant, then the existing wallet balance will be utilized to pay of the additional
            amount of the bill.<br>
            In the case the bill value is above the gift card value and the recipient does not possess any other gift
            cards for the same merchant or a sufficient balance in the virtual wallet; the transaction will be rejected.
          </li>
        </ul>
      </li>
      <li>In the event customer utilizes the gift card facility and the gift card is sent to an unintended mobile number
        and if the funds are availed by an unknown party, the bank will not be held responsible. No reversals will be
        undertaken by the bank.
      </li>
      <li>In the event a recipient does not utilize a gift card by the expiry date, the funds will be reversed to the
        customer subject to a fee that will be decided by the bank. (Refer section 7. b)
      </li>
      <li>When utilizing the cardless cash withdrawal service via the PayMedia Bank ATM network; the customer will be
        eligible to withdraw cash only in the denominations available at the time in the ATM up to the value of funds
        available in the virtual wallet.
      </li>
      <li>When utilizing the cardless cash withdrawal service, in the event the physical cash is not properly dispensed;
        the customer shall immediately inform the nearest branch or contact PayMedia Call Centre on 0112350000.
      </li>
      <li>In the event the customer is unable to log in to the Virtual Wallet through an Automated Teller Machine or any
        other Virtual Wallet enabled device, the customer shall immediately inform the PayMedia Call Centre on 0112350000.
      </li>
      <li>In the case the customer is checking the balance through any other virtual wallet enabled device other than
        the mobile phone; note that the balance reflected is the existing balance of customer’s virtual wallet and not
        of any account linked to the said wallet.
      </li>
      <!-- Continue with the rest of the points -->
    </ol>
    <h3>Fees</h3>
    <ol type="a">
      <li>
        <p>The bank reserves the right to debit the Virtual Wallet Accounts and/or the Bank Accounts for the use
          of the Virtual Wallet Service and/or termination as per the tariff published by the bank from time to
          time.</p>
      </li>
      <li>
        <p>The tariff is available on www.PayMedia.lk and the bank reserves the right to amend the fees and charges
          applicable.</p>
      </li>
    </ol>

    <h3>Undertakings of the Customer</h3>
    <ol type="a">
      <li>
        <p>The safety of the mobile phone or other device utilized for the purpose of accessing the Virtual
          Wallet System lies with the Customer.</p>
      </li>
      <li>
        <p>The Customer shall maintain the confidentiality and security of the Virtual Wallet Account, which
          shall include the following actions:</p>
        <ol type="a">
          <li>Changing PIN codes when a mobile phone is changed</li>
          <li>Keeping the PIN code confidential</li>
          <li>Keeping the mobile phone IMEI code in a separate place</li>
        </ol>
      </li>
      <li>
        <p>Where the confidentiality and security of the Virtual Wallet Account is likely to have been
          compromised, it is the sole responsibility of the Customer to bring such instances to the notice of the bank
          immediately by contacting PayMedia Call Centre on 0112350000.</p>
      </li>
      <li>
        <p>All transactions, financial or non-financial, once completed using the User ID and PINs/passwords
          of a particular Virtual Wallet Account on the Virtual Wallet System are conclusive and binding.</p>
      </li>
      <li>
        <p>The bank need not seek further confirmation on the authenticity of transactions and will not be
          held liable for carrying out instructions that are validated by the User ID and PINs/passwords of a Customer
          whether at the point of transaction or where the customer had already registered the Virtual Wallet using
          PINs/passwords at merchant locations, app-stores, websites, any machine or device (self-service or otherwise)
          or similar instances.</p>
      </li>
      <li>
        <p>The Customer will not hold the bank responsible for not being able to transact due to the Virtual
          Wallet System, merchants System, mobile connections, machines and devices or any other system associated with
          the Virtual Wallet being unavailable for use at any time. The Customer will not hold the bank liable for the
          underlying transactions which the Customers have entered into with Merchants using the Virtual Wallet System.
          It is the responsibility of the Customer to take steps to confirm the completion of all aspects of a
          Transaction as at the point of such Transaction (That is, the payment or acceptance of cash or the provision
          of goods/service should be carried out simultaneously with the respective Transaction being carried out
          through the Virtual Wallet System).</p>
      </li>
      <li>
        <p>The bank shall be entitled to promote any of its products or any third-party products to the
          Customer through the Virtual Wallet System.</p>
      </li>
      <li>
        <p>The bank reserves the right to display the bank’s marketing material electronically on the Virtual
          Wallet System and conduct SMS promotions from time to time.</p>
      </li>
      <li>
        <p>The Customer agrees that by nominating a Bank Account to be linked to the Customer’s Virtual
          Wallet Account, the Customer is consenting to the bank debiting and crediting such Bank Account for the
          purposes of effecting the Transactions. The operating instructions given at the time of opening the said Bank
          Account may be contravened when debiting the account for the purpose of transacting through the Virtual Wallet
          Account of the Customer.</p>
      </li>
    </ol>

    <h3>Reporting Loss of Mobile Phone/Devices</h3>
    <ol type="a">
      <li>
        <p>A Customer shall immediately report the loss of a mobile phone/device to the bank in the
          prescribed manner.</p>
      </li>
      <li>
        <p>A Customer shall be responsible for any transactions which have been done by any unauthorized
          person prior to the bank being informed of the loss of the mobile phone/device and being given reasonable
          notice to act on such notification.</p>
      </li>
      <li>
        <p>The bank will not assume responsibility for any incident, transaction, loss of data or funds that
          may arise due to the loss of a mobile phone/device.</p>
      </li>
    </ol>

    <h3>Instructions and Confirmations</h3>
    <ol type="a">
      <li>
        <p>Instructions sent by the Customer to the bank will be considered received only when the request
          has reached an authorized office of the bank in the prescribed manner and the bank has had reasonable time for
          acting upon such notification.</p>
      </li>
      <li>
        <p>Any instructions to block, modify, vary or recommence the operations of a Virtual Wallet Account
          will take place only after such instructions have been verified by the bank.</p>
      </li>
      <li>
        <p>Any instructions or requests sent by the bank to the Customer will be considered received by the
          Customer after the expiry of three business days from the date of dispatch to the Customer.</p>
      </li>
      <li>
        <p>The bank will provide instructions and information through its call centre and its website at
          www.PayMedia.lk as to the prescribed manner and format in which the Customer is to notify the bank of specified
          events including but not limited to the reporting of lost mobile phones, charge back / stop payment requests,
          and termination notifications.</p>
      </li>
    </ol>

    <h3>Dispute Resolution</h3>
    <ol type="a">
      <li>
        <p>In the event of there being a dispute with regard to the Virtual Wallet Service, the Customer
          shall report the same to the bank and the bank shall assign a reference number to such dispute and exercise
          its best endeavors to settle the dispute within three business days.</p>
      </li>
      <li>
        <p>The bank shall also establish a call centre to respond to customer inquiries, disputes, and
          receive complaints.</p>
      </li>
      <li>
        <p>The bank may independently investigate the incident/s leading to the dispute/complaint and provide
          a resolution.</p>
      </li>
      <li>
        <p>In the event of any dispute not being settled within the time period set out in (a) above, the
          bank shall endeavor to settle such dispute through a process of mediation. Subject to the aforesaid with
          regard to mediation, the Virtual Wallet Service would be subject to the jurisdiction of the courts of Sri
          Lanka.</p>
      </li>
      <li>
        <p>The Virtual Wallet Service will be governed by Sri Lankan law.</p>
      </li>
    </ol>

    <h3>Termination</h3>
    <ol type="a">
      <li>
        <p>If the Customer wishes to terminate the use of the Virtual Wallet Account for whatever reason,
          they should inform the bank in writing and pay any fees or levies applicable for termination.</p>
      </li>
      <li>
        <p>The bank shall have the right to cease offering the Virtual Wallet Services to Customers at any
          given time by giving thirty days’ notice.</p>
      </li>
      <li>
        <p>The bank reserves the right to terminate, suspend, or place restrictions on any particular Virtual
          Wallet Account at its discretion without prior intimation for reasonable cause.</p>
      </li>
      <li>
        <p>The bank reserves the right to impose limits on Virtual Wallet Transactions.</p>
      </li>
      <li>
        <p>If there are any outstanding dues and or unpaid fees, such amounts shall be payable immediately
          prior to termination.</p>
      </li>
      <li>
        <p>A Customer may apply for one Virtual Wallet Account in respect of each mobile connection that
          complies with clause 5. The activation of a further Virtual Wallet Account shall not terminate the existing
          Virtual Wallet Account of the Customer.</p>
      </li>
      <li>
        <p>In the event that the Virtual Wallet Account is terminated for any reason other than for breach of
          the provisions of this Agreement by the Customer or contravention of applicable laws and regulations,
          including but not limited to anti-money laundering legislation by the Customer, the bank will disburse any
          funds lying to the credit of the Virtual Wallet Account (less any charges or dues) by way of a transfer to
          such account as may be specified by the Customer (any costs relating to such transfer being borne by the
          Customer) or in cash where a Customer personally visits any branch of the bank.</p>
      </li>
      <li>
        <p>In the event that the Virtual Wallet Account is terminated by reason of breach of the provisions
          of this Agreement by the Customer or by reason of contravention of applicable laws and regulations, including
          but not limited to anti-money laundering legislation by the Customer, the bank will be entitled to withhold
          payment till the completion of any investigation and/or prosecution conducted in relation thereto and/or make
          payments in such manner as may be prescribed by a regulatory authority. The bank may, at its absolute
          discretion without prior notice, cancel the Customer’s access to the Virtual Wallet System and/or refuse to
          provide access in case of replacement requests in the event that the bank is of the view that the Virtual
          Wallet Account is being used for illegal or unauthorized activities and/or purposes.</p>
      </li>
    </ol>
    <p>I hereby confirm and affirm that I am over 18 years of age and a resident of Sri Lanka and am the owner
      of/have been authorized by the owner of the lawfully acquired and registered mobile number in Sri Lanka provided
      to the bank in my application. I declare that I will be using the Virtual Wallet Account for personal transactions
      and will not use it for any illegal means.</p>

    <p>I have read and understood the terms and conditions herein and agree to abide by them always.</p>
    <h3>Terms and Conditions for Debit Card</h3>
    <ol type="1">
      <li>The Debit Card shall at all the times remain the property of PayMedia Bank PLC (hereinafter referred to as “the Bank”) and shall be returned to the Bank unconditionally and immediately upon the Bank’s request.</li>
      <li>The Debit Card is for my own personal use only. It is not transferable. It shall not be used for any purpose other than for transactions designated by the Bank.</li>
      <li>I shall keep my Personal Identification Number (PIN - secret number) strictly confidential and undertake not to reveal the number to any person at any time or under any circumstance.</li>
      <li>I shall accept the full responsibility of all transactions carried out by me and will use the PayMedia Visa Debit Card to carry out only legally valid transactions as instructed by the Bank and the governing laws of the country.</li>
      <li>I hereby authorise you to debit my account with the amount of any withdrawal / transfer / payment made by using the Debit Card with the use of my PIN, with or without my knowledge or authority.</li>
      <li>I further authorise you to debit my account with all charges relating to transactions made internationally through the Debit Card or through any other local network except the Bank’s Network, wherever applicable and also with any other liabilities inclusive of legal fees or other statutory charges, if any, relating to the use of the Debit Card.</li>
      <li>At no time shall I use or attempt to use the Debit Card for withdrawal / transfer or to effect transactions unless sufficient funds are available in my account/s. I agree that the Bank is under no obligation to honour my payment instructions unless there are sufficient funds in my designated account(s) at the time of receiving my payment instructions.</li>
      <li>If any account is a joint account, I shall be jointly and severally liable and indemnify the Bank from all transactions arising from the use of the PayMedia Visa Debit Card.</li>
      <li>I shall accept the Bank's records and statements relating to PayMedia Visa Debit Card as binding and conclusive.</li>
      <li>If the Debit Card is stolen or lost I shall notify the Bank immediately and I shall also give a written confirmation to the Bank. I shall not hold the Bank liable for any loss incurred by the use of the Debit Card that is lost, stolen or used without my authority.</li>
      <li>In the event the Debit Card is captured in a Non - PayMedia Bank Debit Card due to any reason, I agree for the Card to be immediately destroyed by the Debit Card acquirer and to be sent to PayMedia Bank. I agree to apply for a new card in such circumstances.</li>
      <li>I understand and agree that only my primary account will be debited when using the Debit Card at Point of Sale (POS) devices and non PayMedia Bank Debit Cards.</li>
      <li>The Bank shall at any time amend, supplement or vary any of these terms and conditions at its absolute discretion with or without notice to me and any such amendment, supplement or variation shall be binding on me.</li>
      <li>All subsequent PayMedia Visa debit cards issued on reasons specified above shall be subjected to the terms and conditions specified herein.</li>
      <li>The use of the Debit Card shall be subject to the Bank's prevailing Memorandum of Conditions, Rules, Regulations and any Terms and Conditions governing all services, facilities and transactions covered by the Debit Card or otherwise.</li>
      <li>The Bank shall have the full discretion to cancel, withdraw, or renew the Debit Card without any prior notice or any reasons given to me. In the event that I decide to terminate the use of the Debit Card I shall give the Bank not less than 07 days prior notice in writing and forthwith return the Debit Card and obtain a valid receipt thereof.</li>
      <li>The Bank shall determine the privileges attached to the use of the Debit Card and shall have absolute discretion to change, vary or amend these privileges and conditions attached thereto, from time to time, as the Bank deems fit.</li>
    </ol>
    <h3>Exchange Control Declaration - To the Controller of Exchange</h3>
    <ul>
      <li>I hereby undertake that the foreign exchange drawings / payments on the Debit Card will be used outside Sri Lanka only, for authorized purposes such as travel, expenses, hotel charges, incidental expenses, medical expenses and purchase of goods for personal use.</li>
      <li>I am aware that the Debit Card is not permitted to be utilised for payments in respect of capital transactions and / or purchase / import of goods in commercial quantities.</li>
      <li>In the event of my leaving the country either for good or for employment abroad I hereby undertake to surrender the Debit Card issued to me to PayMedia Bank.</li>
    </ul>
    <p>I hereby confirm and affirm that I am over 18 years of age and a resident of Sri Lanka and am the owner
      of/have been authorized by the owner of the lawfully acquired and registered mobile number in Sri Lanka provided
      to the bank in my application. I declare that I will be using the Virtual Wallet Account for personal transactions
      and will not use it for any illegal means.</p>

    <p ref="readAll">I have read and understood the terms and conditions herein and agree to abide by them always.</p>
    <div
        class="ml-4 flex-start align"
        style="justify-content: flex-start !important"
    >
      <b-field>
        <b-checkbox
            native-value="Terms_Conditions_read1"
            @input="isTermsConditionRead1=true"
            v-model="kycFormStore.formData.read_terms_condition1"
        ></b-checkbox>

        <div>
          <p class="en-style ml-4">
            I hereby acknowledge that I have read the PayMedia Bank Terms and Conditions.
            <span class="has-text-danger">*</span>
          </p>
        </div>
      </b-field>
    </div>


    <div
        class="ml-4 flex-start align"
        style="justify-content: flex-start !important"
    >
      <b-field>
        <b-checkbox
            native-value="Terms_Conditions_read2"
            @input="isTermsConditionRead2=true"
            v-model="kycFormStore.formData.read_terms_condition2"
        ></b-checkbox>

        <div>
          <p class="en-style ml-4">
            As such, I am aware of the obligations, liabilities and rights mentioned herein and accept the foregoing
            Terms & Conditions.
            <span class="has-text-danger">*</span>
          </p>
        </div>
      </b-field>
    </div>
    <br>
    <span
        class="has-text-danger ml-6 subhead-line"
        v-if="!kycFormStore.formData.read_terms_condition1||!kycFormStore.formData.read_terms_condition2"
    >Please read the above mentioned terms and condition to continue</span>
  </div>
</template>


<script>
import {useKycFormStore} from "@/stores/KycFormStore";

export default {
  data() {
    return {
      isTermsConditionRead1: false,
      isTermsConditionRead2: false,
      notClicked:true,

    };
  },
  setup() {
    const kycFormStore = useKycFormStore();
    return {kycFormStore};
  },

  methods: {
    handleClick() {
      this.$refs.readAll.scrollIntoView({ behavior: 'smooth' });
    },
  }


}


</script>

<style scoped>

</style>