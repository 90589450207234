/**
 * =====================================================================================================================
 * This class can be used to represent single PEP person detail
 */
export class PepPersonDetail {
    name = ""
    designation = ""

    constructor(name,designation) {
        this.name = name
        this.designation = designation
    }
}